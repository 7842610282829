export class Feature {
    id ?: number;
    Title ?: string;
    DefaultText ?: string;
    LinkText ?: string;
    Description ?: string;
    AdminNavigation ?: boolean;
    PortalNavigation ?: boolean;
    BusinessNavigation ?: boolean;
    UserNavigation ?: boolean;
    AdditionFeatures ?: boolean;
    Depends ?: boolean;
    FeatureFolders ?: boolean;
    Widgets ?: boolean;
    CustomText ?: string;
    Position ?: number;
    children ?: string;
    name ?: string;
    checked ?: boolean;
}

export enum FeatureKey {
    DEFAULT 	        = 	0,
    DASHBOARD 	        = 	1,
    EDIT_BUSINESS 	    = 	6,
    MY_PAGE 	        = 	7,
    EMPLOYEES 	        = 	8,
    USER_LIST 	        = 	9,
    CONTACTS 	        = 	10,
    CLIENT 	            = 	11,
    SUB_CONTRACTOR 	    = 	12,
    MANUALS 	        = 	13,
    ASSGIN_MANUALS 	    = 	14,
    FOLLOW_UP 	        = 	15,
    PROJECT 	        = 	16,
    CALENDAR_ACTIVITY 	= 	18,
    FDV 	            = 	18,
    INTERNAL_CONTROL 	= 	19,
    OTHER_SYSTEMS       =   42,
    QUALITY_SYSTEMS     =   41,
    FOOD_SAFETY 	    = 	20,
    KUBA_CONTROL 	    = 	43,
    VEHICLES 	        = 	21,
    FRAMEWORK_AGREEMENT = 	22,
    SERVICE_AGREEMENT 	= 	23,
    KUNDE_EL 	        = 	24,
    SUPPLIERS 	        = 	25,
    ARCHIVE 	        = 	26,
    ASSIGN_PROJECT 	    = 	27,
    INTERNAL_NEWS 	    = 	28,
    ADMIN_NEWS 	        = 	177,
    ADMIN_NEWS_MEDI3    =   177,
    EMPLOYEE_PRIVATE_DOCUMENTS =31,
    CUSTOMIZE_FAQ 	    = 	33,
    LOG 	            = 	33,
    INFO_VIDEO 	        = 	34,
    TRASH 	            = 	35,
    PROJECT_DEVIATION 	= 	36,
    ELECTRO 	        = 	36,
    PROJECT_TASK_MANAGER= 	37,
    PROJECT_TIME_TABLE 	= 	38,
    PROJECT_SAFETY_JOB_ANALYSIS=39,
    PROJECT_REGISTRATION = 	40,
    LICENSE_INFORMATION  = 	40,
    HANTVERKSDATA 	     = 	44,
    FDV_DEVIATION 	     = 	46,
    FDV_TIME_TABLE 	     = 	48,
    FDV_SAFETY_JOB_ANALYSIS = 	49,
    FDV_REGISTRATIONS 	 = 	50,
    IC_DEVIATION 	     = 	52,
    IC_TIME_TABLE 	     = 	54,
    IC_SAFETY_JOB_ANALYSIS = 55,
    IC_REGISTRATION 	 = 	56,
    FS_DEVIATION 	     = 	58,
    FS_TIME_TABLE 	     = 	60,
    FS_SAFETY_JOB_ANALYSIS = 61,
    FS_REGISTRATION 	 = 	62,
    MANUAL_CONTROL 	     = 	63,
    AUTOMATIC_TEMPERATURE = 64,
    VEHICLE_DEVIATION 	  = 70,
    VEHICLE_TIME_TABLE 	  = 72,
    VEHICLE_CONSUMPTION   = 73,
    VEHICLE_CHECKLISTS 	  =	74,
    VEHICLE_CONTROL 	  =	75,
    VEHICLE_TRACKING 	  =	76,
    FRAMEWORK_DEVIATION   =	78,
    FRAMEWORK_TASK_MANAGER=	79,
    FRAMEWORK_TIME_TABLE  = 80,
    FRAMEWORK_SAFETY_JOB_ANALYSIS   = 81,
    FRAMEWORK_REGISTRATION          = 82,
    SERVICE_AGREEMENT_DEVIATION     = 84,
    SERVICE_AGREEMENT_TASK_MANAGER  = 85,
    SERVICE_AGREEMENT_TIME_TABLE    = 86,
    SERVICE_AGREEMENT_SAFETY_JOB_ANALYSIS =	87,
    SERVICE_AGREEMENT_REGISTRATION  = 88,
    CONTRACT_DEVIATION 	    = 90,
    CONTRACT_TASK_MANAGER   = 91,
    CONTRACT_TIME_TABLE     = 92,
    CONTRACT_SAFETY_JOB_ANALYSIS = 93,
    CONTRACT_REGISTRATION    =  94,
    HMS_TV 	                 =	100,
    DLINKS 	                 =	101,
    ABOUT_KUBA_IK 	         =	102,
    INTERNAL_TV 	         =	103,
    KUBA_LOGO 	             =	103,
    PORTAL_TV 	             =	104,
    COMETLOGGER 	         =	104,
    SETTINGS 	             =	106,
    CALENDAR_MEETING 	     =	108,
    HOME_PAGE 	             =	109,
    LICENSE_AGREEMENT 	     =  110,
    TV 	                     =	110,
    ASSIGN_RIGHTS 	         =  111,
    FAQ 	                 =	114,
    LINKS 	                 =	117,
    EMPLOYEE_SICKNESS_ABSENCE= 	118,
    EMPLOYEE_WORK_EXPERIENCE = 	119,
    EMPLOYEE_LEAVE 	         = 	120,
    EMPLOYEE_TIMETABLE 	     = 	121,
    EMPLOYEE_COURSE_DETAILS  = 	122,
    EMPLOYEE_FIRST_OF_KIN 	 = 	123,
    COURSE_DETAILS 	         = 	126,
    WORK_EXPERIENCE 	     = 	127,
    SICKNESS_ABSENCE 	     = 	128,
    FIRST_OF_KIN 	         = 	129,
    LEAVE 	                 = 	131,
    CALENDAR 	             = 	133,
    MESSAGES 	             = 	134,
    ACTIVITIES 	             = 	135,
    DEVIATIONS 	             = 	136,
    CONSUMPTION 	         = 	137,
    RISK_ANALYSIS 	         = 	138,
    MEETING 	             = 	139,
    NOTE 	                 = 	140,
    SUGGESTION_BOX 	         = 	141,
    TASK_MANAGER 	         = 	145,
    TIME_TABLE 	             = 	146,
    SAFETY_JOB_ANALYSIS 	 = 	147,
    SJA_FORM 	             = 	170,
    FDV_DOKUMENTER_EL 	     = 	149,
    DECLARATION_OF_CONFORMITY= 	150,
    CREW_LIST 	             = 	151,
    CLEANING 	             = 	153,
    RECEPTION_CONTROL 	     = 	154,
    PRODUCTION 	             = 	155,
    CHECKLIST 	             = 	157,
    MEASURE_PROTOCOL 	     = 	160,
    REFERENCE_CHECKLIST 	 = 	161,
    SJA_CHECKLIST 	         = 	164,
    CLIENT_CONTACT 	         = 	165,
    CONTRACTOR_CONTACT 	     = 	166,
    SUPPLIER_CONTACT 	     = 	167,
    ACTIVITY_DONE 	         = 	168,
    DEVIATION_DONE 	         = 	169,
    ACTIVITY_DONE_MAIL 	     = 	170,
    DEVIATION_DONE_MAIL 	 = 	171,
    DEVIATION_DEPARTMENT_ONLY= 	172,
    RISK_ANALYSIS_EXTRA_SORTINGFIELD= 173,
    NEWS_FROM_PORTAL 	     = 	176,
    RUH 	                 = 	176,
    DEFAULT_MENUS 	         = 	178,
    SEND_ONLY_TO_DEPARTMENT  = 	179,
    TIME_LOG 	             = 	180,
    LOGGED_HISTORY 	         = 	190,
    APP_HISTORY 	         = 	191,
    PORTALLINKS 	         = 	195, 
    DOCUMENTED_TRAINING      =  196, 
    DOCUMENTED_TRAINING_MYPAGE =  197,
    APPROVAL_DOCUMENTS = 198,
    CONTRACT_DETAILS 	         = 	201,
    EMPLOYEE_CONTRACT_DETAILS  = 	202,
    FLEXI 	                = 	60,
}
export enum Rights { 
    ACTIVE_ACCOUNT 	                 = 	1,
    EDIT_MY_PAGE 	                 = 	2,
    EDIT_MANUALS 	                 = 	3,
    ADMINISTRATE_ACTIVITIES          =  4,
    ADMINISTRATE_RISK_ANALYSIS       =  5,
    ADMINISTRATE_PROJECTS            =  6,
    ADMINISTRATE_FDV 	             =  7,
    ADMINISTRATE_INTERNAL_CONTROL    =  8,
    ADMINISTRATE_INTERNAL_NEWS 	     =  9,
    APPROVE_ABSENSE 	             =  10,
    APPROVE_LEAVE 	                 =  11,
    APPROVE_TIMETABLE 	             =  12,
    ADD_NEW_EMPLOYEE 	             =  13,
    EDIT_DEPARTMENT_EMPLOYEE_RIGHTS  =  14,
    HIDE_EMPLOYEE_LIST 	             =  15,
    ADMINISTRATE_DEVIATION 	         =  16,
    VIEW_DEVIATION 	                 =  17,
    DISPLAY_OWN_DEVIATION_ONLY       =  18,
    SEE_ONLY_DEVIATION_IN_OWN_DEPT   =  19,
    DEVIATION_RIGHTS                 =  20,
    VIEW_MANUALS 	                 =	21,
    CHOOSE_PROJECTFDV                =	22,
    ADMINISTRATIVE_MACHINE           =  23,
    CHECKLIST_RIGHTS 	             =  24,
    READING_SJA_RIGHTS 	             =  25,
    FRAMEWORK_AGREEMENT_RIGHTS       =  26,
    SERVICE_AGREEMENT_RIGHTS         =	27,
    IK_KUNDE_EL_RIGHTS 	             = 	28,
    CHOOSE_KUNDE_EL                  = 	29,
    RESPONSIBLE_FOR_FOLLOWUP         =  30,
    OBSERVER 	                     = 	31,
    RECEIVE_EMAIL_FOR_COMPLETEDRUH 	 =  32,
    RECEIVE_EMAIL_FOR_CHANGES_IN_RUH =  33,
    RECEIVE_EMAIL_FOR_COMPLETEDRUH_OBSERVER = 34,
    RUH_EDITOR 	                    =   35,
    ADD_RUH_RIGHT                   =   36,
    SERVICE_LEADER_ELECTRO 	        =   37,
    PORTAL_MANAGER 	                = 	37,
    SERVICE_INSTALLER_ELECTRO        = 	38,
    ADMINISTRATE_FOOD_SAFETY        = 	38,
    ADMINISTRATE_ARCHIVE            = 	50,
    GUEST_CLIENT_ELECTRO 	        = 	40,
    HIDE_DEVIATIONS 	            = 	41,
    DEVIATIONS_READONLY 	        = 	42,
    EDIT_DEVIATIONS 	            = 	43,
    ADD_DEVIATIONS 	                = 	44, 
    DOCUMENTED_TRAINING_RIGHTS      =   45,
    APPROVAL_DOCUMENT_RIGHTS        =   46,
   // ADMINISTRATE_SJA_FORM           =   47,    
    ADMINISTRATE_OTHER_SYSTEMS      = 	48,
    ADMINISTRATE_QUALITY_SYSTEM     = 	49,
    ADMINISTRATE_KUBA_CONTROL       = 51,
    ADMINISTRATE_FLEXI              = 52
}

export enum Roles {
    ADMIN = 1,
    PORTAL = 2,
    EDITOR = 3,
    GUEST = 4,
    USER = 5
}

export enum HelpSupportCategory {
    STORY = 1,
    ARTICLES = 2,
    VIDEOS = 3,
    QUESTIONSANSWERS = 4,
    Email = 5
}
export enum LicenseCategory {
    DEMO_INTERNAL_USE = 1,
    MANUAL_WITHOUT_FOLLOWUP = 2,
    KUBA_BASIC = 3,
    KUBA_STANDARD = 4,
    FOOD_SAFETY = 5,
    KUBA_TOTAL = 6,
    ENTERPRISE = 7
}
export enum Application {
    IK = 1,
    BA = 2,
    DIN_HMS = 3,
    AHLSELL = 4,
    UNIPRO = 7
}

export enum Status {
    Active = 1,
    Inactive = 2,
    New = 7,
    Open = 8,
    Done = 9,
    Rejected = 10
}
