<div class="tab-content">
    <div class="page-title">
        <span translate>FIRST_OF_KIN</span>
        <span class="page-actions pull-right">
            <button (click)="viewVideo()" [disabled]="isVideo" class="btn btn-outline-secondary">
                <span class="icon ic-sm icon-circled-play"></span>
                <span translate>INFO_VIDEO</span>
            </button>
        </span>
    </div>
    <div class="table-view">
        <!-- Table starts -->
        <p-table #dt [value]="employeeFirstOfKinList" [rows]="10" [paginator]="true" [pageLinks]="3"
            [rowsPerPageOptions]="[5,10,20]"
            [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="ui-helper-clearfix">
                    <div class="table-options">
                        <div class="pull-left">
                        </div>
                        <div class="pull-right">
                            <div class="action-btns-group">
                                <kuba-export [reportData]="dt.filteredValue || dt.value" [additionalData]="additionalData"></kuba-export>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="EmployeeName">{{'EMPLOYEE_NAME'|translate}} <p-sortIcon field="EmployeeName"></p-sortIcon></th>
                    <th pSortableColumn="ContactName">{{'CONTACT_NAME' | translate}} <p-sortIcon field="ContactName"></p-sortIcon></th>
                    <th pSortableColumn="Mobile">{{'MOBILE' | translate}} <p-sortIcon field="Mobile"></p-sortIcon></th>
                    <th pSortableColumn="Email">{{'Email' | translate}} <p-sortIcon field="Email"></p-sortIcon></th>
                    <th pSortableColumn="Relation">{{'RELATION' | translate}} <p-sortIcon field="Relation"></p-sortIcon></th>
                    <th pSortableColumn="Address">{{'ADDRESS' | translate}} <p-sortIcon field="Address"></p-sortIcon></th>
                    <th pSortableColumn="ZipCode">{{'CITY_ZIP' | translate}} <p-sortIcon field="ZipCode"></p-sortIcon></th>
                </tr>
                <tr>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'EmployeeName', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-employee>
                <tr>
                    <td>
                        <span>{{employee.EmployeeName}}</span>
                    </td>
                    <td>
                        <span>{{employee.ContactName}}</span>
                    </td>
                    <td>
                        <span>{{employee.Mobile}}</span>
                    </td>
                    <td>
                        <span class="text-overflow" pTooltip="{{employee.Email}}">{{employee.Email}}</span>
                    </td>
                    <td>
                        <span>{{employee.Relation}}</span>
                    </td>
                    <td>
                        <span>{{employee.Address}}</span>
                    </td>
                    <td>
                        <span>{{employee.ZipCode}}</span>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="7">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table ends -->
    </div>
    <router-outlet></router-outlet>
</div>
<p-dialog header="{{'INFO_VIDEO' | translate}}" [(visible)]="isInfoVideo" [style]="{width: '500px'}" 
[resizable]="false" [draggable]="false">
    <div *ngIf="safeURL">
        <iframe width="560" height="315" [src]="safeURL" frameborder="0" allowfullscreen></iframe>
    </div>
</p-dialog>