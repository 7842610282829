<form [formGroup]="TimetableForm">
  <div class="form-group" *ngIf="isEmployeeName">
    <label for="lblEmployeeName" class="col-form-label">
      <span translate>EMPLOYEE_NAME</span>
      <span class="required">*</span>
    </label>
    <div *ngIf="isNotSubContractorAndContacts">
      <p-dropdown
        [options]="employeeDropDown"
        [readonly]="readonly"
        [style]="{ width: '160px' }"
        type="text"
        formControlName="EmployeeId"
      ></p-dropdown>
    </div>
    <div *ngIf="isSubContractorAndContacts">
      <input type="text" pInputText value="{{ this.name }}" readonly />
    </div>
    <control-messages
      [control]="TimetableForm.controls['EmployeeId']"
    ></control-messages>
  </div>
  <div class="form-group" *ngIf="isEmployeeTimetable && hasOrigin">
    <div>
      <label for="lblProjectName" class="col-form-label">
        <span translate>ORIGIN</span>
      </label>
      <div>
        <p-dropdown
          [options]="projectDropDown"
          [filter]="true"
          [readonly]="isReadOnly"
          [style]="{ width: '160px' }"
          type="text"
          formControlName="ProjectName"
        ></p-dropdown>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="TypeOfWork" class="col-form-label">
      <span translate>TYPE_OF_WORK</span>
      <span class="required">*</span>
    </label>
    <div class="input-btn-group">
      <p-dropdown
        [filter]="true"
        formControlName="TypeOfWorkId"
        [options]="typeOfWorkDropDown"
        [style]="{ width: '100%' }"
      ></p-dropdown>
      <button
        *ngIf="isHideButton"
        class="btn btn-outline-primary"
        type="button"
        (click)="addType = !addType"
      >
        <i class="pi pi-plus" aria-hidden="true"></i>
      </button>
    </div>
    <control-messages
      [control]="TimetableForm.controls['TypeOfWorkId']"
    ></control-messages>
  </div>
  <div class="form-group-50_50">
    <div class="form-group" *ngIf="TimetableForm.controls['StartDate']">
      <label for="lblStartDate" class="col-form-label">
        <span translate>START_DATE</span>
        <span class="required">*</span>
      </label>
      <div>
        <my-date-picker
          name="StartDate"
          [options]="startDateOptions"
          formControlName="StartDate"
          placeholder="{{ 'SELECT_START_DATE' | translate }}"
          (dateChanged)="onNewTimeTableStartDateChanged($event)"
        ></my-date-picker>
      </div>
      <control-messages
        [control]="TimetableForm.controls['StartDate']"
      ></control-messages>
    </div>
    <div class="form-group" *ngIf="TimetableForm.controls['StartTime']">
      <label for="lblStartTime" class="col-form-label">
        <span translate>START_TIME</span>
        <span class="required">*</span>
      </label>
      <div>
        <p-calendar
          [timeOnly]="true"
          readonlyInput="true"
          [style]="{ width: '160px' }"
          hourformat="24"
          required
          formControlName="StartTime"
          styleClass="dateTimeField"
          (onSelect)="startTimeChanged($event)"
          [defaultDate]="startTime"
        ></p-calendar>
      </div>
    </div>
  </div>
  <div class="form-group-50_50">
    <div class="form-group" *ngIf="hdnEndDate">
      <label for="lblEndDate" class="col-form-label">
        <span translate>END_DATE</span>
        <span class="required">*</span>
      </label>
      <div>
        <my-date-picker
          name="EndDate"
          (dateChanged)="onNewTimeTableEndDateChanged($event)"
          [options]="endDateOptions"
          formControlName="EndDate"
          placeholder="{{ 'SELECT_END_DATE' | translate }}"
          required
        ></my-date-picker>
      </div>
      <control-messages
        [control]="TimetableForm.controls['EndDate']"
      ></control-messages>
    </div>
    <div class="form-group" *ngIf="TimetableForm.controls['EndTime']">
      <label for="lblEndTime" class="col-form-label">
        <span translate>END_TIME</span>
        <span class="required">*</span>
      </label>
      <div>
        <p-calendar
          [timeOnly]="true"
          readonlyInput="true"
          [style]="{ width: '160px' }"
          hourformat="24"
          showTime="showTime"
          formControlName="EndTime"
          (onSelect)="endTimeChanged($event)"
          [defaultDate]="endTime"
        ></p-calendar>
      </div>
    </div>
  </div>
  <div
    class="form-group"
    *ngIf="
      TimetableForm.controls['HoursSpent'].value !== null &&
      isEmployeeParent === 'EMPLOYEE'
    "
  >
    <label for="lblBreaktime" class="col-form-label">
      <span translate>Break</span>
    </label>
    <div>
      <p-calendar
        [timeOnly]="true"
        readonlyInput="true"
        [style]="{ width: '160px' }"
        hourformat="24"
        showTime="showTime"
        formControlName="BreakTime"
        [defaultDate]="breakTime"
      ></p-calendar>
    </div>
    <control-messages
      [control]="TimetableForm.controls['BreakTime']"
    ></control-messages>
  </div>
  <div class="form-group" *ngIf="TimetableForm.controls['HoursSpent']">
    <label for="lblHoursSpent" class="col-form-label">
      <span translate>HOURS_SPENT</span>
    </label>
    <div>
      <input [attr.disabled]="true" pInputText formControlName="HoursSpent" />
    </div>
  </div>
  <div class="form-group">
    <label for="lblApprovalPersons" class="col-form-label">
      <span translate>APPROVAL_PERSON</span>
      <span class="required">*</span>
    </label>
    <div>
      <p-multiSelect
        [options]="approvalPersons"
        [style]="{ width: '100%', height: '30px' }"
        formControlName="ApprovalUserId"
      >
      </p-multiSelect>
    </div>
    <control-messages
      [control]="TimetableForm.controls['TimeTableApproverId']"
    ></control-messages>
  </div>
  <div class="form-group">
    <label for="lblComments" class="col-form-label">
      <span translate>COMMENTS</span>
    </label>
    <div>
      <textarea pInputTextarea formControlName="Comments"></textarea>
    </div>
  </div>
</form>
<p-dialog
  header="{{ 'ADD_TYPEWORK' | translate }}"
  [(visible)]="addType"
  [style]="{ width: '350px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforeTypeofWorkDialogHide()"
>
  <form [formGroup]="TypeofWorkPopup">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>TYPE</span>
        <span class="required">*</span>
      </label>
      <div>
        <input
          type="text"
          formControlName="typeofWork"
          class="label-input form-control"
          required
        />
        <span style="color: red" *ngIf="isNameExists">{{
          existsAlerttext
        }}</span>
      </div>
      <control-messages
        [control]="TypeofWorkPopup.controls['typeofWork']"
      ></control-messages>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewType = !viewType"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [ngClass]="{ loading: loading }"
        [disabled]="!TypeofWorkPopup.valid || loading"
        (click)="saveType()"
      >
        <span *ngIf="!loading" translate>SAVE</span>
        <span *ngIf="loading" translate>SAVE_PROCESS</span>
        <span *ngIf="loading" class="loader-icon"></span>
      </button>
    </div>
    <div *ngIf="viewType">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #dt
          [value]="editTypes"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "TYPE" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    dt.filter($any($event.target)?.value, 'Title', 'contains')
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-categoryType>
            <tr>
              <td>
                <span>{{ categoryType.Title }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editType(categoryType)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteType(categoryType.Id)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>
