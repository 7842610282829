import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { EmployeeFirstOfKin } from './../models/employeeFirstOfKin';
import { EmployeeFirstOfKinServices } from './employeefirstofkin.service';

@Injectable()
export class FirstOfKinListResolver implements Resolve<EmployeeFirstOfKin[]> {
    constructor(private backend: EmployeeFirstOfKinServices) {}
    resolve(route: ActivatedRouteSnapshot): Observable<EmployeeFirstOfKin[]> {
        return this.backend.getByBusiness();
    }
}
@Injectable()
export class InfoVideoResolverFirstOfKin implements Resolve<any> {
    constructor(private backend: EmployeeFirstOfKinServices) {}
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getVideoByAdditionalFeatureId(33);
    }
}
