import { Location } from '@angular/common';
import * as _ from 'lodash';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  FormArray,
} from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';

import {
  UploadFile,
  UploadInput,
  UploaderOptions,
  UploadOutput,
  UploadStatus,
} from 'ngx-uploader';
import { User } from './../../projects/models/user';
import { BusinessPropertyService } from './../../businesses/services/businessProperty.service';
import { BaseServices } from './../../kuba.services';
import {
  FireDeviation,
  BusinessProperty,
  PropertyObjects,
  FireDeviationUser,
  SaveContact,
  ChooseObject,
} from './../models/fdv';
import { ProjectServices } from './../../projects/services/project.service';
import { FDVServices } from './../services/fdv.service';
import { SelectItem } from 'primeng/api';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { environment } from 'src/environments/environment';
import { Rights } from 'src/app/_models';
import { HelperService } from 'src/app/_services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
@Component({
  templateUrl: 'fdvNew.component.html',
})
export class FdvNewComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @Input() myForm: FormGroup;
  isVisible = false;
  isQty = false;
  Email: string;
  Mobile: number;
  Ids: any;
  categories: any[];
  @Input() rowIndex: number;
  fdvForm: FormGroup;
  data: any;
  title: string;
  fdvId: number;
  fdvUsers = new FireDeviationUser();
  businessProperty = new BusinessProperty();
  fdv = new FireDeviation();
  businessPropertyId: number;
  allCategories = new FormArray([]);
  propertyObjectData: any;
  selectedUsers: any = [];
  typeOfObject: SelectItem[];
  contact: SelectItem[];
  propertyObjectUsers: any[];
  fdvUser: SelectItem[];
  chooseObject: SelectItem[];
  fdvImageUpload: string;
  fdvImage: string;
  email: string;
  sms: string;
  isFileChoosed = false;
  files: UploadFile[] = [];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  options: UploaderOptions;
  isHideSaveAndCreateButton = true;
  isAdministrateFDV: boolean;
  public selectedFiles: any;
  userId: number;
  uploadStart: boolean;
  isChooseProject = true;
  checkedAll: boolean;
  userSubscribers: SelectItem[];
  selectedSubscribers: SelectItem[];
  selectedEmailSubscriber = [];
  selectAllEmailSubscriber = [];
  emailUsers: any[];
  bindAllUsers = false;
  private subscriptions: Subscription[] = [];
  bindSelectedUsers = true;

  /**
   * constructor
   * @param formBuilder {{FormBuilder}}
   * @param location {{Location}}
   * @param fdvService {{FDVServices}}
   * @param projectService {{ProjectServices}}
   * @param route {{ActivatedRoute}}
   * @param busPropertyService {{BusinessPropertyService}}
   */
  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private fdvService: FDVServices,
    private projectService: ProjectServices,
    private route: ActivatedRoute,
    private busPropertyService: BusinessPropertyService,
    private http: HttpClient,
    private translate: TranslateService
  ) {
    this.userId = +BaseServices.UserId;
  }

  /**
   * go to FDV list
   */
  gotoBack() {
    this.location.back();
  }
  /**
   * edit fire deviaton and user
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    if (BaseServices.UserRole === 'Guest') {
      this.isAdministrateFDV = false;
      this.isHideSaveAndCreateButton = false;
    }
    this.selectedSubscribers = [];
    this.userSubscribers = [];
    let userSubscription = this.route.snapshot.data['users_subscription'];
    this.emailUsers = userSubscription;
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.userSubscribers = [];
        if (userSubscription) {
          userSubscription.forEach((element: any) => {
            this.selectedSubscribers.push({
              label: element.Name,
              value: {
                Id: element.Id,
                FeatureKey: element.FeatureKey,
                FeatureId: element.FeatureId,
              },
            });
            // all options for email/sms dropdown
            if (element.Id == BaseServices.UserId) {
              this.userSubscribers.push({
                label: element.Name,
                value: {
                  Id: element.Id,
                  FeatureKey: element.FeatureKey,
                  FeatureId: element.FeatureId,
                },
              });
            }
          });
        }
      })
    );
    /**
     * get user list by business id
     */
    let users = this.route.snapshot.data['contact'];
    if (users) {
      this.propertyObjectUsers = users;
    }
    this.uploadInput = new EventEmitter<UploadInput>();
    this.fdvId = this.route.snapshot.params['id'];
    this.fdvImageUpload = `${environment.BASE_URL}/file/upload/business/logo/${BaseServices.ApplicationId}/${this.fdvId}`;

    /**
     * edit FDV by id
     */
    if (this.fdvId) {
      this.fdv = this.route.snapshot.data['edit'];
      if (this.fdv) {
        this.isChooseProject = this.fdv.ProjectTemplateId > 0 ? false : true;
        this.initForm(this.fdv);
      }
    } else {
      this.initForm();
    }
    this.bindDropDowns();
    this.onAdministrateFDV();
  }

  checked(value) {
    if (value.status) {
      this.selectedUsers.push(value);
      if (this.emailUsers) {
        this.emailUsers.forEach((element: any) => {
          if (
            element.Id === value.Id &&
            value.Id !== BaseServices.UserId &&
            !value.isEmail
          ) {
            this.userSubscribers.push({
              label: element.Name,
              value: {
                Id: element.Id,
                FeatureKey: element.FeatureKey,
                FeatureId: element.FeatureId,
              },
            });
          }
        });
      }
    } else {
      _.remove(this.selectedUsers, (val) => {
        if (this.userSubscribers) {
          _.forEach(this.userSubscribers, (item: any) => {
            if (item.value.Id == value.Id) {
              this.userSubscribers.pop();
            }
          });
        }
        return val === value;
      });
    }
  }

  checkAll(event) {
    _.forEach(this.propertyObjectUsers, (item: any) => {
      if (event) {
        item.status = true;
      } else {
        item.status =
          // to remain checked state for current user
          this.userId === +item.Id ? true : false;
      }
    });

    this.selectedUsers = this.propertyObjectUsers;
    if (!event) {
      this.selectedUsers = [];
      this.bindAllUsers = false;
      this.bindSelectedUsers = true;
    } else {
      this.bindAllUsers = true;
      this.bindSelectedUsers = false;
    }
  }

  /**
   * bind dropdowns
   */
  bindDropDowns() {
    this.typeOfObject = [];
    let typeOfObjects = this.route.snapshot.data['typeOfObject'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.typeOfObject = [];
        this.typeOfObject.push({ label: val.SELECT, value: '' });
        if (typeOfObjects) {
          typeOfObjects.forEach((element: any) => {
            this.typeOfObject.push({ label: element.Name, value: element.Id });
          });
        }
      })
    );

    this.contact = [];
    this.fdvUser = [];
    let contacts = this.route.snapshot.data['contact'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.contact = [];
        this.contact.push({ label: val.SELECT, value: '' });
        if (contacts) {
          contacts.forEach((element: any) => {
            this.fdvUser.push({ label: element.Name, value: element.Id });
            this.contact.push({ label: element.Name, value: element.Id });
          });
        }
      })
    );

    // choose object dropdown
    this.chooseObject = [];
    let chooseObjects = this.route.snapshot.data['chooseObject'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.chooseObject = [];
        this.chooseObject.push({ label: val.SELECT, value: '' });
      })
    );
    if (chooseObjects) {
      chooseObjects.forEach((element: any) => {
        this.chooseObject.push({ label: element.Name, value: element.Id });
      });
    }
  }
  /**
   * edit Fdv deviation and user
   * @param fdvData {{any}}
   */
  initForm(fdvData?: any) {
    let objectType = '';
    let objectName = '';
    let apartments = '';
    let floor = '';
    let address = '';
    let qty = '';
    let id = '';
    let zip = '';
    let location = '';
    let contact = '';
    let description = '';
    let isEmail = '';
    let isSms = '';
    let businessDescription = '';
    let chooseObject = '';
    let fdvImage = '';
    let copySpj: boolean;
    let IsShowDeviationChecked = false;
    let allCategories: FormArray = new FormArray([]);
    let propertyObjects = this.route.snapshot.data['propertyObject'];
    let selectedEmailSubscriber = this.selectedEmailSubscriber;
    let selectAllEmailSubscriber = this.selectAllEmailSubscriber;
    if (propertyObjects) {
      this.categories = propertyObjects;
    }
    this.checkAll(false);
    for (let i = 0; i < this.categories.length; i++) {
      let fg = new FormGroup({});
      let isChecked = false;
      let quantity = 0;
      let data = null;
      if (fdvData) {
        data =
          fdvData.BusinessProperty && fdvData.BusinessProperty.PropertyObjects
            ? fdvData.BusinessProperty.PropertyObjects.filter(
                (x: any) => x.BusinessPropertyObjectId === this.categories[i].Id
              )
            : null;
        isChecked = data
          ? fdvData.BusinessProperty.PropertyObjects.findIndex(
              (x: any) => x.BusinessPropertyObjectId === this.categories[i].Id
            ) > -1
          : false;
      }
      if (this.categories[i].Id === 7) {
        let dataQty = data
          ? fdvData.BusinessProperty.PropertyObjects.filter(
              (x: any) => x.BusinessPropertyObjectId === 7
            )
          : 0;
        quantity = dataQty.length > 0 ? dataQty[0].Quantity : 0;
        fg.addControl('Quantity', new FormControl(quantity));
      }
      fg.addControl(`${this.categories[i].Id}`, new FormControl(isChecked));
      allCategories.push(fg);
    }

    if (allCategories) {
      if (fdvData) {
        this.fdvImage = fdvData.ImagePath ? fdvData.ImagePath : null;
        this.title = fdvData.Title;
        objectType = fdvData.BusinessProperty.BusinessPropertyTypeId
          ? fdvData.BusinessProperty.BusinessPropertyTypeId
          : '';
        objectName = fdvData.Title ? fdvData.Title : '';
        apartments = fdvData.BusinessProperty.ApartmentCount
          ? fdvData.BusinessProperty.ApartmentCount
          : '';
        floor = fdvData.BusinessProperty.FloorCount
          ? fdvData.BusinessProperty.FloorCount
          : '';
        address = fdvData.BusinessProperty.Address
          ? fdvData.BusinessProperty.Address
          : '';
        zip = fdvData.BusinessProperty.Zip ? fdvData.BusinessProperty.Zip : '';
        location = fdvData.BusinessProperty.Location
          ? fdvData.BusinessProperty.Location
          : '';
        contact = fdvData.ContactId ? fdvData.ContactId : '';
        description = fdvData.Description ? fdvData.Description : '';
        IsShowDeviationChecked = !fdvData.ShowDeviation;
        businessDescription = fdvData.BusinessProperty.Description
          ? fdvData.BusinessProperty.Description
          : '';
        if (fdvData.FireDeviationUserDto) {
          // set selected users
          fdvData.FireDeviationUserDto.forEach((element: any) => {
            let selectedUsers = this.propertyObjectUsers!.find(
              (x) => x.Id === element.UserId
            );
            if (selectedUsers) {
              selectedUsers.status = true;
              selectedUsers.isEmail = false;
              this.checked(selectedUsers);
              if (element.IsEmail) {
                this.selectedEmailSubscriber.push({
                  Id: element.UserId,
                  FeatureKey: element.FeatureKey,
                  FeatureId: element.FeatureId,
                });
                this.selectAllEmailSubscriber.push({
                  Id: element.Id,
                  FeatureKey: element.FeatureKey,
                  FeatureId: element.FeatureId,
                });
              }
            }
          });
        }
        // isEmail = fdvData.IsEmail;
        // isSms = fdvData.IsSMS;
        // this.Mobile = fdvData.ContactDto.Mobile;
        // this.Email = fdvData.ContactDto.Emailid;
        // this.isVisible = true;
        chooseObject = fdvData.chooseObject ? fdvData.chooseObject : '';
        copySpj = fdvData.copySpj ? fdvData.copySpj : '';
        fdvImage = fdvData.ImagePath ? fdvData.ImagePath : '';
      }

      this.fdvForm = this.formBuilder.group({
        objectType: new FormControl(objectType, Validators.required),
        objectName: new FormControl(objectName, Validators.required),
        apartments: new FormControl(apartments),
        floor: new FormControl(floor),
        address: new FormControl(address),
        zip: new FormControl(zip),
        location: new FormControl(location),
        contact: new FormControl(contact),
        description: new FormControl(description),
        businessDescription: new FormControl(businessDescription),
        checkboxProperties: allCategories,
        // isEmail: new FormControl(isEmail),
        // isSms: new FormControl(isSms),
        chooseObject: new FormControl(chooseObject),
        copySpj: new FormControl(copySpj),
        IsShowDeviationChecked: new FormControl(IsShowDeviationChecked),
        selectedEmailSubscriber: new FormControl(selectedEmailSubscriber),
        selectAllEmailSubscriber: new FormControl(selectAllEmailSubscriber),
      });
    }
  }

  filesSelect(selectedFiles: any): void {
    this.uploadStart = true;
    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(
      (file) => file
    );
    let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);
    let session = JSON.parse(sessionStorage.getItem('session'));
    let token = session.AccessToken;
    var headers = {
      Authorization: 'Bearer ' + token, // Add JWT token to headers
      // Add any other headers if needed
    };

    this.subscriptions.push(
      this.http
        .post(
          environment.BASE_URL +
            '/firedeviation/file/upload/fdv-image/' +
            BaseServices.ApplicationId +
            '/' +
            this.fdvId,
          formData,
          { headers: headers }
        )
        .map((response: any) => {
          this.uploadStart = false;
          let res = response;
          this.fdvImage = res.path;
        })
        .subscribe(
          (result) => {},
          (error) => console.log('Upload article Sign Error : ', error),
          () => console.log('Upload article  Sign Complete')
        )
    );
  }
  /**
   * file upload
   */
  onUpload(event: any) {
    let responce = JSON.parse(event.xhr.response);
    this.fdvImage = responce.Result.Uri;
  }

  /**
   * save FDV and create new
   */
  saveAndCreateNew() {
    // this.saveFdv();
    this.initForm();
    this.selectedUsers = [];
    this.fdvImage = '';
  }
  /**
   * save/update FDVs
   */
  saveFdv(mode: string) {
    let userId = BaseServices.UserId;
    let name = BaseServices.userName;
    let checkUserId = this.selectedUsers.filter((x) => x.Id == userId);
    if (checkUserId.length === 0) {
      this.selectedUsers.push({ Id: userId, Name: name });
    }
    if (this.fdvForm.valid) {
      if (this.fdvId) {
        // update
        // this.fdv.IsEmail=this.fdvForm.value.isEmail;
        // this.fdv.IsSMS=this.fdvForm.value.isSms;
        this.fdv.Description = this.fdvForm.value.description;
        this.fdv.Name = this.fdvForm.value.objectName;
        this.fdv.Status = '1';
        this.fdv.ShowDeviation = !this.fdvForm.value.IsShowDeviationChecked;
        this.fdv.BusinessProperty.Name = this.fdvForm.value.objectName;
        this.fdv.BusinessProperty.BusinessPropertyTypeId =
          this.fdvForm.value.objectType;
        this.fdv.BusinessProperty.ApartmentCount =
          this.fdvForm.value.apartments;
        this.fdv.ContactId = this.fdvForm.value.contact;
        this.fdv.BusinessProperty.FloorCount = this.fdvForm.value.floor;
        this.fdv.BusinessProperty.Address = this.fdvForm.value.address;
        this.fdv.BusinessProperty.Zip = this.fdvForm.value.zip;
        this.fdv.ImagePath = this.fdvImage;
        this.fdv.ProjectTemplateId = this.fdvForm.value.chooseObject;
        this.fdv.CopySja = this.fdvForm.value.copySpj;
        this.fdv.BusinessProperty.Location = this.fdvForm.value.location;
        this.fdv.BusinessProperty.ModifiedBy = BaseServices.UserId;
        this.fdv.BusinessProperty.ModifiedOn = new Date();
        this.fdv.BusinessProperty.Status = '1';
        this.fdv.BusinessProperty.ContactId = this.fdvForm.value.contact;
        this.fdv.BusinessProperty.Description =
          this.fdvForm.value.businessDescription;
        this.fdv.BusinessProperty.logopath = this.fdvForm.value.image;
        let fdvUsers: FireDeviationUser = new FireDeviationUser();
        this.fdvUsers.FireDeviationId = this.fdvId ? this.fdvId : 0;
        let Ids: any = ([] = []);
        this.selectedUsers.forEach((element: any) => {
          Ids.push({
            UserId: element.Id,
            FireDeviationId: this.fdvUsers.FireDeviationId,
            Name: element.Name,
          });
        });
        this.fdv.FireDeviationUserDto = Ids;
        this.fdv.ContactDto.Id = this.fdvForm.value.contact
          ? this.fdvForm.value.contact
          : 0;
        this.fdv.BusinessProperty.PropertyObjects = [];
        this.fdvForm.value.checkboxProperties.forEach((x: any, index: any) => {
          let id = index + 1;
          if (x[id]) {
            let propertyObj = new PropertyObjects();
            propertyObj.BusinessPropertyId = 0; // assign proper businessProperty id
            propertyObj.BusinessPropertyObjectId = id;
            propertyObj.Quantity = id === 7 && x.Quantity ? x.Quantity : 0;
            this.fdv.BusinessProperty.PropertyObjects.push(propertyObj);
          }
        });
      } else {
        this.fdv.Id = 0;
        // this.fdv.IsEmail=this.fdvForm.value.isEmail;
        // this.fdv.IsSMS=this.fdvForm.value.isSms;
        this.fdv.BusinessPropertyId = 0;
        this.fdv.BusinessId = BaseServices.BusinessId;
        this.fdv.CreatedBy = BaseServices.UserId;
        this.fdv.Status = '1';
        this.fdv.CreatedOn = new Date();
        this.fdv.Title = this.fdvForm.value.objectName;
        this.fdv.ProjectTemplateId = this.fdvForm.value.chooseObject;
        this.fdv.CopySja = this.fdvForm.value.copySpj;
        this.fdv.Description = this.fdvForm.value.description;
        this.fdv.ShowDeviation = !this.fdvForm.value.IsShowDeviationChecked;
        this.fdv.ContactId = this.fdvForm.value.contact;
        this.fdv.ImagePath = this.fdvImage;
        this.fdv.Name = this.fdvForm.value.objectName;
        this.fdv.BusinessPropertyId = this.fdvForm.value.objectType;
        let fdvUsers: FireDeviationUser = new FireDeviationUser();
        this.fdvUsers.FireDeviationId = this.fdvId ? this.fdvId : 0;
        let Ids: any = ([] = []);
        this.selectedUsers.forEach((element: any) => {
          Ids.push({
            UserId: element.Id,
            FireDeviationId: this.fdvUsers.FireDeviationId,
            Name: element.Name,
          });
        });
        this.fdv.FireDeviationUserDto = Ids;
        let businessProperty = new BusinessProperty();
        businessProperty.BusinessId = BaseServices.BusinessId;
        businessProperty.Name = this.fdvForm.value.objectName;
        businessProperty.BusinessPropertyTypeId = this.fdvForm.value.objectType;
        businessProperty.CreatedBy = BaseServices.UserId;
        businessProperty.CreatedOn = new Date();
        businessProperty.ApartmentCount = this.fdvForm.value.apartments;
        businessProperty.FloorCount = this.fdvForm.value.floor;
        businessProperty.Address = this.fdvForm.value.address;
        businessProperty.Zip = this.fdvForm.value.zip;
        businessProperty.Status = '1';
        businessProperty.Location = this.fdvForm.value.location;
        businessProperty.ContactId = this.fdvForm.value.contact;
        businessProperty.Description = this.fdvForm.value.businessDescription;
        businessProperty.logopath = this.fdvImage;
        this.fdv.BusinessProperty = businessProperty;
        this.fdv.BusinessProperty.PropertyObjects = [];
        this.fdvForm.value.checkboxProperties.forEach((x, index) => {
          let jsonKey = Object.keys(x)[0];
          let id = +jsonKey;
          if (x[jsonKey]) {
            let propertyObj = new PropertyObjects();
            propertyObj.BusinessPropertyId = 0; // assign proper businessProperty id
            propertyObj.BusinessPropertyObjectId = id;
            propertyObj.Quantity = id === 7 && x.Quantity ? x.Quantity : 0;
            this.fdv.BusinessProperty.PropertyObjects.push(propertyObj);
          }
        });
        let contacts = new SaveContact();
        contacts.Id = this.fdvForm.value.contact
          ? this.fdvForm.value.contact
          : 0;
        this.fdv.ContactDto = contacts;
      }
      if (
        this.bindSelectedUsers &&
        this.fdvForm.value.selectedEmailSubscriber
      ) {
        this.fdv.FDVEmailSubscribers = [];
        let emailSubscriber = this.fdvForm.value.selectedEmailSubscriber;
        emailSubscriber.map(function (x) {
          x.IsChecked = true;
          return x;
        });
        this.fdv.FDVEmailSubscribers.push(...emailSubscriber);
      }
      if (this.bindAllUsers && this.fdvForm.value.selectAllEmailSubscriber) {
        this.fdv.FDVEmailSubscribers = [];
        let emailAllSubscriber = this.fdvForm.value.selectAllEmailSubscriber;
        emailAllSubscriber.map(function (x) {
          x.IsChecked = true;
          return x;
        });
        this.fdv.FDVEmailSubscribers.push(...emailAllSubscriber);
      }
      this.subscriptions.push(
        this.fdvService.addFdv(this.fdv).subscribe((result) => {
          if (result) {
            this.toasterComponent.callToast();
            if (mode && mode === 'RESET') {
              this.reset();
            } else {
              this.location.back();
            }
          }
        })
      );
    }
  }
  /**
   * reset form
   */
  reset() {
    this.fdvId = 0;
    this.fdvForm.reset();
    this.selectedUsers = [];
    this.fdvImage = '';
  }
  /**
   * number key press event
   */
  onNumberChange(e: any, limitNumber: any) {
    const limit = limitNumber;
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      (charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      e.preventDefault();
    }
  }

  onAdministrateFDV() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_FDV;
    if (currentUserRole === 'User') {
      this.isAdministrateFDV = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
      this.isHideSaveAndCreateButton = false;
    } else {
      this.isAdministrateFDV = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
