import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Module } from '../../follow-ups/models/deviation';

import { Faq } from './../models/faqs';
import { FaqServices } from './faqs.service';

@Injectable()
export class MenuDropDownResolver implements Resolve<Faq> {
    Id: number;
    constructor(private backend: FaqServices) {}
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getInfoVideoMenu();
    }
}
@Injectable()
export class SubMenuDropDownResolver implements Resolve<Faq> {
    Id: number;
    constructor(private backend: FaqServices) {}
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getInfoVideoSubMenu(Module.EMPLOYEES);
    }
}
