import { Execution } from './../models/activities';
import { FeatureKey } from './../../../_models/feature';
import { ActivityServices } from './../services/activities.services';
import { FeatureBase } from './../../features/models/features-base.model';
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    EventEmitter
} from '@angular/core';
import { BusinessDepartmentService } from './../../business-department.services';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { JobAnalysisService } from '../../safety-job-analysis/services/jobanalysis.services';
import { ConfirmationService } from 'primeng/api';
import { UploadOutput, UploadFile, UploadStatus } from 'ngx-uploader';
import { UploadInput } from 'ngx-uploader';
import {
    TaskPlanList,
    TaskPlan,
} from '../../safety-job-analysis/models/taskplan';
import { environment } from 'src/environments/environment.prod';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { BaseServices, KubaServices } from './../../kuba.services';
import { HelperService } from './../../../_services/helper.service';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { RiskanalysisService } from '../services/risk-analysis.service';
import {
    Department,
    Topic,
    ActivitySchedule,
    Riskanalysis,
    RiskActivity,
    RiskAnalysisAttachment,
    ActivityAttachment,
    RiskAnalysisCategory
} from './../models/riskanalysis';
import { SafetyBaseComponent } from '../../safety-job-analysis/components/safety-base.component';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Options } from 'src/app/_models/options';
import { Rights } from 'src/app/_models';
import { ArchiveServices } from 'src/app/kuba/archive/archive.services';
import * as _ from 'lodash';
import { UserDropdownSelectTranslate } from './../../../_helpers/pipes';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { element } from 'protractor';
import { Category } from 'src/app/kuba/businesses/models';
import { result, values } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';


@Component({
    selector: 'riskanalysis-New',
    templateUrl: 'riskanalysis-new.component.html',
    styleUrls: [
        'riskanalysis-new.component.css',
        'riskanalysis-list.component.css'
    ]
})
export class NewRiskAnalysisComponent extends SafetyBaseComponent
    implements OnInit {
    //#region variable

    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    @Input() riskForm: FormGroup;
    isSorting = true;
    isArchive = false;
    uploadedRiskFiles = [];
    riskUploadedFiles: UploaderConfig;
    newUploadedFiles: ActivityAttachment[] = [];
    upConfig: UploaderConfig;
    upRiskConfig: UploaderConfig;
    taskId: number;
    additionalData: any;
    riskAdditionalData: any
    title: string;
    dragOver: boolean;
    actionplanForm: FormGroup;
    isFileChoosed = false;
    FollowUpDays: any[];
    showNotification = false;
    isActionExist = false;
    activityScheduleId: number;
    currentDeadLine: Date;
    currentDate: Date;
    dateDiff: number;
    deletedImage = [];


    options = [
        new Options(8, 'OPEN'),
        new Options(9, 'DONE'),
        new Options(10, 'REJECTED')
    ];
    riskanalysisId: number;
    RiskEditTopicPopUp: FormGroup;
    files: UploadFile[] = [];
    RiskEditDeptPopUp: FormGroup;
    uploadInput: EventEmitter<UploadInput>;
    figureValue: any;
    Topics: SelectItem[];
    departments: any[];
    IsEditRisk = false;
    isVisibleSave = true;
    executions: SelectItem[];
    followUps: SelectItem[];
    locale: string = this.translator.currentLang
        ? this.translator.currentLang
        : 'en';
    notifyBeforeDeadLineMaxValue = '7';
    selectedTopic = new Topic();
    editDepartments: any = [];
    editTopics: any = [];
    status: string;
    showViewLog = false;
    actvityFilePath: string;
    documentPath: string;
    actionPlanList: any = [];
    actionPlan = new TaskPlan();
    riskanalysis: any;
    activityRowIndex: number;
    activitylist: any = [];
    addTopicType: boolean;
    addDept: boolean;
    viewType: boolean;
    featureBase: FeatureBase;
    viewTopicType: boolean;
    IsActive: boolean;
    topicId: number;
    departmentId: number;
    sorting: SelectItem[];
    activityDocument: any;
    Sorting: string;
    isAdministrateRiskAnalysis = true;
    showArchiveDocumentDailog = false;
    treeNode: any;
    editMode = false;
    treeActiveNode: any;
    selectFolderId?: number;
    selectFolderName?: string;
    addType = false;
    public selectedFiles: any;
    loading: boolean;
    documentName: string;
    createdby: string;
    createdon: string;
    createdOnForSave: Date;
    time: string;
    activityLogData: any = [];
    fileName: string;
    attachmentUrl: string;
    attachment: any[];
    currentBusinessId: number;
    roleId: any;
    isTaskAdded = false;
    activityDocumentObject: any;
    newActivityDocumentObject: any;
    existingDocument: any;
    departmentExisting: string;
    departmentAlerttext: string;
    isdepartmentExists = false;
    isdepartmentAlert = false;
    topicExisting: string;
    topicAlerttext: string;
    istopicNameExists = false;
    istopicExists = false;
    showNotificationForTask = false;
    newDocumentAdded: any = [];
    actionCreatedBy: number;
    actionModifiedBy: number;
    actionCreatedOn: Date;
    riskArchieveDocument: any;
    uploadedArchieveFiles: any = [];
    loadingForNewRisk: boolean;
    loadingForSaveAndActivity: boolean;
    loadingForAttachment: boolean;
    createdUserId: number;
    taskIndex: number;
    loadingReport = false;
    FromRiskAnalysis = 1;
    NoRepetition = 1;
    addCategory: boolean;
    categoryId: number;
    categoryExisting: string;
    iscategoryExists = false;
    RiskCategoryPopUp: FormGroup;
    viewCategory: boolean;
    Category: SelectItem[];
    editCategory: any = [];
    categoryAlert: string;
    isCategoryExistsInAnotherRA = false;
    ActionPlanInfo: boolean;
    isReadOnly = false;
    categoryName: string;

    buttonVisible: boolean = false;
    businesses: SelectItem[];
    isPortal: boolean;
    selectedBusiness: any = [];
    showSave = false;
    selectedBusinessId: any;
    isPortalAccess: boolean;
    isPrint: boolean;
    isEdit: boolean;
    /**
     * Date picker configuration option
     */
    public dateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        },
        disableUntil: this.getCurrentDate()
    };
    RiskAnalysisDepartmentList: any = [];
    isfunctionExists: boolean;

    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor

    /**
     * constructor
     * @param route {ActivatedRoute}
     * @param translator {TranslateService}
     * @param riskanalysisService {RiskanalysisService}
     * @param kubaServices {KubaServices}
     * @param _fb {FormBuilder}
     */
    constructor(
        private route: ActivatedRoute,
        private translator: TranslateService,
        private riskanalysisService: RiskanalysisService,
        private kubaServices: KubaServices,
        private location: Location,
        private _fb: FormBuilder,
        private confirmationService: ConfirmationService,
        private safetyjobservice: JobAnalysisService,
        private archiveServices: ArchiveServices,
        private activityServices: ActivityServices,
        private http: HttpClient,
        private router: Router,
        private businessService: BusinessServices,
        private businessDepartmentService: BusinessDepartmentService,
        private translate: TranslateService
    ) {
        super();
        this.subscriptions.push(this.route.params.subscribe((params: Params) => {
            this.riskanalysisId = params['rid'];
        }));
        this.subscriptions.push(this.kubaServices.getLanguage$.subscribe(lang => {
            this.locale = lang;
        }));
        this.RiskEditTopicPopUp = this._fb.group({
            topic: [
                '',
                [Validators.required, ValidationService.noWhitespaceValidator]
            ]
        });
        this.RiskEditDeptPopUp = this._fb.group({
            department: [
                '',
                [Validators.required, ValidationService.noWhitespaceValidator]
            ]
        });
        this.RiskCategoryPopUp = this._fb.group({
            category: ['', Validators.required]
        });

        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            ActivityId: 0,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date()
        };

        this.upConfig = {
            title: 'Activity Document',
            titleAsLabel: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            uploaderUri:
                environment.BASE_URL +
                '/file/upload/business/followup/activity-docs',
            addtionalData: this.additionalData
        };

        this.riskAdditionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            ActivityId: 0,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date()
        };

        this.upRiskConfig = {
            title: '',
            titleAsLabel: true,
            editMode: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            showDeleteButton: true,
            uploaderUri:
                environment.BASE_URL +
                '/file/upload/riskanalysis-doc/' +
                BaseServices.ApplicationId +
                '/' +
                this.riskanalysisId,
            addtionalData: this.riskAdditionalData
        };
    }

    //#endregion

    //#region page-event

    /**
     * editing risk analysis and binding dropdowns
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        let curSession = JSON.parse(sessionStorage.getItem('RModeBusiness'));

        let FollowUpActivityFeatures;
        let FollowUpActivity;
        let FollowUpActivityFeaturesJson;
        if (curSession != null) {
            FollowUpActivity = localStorage.getItem("BusinessDetails");
            FollowUpActivityFeaturesJson = JSON.parse(FollowUpActivity).Features;
            FollowUpActivityFeatures = JSON.parse(FollowUpActivityFeaturesJson);
            console.log('FollowUpActivityFeatures:', FollowUpActivityFeatures)
        } else {

            FollowUpActivityFeatures = JSON.parse(BaseServices.userSettings.userFeature.Features);
        }
        const parentElement = FollowUpActivityFeatures.find(element => element.id === 15);

        if (parentElement && parentElement.children) {
            const childElement = parentElement.children.find(child => child.id === 135);

            if (childElement && childElement.checked) {
                this.buttonVisible = true;
            }
            else {
                this.buttonVisible = false;
            }
        }
        this.roleId = BaseServices.roleId;
        // this.BindTreeNode();
        if (BaseServices.roleId == '1' || BaseServices.roleId == '2') {
            this.isReadOnly = true;
        }
        this.selectedBusinessId = +sessionStorage.getItem('CurrentBusinessId');
        this.isPortal = BaseServices.roleId == '2' ? true : false;
        if (this.roleId == '2') {
            this.isPortalAccess = this.selectedBusinessId > 0 ? false : true;
        }
        else {
            this.isPortalAccess = false;
        }
        if (window.name === 'Remote') {
            this.subscriptions.push(this.businessService.getById(BaseServices.BusinessId).subscribe((res) => {
                let featureRights = [];
                featureRights = JSON.parse(res.Features);
                let features = [];
                features = featureRights;
                if (features) {
                    features.forEach((feature: any) => {
                        if (feature.id === FeatureKey.FOLLOW_UP) {
                            let childrens = feature.children;
                            childrens.forEach((children: any) => {
                                if (children.id === FeatureKey.RISK_ANALYSIS_EXTRA_SORTINGFIELD && children.checked) {
                                    this.isSorting = false;
                                }
                            });
                        }
                    });
                }
                if (BaseServices.ApplicationId == 4) {
                    this.isSorting = false;
                }
            }));
        } else {
            this.featureBase = BaseServices.getUserFeatures();
            let features = [];
            features = JSON.parse(this.featureBase.Features);
            if (features) {
                features.forEach((feature: any) => {
                    if (feature.id === FeatureKey.FOLLOW_UP) {
                        let childrens = feature.children;
                        childrens.forEach((children: any) => {
                            if (children.id === FeatureKey.RISK_ANALYSIS_EXTRA_SORTINGFIELD && children.checked) {
                                this.isSorting = false;
                            }
                        });
                    }
                    if (BaseServices.ApplicationId == 4) {
                        this.isSorting = false;
                    }
                });
            }
        }
        let leftNav = JSON.parse(sessionStorage.getItem('leftNav')!);
        if (BaseServices.roleId !== '1') {
            let filteredTemplate = HelperService.filterTree('Archive', leftNav.filter((x: any) => {
                if (x.name === 'Archive' && x.checked == true) {
                    this.isArchive = true;

                }
            }), 'isArchive');
        }
        let documentList = this.route.snapshot.data['attachment']
        if (documentList) {
            this.attachment = documentList;
        }
        this.onAdministrateRiskAnalysis();
        this.uploadInput = new EventEmitter<UploadInput>();
        switch (BaseServices.UserRole) {
            case 'Admin':
                this.isVisibleSave = true;
                break;
            case 'Editor':
                this.isVisibleSave = true;
                break;
            case 'User':
                this.isVisibleSave = true;
                break;
            case 'Guest':
                this.isVisibleSave = false;
                break;
            case 'Portal':
                this.isVisibleSave = true;
                break;
        }
        this.initializeRiskForm();
        this.initializeActionPlanForm();
        this.subscriptions.push(this.route.params.subscribe((params: Params) => {
            let rid = params['rid'];
            this.riskanalysisId = +rid;
            this.fetchActivities();
            if (this.riskanalysisId) {
                this.subscriptions.push(this.riskanalysisService
                    .getById(this.riskanalysisId)
                    .subscribe((riskForm: any) => {
                        if (riskForm) {
                            this.createdUserId = riskForm.CreatedBy;
                            this.createdby = riskForm.CreatorName;
                            this.currentBusinessId = riskForm.BusinessId;
                            this.createdOnForSave = riskForm.CreatedOn;
                            this.createdon = new Date(
                                riskForm.CreatedOn
                            ).toLocaleDateString();
                            this.time = new Date(
                                riskForm.CreatedOn
                            ).toLocaleTimeString();
                            if (riskForm.Sorting == "") {
                                riskForm.Sorting = null;
                            }
                            this.initializeRiskForm(riskForm);
                            this.IsEditRisk = true;
                        }
                    }));
            }
        }));
        this.dropdownBinding();
        this.title = this.riskanalysisId ? 'RISK_SCHEME' : 'NEW_RISK_ANALYSIS'
    }

    gotoList() {
        if (this.taskId == 0) {
            this.ActionPlanInfo = true;
        }
        else {
            this.location.back();
        }
    }

    /**
     * save uploaded file
     */
    saveUploaded(event: any) {
        if (event) {
            let eventType = event.eventName;
            switch (eventType) {
                case 'DeleteDefaultFile':
                    if (event.item.data.id === 0) {
                        // remove unsaved attachment
                        this.newDocumentAdded = this.newDocumentAdded.filter(
                            (x: any) => x.Path !== event.item.data.path
                        );
                        this.uploadedRiskFiles = this.uploadedRiskFiles.filter(
                            (x: any) => x.path !== event.item.data.path
                        );
                        this.toasterComponent.callToastDlt();
                    } else {
                        // remove existing attachement
                        // this.riskanalysisService
                        //     .deleteRiskAttachment(event.item.data.id)
                        //     .subscribe((result: any) => {
                        //         if (result) {
                        // remove from display list
                        this.uploadedRiskFiles = this.uploadedRiskFiles.filter(
                            x => x.path !== event.item.data.path
                        );
                        this.deletedImage.push(event.item.data.id);
                        // this.toasterComponent.callToastDlt();
                        // }
                        // });
                    }
                    break;
                default:
                    let keyBindings = {
                        Originalname: 'OriginalFileName',
                        FileTypeId: 'FileType'
                    };
                    let suggestionDoc = Object.assign(
                        {},
                        event,
                        this.additionalData
                    );
                    suggestionDoc.id = 0;
                    suggestionDoc.createdBy = BaseServices.UserId;
                    suggestionDoc.status = 1;
                    this.newDocumentAdded.push(
                        HelperService.switchKeysCase(suggestionDoc, keyBindings)
                    );
                    break;
            }
        }
    }
    /**
     * risk form initialize
     * @param riskanalysis {any}
     */
    initializeRiskForm(riskanalysis?: any) {
        let CategoryId;
        let riskTitle = '';
        let location = '';
        let TopicId;
        let DepartmentId;
        let ExpectedBehaviour = '';
        let RelatedIncident = '';
        let Alreadyactiontaken = '';
        let Probability = 1;
        let Consequence = 1;
        let RiskFigure = 1;
        let Participants = '';
        let IsActive = false;
        let Sorting = '';

        if (riskanalysis) {
            this.uploadedRiskFiles = [];
            if (riskanalysis.RiskAnalysisAttachment) {
                riskanalysis.RiskAnalysisAttachment.forEach((element: any) => {
                    this.uploadedRiskFiles.push(
                        HelperService.switchKeysCase(element, null, 'L')
                    );
                });
            }
            CategoryId = riskanalysis.CategoryId;
            riskTitle = riskanalysis.Title;
            location = riskanalysis.Location;
            TopicId = riskanalysis.TopicId;
            let departmentList: any = [];
            let checkDepartmentList = [];
            riskanalysis.RiskAnalysisDepartment != null ?
                riskanalysis.RiskAnalysisDepartment.forEach((element: any) => {
                    departmentList.push(element.RiskDepartmentId);
                }) : riskanalysis.DepartmentId;
            departmentList.forEach((element: any) => {
                let checkValueDept = this.departments.filter((x: any) => x.value == element);
                if (checkValueDept.length > 0) {
                    checkDepartmentList.push(checkValueDept);
                }
            })
            if (checkDepartmentList.length == 0) {
                departmentList = [];
            }
            DepartmentId = departmentList;
            ExpectedBehaviour = riskanalysis.ExpectedBehaviour;
            RelatedIncident = riskanalysis.RelatedIncident;
            Alreadyactiontaken = riskanalysis.Alreadyactiontaken;
            Probability = riskanalysis.Probability;
            Consequence = riskanalysis.Consequence;
            RiskFigure = riskanalysis.RiskFigure;
            Participants = riskanalysis.Partcipants;
            IsActive = riskanalysis.IsActive;
            this.actionPlan = riskanalysis.Activity;
            Sorting = riskanalysis.Sorting;
            this.consequenceValue = riskanalysis.Consequence;
            this.probabilityValue = riskanalysis.Probability;
        }
        this.setRiskFigure(Probability, Consequence);

        this.riskForm = this._fb.group({
            CategoryId: [CategoryId],
            Title: [riskTitle, Validators.required],
            Location: location,
            TopicId: [TopicId],
            DepartmentId: [DepartmentId],
            ExpectedBehaviour: [ExpectedBehaviour],
            RelatedIncident: [RelatedIncident],
            Alreadyactiontaken: [Alreadyactiontaken],
            Probability: Probability,
            Consequence: Consequence,
            RiskFigure: [{ value: this.riskData.Id, disabled: true }],
            Participants: Participants,
            IsActive: IsActive,
            Sorting: Sorting
        });
    }
    /**
     * plan form initialize
     * @param actionPlan {any}
     */
    initializeActionPlanForm(data?: any) {
        let status = '';
        let actionTitle = '';
        let description = '';
        let goal = '';
        let estimatedCost = 0;
        let deadline: any;
        let execution = '';
        let followup = '';
        let notifyBeforeDeadLine = '0';
        let notifyBeforeFollowUp = 7;
        let beforefollowup = '';
        let beforedeadline = '0';
        if (data) {
            actionTitle = data.Title ? data.Title : 0,
                description = data.Description ? data.Description : 0,
                goal = data.Goal ? data.Goal : 0,
                estimatedCost = data.EstimatedCost ? data.EstimatedCost : 0,
                deadline = HelperService.formatInputDate(data.Deadline),
                execution = data.ExecutionUserId ? data.ExecutionUserId : 0,
                followup = data.FollowupUserId ? data.FollowupUserId : 0,
                notifyBeforeDeadLine = data.ActivityScheduleDto ? data.ActivityScheduleDto.NotifyBeforeDeadLine : 0,
                notifyBeforeFollowUp = data.ActivityScheduleDto ? data.ActivityScheduleDto.NotifyBeforeFollowup : 7
            status = data.Status
        }
        this.actionplanForm = this._fb.group({
            Id: [],
            Title: [actionTitle, [Validators.required]],
            Description: description,
            Goal: goal,
            EstimatedCost: estimatedCost,
            Execution: ['', Validators.required],
            Followup: ['', Validators.required],

            Status: status,

            days: this._fb.group(
                {
                    Deadline: new FormControl(deadline, [Validators.required, Validators.nullValidator,
                    ],
                    ),
                    NotifyBeforeDeadLine: new FormControl(beforedeadline, [
                        ValidationService.nonNegativeValidator
                    ],),
                    NotifyBeforeFollowUp: new FormControl(beforefollowup),
                }
            ),

        });
    }
    onDeadLineChanged(event: IMyDateModel) {
        this.currentDeadLine = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        this.currentDate = new Date();

    }
    notificationdays(control: FormControl) {
        this.currentDate = new Date();
        if (this.currentDate && this.currentDeadLine) {
            this.currentDate.setHours(0, 0, 0, 0);
            this.currentDeadLine.setHours(0, 0, 0, 0);
            let ONE_DAY = 1000 * 60 * 60 * 24;
            // Convert both dates to milliseconds
            let date1 = this.currentDate.getTime();
            let date2 = this.currentDeadLine.getTime();
            // Calculate the difference in milliseconds
            let difference_ms = Math.abs(date1 - date2);
            this.dateDiff = Math.round(difference_ms / ONE_DAY) + 1;
        }
        const q = new Promise((resolve) => {
            setTimeout(() => {
                if (control.value >= this.dateDiff) {
                    resolve({ 'notificationdays': true });
                } else {
                    resolve(null)
                }
            }, 1000);
        });
        return q;
    }

    //#endregion

    //#region control-event

    /**
     * save department
     */
    saveDept() {
        let riskDept = new Department();
        riskDept.Id = this.departmentId ? this.departmentId : 0;
        riskDept.Name = this.RiskEditDeptPopUp.value.department;
        riskDept.ApplicationId = BaseServices.ApplicationId;
        riskDept.Status = '1';
        if (this.roleId === '1' || this.roleId === '2') {
            riskDept.BusinessId = this.currentBusinessId;
        } else {
            riskDept.BusinessId = BaseServices.BusinessId;
        }
        if (riskDept.Id > 0) {
            this.clearCachedDepartment();
            this.subscriptions.push(this.riskanalysisService
                .updateDept(riskDept)
                .subscribe((isDeptUpdated: any) => {
                    if (isDeptUpdated) {
                        this.addDept = false;
                        this.bindDepartmentDropDown();
                        this.toasterComponent.callToast();
                        let departmentList = [];
                        departmentList.push(isDeptUpdated.Id);
                        this.riskForm
                            .get('DepartmentId')!
                            .patchValue(departmentList);
                    } else {
                        this.departmentExisting = this.translate.instant(`TOPIC_EXIST`);
                        this.isdepartmentExists = true;
                        setTimeout(
                            () => {
                                this.isdepartmentExists = false;
                            },
                            3000
                        );
                    }
                }));
        } else {
            this.clearCachedDepartment();
            this.subscriptions.push(this.riskanalysisService
                .createDept(riskDept)
                .subscribe((isDeptAdded: any) => {
                    if (isDeptAdded) {
                        this.addDept = false;
                        this.bindDepartmentDropDown();
                        this.toasterComponent.callToast();
                        let departmentList = [];
                        departmentList.push(isDeptAdded.Id);
                        this.riskForm
                            .get('DepartmentId')!
                            .patchValue(departmentList);
                    } else {
                        this.departmentExisting = this.translate.instant(`TOPIC_EXIST`);
                        this.isdepartmentExists = true;
                        setTimeout(
                            () => {
                                this.isdepartmentExists = false;
                            },
                            3000
                        );
                    }
                }));
        }
    }
    /**
     * save topic
     */
    saveTopic() {
        let reskTopic = new Topic();
        reskTopic.Id = this.topicId ? this.topicId : 0;
        reskTopic.Name = this.RiskEditTopicPopUp.value.topic;
        reskTopic.Status = '1';
        if (this.roleId === '1') {
            reskTopic.BusinessId = this.currentBusinessId;
        } else {
            reskTopic.BusinessId = BaseServices.BusinessId;
        }
        if (reskTopic.Id > 0) {
            this.subscriptions.push(this.riskanalysisService
                .updateTopic(reskTopic)
                .subscribe((isTopicUpdated: any) => {
                    if (isTopicUpdated) {
                        this.addTopicType = false;
                        this.toasterComponent.callToast();
                        this.bindTopicDropDown();
                        this.riskForm
                            .get('TopicId')!
                            .patchValue(isTopicUpdated.Id);
                    } else {
                        this.topicExisting = this.translate.instant(`TOPIC_EXIST`);
                        this.istopicNameExists = true;
                        setTimeout(
                            () => {
                                this.istopicNameExists = false;
                            },
                            3000
                        );
                    }
                }));
        } else {
            this.subscriptions.push(this.riskanalysisService
                .createTopic(reskTopic)
                .subscribe((isTopicAdded: any) => {
                    if (isTopicAdded) {
                        this.addTopicType = false;
                        this.toasterComponent.callToast();
                        this.bindTopicDropDown();
                        this.riskanalysisService.clearCachedTopic();
                        this.riskForm
                            .get('TopicId')!
                            .patchValue(isTopicAdded.Id);
                    } else {
                        this.topicExisting = this.translate.instant(`TOPIC_EXIST`);
                        this.istopicNameExists = true;
                        setTimeout(
                            () => {
                                this.istopicNameExists = false;
                            },
                            3000
                        );
                    }
                }));
        }
    }
    /**
     * editing department
     * @param DeptType {any}
     */
    editDeptType(DeptType: Department) {
        this.departmentId = DeptType.Id!;
        let result = {
            department: DeptType.Name
        };
        (<FormGroup>this.RiskEditDeptPopUp).setValue(result, {
            onlySelf: true
        });
    }
    /**
     * editing topic
     * @param Topictype {any}
     */
    editTopicType(Topictype: Topic) {
        this.topicId = Topictype.Id!;
        let result = {
            topic: Topictype.Name
        };
        (<FormGroup>this.RiskEditTopicPopUp).setValue(result, {
            onlySelf: true
        });
    }

    /**
     * editing category
     * @param Category {any}
     */
    editCategoryName(Category: any) {
        this.categoryId = Category.Id;
        this.categoryName = Category.Name;
        let result = {
            category: Category.Name
        };
        (<FormGroup>this.RiskCategoryPopUp).setValue(result, {
            onlySelf: true
        });
    }

    /**
     * delete task
     * @param activity {any}
     * @param index {any}
     */
    confirm(activity: any, index: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                const data = activity;
                if (data.Id) {
                    this.subscriptions.push(this.riskanalysisService
                        .deleteTaskPlan(data.Id)
                        .subscribe(result => {
                            if (result) {
                                this.toasterComponent.callToastDlt();
                                this.fetchActivities();
                            }
                        }));
                } else {
                    this.actionPlanList = this.actionPlanList.filter(
                        (val: any, i: any) => i !== index
                    );
                    this.toasterComponent.callToastDlt();
                }
            }
        });
    }
    /**
     * delete department
     * @param selectedDept {any}
     */
    deleteDept(selectedDept: any) {
        if (selectedDept.BusinessId > 0) {
            this.subscriptions.push(this.riskanalysisService
                .checkAllocatedDepartment(selectedDept.Id)
                .subscribe((result: any) => {
                    if (result) {
                        this.isdepartmentExists = true;
                        this.departmentExisting = this.translate.instant("CANT_DELETE_DEPARTMENT_USED_BY_ANOTHER_RA");
                        setTimeout(
                            () => {
                                this.isfunctionExists = false;
                            },
                            3000
                        );
                    } else {
                        this.confirmationService.confirm({
                            message: this.translate.instant('DELETE_THIS_RECORD'),
                            accept: () => {
                                this.subscriptions.push(this.riskanalysisService
                                    .deleteDepartement(selectedDept.Id)
                                    .subscribe(isDeptDeleted => {
                                        if (isDeptDeleted) {
                                            this.clearCachedDepartment();
                                            this.toasterComponent.callToastDlt();
                                            this.bindDepartmentDropDown();
                                        }
                                    }));
                            }
                        })
                    }
                }))
        }
    }
    /**
     * delete topic
     * @param selectedTopic {any}
     */
    deleteTopic(selectedTopic: any) {
        this.subscriptions.push(this.riskanalysisService
            .checkAllocatedTopic(selectedTopic.Id)
            .subscribe((result: any) => {
                if (result) {
                    this.istopicExists = true;
                    this.topicAlerttext = this.translate.instant('CANT_DELETE_DEPARTMENT_USED_BY_ANOTHER_RA');
                    setTimeout(
                        () => {
                            this.istopicExists = false;
                        },
                        3000
                    );
                } else {
                    this.confirmationService.confirm({
                        message: this.translate.instant('DELETE_THIS_RECORD'),
                        accept: () => {
                            this.subscriptions.push(this.riskanalysisService
                                .DeleteTopic(selectedTopic.Id)
                                .subscribe(isTopicDeleted => {
                                    if (isTopicDeleted) {
                                        this.riskanalysisService.clearCachedTopic();
                                        this.bindTopicDropDown();
                                        this.toasterComponent.callToastDlt();
                                    }
                                }));
                        }
                    })
                }
            }))
    }
    deleteAttachment(selectedAttachment: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.riskanalysisService.deleteRiskAttachment(selectedAttachment.Id).subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToastDlt();
                        this.bindAttachment();
                    }
                }))
            }
        })
    }
    bindAttachment() {
        this.uploadedRiskFiles = [];
        if (this.riskanalysisId > 0) {
            this.subscriptions.push(this.riskanalysisService.getRiskAttachmentByRisk(this.riskanalysisId).subscribe((list: any) => {
                if (list) {
                    list.forEach((element: any) => {
                        this.uploadedRiskFiles.push(
                            HelperService.switchKeysCase(element, null, 'L')
                        );
                    });
                    this.loadingForAttachment = false;
                }
            }))
        }
    }
    /**
     * show log dailog
     */
    showLog() {
        this.showViewLog = true;
        this.activityLogData = this.route.snapshot.data['log'];
    }
    /**
     * saving risk analysis
     */
    saveRisk(mode: string) {
        if (this.actionplanForm.valid) {
            this.showNotificationForTask = true;
        } else {
            if (
                this.actionplanForm.value.Title &&
                this.actionplanForm.value.Title !== ''
            ) {
                this.showNotification = true;
            } else {
                this.loading = mode === 'NOTNEW' ? true : false;
                this.loadingForNewRisk = mode === 'NEWRISK' ? true : false;
                let riskanlysis = new Riskanalysis();
                let activityPlan: RiskActivity[] = [];
                let riskAttachment = new RiskAnalysisAttachment();
                let riskanalysisattachment: RiskAnalysisAttachment[] = [];
                riskanlysis.Activity = this.actionPlanList;
                riskanlysis.ApplicationId = BaseServices.ApplicationId;
                if (this.newDocumentAdded) {
                    riskanlysis.RiskAnalysisAttachment = this.newDocumentAdded;
                }
                riskanlysis.CategoryId = this.riskForm.value.CategoryId;
                riskanlysis.Title = this.riskForm.value.Title;
                riskanlysis.Location = this.riskForm.value.Location;
                riskanlysis.TopicId = this.riskForm.value.TopicId;
                riskanlysis.BusinessDepartmentId = 0;
                this.RiskAnalysisDepartmentList = [];
                this.riskForm.value.DepartmentId != null ?
                    this.riskForm.value.DepartmentId.forEach((departmentuser: any) => {
                        let department = {
                            Id: 0,
                            RiskId: this.riskanalysisId,
                            RiskDepartmentId: departmentuser,
                        }
                        this.RiskAnalysisDepartmentList.push(department);
                    }) : this.riskForm.value.DepartmentId;
                riskanlysis.BusinessDepartmentList = this.RiskAnalysisDepartmentList;
                riskanlysis.ExpectedBehaviour = this.riskForm.value.ExpectedBehaviour;
                riskanlysis.RelatedIncident = this.riskForm.value.RelatedIncident;
                riskanlysis.Alreadyactiontaken = this.riskForm.value.Alreadyactiontaken;
                riskanlysis.Probability = this.riskForm.value.Probability;
                riskanlysis.Consequence = this.riskForm.value.Consequence;
                riskanlysis.RiskFigure = this.riskData.Id;
                riskanlysis.Partcipants = this.riskForm.value.Participants;
                riskanlysis.IsMappedActivity = false;
                let roleId = BaseServices.roleId;
                riskanlysis.CultureInfo = sessionStorage.getItem('languageMode')!;
                if (roleId === '1' || roleId === '2') {
                    riskanlysis.BusinessId = this.currentBusinessId;
                    this.selectedBusiness.push(riskanlysis.BusinessId);
                }
                else if (roleId >= '3') {
                    riskanlysis.BusinessId = BaseServices.BusinessId;
                    this.selectedBusiness.push(riskanlysis.BusinessId);
                }
                riskanlysis.IsActive = this.riskForm.value.IsActive;
                riskanlysis.ProjectId = 1; // here we have to pass project id
                riskanlysis.Sorting = this.riskForm.value.Sorting;
                riskanlysis.Status = '1';
                riskanlysis.RoleId = +BaseServices.roleId;
                let riskCount = 0;
                for (var i = 0; i <= this.selectedBusiness.length - 1; i++) {
                    if (roleId == '2') {
                        riskanlysis.BusinessId = this.selectedBusiness[i].value;
                    }
                }
                if (this.riskanalysisId) {
                    riskanlysis.Activity = [];
                    riskanlysis.Activity = [...this.actionPlanList];
                    riskanlysis.Id = this.riskanalysisId;
                    riskanlysis.CreatedBy = this.createdUserId;
                    riskanlysis.ModifiedBy = BaseServices.UserId;
                    riskanlysis.CreatedOn = this.createdOnForSave;
                    riskanlysis.ModifiedOn = new Date();
                    this.subscriptions.push(this.riskanalysisService
                        .update(riskanlysis)
                        .subscribe(isRiskUpdted => {
                            if (isRiskUpdted) {
                                let doc: any;
                                this.uploadedArchieveFiles.forEach((element: any) => {
                                    doc = HelperService.switchKeysCase(element, null, 'U');
                                    doc.RiskAnalysisId = isRiskUpdted.Id;
                                    this.saveRiskAnalysisAttachment(doc);
                                });
                                this.riskanalysisService.RiskBusinessupdate(this.RiskAnalysisDepartmentList).subscribe(res => {

                                })
                                // });
                                this.loading = false;
                                this.loadingForNewRisk = false;
                                this.backToList(mode);
                                this.fetchActivities();
                                this.toasterComponent.callToast();
                                Object.keys(this.riskForm.controls)
                                    .forEach(key => {
                                        if (key !== "CategoryId") {
                                            let currentControl = this.riskForm.controls[key];
                                            if (currentControl.dirty == true) {
                                                this.deletApprovalNotice();
                                                return;
                                            }
                                        }
                                    })
                            }
                        }));
                } else {
                    riskanlysis.CreatedBy = BaseServices.UserId;
                    this.subscriptions.push(this.riskanalysisService.followupriskadd(riskanlysis).subscribe((isRiskAdded: any) => {
                        if (isRiskAdded) {
                            let doc: any;
                            this.uploadedArchieveFiles.forEach((element: any) => {
                                doc = HelperService.switchKeysCase(element, null, 'U');
                                doc.RiskAnalysisId = isRiskAdded.Id;
                                this.saveRiskAnalysisAttachment(doc);

                            });
                            this.RiskAnalysisDepartmentList = [];

                            this.riskForm.value.DepartmentId != null ?
                                this.riskForm.value.DepartmentId.forEach((departmentuser: any) => {
                                    let department = {
                                        Id: 0,
                                        RiskId: isRiskAdded.Id,
                                        RiskDepartmentId: departmentuser,
                                    }
                                    this.RiskAnalysisDepartmentList.push(department);
                                }) : this.riskForm.value.DepartmentId;
                            this.subscriptions.push(this.riskanalysisService.RiskBusinessupdate(this.RiskAnalysisDepartmentList).subscribe(res => {
                            }))
                            this.loading = false;
                            this.riskForm.reset();
                            this.loadingForNewRisk = false;
                            this.backToList(mode);
                            this.toasterComponent.callToast();
                            this.fetchActivities();
                        }
                    }));
                }
            }
        }
        if (this.deletedImage.length > 0) {
            this.deletedImage.forEach(img => {
                this.subscriptions.push(this.riskanalysisService
                    .deleteRiskAttachment(img)
                    .subscribe((result: any) => {
                        this.toasterComponent.callToastDlt();
                    }))
            });

        }
    }
    /**
     * to back to risk list
     * @param mode {string}
     */
    backToList(mode: string) {
        if (mode && mode === 'NEWRISK') {
            this.clearFormControl();
            this.riskanalysisId = 0;
        } else {
            setTimeout(() => {
                this.location.back();
            }, 2000);
        }
    }

    goToList() {
        setTimeout(() => {
            this.location.back();
        }, 2000);
    }
    /**
     * get activity list
     */
    fetchActivities() {
        if (this.riskanalysisId) {
            this.subscriptions.push(this.riskanalysisService
                .getByAnalysis(this.riskanalysisId)
                .subscribe((result: any) => {
                    this.actionPlanList = [];
                    let list = result;
                    if (list) {
                        list.forEach((analysis: any) => {
                            let task: any = new TaskPlanList();
                            this.activityScheduleId = analysis.ActivityScheduleId;
                            task.Id = analysis.Id;
                            task.ActivityScheduleId = analysis.ActivityScheduleDto.Id;
                            task.Title = analysis.Title;
                            task.Deadline = analysis.Deadline;
                            task.Status = analysis.StatusId;
                            task.Description = analysis.Description;
                            task.ExecutionUserId = analysis.ExecutionUserId;
                            task.FollowupUserId = analysis.FollowupUserId;
                            task.FollowupUsersList = analysis.FollowupUsers;
                            task.ExecutionUsersList = analysis.ExecutionUsers;
                            task.ExecutionUsers = analysis.ExecutionUsers;
                            task.FollowupUsers = analysis.FollowupUsers;
                            task.ExecutionUserName = analysis.ExecutionUserName;
                            task.FollowupPerson = analysis.FollowupPerson;
                            task.ActivityNumber = analysis.ActivityNumber;
                            task.Activity_Number_Prefix = analysis.Activity_Number_Prefix;
                            task.StatusText = analysis.StatusText;
                            task.Goal = analysis.Goal;
                            task.CreatedBy = analysis.CreatedBy;
                            task.CreatedOn = analysis.CreatedOn;
                            task.EstimatedCost = analysis.EstimatedCost;
                            task.ActivityAttachmentDto = [];
                            if (analysis.ActivityAttachmentDto) {
                                task.ActivityAttachmentDto.push(analysis.ActivityAttachmentDto);
                            }
                            task.DocumentType = analysis.DocumentType;
                            let activitySchedule = new ActivitySchedule();
                            activitySchedule.EndDate =
                                analysis.ActivityScheduleDto ? analysis.ActivityScheduleDto.EndDate : '';
                            activitySchedule.NotifyBeforeDeadLine =
                                analysis.ActivityScheduleDto ? analysis.ActivityScheduleDto.NotifyBeforeDeadLine : 0;
                            activitySchedule.NotifyBeforeFollowup =
                                analysis.ActivityScheduleDto ? analysis.ActivityScheduleDto.NotifyBeforeFollowup : 7;
                            task.ActivityScheduleDto = activitySchedule;
                            this.actionPlanList.push(task);
                        });
                    } else {
                        list.forEach((risk: any) => {
                            if (risk.ActivityAttachmentDto !== null) {
                                let ActivityAttachmentDto =
                                    risk.ActivityAttachmentDto.Path;
                                return (risk.ActivityAttachmentDto = ActivityAttachmentDto);
                            }
                        });
                        this.actionPlanList.push(...list);
                    }
                }));
        }
    }

    /**
     * image upload and save task in temp table
     */
    addTask() { }
    /**
     * on upload event
     * @param output
     */
    onUploadOutput(output: UploadOutput): void {
        if (output.type === 'allAddedToQueue') {
        } else if (
            output.type === 'addedToQueue' &&
            typeof output.file !== 'undefined'
        ) {
            this.isFileChoosed = true;
            this.files.push(output.file);
        } else if (
            output.type === 'uploading' &&
            typeof output.file !== 'undefined'
        ) {
            const index = this.files.findIndex(
                file =>
                    typeof output.file !== 'undefined' &&
                    file.id === output.file.id
            );
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            this.files = this.files.filter(
                (file: UploadFile) => file !== output.file
            );
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'drop') {
            this.dragOver = false;
        } else if (output.type === 'done') {
            this.activityDocument = output.file!.response;
            this.addAndNewAction();
        } else if (
            output.type === 'rejected' &&
            typeof output.file !== 'undefined'
        ) {
        }

        this.files = this.files.filter(
            file => file.progress.status !== UploadStatus.Done
        );
    }
    /**
     * Clearing risk fields
     */
    clearFormControl() {
        this.riskanalysisId = 0;
        this.documentName = '';
        this.actionPlanList = [];
        this.uploadedRiskFiles = [];
        this.initializeRiskForm();
        this.initializeActionPlanForm();
    }
    /**
     * upload event
     */
    filesSelect(selectedFiles: any) {
        this.loadingForAttachment = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };

        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/file/upload/activity-doc/' +
                BaseServices.ApplicationId +
                '/' +
                this.riskanalysisId,
                formData, { headers: headers }
            )
            .map((response: any) => {
                this.loadingForAttachment = false;
                let res = response;
                this.newActivityDocumentObject = res.Result;
                this.activityDocument = res.Result.path;
                this.documentName = this.activityDocument.replace(
                    /^.*[\\\/]/,
                    ''
                );
                this.loadingForAttachment = false;
            })
            .subscribe(
                result => { },
                error => console.log('Upload article Sign Error : ', error),
                () => console.log('Upload article  Sign Complete')
            ));
    }

    /**
    * upload event
    */
    attachmentSelect(selectedFiles: any) {
        this.loading = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };

        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/file/upload/riskanalysis-doc/' +
                BaseServices.ApplicationId +
                '/' +
                this.riskanalysisId,
                formData, { headers: headers }
            )
            .map((response: any) => {
                this.loading = false;
                let res = response;
                let riskAttachment: any;
                riskAttachment = {
                    Id: 0,
                    RiskAnalysisId: this.riskanalysisId,
                    OriginalFileName: res.Result.originalname,
                    Filename: res.Result.originalname,
                    MimeType: res.Result.mimetype,
                    Path: res.Result.path,
                    CreatedBy: BaseServices.UserId,
                    FileType: 1,
                    Status: 1,
                    CreatedOn: new Date()
                };
                this.saveRiskAnalysisAttachment(riskAttachment);
                this.loading = false;
            })
            .subscribe(
                result => { },
                error => console.log('Upload article Sign Error : ', error),
                () => console.log('Upload article  Sign Complete')
            ));
    }
    /**
     * binding activity list
     */
    addAndNewAction() {
        let task: any = new TaskPlanList();
        let executionUsers = this.executions;
        let followupUsers = this.followUps;
        let executionUser = this.actionplanForm.value.ExecutionUserId ?
            executionUsers.filter((x: any) => x.value === this.actionplanForm.value.ExecutionUserId) : null;

        let followupuser = this.actionplanForm.value.FollowupUserId ?
            followupUsers.filter((x: any) => x.value == this.actionplanForm.value.FollowupUserId) : null;

        let executionList: any = [];
        let count = 0;
        let followupList: any = [];
        let executionNameList = '';
        let followupNameList = '';

        this.actionplanForm.value.Execution.forEach((element: any) => {
            executionList.push({ Id: element });
            let username = executionUsers.filter(x => x.value === element);
            if (username.length > 0) {
                let name = executionUsers.filter(x => x.value === element)[0].label;
                executionNameList += count == 0 ? (name) : (',' + name);
                count++
            }
        });

        count = 0;
        this.actionplanForm.value.Followup.forEach((element: any) => {
            followupList.push({ Id: element });
            let username = followupUsers.filter(x => x.value === element);
            if (username.length > 0) {
                let name = followupUsers.filter(x => x.value === element)[0].label;
                followupNameList += count == 0 ? (name) : (',' + name);
                count++
            }
        });

        if (
            (this.activityRowIndex &&
                this.activityRowIndex !== null &&
                this.actionPlanList &&
                this.actionPlanList.length > 0 &&
                this.actionPlanList[this.activityRowIndex].Id > 0) ||
            (this.activityRowIndex > -1 && this.activityRowIndex !== null)
        ) {
            this.actionPlanList[
                this.activityRowIndex
            ].ExecutionUsersList = executionList;
            this.actionPlanList[
                this.activityRowIndex
            ].FollowupUsersList = followupList;
            this.actionPlanList[
                this.activityRowIndex
            ].Title = this.actionplanForm.value.Title;
            this.actionPlanList[
                this.activityRowIndex
            ].Goal = this.actionplanForm.value.Goal;
            this.actionPlanList[
                this.activityRowIndex
            ].EstimatedCost = this.actionplanForm.value.EstimatedCost;
            this.actionPlanList[
                this.activityRowIndex
            ].Description = this.actionplanForm.value.Description;
            if (this.actionplanForm.value.days.Deadline) {
                this.actionPlanList[
                    this.activityRowIndex
                ].Deadline = HelperService.formatDateFilter(this.actionplanForm.value.days.Deadline.formatted)
            }
            this.actionPlanList[
                this.activityRowIndex
            ].ActivityScheduleId = this.activityScheduleId;
            this.actionPlanList[
                this.activityRowIndex]
                .Status = task.Status = this.actionplanForm.value.Status;
            this.actionPlanList[
                this.activityRowIndex]
                .CreatedOn = this.actionCreatedOn;
            this.actionPlanList[
                this.activityRowIndex]
                .CreatedBy = this.actionCreatedBy;
            this.actionPlanList[
                this.activityRowIndex]
                .ModifiedBy = BaseServices.UserId;
            let execution = new Execution();
            execution.Id = this.actionplanForm.value.ExecutionUserId;
            this.actionPlanList[
                this.activityRowIndex
            ].ExecutionUsers = executionList;
            let followup = new Execution();
            followup.Id = this.actionplanForm.value.FollowupUserId;
            this.actionPlanList[
                this.activityRowIndex
            ].FollowupUsers = followupList;
            this.actionPlanList[
                this.activityRowIndex
            ].ExecutionUserName = executionNameList;
            this.actionPlanList[
                this.activityRowIndex
            ].FollowupPerson = followupNameList;
            this.actionPlanList[this.activityRowIndex].FollowupUserId = followupuser ? followupuser[0].value : '';
            this.actionPlanList[this.activityRowIndex].ExecutionUserId = executionUser ? executionUser[0].value : '';
            this.actionPlanList[
                this.activityRowIndex
            ].ActivityScheduleDto.NotifyBeforeDeadLine = this.actionplanForm.value.days.NotifyBeforeDeadLine;
            this.actionPlanList[
                this.activityRowIndex
            ].ActivityScheduleDto.NotifyBeforeFollowup = this.actionplanForm.value.days.NotifyBeforeFollowUp;
            this.actionPlanList[
                this.activityRowIndex
            ].ActivityScheduleDto.Id = this.activityScheduleId;
            this.actionPlanList[this.activityRowIndex].StatusText = this.options.find(x => x.id === +this.actionplanForm.value.Status).name;
            this.actionPlanList[this.activityRowIndex].ActivityScheduleDto.EndDate = this.actionplanForm.value.days.Deadline
                ? HelperService.formatDateFilter(this.actionplanForm.value.days.Deadline.formatted)
                : null;
            this.actionPlanList[this.activityRowIndex].ActivityScheduleDto.NotifyBeforeDeadLine =
                this.actionplanForm.value.days.NotifyBeforeDeadLine;
            if (this.activityDocumentObject) {
                this.actionPlanList[this.activityRowIndex].ActivityAttachmentDto = [];
                this.actionPlanList[this.activityRowIndex].ActivityAttachmentDto.push(
                    HelperService.switchKeysCase(
                        this.activityDocumentObject,
                        null,
                        'U'
                    ));
                this.actionPlanList[
                    this.activityRowIndex
                ].DocumentType = this.activityDocumentObject;
                task.DocumentType = this.activityDocumentObject;
            } else if (this.newActivityDocumentObject) {
                this.actionPlanList[this.activityRowIndex].ActivityAttachmentDto = [];
                this.actionPlanList[this.activityRowIndex].ActivityAttachmentDto.push(
                    HelperService.switchKeysCase(
                        this.newActivityDocumentObject,
                        null,
                        'U'
                    ));
                this.actionPlanList[
                    this.activityRowIndex
                ].DocumentType = this.newActivityDocumentObject;
                task.DocumentType = this.newActivityDocumentObject;
            } else {
                this.actionPlanList[this.activityRowIndex].ActivityAttachmentDto = [];
            }

        } else {

            task.Id = this.taskId ? this.taskId : 0;
            task.Title = this.actionplanForm.value.Title;
            task.Goal = this.actionplanForm.value.Goal;
            task.EstimatedCost = this.actionplanForm.value.EstimatedCost;
            if (this.actionplanForm.value.days.Deadline) {
                task.Deadline = HelperService.formatDateFilter(this.actionplanForm.value.days.Deadline.formatted)
            }
            task.Description = this.actionplanForm.value.Description;
            task.ExecutionUsers = executionList;
            task.FollowupUsers = followupList;
            let excution = new Execution();
            excution.Id = this.actionplanForm.value.ExecutionUserId;
            let followup = new Execution();
            followup.Id = this.actionplanForm.value.FollowupUserId;
            task.ExecutionUserName = executionNameList;
            task.FollowupPerson = followupNameList;
            task.FollowupUserId = followupuser ? followupuser[0].value : '';
            task.ExecutionUserId = executionUser ? executionUser[0].value : '';;
            task.Status = '8';
            task.StatusText = this.options.find((x: any) => x.id === +task.Status)!.name;
            if (this.newActivityDocumentObject) {
                task.ActivityAttachmentDto = [];
                task.ActivityAttachmentDto.push(
                    HelperService.switchKeysCase(
                        this.newActivityDocumentObject,
                        null,
                        'U'
                    )
                );

                task.DocumentType = this.activityDocument;
            } else {
                task.ActivityAttachmentDto = [];
            }
            let activitySchedule = new ActivitySchedule();
            if (this.actionplanForm.value.days.Deadline) {
                activitySchedule.EndDate = HelperService.formatDateFilter(this.actionplanForm.value.days.Deadline.formatted)
            }
            activitySchedule.NotifyBeforeDeadLine = this.actionplanForm.value.days.NotifyBeforeDeadLine;
            activitySchedule.NotifyBeforeFollowup = this.actionplanForm.value.days.NotifyBeforeFollowUp;
            task.ActivityScheduleDto = activitySchedule;
            task.CreatedBy = BaseServices.UserId;
            task.CreatedOn = new Date();
            this.actionPlanList = [...this.actionPlanList, task];
            this.isTaskAdded = true;
        }
        this.clearTask();
    }
    clearTask() {
        this.isActionExist = false;
        this.activityRowIndex = null!;
        this.taskId = 0;
        this.initializeActionPlanForm();
        this.documentName = '';
    }
    saveAndAddTask() {
        if (this.actionplanForm.valid) {
            this.showNotificationForTask = true;
        } else {
            this.loadingForSaveAndActivity = true;
            let taskplan: any = new Riskanalysis();
            taskplan.Id = this.riskanalysisId ? this.riskanalysisId : 0;
            if (this.roleId === '1' || this.roleId === '2') {
                taskplan.BusinessId = this.currentBusinessId;
            } else {
                taskplan.BusinessId = BaseServices.BusinessId;
            }
            taskplan.ModifiedBy = BaseServices.UserId;
            taskplan.CategoryId = this.riskForm.value.CategoryId;
            taskplan.Title = this.riskForm.value.Title;
            taskplan.Location = this.riskForm.value.Location;
            taskplan.TopicId = this.riskForm.value.TopicId;
            taskplan.FeatureId = FeatureKey.FOLLOW_UP;
            taskplan.Status = '7';
            taskplan.IsMappedActivity = true;
            taskplan.DepartmentId = '0';
            this.RiskAnalysisDepartmentList = [];
            if (this.riskForm.value.DepartmentId == null) {

                this.riskForm.value.DepartmentId = [];

            }
            this.riskForm.value.DepartmentId.forEach((departmentuser: any) => {
                let department = {
                    Id: 0,
                    RiskId: this.riskanalysisId,
                    RiskDepartmentId: departmentuser,
                }
                this.RiskAnalysisDepartmentList.push(department);
            });
            taskplan.ExpectedBehaviour = this.riskForm.value.ExpectedBehaviour;
            taskplan.RelatedIncident = this.riskForm.value.RelatedIncident;
            taskplan.Alreadyactiontaken = this.riskForm.value.Alreadyactiontaken;
            taskplan.Probability = this.riskForm.value.Probability;
            taskplan.Consequence = this.riskForm.value.Consequence;
            taskplan.RiskFigure = this.riskData.Id;
            taskplan.ApplicationId = BaseServices.ApplicationId;
            taskplan.Partcipants = this.riskForm.value.Participants;
            taskplan.IsActive = this.riskForm.value.IsActive;
            taskplan.Sorting = this.riskForm.value.Sorting;
            taskplan.Activity = [];
            taskplan.SaveType = 'ADDTASK';
            taskplan.Activity = [...this.actionPlanList];
            taskplan.CreatedBy = this.createdUserId;
            taskplan.Activity.forEach((element: any) => {
                if (element.Id == 0) {
                    taskplan.Activity.forEach((element: any) => element.IsCreatedFrom = this.FromRiskAnalysis);
                    taskplan.Activity.forEach((element: any) => element.RepetitionId = this.NoRepetition);
                }
            })
            if (this.riskanalysisId > 0) {
                taskplan.CreatedOn = this.createdOnForSave;
            }
            this.subscriptions.push(this.riskanalysisService.update(taskplan).subscribe(result => {
                if (result) {
                    this.subscriptions.push(this.riskanalysisService.RiskBusinessupdate(this.RiskAnalysisDepartmentList).subscribe(res => {
                    }))
                    this.loadingForSaveAndActivity = false;
                    this.toasterComponent.callToast();
                    this.fetchActivities();
                    this.goToList();
                }
            }));
        }
    }


    /**
     * edit activity
     * @param data {any}
     * @param index {number}
     */
    // have to handle db update and temp table update
    editActivity(data: any, index: any) {
        this.taskIndex = index;
        this.actionPlan = data;
        this.isActionExist = true;
        this.newActivityDocumentObject = null;
        this.taskId = data.Id;
        let followupList: any = [];
        let executionList: any = [];
        this.options = [
            new Options(8, 'OPEN'),
            new Options(9, 'DONE'),
            new Options(10, 'REJECTED')
        ];
        if (data) {
            this.actionCreatedBy = data.CreatedBy;
            this.actionCreatedOn = data.CreatedOn;
            this.activityScheduleId = data.ActivityScheduleId;
            // }
            data.ExecutionUsers.forEach((element: any) => {
                executionList.push(element.Id)
            });
            data.FollowupUsers.forEach((element: any) => {
                followupList.push(element.Id)
            });

            let actionplan = {
                Id: this.taskId ? this.taskId : 0,
                Title: data.Title ? data.Title : 0,
                Description: data.Description ? data.Description : '',
                Goal: data.Goal ? data.Goal : '',
                EstimatedCost: data.EstimatedCost ? data.EstimatedCost : 0,
                days: {
                    Deadline: HelperService.formatInputDate(data.Deadline),
                    NotifyBeforeDeadLine: data.ActivityScheduleDto ? data.ActivityScheduleDto.NotifyBeforeDeadLine : 0,
                    NotifyBeforeFollowUp: data.ActivityScheduleDto ? data.ActivityScheduleDto.NotifyBeforeFollowup : 7,
                },
                Status: data.Status ? +data.Status : 8,
                Followup: followupList,
                Execution: executionList
            };
            if (data.ActivityAttachmentDto.length > 0) {
                data.ActivityAttachmentDto.forEach((x: any) => {
                    if (x) {
                        this.activityDocumentObject = x;
                        this.documentName = x.Path.replace(/^.*[\\\/]/, '');
                    }
                })
            } else {
                this.activityDocumentObject = null;
                this.documentName = '';
            }

            (<FormGroup>this.actionplanForm).setValue(actionplan, {
                onlySelf: true
            });
            this.activityRowIndex = index;
        }
    }
    /**
     * deleting activity list by index
     * @param index {number}
     */
    deleteActivity(activity: any, index: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                const data = activity;
                if (data.Id) {
                    this.subscriptions.push(this.safetyjobservice
                        .deleteTaskPlan(data.Id)
                        .subscribe((result: any) => {
                            if (result) {
                                this.toasterComponent.callToastDlt();
                                this.fetchActivities();
                            }
                        }));
                } else {
                    this.actionPlanList = this.actionPlanList.filter(
                        (val: any, i: any) => i !== index
                    );
                    this.toasterComponent.callToastDlt();
                }
            }
        });

    }

    /**
     * filter riskanalysis list by Status
     * @param status {any}
     */
    onStatusChanged(status: any) {
        this.status = status;
    }
    /**
     * getting files to upload
     * @param event {any}
     */
    onUpload(event: any) {
        for (let file of event.files) {
            this.documentPath = file.name;
        }
    }
    /**
     * getting files to upload
     * @param event {any}
     */
    onfilesUpload(event: any) {
        for (let file of event.taskFiles) {
            this.actvityFilePath = file.name;
        }
    }
    /**
     * topic popup reset
     */
    onBeforeRiskEditTopicDialogHide() {
        this.RiskEditTopicPopUp.reset();
    }
    /**
     * topic popup reset
     */
    onBeforeRiskEditDeptDialogHide() {
        this.RiskEditDeptPopUp.reset();
    }

    //#endregion

    //#region methods

    /**
     * bind dropdowns
     */
    dropdownBinding() {
        let department: any[];
        let topics: any[];
        let category: any[];
        // Probability && Consequence dropdown value
        this.figureValue = [];
        for (let n = 1; n <= 5; n++) {
            this.figureValue.push({ label: n, value: n });
        }
        this.FollowUpDays = [];
        for (let n = 1; n <= 14; n++) {
            this.FollowUpDays.push({ label: n, value: n });
        }
        // Department
        this.departments = [];
        this.editDepartments = [];
        if (this.roleId === 1) {
            department = this.route.snapshot.data['adminDepartment'];
        } else {
            department = this.route.snapshot.data['department'];
        }
        /* if (department) {
            this.editDepartments = department;
            this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.departments = [];
                department.forEach((departmentList: any) => {
                    this.departments.push({
                        label: departmentList.Name,
                        value: departmentList.Id
                    });
                });
            });
        } */
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.departments.push(
                { label: val.SELECT, value: null }
            );
        }))
        if (department) {
            this.editDepartments = department;
            const uniqueTypeSet = new Set<string>(); // Use a Set to store unique types
            department.forEach((typeOfEmp: any) => {
                const typeLabel = typeOfEmp.Name;
                const typeId = typeOfEmp.Id;
                const typebusId = typeOfEmp.BusinessId;

                // Check if the typeLabel is unique before pushing
                if (!uniqueTypeSet.has(typeLabel)) {
                    uniqueTypeSet.add(typeLabel);

                    // Push into departments
                    this.departments.push({ label: typeLabel, value: typeId, busId: typebusId });

                    // Push into editDepartments
                    // this.editDepartments.push({ Name: typeLabel, Id: typeId, busId: typebusId });
                }
            });
        }
        // Topics
        this.Topics = [];
        this.editTopics = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.Topics = [];
            this.Topics.push(
                { label: val.SELECT, value: null }
            );
            if (this.roleId === 1) {
                topics = this.route.snapshot.data['adminTopic'];
            } else {
                topics = this.route.snapshot.data['topic'];
            }
            if (topics) {
                topics.forEach((topicsList: any) => {
                    this.Topics.push({
                        label: topicsList.Name,
                        value: topicsList.Id
                    });
                    this.editTopics.push({
                        Name: topicsList.Name,
                        Id: topicsList.Id
                    });
                });
            }
        }));

        // Category
        this.Category = [];
        this.editCategory = [];
        this.subscriptions.push(this.translate.stream('SELECT_FOR_CATEGORY').subscribe(val => {
            this.Category = [];
            let isLiveEnvironment = environment.BASE_FRONT_URL == 'http://ik2.kuba.no/' ? true : false;
            if (isLiveEnvironment) {
                this.Category.push(
                    { label: val.SELECT, value: null },
                    { label: val.BRANN, value: 206 },
                    { label: val.PERSONKADER, value: 207 },
                    { label: val.ERGONOMI, value: 208 },
                    { label: val.PSYKOSOSIALT, value: 209 },
                    { label: val.TYVERI, value: 210 },
                    { label: val.FYSISKEFARER, value: 535 },
                    { label: val.KJEMISKOGBIOLOGISK, value: 536 },
                    { label: val.ORGANIZATIONALLY, value: 842 }
                );
                this.editCategory.push(
                    { Name: val.BRANN, value: 206 },
                    { Name: val.PERSONKADER, value: 207 },
                    { Name: val.ERGONOMI, value: 208 },
                    { Name: val.PSYKOSOSIALT, value: 209 },
                    { Name: val.TYVERI, value: 210 },
                    { Name: val.FYSISKEFARER, value: 535 },
                    { Name: val.KJEMISKOGBIOLOGISK, value: 536 },
                    { Name: val.ORGANIZATIONALLY, value: 842 }
                );
            }
            else {
                this.Category.push(
                    { label: val.SELECT, value: null },
                    { label: val.BRANN, value: 69 },
                    { label: val.PERSONKADER, value: 70 },
                    { label: val.ERGONOMI, value: 71 },
                    { label: val.PSYKOSOSIALT, value: 72 },
                    { label: val.TYVERI, value: 73 },
                    { label: val.FYSISKEFARER, value: 119 },
                    { label: val.KJEMISKOGBIOLOGISK, value: 120 },
                    { label: val.ORGANIZATIONALLY, value: 121 }
                );
                this.editCategory.push(
                    { Name: val.BRANN, value: 69 },
                    { Name: val.PERSONKADER, value: 70 },
                    { Name: val.ERGONOMI, value: 71 },
                    { Name: val.PSYKOSOSIALT, value: 72 },
                    { Name: val.TYVERI, value: 73 },
                    { Name: val.FYSISKEFARER, value: 119 },
                    { Name: val.KJEMISKOGBIOLOGISK, value: 120 },
                    { Name: val.ORGANIZATIONALLY, value: 121 },
                );
            }
            category = this.route.snapshot.data['category'];
            if (category) {
                category.forEach((categoryList: any) => {
                    this.Category.push({
                        label: categoryList.Name,
                        value: categoryList.Id
                    });
                    this.editCategory.push({
                        Name: categoryList.Name,
                        Id: categoryList.Id
                    });
                });
            }
        }));

        this.executions = [];
        // added by remesh
        this.followUps = [];
        let userList: any[];

        if (this.roleId === '1') {
            userList = this.route.snapshot.data['usersAdmin'];
        } else {
            userList = this.route.snapshot.data['user'];
        }
        if (userList && userList.length > 0) {
            userList.forEach((user: any) => {
                this.executions.push({
                    label: user.Name,
                    value: user.Id
                });
                this.followUps.push({
                    label: user.Name,
                    value: user.Id
                });
            });
        }
        // Sorting
        this.subscriptions.push(this.translate.stream('SELECT_SORTING').subscribe(val => {
            this.sorting = [];
            this.sorting.push({ label: val.SELECT, value: null });
            this.sorting.push({ label: val.PERSON, value: val.PERSON });
            this.sorting.push({ label: val.ENVIRONMENTAL, value: val.ENVIRONMENTAL });
            this.sorting.push({ label: val.MATERIAL, value: val.MATERIAL });
        }));
    }
    /**
     * text box event
     * @param e {any}
     * @param limitNumber {any}
     */
    onNumberChange(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = (e.which) ? e.which : e.keyCode
        if (e.target.value.length === limit || charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    }
    onCostChange(e: any) {
        let charCode = (e.which) ? e.which : e.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    }
    /**
     * binding department dropdown
     */
    bindDepartmentDropDown() {
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.departments = [];
            this.departments.push(
                { label: val.SELECT, value: null }
            );
            this.subscriptions.push(this.businessDepartmentService.getCachedBusinessDepartment().subscribe(departmentList => {
                if (departmentList) {
                    this.editDepartments = departmentList;
                    const uniqueTypeSet = new Set<string>(); // Use a Set to store unique types
                    departmentList.forEach((typeOfEmp: any) => {
                        const typeLabel = typeOfEmp.Name;
                        const typeId = typeOfEmp.Id;
                        const typebusId = typeOfEmp.BusinessId;
        
                        // Check if the typeLabel is unique before pushing
                        if (!uniqueTypeSet.has(typeLabel)) {
                            uniqueTypeSet.add(typeLabel);
        
                            // Push into departments
                            this.departments.push({ label: typeLabel, value: typeId, busId: typebusId });
                        }
                    });
                }

            }));
        }));
    }
    /**
     * binding topic dropwdown
     */
    bindTopicDropDown() {
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.Topics = [];
            this.Topics.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.subscriptions.push(this.riskanalysisService
            .getTopicByBusiness(BaseServices.BusinessId)
            .subscribe(topicList => {
                if (topicList) {
                    this.editTopics = topicList;
                    topicList.forEach((topicsList: any) => {
                        this.Topics.push({
                            label: topicsList.Name,
                            value: topicsList.Id
                        });
                    });
                }
            }));
    }

    /**
     * binding category dropwdown
     */
    bindCategoryDropDown() {
        this.subscriptions.push(this.translate.stream('SELECT_FOR_CATEGORY').subscribe(result => {
            this.Category = [];
            this.Category.push(
                { label: result.SELECT, value: null },
                { label: result.BRANN, value: 69 },
                { label: result.PERSONKADER, value: 70 },
                { label: result.ERGONOMI, value: 71 },
                { label: result.PSYKOSOSIALT, value: 72 },
                { label: result.TYVERI, value: 73 },
                { label: result.FYSISKEFARER, value: 119 },
                { label: result.KJEMISKOGBIOLOGISK, value: 120 },
                { label: result.ORGANIZATIONALLY, value: 121 }
            );
        }));
        this.subscriptions.push(this.riskanalysisService.getCategoryList(BaseServices.BusinessId, BaseServices.ApplicationId)
            .subscribe(categoryList => {
                if (categoryList) {
                    categoryList.forEach((category: any) => {
                        this.Category.push({
                            label: category.Name,
                            value: category.Id
                        });
                    });
                }
            }));
    }

    /**
    * cache clear of department
    */
    clearCachedDepartment() {
        this.businessDepartmentService.clearCachedAllDepartment();
    }

    //#endregion

    // TODO: to be implemented
    SaveRiskanalysis(FormValue: any) { }
    /**
     * get current date
     */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate() - 1,
        };
    }

    /**
     * disable new button based upon the rights
     */
    onAdministrateRiskAnalysis() {
        let userRightsId = Rights.ADMINISTRATE_RISK_ANALYSIS;
        let role = BaseServices.UserRole;
        if (role === 'User') {
            this.isAdministrateRiskAnalysis = BaseServices.checkUserRights(
                userRightsId,
                role
            );
        } else if (role !== 'Editor') {
            this.isAdministrateRiskAnalysis = false;
        }
    }

    /**
     * show  dailog for upload archive document
     */
    showDocumentDailog() {
        let businessId: number;
        if (this.roleId === '2') {
            businessId = this.currentBusinessId;
        } else {
            businessId = BaseServices.BusinessId;
        }
        this.subscriptions.push(this.riskanalysisService
            .getArchiveTree(businessId)
            .subscribe(
                (result: any) => {
                    this.treeNode = result[0].nodes;
                    let flattenned = this.flattenArrObj(this.treeNode);
                },
                (error: any) => {
                    console.error('Api Error while refreshing tree:', error);
                },
                () => {
                    this.showArchiveDocumentDailog = true;
                    console.log('Refreshing tree service complete.');
                }
            ));
    }
    // Emitter for selected folder from Document Explorer
    setActiveNodeIntree(event: any) {
        this.treeActiveNode = event;
    }
    // Events form Tree
    catchTreeEvent(event: any): void {
        switch (event.eventName) {
            case 'activate':
                if (event.node.data.type === 'folder') {
                    alert(`Please select document only`);
                } else if (event.node.data.type === 'file') {
                    this.riskArchieveDocument = {
                        Id: 0,
                        RiskAnalysisId: this.riskanalysisId,
                        OriginalFileName: event.node.data.originalFileName,
                        Filename: event.node.data.filename,
                        MimeType: event.node.data.mimetype,
                        Path: event.node.data.path,
                        CreatedBy: BaseServices.UserId,
                        FileType: 1,
                        Status: 1,
                        CreatedOn: new Date()
                    };
                    if (this.riskArchieveDocument) {
                        this.showArchiveDocumentDailog = false;
                        this.uploadedArchieveFiles.push(HelperService.switchKeysCase(this.riskArchieveDocument, null, 'U'))
                    }
                    this.uploadedRiskFiles.push(
                        HelperService.switchKeysCase(this.riskArchieveDocument, null, 'L')
                    );
                }
                break;
            default:
                break;
        }

    }

    saveRiskAnalysisAttachment(riskAttachment: any) {
        this.loadingForAttachment = true;
        this.subscriptions.push(this.riskanalysisService
            .addriskdocument(riskAttachment)
            .subscribe(
                response => {
                    if (this.riskanalysisId > 0) {
                        this.bindAttachment()
                    } else {
                        this.loadingForAttachment = false;
                        this.uploadedRiskFiles.push(
                            HelperService.switchKeysCase(response, null, 'L')
                        );
                    }
                    this.showArchiveDocumentDailog = false;
                },
                error =>
                    console.log('Document added error : ', error),
                () => console.log('Document added successfully')
            ));
    }

    catchEvent($event: any) {
        if ($event.eventName === 'focus') {
            this.selectFolderName = $event.node.data.filename;
            this.selectFolderId = $event.node.data.nodeId;
        }
    }

    // private
    private flattenArrObj(arr: any) {
        let newArr: any = [];
        if (Array.isArray(arr) && arr.length > 0) {
            arr.forEach((element: any) => {
                newArr.push(element);
                if (Array.isArray(element.nodes) && element.nodes.length > 0) {
                    newArr = newArr.concat(this.flattenArrObj(element.nodes));
                }
            });
        }
        return newArr;
    }

    gotoBack() {
        this.location.back();
    }
    deletApprovalNotice() {
        if (this.riskanalysisId) {
            this.subscriptions.push(this.riskanalysisService
                .getApprovalNoticeByRisk(this.riskanalysisId)
                .subscribe(result => {
                    if (result) {
                        this.subscriptions.push(this.riskanalysisService
                            .deleteApprovalnoticeList(result)
                            .subscribe(result => { }));
                    }
                }));
            this.riskForm.reset();
        }
    }


    exportPdf() {
        this.loadingReport = true;
        this.fileName = this.translate.instant('RISKANALYSIS_DETAILS');
        let fileType = 'pdf';
        let cultureInfo = sessionStorage.getItem('languageMode')!;
        let ReportJson = this.route.snapshot.data['detail'];
        let featureId = ReportJson.FeatureKey;
        this.isEdit = true;
        this.subscriptions.push(this.riskanalysisService.riskAnalysisReportGenerate(BaseServices.BusinessId, this.riskanalysisId, cultureInfo, ReportJson, featureId, BaseServices.UserId, this.isEdit)
            .subscribe(blob => {
                this.loadingReport = false;
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${this.fileName}.${fileType.toLowerCase()}`;
                link.click();
            },
                (error: any) => {
                    this.loadingReport = false;
                    alert('Export not downloaded');
                }));
    }

    /**
     * save Category
     */
    saveCategory() {
        let riskCategory = new RiskAnalysisCategory();
        if (this.categoryName == this.RiskCategoryPopUp.value.category) {
            riskCategory.Id = this.categoryId ? this.categoryId : 0;
        }
        else {
            riskCategory.Id = 0
        }
        riskCategory.CategoryName = this.RiskCategoryPopUp.value.category;
        riskCategory.ApplicationId = BaseServices.ApplicationId;
        riskCategory.BusinessId = BaseServices.BusinessId;
        riskCategory.CreatedBy = BaseServices.UserId;
        riskCategory.Status = true;
        if (this.roleId === '1' || this.roleId === '2') {
            riskCategory.BusinessId = this.currentBusinessId;
        } else {
            riskCategory.BusinessId = BaseServices.BusinessId;
        }
        if (riskCategory != null) {
            this.subscriptions.push(this.riskanalysisService
                .createCategory(riskCategory)
                .subscribe((result: any) => {
                    if (result) {
                        this.addCategory = false;
                        this.bindCategoryDropDown();
                        this.toasterComponent.callToast();
                        this.riskForm
                            .get('CategoryId')!
                            .patchValue(result.Id);
                    } else {
                        this.categoryExisting = this.translate.instant(`TOPIC_EXIST`);
                        this.iscategoryExists = true;
                        setTimeout(
                            () => {
                                this.iscategoryExists = false;
                            },
                            3000
                        );
                    }
                }));
        }
        this.categoryId = 0;
    }

    deleteCategory(category: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.riskanalysisService
                    .DeleteCategoryById(category.Id)
                    .subscribe(isDeleted => {
                        if (isDeleted == true) {
                            this.bindCategoryDropDown();
                            this.toasterComponent.callToastDlt();
                        }
                        else {
                            this.isCategoryExistsInAnotherRA = true;
                            this.categoryAlert = this.translate.instant('CANT_DELETE_CATEGORY_USED_BY_ANOTHER_RA');
                            setTimeout(() => {
                                this.isCategoryExistsInAnotherRA = false;
                            },
                                3000);
                        }
                    }));
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }

    bindBusiness() {
        this.businesses = [];
        let business = this.route.snapshot.data['business'];
        if (business) {
            business.forEach(element => {
                if (element.Features != null) {
                    var feature = JSON.parse(element.Features);
                    var checkFollowup = feature.find(x => x.id == 15);
                    if (checkFollowup && checkFollowup.checked) {
                        this.businesses.push({ label: element.Name, value: element.Id });
                    }
                }
            });
        }
    }

    BusinessCount() {
        if (this.isPortal) {
            this.showSave = this.selectedBusiness.length > 0 ? true : false;
        }
    }

    SavePortalRisk() {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETION_OF_RISK'),
            accept: () => {
                this.saveRisk('NOTNEW');
            }
        });
    }
}
