import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, ViewChild, OnInit } from '@angular/core';

import { FeatureKey } from './../../../_models/feature';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Module } from 'src/app/kuba/follow-ups/models/deviation';
import { InfoVideo, Menu, SubMenu } from './../models/infovideo';
import { FaqServices } from './../../faqs/services/faqs.service';
import { SelectItem } from 'primeng/api';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    templateUrl: 'infovideo-list.component.html'
})
export class InfoVideoListComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    InfoVideoForm: FormGroup;
    featureId: number;
    rowID: number = null;
    infoVideoId: number;
    isCollapsed = false;
    subMenu: SelectItem[];
    selectedSubMenu: any;
    infoVideoNestedTable: InfoVideoNestedTable[];
    menu: SelectItem[];
    Title: string;
    infoVideoList: InfoVideo[];
    infoVideoSubmenu: any;
    translateKey: string;
    applicationId: number;
    fId:number;
    private subscriptions: Subscription[] = [];
    /**
     * constructor
     * @param formBuilder {{formBulider}}
     * @param activatedRoute {{ActivatedRoute}}
     * @param InfoVideoservice {{InfoVideoService}}
     */
    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private faqServices: FaqServices,
        private translate: TranslateService
    ) { }
    /**
     * page on init
     */
    ngOnInit() {
        this.applicationId = BaseServices.ApplicationId;
        this.InfoVideoForm = this.formBuilder.group({
            Menu: [''],
            SubMenu: ['', Validators.required],
            URL: ['', Validators.required]
        });
        let menu = this.activatedRoute.snapshot.data['menu'];
        this.fId=menu[0].FeatureId;
        this.subscriptions.push(this.translate.stream('SELECT_EMPLOYEE').subscribe(val => {
            this.menu = [];
            this.menu.push(
                { label: val.EMPLOYEE, value: FeatureKey.EMPLOYEES },
            );
        }));
        switch (this.applicationId) {
            case 1:
                this.subscriptions.push(this.translate.stream('INFOVIDEO_DROPDOWN').subscribe(val => {
                    this.subMenu = [];
                    this.subMenu.push(
                        { label: val.SELECT, value: null },
                        { label: val.EMPLOYEES, value: 1 },
                        { label: val.EMPLOYEE_SICKNESS_ABSENCE, value: 4 },
                        { label: val.EMPLOYEE_LEAVE, value: 5 },
                        { label: val.EMPLOYEE_TIMETABLE, value: 6 },
                        { label: val.EMPLOYEE_COURSE_DETAILS, value: 2 },
                        { label: val.EMPLOYEE_CONTRACT_DETAILS, value: 71 },
                        { label: val.EMPLOYEE_FIRST_OF_KIN, value: 3 },
                        { label: val.DOCUMENTED_TRAINING, value: 79 },
                        { label: val.APPROVAL_DOCUMENTS, value: 75}
                       

                    );
                }));
                break;
            case 2:
                this.subscriptions.push(this.translate.stream('INFOVIDEO_DROPDOWN').subscribe(val => {
                    this.subMenu = [];
                    this.subMenu.push(
                        { label: val.SELECT, value: null },
                        { label: val.EMPLOYEES, value: 7 },
                        { label: val.EMPLOYEE_SICKNESS_ABSENCE, value: 10 },
                        { label: val.EMPLOYEE_LEAVE, value: 11 },
                        { label: val.EMPLOYEE_TIMETABLE, value: 12 },
                        { label: val.EMPLOYEE_COURSE_DETAILS, value: 8 },
                        { label: val.EMPLOYEE_CONTRACT_DETAILS, value: 72 },
                        { label: val.EMPLOYEE_FIRST_OF_KIN, value: 9 },
                        { label: val.DOCUMENTED_TRAINING, value: 80 },
                        { label: val.APPROVAL_DOCUMENTS, value: 76}
                       

                    );
                }));
                break;
            case 3:
                this.subscriptions.push(this.translate.stream('INFOVIDEO_DROPDOWN').subscribe(val => {
                    this.subMenu = [];
                    this.subMenu.push(
                        { label: val.SELECT, value: null },
                        { label: val.EMPLOYEES, value: 13 },
                        { label: val.EMPLOYEE_SICKNESS_ABSENCE, value: 16 },
                        { label: val.EMPLOYEE_LEAVE, value: 17 },
                        { label: val.EMPLOYEE_TIMETABLE, value: 18 },
                        { label: val.EMPLOYEE_COURSE_DETAILS, value: 14 },
                        { label: val.EMPLOYEE_CONTRACT_DETAILS, value: 73 },
                        { label: val.EMPLOYEE_FIRST_OF_KIN, value: 15 },
                        { label: val.DOCUMENTED_TRAINING, value: 81 },
                        { label: val.APPROVAL_DOCUMENTS, value: 77}
                      

                    );
                }));
                break;
            case 4:
                this.subscriptions.push(this.translate.stream('INFOVIDEO_DROPDOWN').subscribe(val => {
                    this.subMenu = [];
                    this.subMenu.push(
                        { label: val.SELECT, value: null },
                        { label: val.EMPLOYEES, value: 19 },
                        { label: val.EMPLOYEE_SICKNESS_ABSENCE, value: 22 },
                        { label: val.EMPLOYEE_LEAVE, value: 23 },
                        { label: val.EMPLOYEE_TIMETABLE, value: 24 },
                        { label: val.EMPLOYEE_COURSE_DETAILS, value: 20 },
                        { label: val.EMPLOYEE_CONTRACT_DETAILS, value: 74 },
                        { label: val.EMPLOYEE_FIRST_OF_KIN, value: 21 },  
                        { label: val.DOCUMENTED_TRAINING, value: 82 },     
                        { label: val.APPROVAL_DOCUMENTS, value: 78}
                       

                    );
                }));
                break;
            case 5:
                this.subscriptions.push(this.translate.stream('INFOVIDEO_DROPDOWN').subscribe(val => {
                    this.subMenu = [];
                    this.subMenu.push(
                        { label: val.SELECT, value: null },
                        { label: val.EMPLOYEE, value: 25 },
                        { label: val.EMPLOYEE_COURSE_DETAILS, value: 26 },
                        { label: val.EMPLOYEE_FIRST_OF_KIN, value: 27 },
                        { label: val.EMPLOYEE_SICKNESS_ABSENCE, value: 28 },
                        { label: val.EMPLOYEE_LEAVE, value: 29 },
                        { label: val.EMPLOYEE_TIMETABLE, value: 30 }

                    );
                }));
                break;
            case 6:
                this.subscriptions.push(this.translate.stream('INFOVIDEO_DROPDOWN').subscribe(val => {
                    this.subMenu = [];
                    this.subMenu.push(
                        { label: val.SELECT, value: null },
                        { label: val.EMPLOYEE, value: 71 },
                        { label: val.EMPLOYEE_COURSE_DETAILS, value: 72 },
                        { label: val.EMPLOYEE_FIRST_OF_KIN, value: 73 },
                        { label: val.EMPLOYEE_SICKNESS_ABSENCE, value: 74 },
                        { label: val.EMPLOYEE_LEAVE, value: 75 },
                        { label: val.EMPLOYEE_TIMETABLE, value: 76 }

                    );
                }));
                break;
            default:
                break;
        }


        this.infoVideoSubmenu = this.activatedRoute.snapshot.data['submenu'];

        this.listInfoVideo();
    }
    editInfoVideo(id) {
        this.subscriptions.push(this.faqServices.getInfoVideoById(id).subscribe((result:any) => {
            if (result) {
                this.infoVideoId = result.Id;
                this.Title = result.Title;
                this.translateKey = result.TranslateKey;
                if (result.FeatureId > 0) {
                    this.selectedSubMenu = result.AdditionalFeatureId;
                    this.InfoVideoForm.get('SubMenu').patchValue(result.Id);
                }
                let infovideo;
                infovideo = {
                    Menu: result.FeatureId,
                    SubMenu: result.Id,
                    URL: result.VideoPath
                };
                (<FormGroup>this.InfoVideoForm).setValue(infovideo, {
                    onlySelf: true
                });
            }
        }));
    }
    /**
     *  set collapse value
     * @param i {{number}}
     */

    public setCollapse(i: number): boolean {
        let isCollapsed = i === this.rowID;
        return !isCollapsed;
    }
    /**
     * toggle expand icon click
     * @param i {{number}}
     */
    toggleClick(i: number): void {
        this.rowID = i;
        this.isCollapsed = !this.isCollapsed;
    }
    /**
     * toggle collapse icon click
     * @param i {{number}}
     */
    toggleClickHide(i: number): void {
        this.rowID = null;
    }
    onMenuDropDownChanged(e) {
        this.subMenu = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.subMenu = [];
            this.subMenu.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.featureId = e.value ? e.value : e;
        if (e.value !== '') {
            this.subscriptions.push(this.faqServices
                .getInfoVideoByFeatureId(this.featureId)
                .subscribe((result:any) => {
                    result.forEach(element => {
                        this.subMenu.push({
                            label: element.Title,
                            value: element.Id
                        });
                    });
                }));
        }
    }
    onSubMenuDropDownChanged(e: any, subMenuDropDownChanged: any) {
        if (e.value !== '') {
            this.Title = subMenuDropDownChanged.selectedOption.label;
            this.infoVideoId = e.value;
            this.subscriptions.push(this.faqServices
                .getInfoVideoById(e.value.AdditionalFeatureId)
                .subscribe((result:any) => {
                    if (result) {
                        this.InfoVideoForm.get('URL').patchValue(
                            result.VideoPath
                        );
                        this.Title = result.Title;
                    }
                }));
        }
    }

    bindSubMenu(featureId) {
        this.subscriptions.push(this.faqServices
            .getInfoVideoByFeatureId(featureId)
            .subscribe((result:any) => {
                result.forEach(element => {
                    this.subMenu = [];
                    this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                        this.subMenu = [];
                        this.subMenu.push(
                            { label: val.SELECT, value: null }
                        );
                    }));
                    this.subMenu.push({
                        label: element.Title,
                        value: element.Id
                    });
                });
            }));
    }

    saveInfoVideo() {
        let infoVideo = new InfoVideo();
        infoVideo.Id = this.infoVideoId ? this.infoVideoId : 0;
        infoVideo.VideoPath = this.InfoVideoForm.value.URL;
        infoVideo.FeatureId = Module.EMPLOYEES;
        infoVideo.AdditionalFeatureId = this.InfoVideoForm.value.SubMenu.AdditionalFeatureId;
        infoVideo.Title = this.Title;
        infoVideo.Status = '1';
        infoVideo.ModifiedBy = BaseServices.UserId;
        infoVideo.TranslateKey = this.translateKey;
        this.subscriptions.push(this.faqServices
            .updateInfoVideo(this.infoVideoId, infoVideo)
            .subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                    // this.listInfoVideo();
                    this.refreshGrid();
                    this.InfoVideoForm .reset();
                }
            }));
    }



    /**
     * listing children of each safetyjob
     */
    listInfoVideo() {
        this.infoVideoNestedTable = [];
        let result = this.activatedRoute.snapshot.data['menu'];
        this.infoVideoList = result;
        if (result) {
            result.forEach(element => {
                let data = new InfoVideoNestedTable();
                data = element;
                this.infoVideoNestedTable.push(data);
            });
        }
        // assign tasklist to children node
        let i = 0;
        this.infoVideoNestedTable.forEach(element => {
            this.infoVideoNestedTable[i].children = [];
            this.pushSubMenus(i, element.FeatureId);
            i++;
        });
    }
refreshGrid(){
    this.infoVideoNestedTable = [];
        let result = this.activatedRoute.snapshot.data['menu'];
        this.infoVideoList = result;
        if (result) {
            result.forEach(element => {
               let data = new InfoVideoNestedTable();
                data = element;
                this.infoVideoNestedTable.push(data);
            });
        }
    this.faqServices.getInfoVideoSubMenu( this.fId).subscribe((result:any)=>{
        if(result){
            this.infoVideoSubmenu = result;
            let i = 0;
            this.infoVideoNestedTable.forEach(element => {
            this.infoVideoNestedTable[i].children = [];
            this.pushSubMenus(i, element.FeatureId);
            i++;
        });
        }
    })
}
    


    /**
     * listing taskplan to children node of safetyjob
     * @param index {number}
     * @param menuId {number}
     */
    pushSubMenus(index, menuId) {
        this.infoVideoNestedTable[index].children = [];
        if (this.infoVideoSubmenu.length > 0) {
            this.infoVideoNestedTable[index].children = this.infoVideoSubmenu;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
export class InfoVideoNestedTable extends InfoVideo {
    children: InfoVideo[];
}
