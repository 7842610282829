<div class="main-content">
  <div class="main-heading">
    <strong>
      <span translate>SUB_CONTRACTOR</span>
    </strong>
    <span class="page-actions text-right">
      <a
        routerLink="create"
        [routerLink]="['./../create', 0]"
        class="btn btn-success"
        routerLinkActive="active"
      >
        <span class="icon ic-sm icon-add"></span>
        <span translate>NEW</span>
      </a>
    </span>
  </div>
  <div class="card">
    <div class="card-body">
      <strong>
        <span translate>OUR_SUBCONTRACTOR</span>
      </strong>
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #dt
          exportFilename="contractors list"
          [value]="contractors"
          dataKey="Id"
          [(selection)]="selectedContractor"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
        >
          <!-- Caption -->
          <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
              <div class="table-options">
                <div class="pull-right">
                  <kuba-export
                    [reportData]="dt.filteredValue || dt.value"
                    [additionalData]="additionalData"
                  ></kuba-export>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th *ngIf="guest != false" style="width: 55px">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th pSortableColumn="Name">
                {{ "NAME" | translate }} <p-sortIcon field="Name"></p-sortIcon>
              </th>
              <th pSortableColumn="ContractorTypeName">
                {{ "TYPE_SUBCONTRACTOR" | translate }}
                <p-sortIcon field="ContractorTypeName"></p-sortIcon>
              </th>
              <th pSortableColumn="Mobile">
                {{ "CELLULAR_PHONE" | translate }}
                <p-sortIcon field="Mobile"></p-sortIcon>
              </th>
              <th pSortableColumn="Telephone">
                {{ "TELEPHONE" | translate }}
                <p-sortIcon field="Telephone"></p-sortIcon>
              </th>
              <th pSortableColumn="Email">
                {{ "EMAIL" | translate }}
                <p-sortIcon field="Email"></p-sortIcon>
              </th>
              <th *ngIf="guest != false">{{ "STATUS" | translate }}</th>
            </tr>
            <tr>
              <th *ngIf="guest != false" style="width: 55px"></th>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    dt.filter($any($event.target)?.value, 'Name', 'contains')
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th>
                <p-dropdown
                  width="118px"
                  [options]="contractorTypes"
                  (onChange)="onClientChanged($event, clientDropdown)"
                  #clientDropdown
                  appendTo="body"
                  styleClass="p-column-filter"
                  placeholder=""
                  [showClear]="true"
                >
                  <ng-template let-subcontract pTemplate="item">
                    {{ subcontract.label | translate }}
                  </ng-template>
                </p-dropdown>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th *ngIf="guest != false">
                <p-dropdown
                  width="118px"
                  [options]="statuses"
                  (onChange)="onStatusChanged($event, statusDropdown)"
                  #statusDropdown
                  appendTo="body"
                  styleClass="p-column-filter"
                  placeholder="Select"
                  [showClear]="true"
                >
                  <ng-template let-subcontract pTemplate="item">
                    {{ subcontract.label | translate }}
                  </ng-template>
                </p-dropdown>
              </th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-contractor>
            <tr>
              <td *ngIf="guest != false" style="width: 55px">
                <p-tableCheckbox id="selectedOrders" [value]="contractor">
                </p-tableCheckbox>
              </td>
              <td>
                <a href="#" [routerLink]="['../create/', contractor['Id']]">{{
                  contractor.Name
                }}</a>
              </td>
              <td>
                <span>{{ contractor.ContractorTypeName }}</span>
              </td>
              <td>
                <span>{{ contractor.Mobile }}</span>
              </td>
              <td>
                <span>{{ contractor.Telephone }}</span>
              </td>
              <td>
                <span>{{ contractor.Email }}</span>
              </td>
              <td *ngIf="guest != false">
                <a
                  href="javascript:void(0);"
                  (click)="editStatus(contractor['Id'], contractor['Status'])"
                  routerLinkActive="active"
                  translate
                  >{{ contractor.Status }}</a
                >
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template
            *ngIf="guest != false"
            pTemplate="emptymessage"
            let-columns
          >
            <tr>
              <td colspan="7">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
          <ng-template
            *ngIf="guest == false"
            pTemplate="emptymessage"
            let-columns
          >
            <tr>
              <td colspan="6">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
          <!-- Footer -->
          <ng-template pTemplate="summary">
            <div class="ui-helper-clearfix">
              <div class="footer-data">
                <div *ngIf="guest !== false" class="text-left">
                  <button class="btn btn-danger" (click)="deleteContractor()">
                    <i class="icon ic-sm icon-trash"></i>
                    <span translate>DELETE_CHECKED</span>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
    </div>
  </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="icon icon-030"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
