import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { ConfirmationService } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { BaseServices } from './../../kuba.services';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { EmployeeLeaveService } from '../services/employeeleave.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { EmployeeLeave } from '../models';
import * as $ from 'jquery';
import 'fullcalendar';
import 'fullcalendar-scheduler';
import * as moment from 'moment';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

/**
 * Maps the current language to FullCalendar's relevant locale string.
 *
 * @param lang - The string argument representing the current language ('en', 'no', 'sv', etc.)
 *
 * @returns Returns a string that corresponds to the relevant FullCalendar locale.
 * This method will return 'en-gb' for 'en', 'nb' for 'no', and 'sv' for 'sv'.
 * For any other input, it will return the input value as is.
 */
function mapCurrentLandToFullCalendarLocale(lang: string): string {
  switch (lang) {
    case 'en':
      return 'en-gb';
    case 'no':
      return 'nb';
    case 'sv':
      return 'sv';
    default:
      return lang;
  }
}

@Component({
  moduleId: module.id,
  selector: 'leave-list',
  templateUrl: 'employee-leaveList.component.html',
})
export class LeaveListComponent implements OnInit {
  //#region  variables
  @ViewChild('leavesTable', { static: false }) leavesTable: Table;
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  leaveListForm: FormGroup;
  statusList: SelectItem[];
  dayPilotLeaveList: any;
  dayPilotStartDate: string = '';
  dayPilotEndaDate: string = '';
  DayPilotForm: FormGroup;
  statuses: any;
  addType = true;
  startDate: any;
  getYear: any;
  endDate: any;
  startDateFs: any;
  endDateFs: any;
  events: any;
  employee: SelectItem[];
  leaves: any = [];
  isInfoVideo = false;
  videoUrl: string;
  safeURL: any;
  additionalData: any;
  isVideo = false;
  toolTip: string;
  approvalPersonList: SelectItem[];
  daypilotStartDateFilter: string;
  daypilotEndDateFilter: string;
  dayPilotStartDateRawData: any;
  dayPilotEndDateRawData: any;
  groupedlist: any;
  leaveDetail = new EmployeeLeave();
  modifiedEmployeeLeaves: any = [];
  holidayList: any = [];
  resourcesList: any = [];
  eventsList: any = [];
  expandDayPilotScheduler = false;
  displayMenu = false;
  currentEvent: any = [];
  fullscreen = false;
  loading = false;
  dayPilotSourceEnd: any;
  dayPilotSourceStart: any;
  dayPilotSourceEndFilter: any;
  dayPilotSourceStartFilter: any;
  startDateSource: any;
  endDateSource: any;
  startDateFilter: any = null;
  iscreatedToFilter = true;
  IsEditor: boolean = false;
  userRights: any;
  IsUser: boolean = false;
  yearList: SelectItem[];
  locale = this.translate.currentLang ? this.translate.currentLang : 'en';

  /**
   * Date picker configuration option
   */
  public startDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '26px',
    selectionTxtFontSize: '14px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
    highlightDates: this.holidayList,
  };
  username: string;
  Approver: any;
  private subscriptions: Subscription[] = [];

  //#endregion

  //#region constructor
  /**
   * constructor
   * @param employeeLeaveService {EmployeeLeaveService}
   * @param route {ActivatedRoute}
   * @param _fb {FormBuilder}
   */
  constructor(
    public employeeLeaveService: EmployeeLeaveService,
    private route: ActivatedRoute,
    private _fb: FormBuilder,
    private _sanitizer: DomSanitizer,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private businessservice: BusinessServices
  ) {
    console.log(this.translate.currentLang);

    this.leaveListForm = this._fb.group({
      startDate: [''],
      endDate: [''],
    });
    this.DayPilotForm = this._fb.group({
      DayPilotStartDate: [''],
      DayPilotEndDate: [''],
    });
    let videoPath = this.route.snapshot.data['infovideo'];
    if (videoPath && videoPath.VideoPath) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        videoPath.VideoPath
      );
    }
    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe((val) => {
        this.additionalData = {
          fileName: val.EMPLOYEE_LEAVE_LIST,
          header: 'Employee Leave List',
          businessId: BaseServices.BusinessId,
          cultureInfo: BaseServices.userCultureInfo(),
          columnNames: [
            { title: 'Employee Name', dataKey: 'EmployeeName' },
            { title: 'Start Date', dataKey: 'StartDate' },
            { title: 'End Date', dataKey: 'EndDate' },
            { title: 'No Of Days', dataKey: 'NoOfDays' },
            { title: 'Status', dataKey: 'StatusText' },
            { title: 'Approval Person', dataKey: 'ApprovalPersonName' },
            { title: 'Date Approval', dataKey: 'AprDate' },
          ],
        };
      })
    );
  }
  //#endregion

  //#region page-events
  /**
   * page load event
   */
  ngOnInit() {
    this.getYear = new Date().getFullYear();
    document.querySelector('body').classList.remove('opened');
    this.leaves = [];
    let list = this.route.snapshot.data['list'];
    let customList = list.slice(0, 20);
    customList.sort(function (a: any, b: any) {
      let c: any = new Date(a.StartDate);
      let d: any = new Date(b.StartDate);
      return c - d;
    });
    if (list) {
      this.leaves = list;
      this.dayPilotLeaveList = list;
    }
    this.bindDropdowns();
    this.subscriptions.push(
      this.employeeLeaveService
        .getAllHolidays(BaseServices.BusinessId)
        .subscribe((hList) => {
          hList.forEach((element) => {
            var hDate = new Date(element['HolidayDate']);
            var hDateFormatted = {
              day: hDate.getDate(),
              month: hDate.getMonth() + 1,
              year: hDate.getFullYear(),
            };
            this.holidayList.push(hDateFormatted);
          });
        })
    );
    this.resourcesList = [{ name: '', id: 0, days: 0, remainingDays: 0 }];
    this.loadFullCalender();
    this.yearList = [];
    this.yearList.push({ label: 'All', value: null });
    this.leaves.forEach((x: any) => {
      let year = new Date(x.StartDate).getFullYear();
      this.yearList.push({ label: year + '', value: year });
    });
    this.yearList = Array.from(
      this.yearList.reduce((m, t) => m.set(t.value, t), new Map()).values()
    );
    this.yearList = this.yearList.sort((a, b) => a.value - b.value);
  }

  loadFullCalender() {
    (<any>$('.kubaCalendar')).fullCalendar({
      locale: mapCurrentLandToFullCalendarLocale(this.translate.currentLang),
      customButtons: {
        save: {
          text: this.translate.instant('SAVE'),
          click: () => this.saveDayPilotEvents(),
        },
        fullScreen: {
          text: this.translate.instant('EXPAND'),
          click: () => this.fullScreen(),
        },
        exportExcel: {
          text:
            this.translate.instant('EXPORT_TO') +
            ' ' +
            this.translate.instant('EXCEL'),
          click: () => this.exportLeaveDataToExcel(),
        },
        year: {
          text: this.translate.instant('YEAR'),
          click: () => this.changeDayPilotHeader(3),
        },
        month: {
          text: this.translate.instant('MONTH'),
          click: () => this.changeDayPilotHeader(2),
        },
        week: {
          text: this.translate.instant('WEEK'),
          click: () => this.changeDayPilotHeader(1),
        },
      },
      header: {
        left: 'year,month,week',
        right: 'save,fullScreen,exportExcel',
      },
      defaultView: 'timelineDays',
      visibleRange: {
        start: moment(new Date()).format('YYYY-MM-DD'),
        end: moment(new Date().setDate(new Date().getDate() + 1)),
      },
      views: {
        timelineDays: {
          type: 'timeline',
          slotDuration: { days: 1 },
        },
        timelineWeek: {
          type: 'timeline',
          duration: { weeks: 1 },
          slotDuration: { days: 1 },
          buttonText: this.translate.instant('WEEK'),
        },
        timelineMonth: {
          type: 'timeline',
          duration: { months: 1 },
          slotDuration: { days: 1 },
          buttonText: this.translate.instant('MONTH'),
        },
        timelineYear: {
          type: 'timeline',
          duration: { years: 1 },
          slotDuration: { days: 1 },
          buttonText: this.translate.instant('YEAR'),
        },
      },
      resourceColumns: [
        {
          labelText: this.translate.instant('NAME'),
          field: 'name',
          width: '80px',
        },
        {
          labelText: this.translate.instant('ALLOCATED_DAYS'),
          field: 'days',
          width: '80px',
        },
        {
          labelText: this.translate.instant('REMAINING_DAYS'),
          field: 'remainingDays',
          width: '80px',
        },
      ],

      resources: this.resourcesList,
      events: this.eventsList,
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      contentHeight: 'auto',
      editable: true,
      //aspectRatio: 1.5,

      eventAfterRender: function (event: any, element: any, view: any) {
        var calStart = (<any>$('.kubaCalendar'))
          .fullCalendar('getView')
          .start.format();
        var calEnd = moment(
          (<any>$('.kubaCalendar')).fullCalendar('getView').end.format()
        )
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
        if (event.end == null) event.end = event.start;
        var a = moment(event.start).format();
        var b = moment(event.end).format();
        if (calStart > a) a = calStart;
        if (calEnd < b) b = calEnd;
        var nofDays = moment(b).diff(a, 'days') + 1;
        var width = nofDays * 42 + 'px';
        $(element).css('width', width);
        $(element).css('height', '30px');
        element.append(event.title);
        element.attr('title', event.toolTip);
      },
      eventDrop: (
        event: any,
        delta: any,
        minuteDelta: any,
        allDay: any,
        revertFunc: any,
        view: any
      ) => {
        event.start = event.start;
        event.end = event.end;
        var resourceObject = (<any>$('.kubaCalendar')).fullCalendar(
          'getResourceById',
          event.resourceId
        );
        let daystoSubtract = 0,
          count = 0;
        var i = this.leaves.findIndex((x: any) => x.Id == event.id);
        var index = this.eventsList.findIndex((x: any) => x.id == event.id);
        if (
          new Date(this.eventsList[index].start) <
          new Date(this.dayPilotStartDate)
        ) {
          let tempStart = new Date(this.dayPilotStartDate);
          while (tempStart >= new Date(this.eventsList[index].start)) {
            if (
              this.holidayList.findIndex(
                (x: any) =>
                  x.year == tempStart.getFullYear() &&
                  x.month == tempStart.getMonth() + 1 &&
                  x.day == tempStart.getDate()
              ) > 0 ||
              ((!this.leaves[i].IsShowWeekend ||
                this.leaves[i].IsShowWeekend == null) &&
                (new Date(tempStart).getDay() == 0 ||
                  new Date(tempStart).getDay() == 6))
            )
              count++;
            tempStart = new Date(
              new Date(tempStart).setDate(new Date(tempStart).getDate() - 1)
            );
          }
          daystoSubtract =
            daystoSubtract +
            moment(this.dayPilotStartDate).diff(
              moment(this.eventsList[i].start),
              'days'
            ) +
            1 -
            count;
        }

        if (
          new Date(this.eventsList[index].end) > new Date(this.dayPilotEndaDate)
        ) {
          let tempEnd = new Date(this.dayPilotEndaDate);
          count = 0;
          while (tempEnd <= new Date(this.eventsList[index].end)) {
            if (
              this.holidayList.findIndex(
                (x: any) =>
                  x.year == tempEnd.getFullYear() &&
                  x.month == tempEnd.getMonth() + 1 &&
                  x.day == tempEnd.getDate()
              ) > 0 ||
              ((!this.leaves[i].IsShowWeekend ||
                this.leaves[i].IsShowWeekend == null) &&
                (new Date(tempEnd).getDay() == 0 ||
                  new Date(tempEnd).getDay() == 6))
            )
              count++;
            tempEnd = new Date(
              new Date(tempEnd).setDate(new Date(tempEnd).getDate() + 1)
            );
          }
          daystoSubtract =
            daystoSubtract +
            moment(this.eventsList[i].end).diff(
              moment(this.dayPilotEndaDate),
              'days'
            ) +
            1 -
            count;
        }

        if (i >= 0) {
          this.leaves[i].StartDate = moment(event.start).format();
          this.leaves[i].EndDate = moment(event.end).format();
        }

        if (index >= 0) {
          this.eventsList[index].start = moment(event.start).format(
            'YYYY-MM-DD'
          );
          this.eventsList[index].end = moment(event.end).format('YYYY-MM-DD');
        }

        resourceObject['days'] =
          parseInt(resourceObject['days']) - event.nofDays + daystoSubtract;

        var daysToReduce = 0;
        var tempStart = moment(event.start).format('YYYY-MM-DD');
        while (tempStart <= moment(event.end).format('YYYY-MM-DD')) {
          if (
            this.holidayList.findIndex(
              (x: any) =>
                x.year == new Date(tempStart).getFullYear() &&
                x.month == new Date(tempStart).getMonth() + 1 &&
                x.day == new Date(tempStart).getDate()
            ) > 0 ||
            ((!this.leaves[i].IsShowWeekend ||
              this.leaves[i].IsShowWeekend == null) &&
              (new Date(tempStart).getDay() == 0 ||
                new Date(tempStart).getDay() == 6))
          )
            daysToReduce++;
          tempStart = moment(tempStart).add(1, 'days').format('YYYY-MM-DD');
        }
        daystoSubtract = 0;
        count = 0;
        if (moment(event.start).format() < this.dayPilotStartDate) {
          let tempStart = new Date(this.dayPilotStartDate);
          while (tempStart >= event.start) {
            if (
              this.holidayList.findIndex(
                (x: any) =>
                  x.year == tempStart.getFullYear() &&
                  x.month == tempStart.getMonth() + 1 &&
                  x.day == tempStart.getDate()
              ) > 0 ||
              ((!this.leaves[i].IsShowWeekend ||
                this.leaves[i].IsShowWeekend == null) &&
                (new Date(tempStart).getDay() == 0 ||
                  new Date(tempStart).getDay() == 6))
            )
              count++;
            tempStart = new Date(
              new Date(tempStart).setDate(new Date(tempStart).getDate() - 1)
            );
          }
          daystoSubtract =
            daystoSubtract +
            moment(this.dayPilotStartDate).diff(moment(event.start), 'days') +
            1 -
            count;
        }

        if (moment(event.end).format() > this.dayPilotEndaDate) {
          let tempEnd = new Date(this.dayPilotEndaDate);
          count = 0;
          while (tempEnd <= event.end) {
            if (
              this.holidayList.findIndex(
                (x: any) =>
                  x.year == tempEnd.getFullYear() &&
                  x.month == tempEnd.getMonth() + 1 &&
                  x.day == tempEnd.getDate()
              ) > 0 ||
              ((!this.leaves[i].IsShowWeekend ||
                this.leaves[i].IsShowWeekend == null) &&
                (new Date(tempEnd).getDay() == 0 ||
                  new Date(tempEnd).getDay() == 6))
            )
              count++;
            tempEnd = new Date(
              new Date(tempEnd).setDate(new Date(tempEnd).getDate() + 1)
            );
          }
          daystoSubtract =
            daystoSubtract +
            moment(event.end).diff(moment(this.dayPilotEndaDate), 'days') +
            1 -
            count;
        }

        var a = moment(event.start).format();
        var b = moment(event.end).format();
        event.nofDays = moment(b).diff(a, 'days') + 1;
        event.allDay = true;
        event.width = event.nofDays * 50 + 'px';
        this.leaves[i].NoOfDays = event.nofDays - daysToReduce;
        this.eventsList[index].nofDays = event.nofDays - daysToReduce;
        event.nofDays = event.nofDays - daysToReduce;
        event.toolTip =
          ' ' +
          this.translate.instant('LEAVE_DETAILS') +
          ' \n' +
          '' +
          this.translate.instant('NAME') +
          ': ' +
          this.eventsList[index].eName +
          ' \n' +
          '' +
          this.translate.instant('FROM_DATE') +
          ': ' +
          this.eventsList[index].start +
          ' \n' +
          '' +
          this.translate.instant('TO_DATE') +
          ': ' +
          this.eventsList[index].end +
          ' \n' +
          '' +
          this.translate.instant('NO_OF_DAYS') +
          ': ' +
          this.eventsList[index].nofDays +
          ' \n' +
          '' +
          this.translate.instant('STATUS') +
          ': ' +
          (this.eventsList[index].status === 8
            ? this.translate.instant('OPEN')
            : this.eventsList[index].status === 6
            ? this.translate.instant('APPROVED')
            : this.translate.instant('REJECTED')) +
          ' \n' +
          '' +
          this.translate.instant('APPROVAL_PERSON') +
          ': ' +
          this.eventsList[index].aName +
          ' \n' +
          '';
        resourceObject['days'] =
          parseInt(resourceObject['days']) + event.nofDays - daystoSubtract;
        (<any>$('.kubaCalendar')).fullCalendar(
          'option',
          'resources',
          resourceObject
        );
        (<any>$('.kubaCalendar')).fullCalendar('removeEvents', event.id);
        (<any>$('.kubaCalendar')).fullCalendar('renderEvent', event);
      },
      eventResize: (event: any, delta: any, revertFunc: any, ui: any) => {
        event.start = event.start; //moment(start.setDate(start.getDate() + delta.days())).format('YYYY-MM-DD');
        event.end = event.end; //moment(end.setDate(end.getDate() + delta.days())).format('YYYY-MM-DD');
        var resourceObject = (<any>$('.kubaCalendar')).fullCalendar(
          'getResourceById',
          event.resourceId
        );
        let daystoSubtract = 0,
          count = 0;
        var i = this.leaves.findIndex((x: any) => x.Id == event.id);
        var index = this.eventsList.findIndex((x: any) => x.id == event.id);
        if (
          moment(this.eventsList[index].start).format() <
          moment(this.dayPilotStartDate).format()
        ) {
          let tempStart = new Date(this.dayPilotStartDate);
          while (tempStart >= new Date(this.eventsList[index].start)) {
            if (
              this.holidayList.findIndex(
                (x: any) =>
                  x.year == tempStart.getFullYear() &&
                  x.month == tempStart.getMonth() + 1 &&
                  x.day == tempStart.getDate()
              ) > 0 ||
              ((!this.leaves[i].IsShowWeekend ||
                this.leaves[i].IsShowWeekend == null) &&
                (new Date(tempStart).getDay() == 0 ||
                  new Date(tempStart).getDay() == 6))
            )
              count++;
            tempStart = new Date(
              new Date(tempStart).setDate(new Date(tempStart).getDate() - 1)
            );
          }
          daystoSubtract =
            daystoSubtract +
            moment(this.dayPilotStartDate).diff(
              moment(this.eventsList[i].start),
              'days'
            ) -
            count;
        }

        if (
          new Date(this.eventsList[index].end) > new Date(this.dayPilotEndaDate)
        ) {
          let tempEnd = new Date(this.dayPilotEndaDate);
          count = 0;
          while (tempEnd <= new Date(this.eventsList[index].end)) {
            if (
              this.holidayList.findIndex(
                (x: any) =>
                  x.year == tempEnd.getFullYear() &&
                  x.month == tempEnd.getMonth() + 1 &&
                  x.day == tempEnd.getDate()
              ) > 0 ||
              ((!this.leaves[i].IsShowWeekend ||
                this.leaves[i].IsShowWeekend == null) &&
                (new Date(tempEnd).getDay() == 0 ||
                  new Date(tempEnd).getDay() == 6))
            )
              count++;
            tempEnd = new Date(
              new Date(tempEnd).setDate(new Date(tempEnd).getDate() + 1)
            );
          }
          daystoSubtract =
            daystoSubtract +
            moment(this.eventsList[i].end).diff(
              moment(this.dayPilotEndaDate),
              'days'
            ) -
            count;
        }

        if (i >= 0) {
          this.leaves[i].StartDate = moment(event.start).format();
          this.leaves[i].EndDate = moment(event.end).format();
        }

        if (index >= 0) {
          this.eventsList[index].start = moment(event.start).format(
            'YYYY-MM-DD'
          );
          this.eventsList[index].end = moment(event.end).format('YYYY-MM-DD');
        }

        resourceObject['days'] =
          parseInt(resourceObject['days']) - event.nofDays + daystoSubtract;

        var daysToReduce = 0;
        var tempStart = moment(event.start).format('YYYY-MM-DD');
        while (tempStart <= moment(event.end).format('YYYY-MM-DD')) {
          if (
            this.holidayList.findIndex(
              (x: any) =>
                x.year == new Date(tempStart).getFullYear() &&
                x.month == new Date(tempStart).getMonth() + 1 &&
                x.day == new Date(tempStart).getDate()
            ) > 0 ||
            ((!this.leaves[i].IsShowWeekend ||
              this.leaves[i].IsShowWeekend == null) &&
              (new Date(tempStart).getDay() == 0 ||
                new Date(tempStart).getDay() == 6))
          )
            daysToReduce++;
          tempStart = moment(tempStart).add(1, 'days').format('YYYY-MM-DD');
        }
        daystoSubtract = 0;
        count = 0;
        if (moment(event.start).format() < this.dayPilotStartDate) {
          let tempStart = new Date(this.dayPilotStartDate);
          while (tempStart >= event.start) {
            if (
              this.holidayList.findIndex(
                (x: any) =>
                  x.year == tempStart.getFullYear() &&
                  x.month == tempStart.getMonth() + 1 &&
                  x.day == tempStart.getDate()
              ) > 0 ||
              ((!this.leaves[i].IsShowWeekend ||
                this.leaves[i].IsShowWeekend == null) &&
                (new Date(tempStart).getDay() == 0 ||
                  new Date(tempStart).getDay() == 6))
            )
              count++;
            tempStart = new Date(
              new Date(tempStart).setDate(new Date(tempStart).getDate() - 1)
            );
          }
          daystoSubtract =
            daystoSubtract +
            moment(this.dayPilotStartDate).diff(moment(event.start), 'days') -
            count;
        }

        if (moment(event.end).format() > this.dayPilotEndaDate) {
          let tempEnd = new Date(this.dayPilotEndaDate);
          count = 0;
          while (tempEnd <= event.end) {
            if (
              this.holidayList.findIndex(
                (x: any) =>
                  x.year == tempEnd.getFullYear() &&
                  x.month == tempEnd.getMonth() + 1 &&
                  x.day == tempEnd.getDate()
              ) > 0 ||
              ((!this.leaves[i].IsShowWeekend ||
                this.leaves[i].IsShowWeekend == null) &&
                (new Date(tempEnd).getDay() == 0 ||
                  new Date(tempEnd).getDay() == 6))
            )
              count++;
            tempEnd = new Date(
              new Date(tempEnd).setDate(new Date(tempEnd).getDate() + 1)
            );
          }
          daystoSubtract =
            daystoSubtract +
            moment(event.end).diff(moment(this.dayPilotEndaDate), 'days') -
            count;
        }

        var a = moment(event.start).format();
        var b = moment(event.end).format();
        event.nofDays = moment(b).diff(a, 'days') + 1;
        event.allDay = true;
        event.width = event.nofDays * 50 + 'px';
        this.leaves[i].NoOfDays = event.nofDays - daysToReduce;
        this.eventsList[index].nofDays = event.nofDays - daysToReduce;
        event.nofDays = event.nofDays - daysToReduce;
        event.toolTip =
          ' ' +
          this.translate.instant('LEAVE_DETAILS') +
          ' \n' +
          '' +
          this.translate.instant('NAME') +
          ': ' +
          this.eventsList[index].eName +
          ' \n' +
          '' +
          this.translate.instant('FROM_DATE') +
          ': ' +
          this.eventsList[index].start +
          ' \n' +
          '' +
          this.translate.instant('TO_DATE') +
          ': ' +
          this.eventsList[index].end +
          ' \n' +
          '' +
          this.translate.instant('NO_OF_DAYS') +
          ': ' +
          this.eventsList[index].nofDays +
          ' \n' +
          '' +
          this.translate.instant('STATUS') +
          ': ' +
          (this.eventsList[index].status === 8
            ? this.translate.instant('OPEN')
            : this.eventsList[index].status === 6
            ? this.translate.instant('APPROVED')
            : this.translate.instant('REJECTED')) +
          ' \n' +
          '' +
          this.translate.instant('APPROVAL_PERSON') +
          ': ' +
          this.eventsList[index].aName +
          ' \n' +
          '';
        resourceObject['days'] =
          parseInt(resourceObject['days']) + event.nofDays - daystoSubtract;
        (<any>$('.kubaCalendar')).fullCalendar(
          'option',
          'resources',
          resourceObject
        );
        (<any>$('.kubaCalendar')).fullCalendar('removeEvents', event.id);
        (<any>$('.kubaCalendar')).fullCalendar('renderEvent', event);
      },
      eventClick: function (event: any, jsEvent: any, view: any) {
        var sw: any = document.getElementById('menu');
        $('#eventPara').remove();
        if (sw.style.display === 'block') {
          sw.style.display = 'none';
          $('#eventPara').remove();
        } else {
          sw.style.display = 'block';
          $('#menu').append(
            '<p id="eventPara" style="color:rgba(0, 0, 0, 0);height:0px">' +
              event.id +
              '</p>'
          );
          $('#menu').css({
            top: jsEvent.pageY - 275,
            left: jsEvent.pageX - 260,
            width: '120px',
            position: 'absolute',
            border: '1px solid black',
            padding: '5px',
          });
        }
      },
    });
  }
  OpenRequest() {
    var eventId = document.getElementById('eventPara')!.innerText;
    var sw: any = document.getElementById('menu');
    if (sw.style.display === 'block') {
      sw.style.display = 'none';
    } else {
      sw.style.display = 'block';
    }
    let rowId = eventId;
    let leaveDetails = this.leaves.filter((x: any) => x.Id === parseInt(rowId));
    if (leaveDetails.length > 0) {
      if (leaveDetails[0].Status == 6 && parseInt(BaseServices.roleId) != 3) {
        alert(this.translate.instant('OPEN_ERR_MSG_1'));
        return;
      }
      if (leaveDetails[0].Status == 8) {
        alert(this.translate.instant('OPEN_ERR_MSG_2'));
        return;
      }
      if (leaveDetails[0].Status !== '8') {
        if (
          leaveDetails[0].ApprovalPerson === BaseServices.UserId ||
          parseInt(BaseServices.roleId) == 3
        ) {
          this.leaveDetail = new EmployeeLeave();
          this.leaveDetail = leaveDetails[0];
          this.leaveDetail.Status = '8';
          if (confirm(this.translate.instant('CONFIRM_LEAVE_OPEN'))) {
            var index = this.eventsList.findIndex((x: any) => x.id == eventId);
            if (index >= 0) {
              this.eventsList[index].status = 8;
              var i = this.leaves.findIndex((x: any) => x.Id == eventId);
              if (i >= 0) {
                this.leaves[i].Status = 8;
                this.leaves[i].StatusText = 'OPEN';
                this.eventsList[index].backgroundColor = '#FFFF33';
                this.eventsList[index].title = this.translate.instant('OPEN');
                this.eventsList[index].toolTip =
                  ' ' +
                  this.translate.instant('LEAVE_DETAILS') +
                  ' \n' +
                  '' +
                  this.translate.instant('NAME') +
                  ': ' +
                  this.eventsList[index].eName +
                  ' \n' +
                  '' +
                  this.translate.instant('FROM_DATE') +
                  ': ' +
                  this.eventsList[index].start +
                  ' \n' +
                  '' +
                  this.translate.instant('TO_DATE') +
                  ': ' +
                  this.eventsList[index].end +
                  ' \n' +
                  '' +
                  this.translate.instant('NO_OF_DAYS') +
                  ': ' +
                  this.eventsList[index].nofDays +
                  ' \n' +
                  '' +
                  this.translate.instant('STATUS') +
                  ': ' +
                  (this.eventsList[index].status === 8
                    ? this.translate.instant('OPEN')
                    : this.eventsList[index].status === 6
                    ? this.translate.instant('APPROVED')
                    : this.translate.instant('REJECTED')) +
                  ' \n' +
                  '' +
                  this.translate.instant('APPROVAL_PERSON') +
                  ': ' +
                  BaseServices.userProfile['Name'] +
                  ' \n' +
                  '';
                this.eventsList[index].startEditable = true;
                this.eventsList[index].durationEditable = true;
                this.eventsList[index].aName = BaseServices.userProfile['Name'];
                this.leaves[i].ApprovalPersonName =
                  BaseServices.userProfile['Name'];
                this.leaves[i].AprDate = null;
                this.filterTable();
              }
              (<any>$('.kubaCalendar')).fullCalendar(
                'option',
                'events',
                this.eventsList
              );
              (<any>$('.kubaCalendar')).fullCalendar('refetchEvents');

              (<any>$('.kubaCalendar')).fullCalendar('removeEvents');
              (<any>$('.kubaCalendar')).fullCalendar(
                'addEventSource',
                this.eventsList
              );
              alert(this.translate.instant('ALERT_LEAVE_OPEN'));
            }
          }
        } else {
          alert('OPEN_ERR_MSG_3');
        }
      }
    }
  }
  ApproveRequest() {
    var eventId = document.getElementById('eventPara')!.innerText;
    var sw: any = document.getElementById('menu');
    if (sw.style.display === 'block') {
      sw.style.display = 'none';
    } else {
      sw.style.display = 'block';
    }
    let rowId = eventId.toString();
    let leaveDetails = this.leaves.filter((x: any) => x.Id === parseInt(rowId));
    if (leaveDetails.length > 0) {
      if (leaveDetails[0].Status == 6) {
        alert(this.translate.instant('APPROVED_ERR_MSG_2'));
        return;
      }
      if (leaveDetails[0].Status !== '6') {
        if (
          leaveDetails[0].ApprovalPerson === BaseServices.UserId ||
          parseInt(BaseServices.roleId) == 3
        ) {
          this.leaveDetail = new EmployeeLeave();
          this.leaveDetail = leaveDetails[0];
          this.leaveDetail.Status = '6';
          if (confirm(this.translate.instant('CONFIRM_LEAVE_APPROVED'))) {
            var index = this.eventsList.findIndex((x: any) => x.id == eventId);
            if (index >= 0) {
              this.eventsList[index].status = 6;
              var i = this.leaves.findIndex((x: any) => x.Id == eventId);
              if (i >= 0) {
                this.leaves[i].Status = 6;
                this.leaves[i].StatusText = 'APPROVED';
                this.eventsList[index].backgroundColor = '#2db300';
                this.eventsList[index].title =
                  this.translate.instant('APPROVED');
                this.eventsList[index].toolTip =
                  ' ' +
                  this.translate.instant('LEAVE_DETAILS') +
                  ' \n' +
                  '' +
                  this.translate.instant('NAME') +
                  ': ' +
                  this.eventsList[index].eName +
                  ' \n' +
                  '' +
                  this.translate.instant('FROM_DATE') +
                  ': ' +
                  this.eventsList[index].start +
                  ' \n' +
                  '' +
                  this.translate.instant('TO_DATE') +
                  ': ' +
                  this.eventsList[index].end +
                  ' \n' +
                  '' +
                  this.translate.instant('NO_OF_DAYS') +
                  ': ' +
                  this.eventsList[index].nofDays +
                  ' \n' +
                  '' +
                  this.translate.instant('STATUS') +
                  ': ' +
                  (this.eventsList[index].status === 8
                    ? this.translate.instant('OPEN')
                    : this.eventsList[index].status === 6
                    ? this.translate.instant('APPROVED')
                    : this.translate.instant('REJECTED')) +
                  ' \n' +
                  '' +
                  this.translate.instant('APPROVAL_PERSON') +
                  ': ' +
                  BaseServices.userProfile['Name'] +
                  ' \n' +
                  '';
                this.eventsList[index].startEditable = false;
                this.eventsList[index].durationEditable = false;
                this.eventsList[index].aName = BaseServices.userProfile['Name'];
                this.leaves[i].ApprovalPersonName =
                  BaseServices.userProfile['Name'];

                this.leaves[i].AprDate = HelperService.formatDate(new Date());
                this.filterTable();
              }
              (<any>$('.kubaCalendar')).fullCalendar(
                'option',
                'events',
                this.eventsList
              );
              (<any>$('.kubaCalendar')).fullCalendar('refetchEvents');

              (<any>$('.kubaCalendar')).fullCalendar('removeEvents');
              (<any>$('.kubaCalendar')).fullCalendar(
                'addEventSource',
                this.eventsList
              );
              alert(this.translate.instant('ALERT_LEAVE_APPROVED'));
            }
          }
        } else {
          alert(this.translate.instant('APPROVED_ERR_MSG_3'));
        }
      }
    }
  }
  RejectRequest() {
    var eventId = document.getElementById('eventPara')!.innerText;
    var sw: any = document.getElementById('menu');
    if (sw.style.display === 'block') {
      sw.style.display = 'none';
    } else {
      sw.style.display = 'block';
    }
    let rowId = eventId;
    let leaveDetails = this.leaves.filter((x: any) => x.Id === parseInt(rowId));
    if (leaveDetails.length > 0) {
      if (leaveDetails[0].Status == 6 && parseInt(BaseServices.roleId) != 3) {
        alert(this.translate.instant('REJECTED_ERR_MSG_1'));
        return;
      }
      if (leaveDetails[0].Status == 10) {
        alert(this.translate.instant('REJECTED_ERR_MSG_2'));
        return;
      }
      if (leaveDetails[0].Status !== '10') {
        if (
          leaveDetails[0].ApprovalPerson === BaseServices.UserId ||
          parseInt(BaseServices.roleId) == 3
        ) {
          this.leaveDetail = new EmployeeLeave();
          this.leaveDetail = leaveDetails[0];
          this.leaveDetail.Status = '10';
          if (confirm(this.translate.instant('CONFIRM_LEAVE_REJECTED'))) {
            var index = this.eventsList.findIndex((x: any) => x.id == eventId);
            if (index >= 0) {
              this.eventsList[index].status = 10;
              var i = this.leaves.findIndex((x: any) => x.Id == eventId);
              if (i >= 0) {
                this.leaves[i].Status = 10;
                this.leaves[i].StatusText = 'REJECTED';
                this.eventsList[index].backgroundColor = '#cc0033';
                this.eventsList[index].title =
                  this.translate.instant('REJECTED');
                this.eventsList[index].toolTip =
                  ' ' +
                  this.translate.instant('LEAVE_DETAILS') +
                  ' \n' +
                  '' +
                  this.translate.instant('NAME') +
                  ': ' +
                  this.eventsList[index].eName +
                  ' \n' +
                  '' +
                  this.translate.instant('FROM_DATE') +
                  ': ' +
                  this.eventsList[index].start +
                  ' \n' +
                  '' +
                  this.translate.instant('TO_DATE') +
                  ': ' +
                  this.eventsList[index].end +
                  ' \n' +
                  '' +
                  this.translate.instant('NO_OF_DAYS') +
                  ': ' +
                  this.eventsList[index].nofDays +
                  ' \n' +
                  '' +
                  this.translate.instant('STATUS') +
                  ': ' +
                  (this.eventsList[index].status === 8
                    ? this.translate.instant('OPEN')
                    : this.eventsList[index].status === 6
                    ? this.translate.instant('APPROVED')
                    : this.translate.instant('REJECTED')) +
                  ' \n' +
                  '' +
                  this.translate.instant('APPROVAL_PERSON') +
                  ': ' +
                  BaseServices.userProfile['Name'] +
                  ' \n' +
                  '';
                this.eventsList[index].startEditable = false;
                this.eventsList[index].durationEditable = false;
                this.eventsList[index].aName = BaseServices.userProfile['Name'];
                this.leaves[i].ApprovalPersonName =
                  BaseServices.userProfile['Name'];
                this.leaves[i].AprDate = null;
                this.filterTable();
              }
              (<any>$('.kubaCalendar')).fullCalendar(
                'option',
                'events',
                this.eventsList
              );
              (<any>$('.kubaCalendar')).fullCalendar('refetchEvents');

              (<any>$('.kubaCalendar')).fullCalendar('removeEvents');
              (<any>$('.kubaCalendar')).fullCalendar(
                'addEventSource',
                this.eventsList
              );
              alert(this.translate.instant('ALERT_LEAVE_REJECTED'));
            }
          }
        } else {
          alert(this.translate.instant('REJECTED_ERR_MSG_3'));
        }
      }
    }
  }
  RefreshDayPilotList() {
    if (
      this.dayPilotStartDate == null ||
      this.dayPilotStartDate == '' ||
      this.dayPilotStartDate == undefined
    ) {
      if (this.fullscreen) return;
      alert(this.translate.instant('FROM_DATE_ERR'));
      return;
    }
    if (
      this.dayPilotEndaDate == null ||
      this.dayPilotEndaDate == '' ||
      this.dayPilotEndaDate == undefined
    ) {
      if (this.fullscreen) return;
      alert(this.translate.instant('END_DATE_ERR'));
      return;
    }
    if (
      moment(this.daypilotEndDateFilter).format('YYYY-MM-DD') <
      moment(this.daypilotStartDateFilter).format('YYYY-MM-DD')
    ) {
      if (this.fullscreen) return;
      alert(this.translate.instant('FROM_END_DATE_INVALID_ERR'));
      return;
    }
    if (
      moment(moment(this.daypilotEndDateFilter).format('YYYY-MM-DD')).diff(
        moment(this.daypilotStartDateFilter).format('YYYY-MM-DD'),
        'days'
      ) > 731
    ) {
      if (this.fullscreen) return;
      alert(this.translate.instant('FROM_END_DATE_EXCEED_ERR'));
      return;
    }
    $('.kubaCalendar').children().prop('disabled', true);
    this.loading = true;
    this.subscriptions.push(
      this.employeeLeaveService
        .getAllByBusinessId(BaseServices.BusinessId, this.getYear)
        .subscribe((result) => {
          if (result) {
            this.leaves = result;
            this.dayPilotLeaveList = result;
            this.filterDaypilotEmpList();
            this.filterTable();
            this.loading = false;
            $('.kubaCalendar').children().prop('disabled', false);
          }
        })
    );
  }

  pad(n: any) {
    return n < 10 ? '0' + n : n;
  }

  changeDayPilotHeader(type: number) {
    var date = new Date();
    var month = date.getMonth();
    var year = date.getFullYear();
    var fdw = new Date(
      date.setDate(
        date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)
      )
    );
    var firstDayOfWeek =
      this.pad(fdw.getMonth() + 1) +
      '/' +
      this.pad(fdw.getDate()) +
      '/' +
      fdw.getFullYear();
    var firstDayOfWeekFilter =
      this.pad(fdw.getDate()) +
      '/' +
      this.pad(fdw.getMonth() + 1) +
      '/' +
      fdw.getFullYear();
    var fdm = new Date(year, month, 1);
    var firstDayOfMonth =
      fdm.getMonth() + 1 + '/' + fdm.getDate() + '/' + fdm.getFullYear();
    var firstDayOfMonthFilter =
      this.pad(fdm.getDate()) +
      '/' +
      this.pad(fdm.getMonth() + 1) +
      '/' +
      fdm.getFullYear();
    var fdy = new Date(year, 0, 1);
    var firstDayOfYear =
      fdy.getMonth() + 1 + '/' + fdy.getDate() + '/' + fdy.getFullYear();
    var firstDayOfYearFilter =
      this.pad(fdy.getDate()) +
      '/' +
      this.pad(fdy.getMonth() + 1) +
      '/' +
      fdy.getFullYear();
    var ldw = new Date(
      date.setDate(
        date.getDate() + ((date.getDay() === 7 ? 0 : 7) - date.getDay())
      )
    );
    var lastDayOfWeek =
      this.pad(ldw.getMonth() + 1) +
      '/' +
      this.pad(ldw.getDate()) +
      '/' +
      ldw.getFullYear();
    var lastDayOfWeekFilter =
      this.pad(ldw.getDate()) +
      '/' +
      this.pad(ldw.getMonth() + 1) +
      '/' +
      ldw.getFullYear();
    var ldm = new Date(year, month + 1, 0);
    var lastDayOfMonth =
      this.pad(ldm.getMonth() + 1) +
      '/' +
      this.pad(ldm.getDate()) +
      '/' +
      ldm.getFullYear();
    var lastDayOfMonthFilter =
      this.pad(ldm.getDate()) +
      '/' +
      this.pad(ldm.getMonth() + 1) +
      '/' +
      ldm.getFullYear();
    var ldy = new Date(year + 1, 0, 0);
    var lastDayOfYear =
      ldy.getMonth() + 1 + '/' + ldy.getDate() + '/' + ldy.getFullYear();
    var lastDayOfYearFilter =
      this.pad(ldy.getDate()) +
      '/' +
      this.pad(ldy.getMonth() + 1) +
      '/' +
      ldy.getFullYear();
    if (type == 1) {
      this.daypilotStartDateFilter = firstDayOfWeek;
      this.daypilotEndDateFilter = lastDayOfWeek;
      this.dayPilotStartDate = HelperService.formateDateFilterYMD(
        firstDayOfWeekFilter,
        '-'
      );
      this.dayPilotEndaDate = HelperService.formateDateFilterYMD(
        lastDayOfWeekFilter,
        '-'
      );
      this.startDate = firstDayOfWeek;
      this.endDate = lastDayOfWeek;
      this.RefreshDayPilotList();
    }
    if (type == 2) {
      this.daypilotStartDateFilter = firstDayOfMonth;
      this.daypilotEndDateFilter = lastDayOfMonth;
      this.dayPilotStartDate = HelperService.formateDateFilterYMD(
        firstDayOfMonthFilter,
        '-'
      );
      this.dayPilotEndaDate = HelperService.formateDateFilterYMD(
        lastDayOfMonthFilter,
        '-'
      );
      this.startDate = firstDayOfMonth;
      this.endDate = lastDayOfMonth;
      this.RefreshDayPilotList();
    }
    if (type == 3) {
      this.daypilotStartDateFilter = firstDayOfYear;
      this.daypilotEndDateFilter = lastDayOfYear;
      this.dayPilotStartDate = HelperService.formateDateFilterYMD(
        firstDayOfYearFilter,
        '-'
      );
      this.dayPilotEndaDate = HelperService.formateDateFilterYMD(
        lastDayOfYearFilter,
        '-'
      );
      this.startDate = firstDayOfYear;
      this.endDate = lastDayOfYear;
      this.RefreshDayPilotList();
    }
  }

  //#endregion

  //#region methodsl
  /**
   * get current date
   */
  formatDate(date: any) {
    let delimiter = '-';
    date = new Date(date);
    if (date) {
      let formattedMonth =
        date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      let formattedDate =
        date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      let currentDate: any = `${date.getFullYear()}${delimiter}${formattedMonth}${delimiter}${formattedDate}`;
      return currentDate;
    }
  }

  /**
   * table reset
   */
  refreshTable() {
    let list = this.route.snapshot.data['list'];
    if (list) {
      this.leaves = list;
    }
    let leaveDetails;
    leaveDetails = {
      startDate: '',
      endDate: '',
    };
    (<FormGroup>this.leaveListForm).setValue(leaveDetails, {
      onlySelf: true,
    });
  }
  /**
   * bind dropdown
   */
  bindDropdowns() {
    let approverList: any = [];
    this.leaves.forEach((x: any) => {
      x.ApprovalPersonNameList.forEach((y: any) => {
        approverList.push(y);
      });
    });
    approverList.sort();
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        let filteredEmployeeList = approverList
          .map((item) => item)
          .filter((value, index, self) => self.indexOf(value) === index);
        this.approvalPersonList = [];
        this.approvalPersonList.push({ label: val.SELECT, value: null });
        filteredEmployeeList.forEach((element: any) => {
          this.approvalPersonList.push({ label: element, value: element });
        });
      })
    );

    this.subscriptions.push(
      this.translate.stream('SELECT_STATUSESFORLEAVE').subscribe((val) => {
        this.statusList = [];
        this.statusList.push(
          { label: val.SELECT, value: null },
          { label: val.OPEN, value: 8 },
          { label: val.APPROVED, value: 6 },
          { label: val.REJECTED, value: 10 }
        );
      })
    );
    this.globalEmployeeLeaveFilter();
  }
  /**
   * pdf export
   */
  exportPdf() {
    let pdfSetting = new PdfSetting();
    pdfSetting.date = 'Date:' + HelperService.formatDate(new Date());
    pdfSetting.businessName = BaseServices.BusinessName;
    pdfSetting.pageHeader = 'Leave';
    let data = this.leaves;
    let columns: any = [
      { title: 'Employee Name', dataKey: 'EmployeeName' },
      { title: 'Start Date', dataKey: 'StartDate' },
      { title: 'End Date', dataKey: 'EndDate' },
      { title: 'No Of Days', dataKey: 'NoOfDays' },
      { title: 'Status', dataKey: 'Status' },
      { title: 'Approval Person', dataKey: 'ApprovalPerson' },
      { title: 'Date Of Approval', dataKey: 'DateOfApproval' },
    ];
    HelperService.generatePdf(
      data,
      columns,
      'EmployeeLeaveDetails',
      pdfSetting,
      'l'
    );
  }
  //#endregion

  //#region control-events
  /**
   * to filter status in list
   * @param e {event}
   * @param statusDropdown {any}
   */
  onStatusChanged(e: any, statusDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.leavesTable.filter(
        statusDropdown.selectedOption.value,
        'Status',
        'equals'
      );
    } else {
      this.leavesTable.reset();
    }
  }
  /**
   * startdate calendar onselect event
   * @param event
   */
  onStartDateChanged(event: any) {
    // custom filter datatable
    sessionStorage.setItem('search_EmployeeLeave_StartDate', event.jsdate);
    this.startDate = HelperService.formatDateFilter(event.formatted);
    this.startDateSource = this.startDate;
    this.filterTable();
    this.getYear = HelperService.getYearFilter(this.startDate);
  }
  /**
   * enddate calendar onselect event
   * @param event {Event}
   */
  onEndDateChanged(event: IMyDateModel) {
    // custom filter datatable
    this.endDate = HelperService.formatDateFilter(event.formatted);
    this.endDateSource = this.endDate;
    this.filterTable();
  }

  viewVideo() {
    if (this.safeURL.changingThisBreaksApplicationSecurity.trim() == '') {
      alert(this.translate.instant('INFO_VIDEO_URL'));
      this.isVideo = true;
    } else {
      this.isInfoVideo = true;
    }
  }
  filterTable() {
    let list = this.leaves;
    if (list) {
      this.leaves = [];
      if (this.startDate || this.endDate) {
        if (this.startDate && this.endDate) {
          list = list.filter((x: any) => {
            return (
              (new Date(x.StartDateForFilter) >= new Date(this.startDate) &&
                new Date(x.StartDateForFilter) <= new Date(this.endDate)) ||
              (new Date(x.EndDateForFilter) >= new Date(this.startDate) &&
                new Date(x.EndDateForFilter) <= new Date(this.endDate))
            );
          });
        } else if (this.endDate) {
          list = list.filter((x: any) => {
            return new Date(x.EndDateForFilter) <= new Date(this.endDate);
          });
        } else {
          list = list.filter((x: any) => {
            return new Date(x.StartDateForFilter) >= new Date(this.startDate);
          });
        }
      }
      this.leaves.push(...list);
    }
  }
  dayPilot() {
    if (this.dayPilotStartDate) {
      let list = this.dayPilotLeaveList;

      if (list) {
        if (this.dayPilotStartDate || this.dayPilotEndaDate) {
          if (this.dayPilotStartDate && this.dayPilotEndaDate) {
            list = list.filter((x: any) => {
              return (
                new Date(x.StartDateForFilter) >=
                  new Date(this.dayPilotStartDate) &&
                new Date(x.EndDateForFilter) <= new Date(this.dayPilotEndaDate)
              );
            });
          } else if (this.dayPilotEndaDate) {
            list = list.filter((x: any) => {
              return (
                new Date(x.EndDateForFilter) <= new Date(this.dayPilotEndaDate)
              );
            });
          } else {
            list = list.filter((x: any) => {
              return (
                new Date(x.StartDateForFilter) >=
                new Date(this.dayPilotStartDate)
              );
            });
          }
        }
        let employeeNames: any = [];
        let events = [];
        list.forEach((result) => {
          this.subscriptions.push(
            this.employeeLeaveService
              .getEmployeeAppliedLeavesByEmployeeId(result.EmployeeId)
              .subscribe((empLeave) => {
                if (empLeave) {
                  let checkEmployeeId = employeeNames.filter(
                    (x: any) => x.employeeId === result.EmployeeId
                  );
                  if (checkEmployeeId.length === 0) {
                    employeeNames.push({
                      name: result.EmployeeName,
                      id: `${result.Id}`,
                      employeeId: result.EmployeeId,
                      columns: [
                        {
                          html: empLeave + 'days',
                        },
                      ],
                    });
                  }
                }
              })
          );
          let checkStatus = employeeNames.filter(
            (x) =>
              x.employeeId === result.EmployeeId && x.status === result.Status
          );
          if (checkStatus.length === 0) {
          }
        });
      }
    }
  }
  onDayPilotStartDateOnChanged(event: IMyDateModel) {
    this.dayPilotStartDateRawData = event;
    this.daypilotStartDateFilter = HelperService.formatDateFilter(
      event.formatted
    );
    this.dayPilotStartDate = HelperService.formateDateFilterYMD(
      event.formatted,
      '-'
    );
    this.dayPilotSourceStart = this.dayPilotStartDate;
    this.dayPilotSourceStartFilter = this.daypilotStartDateFilter;
    this.dayPilot();
  }
  onDayPilotEndDateOnChanged(event: IMyDateModel) {
    this.dayPilotEndDateRawData = event;
    this.daypilotEndDateFilter = HelperService.formatDateFilter(
      event.formatted
    );
    this.dayPilotEndaDate = HelperService.formateDateFilterYMD(
      event.formatted,
      '-'
    );
    this.dayPilotSourceEnd = this.dayPilotEndaDate;
    this.dayPilotSourceEndFilter = this.daypilotEndDateFilter;
    this.dayPilot();
  }
  //#endregion

  // #region export leave data to execFile
  exportLeaveDataToExcel() {
    if (
      this.dayPilotStartDate == null ||
      this.dayPilotStartDate == '' ||
      this.dayPilotStartDate == undefined
    ) {
      if (this.fullscreen) return;
      alert(this.translate.instant('FROM_DATE_ERR'));
      return;
    }
    if (
      this.dayPilotEndaDate == null ||
      this.dayPilotEndaDate == '' ||
      this.dayPilotEndaDate == undefined
    ) {
      alert(this.translate.instant('END_DATE_ERR'));
      return;
    }
    if (
      moment(this.daypilotEndDateFilter).format('YYYY-MM-DD') <
      moment(this.daypilotStartDateFilter).format('YYYY-MM-DD')
    ) {
      alert(this.translate.instant('FROM_END_DATE_INVALID_ERR'));
      return;
    }
    if (
      moment(moment(this.daypilotEndDateFilter).format('YYYY-MM-DD')).diff(
        moment(this.daypilotStartDateFilter).format('YYYY-MM-DD'),
        'days'
      ) > 731
    ) {
      alert(this.translate.instant('FROM_END_DATE_EXCEED_ERR'));
      return;
    }
    let sDate = new Date(this.dayPilotStartDate).toISOString();
    let eDate = new Date(this.dayPilotEndaDate).toISOString();
    this.subscriptions.push(
      this.employeeLeaveService
        .exportEmployeeLeaveToExcel(
          parseInt(sessionStorage.getItem('languageId')),
          BaseServices.BusinessId,
          sDate.toString(),
          eDate.toString()
        )
        .subscribe((data) => {
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download =
            this.translate.instant('EMPLOYEE_LEAVE_DATA') +
            '-' +
            BaseServices.BusinessName +
            '.xlsx';
          a.href = URL.createObjectURL(data);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
        })
    );
  }
  filterDaypilotEmpListFs() {
    this.dayPilotStartDate = this.dayPilotSourceStart;
    this.dayPilotEndaDate = this.dayPilotSourceEnd;
    this.daypilotEndDateFilter = this.dayPilotSourceEndFilter;
    this.daypilotStartDateFilter = this.dayPilotSourceStartFilter;
    this.startDate = this.startDateSource;
    this.endDate = this.endDateSource;
    if (
      this.dayPilotStartDate == null ||
      this.dayPilotStartDate == '' ||
      this.dayPilotStartDate == undefined
    ) {
      alert(this.translate.instant('FROM_DATE_ERR'));
      return;
    }
    if (
      this.dayPilotEndaDate == null ||
      this.dayPilotEndaDate == '' ||
      this.dayPilotEndaDate == undefined
    ) {
      alert(this.translate.instant('END_DATE_ERR'));
      return;
    }
    if (
      moment(this.daypilotEndDateFilter).format('YYYY-MM-DD') <
      moment(this.daypilotStartDateFilter).format('YYYY-MM-DD')
    ) {
      alert(this.translate.instant('FROM_END_DATE_INVALID_ERR'));
      return;
    } else {
      this.RefreshDayPilotList();
    }

    if (
      moment(moment(this.daypilotEndDateFilter).format('YYYY-MM-DD')).diff(
        moment(this.daypilotStartDateFilter).format('YYYY-MM-DD'),
        'days'
      ) > 731
    ) {
      alert(this.translate.instant('FROM_END_DATE_EXCEED_ERR'));
      return;
    } else {
      this.RefreshDayPilotList();
    }
  }

  filterDaypilotEmpList() {
    this.events = [];
    this.groupedlist = [];
    let resource: any = [];
    let event: any = [];
    let list = this.dayPilotLeaveList; //this.route.snapshot.data['list'];
    let dayPilotList: any = [];
    if (this.daypilotStartDateFilter && this.daypilotEndDateFilter) {
      let dayPilotStartDateRawData = new Date(this.daypilotStartDateFilter);
      let dayPilotEndDateRawData = new Date(this.daypilotEndDateFilter);

      dayPilotStartDateRawData.setHours(0, 0, 0, 0);
      dayPilotEndDateRawData.setHours(0, 0, 0, 0);

      // The number of milliseconds in one day
      let ONE_DAY = 1000 * 60 * 60 * 24;
      let startDate_ms = dayPilotStartDateRawData.getTime();
      let endDate_ms = dayPilotEndDateRawData.getTime();
      let difference_ms = Math.abs(startDate_ms - endDate_ms);
      let totalDays = Math.round(difference_ms / ONE_DAY) + 1;
      let allocatedleavedays = 0;

      dayPilotList = list.filter((x: any) => {
        return (
          (new Date(x.StartDateForFilter) >=
            new Date(this.daypilotStartDateFilter) &&
            new Date(x.StartDateForFilter) <=
              new Date(this.daypilotEndDateFilter)) ||
          (new Date(x.EndDateForFilter) >=
            new Date(this.daypilotStartDateFilter) &&
            new Date(x.EndDateForFilter) <=
              new Date(this.daypilotEndDateFilter))
        );
      });
      this.groupedlist = _.chain(dayPilotList)
        // Group the elements of Array based on `EmployeeId` property
        .groupBy('EmployeeId')
        // `key` is group's name (EmployeeId), `value` is the array of objects
        .map((value, key) => ({ employeeId: key, list: value, totalDays: 0 }))
        .value();
      this.groupedlist.forEach((element: any) => {
        let employeeId = element.employeeId;
        let empName = '';
        let noOfDays = 0;
        let remainDays = 0;
        let allocatedDays = 0;
        element.list.forEach((element) => {
          empName = element.EmployeeName;
          remainDays = element.RemainingDays;
          allocatedDays = element.TotalDays;
          let daystoSubtract = 0,
            count = 0;
          if (element.StartDate <= this.dayPilotStartDate) {
            let tempStart = new Date(
              new Date(this.dayPilotStartDate).setDate(
                new Date(this.dayPilotStartDate).getDate() - 1
              )
            );
            while (
              new Date(new Date(tempStart).setHours(0, 0, 0, 0)) >=
              new Date(element.StartDate)
            ) {
              if (
                this.holidayList.findIndex(
                  (x: any) =>
                    x.year == tempStart.getFullYear() &&
                    x.month == tempStart.getMonth() + 1 &&
                    x.day == tempStart.getDate()
                ) > 0 ||
                ((!element.IsShowWeekend || element.IsShowWeekend == null) &&
                  (new Date(tempStart).getDay() == 0 ||
                    new Date(tempStart).getDay() == 6))
              )
                count++;
              tempStart = new Date(
                new Date(tempStart).setDate(new Date(tempStart).getDate() - 1)
              );
            }
            daystoSubtract =
              daystoSubtract +
              moment(this.dayPilotStartDate).diff(
                moment(element.StartDate),
                'days'
              ) -
              count;
          }
          if (element.EndDate >= this.dayPilotEndaDate) {
            let tempEnd = new Date(
              new Date(this.dayPilotEndaDate).setDate(
                new Date(this.dayPilotEndaDate).getDate() + 1
              )
            );
            count = 0;
            while (
              new Date(new Date(tempEnd).setHours(0, 0, 0, 0)) <=
              new Date(element.EndDate)
            ) {
              if (
                this.holidayList.findIndex(
                  (x: any) =>
                    x.year == tempEnd.getFullYear() &&
                    x.month == tempEnd.getMonth() + 1 &&
                    x.day == tempEnd.getDate()
                ) > 0 ||
                ((!element.IsShowWeekend || element.IsShowWeekend == null) &&
                  (new Date(tempEnd).getDay() == 0 ||
                    new Date(tempEnd).getDay() == 6))
              )
                count++;
              tempEnd = new Date(
                new Date(tempEnd).setDate(new Date(tempEnd).getDate() + 1)
              );
            }
            daystoSubtract =
              daystoSubtract +
              moment(element.EndDate).diff(
                moment(this.dayPilotEndaDate),
                'days'
              ) -
              count;
          }
          noOfDays += element.NoOfDays - daystoSubtract;
          let eve = {
            id: element.Id,
            resourceId: employeeId,
            status: element.Status,
            eName: empName,
            aName: element.ApprovalPersonName,
            start: moment(element.StartDateForFilter).format('YYYY-MM-DD'),
            end: moment(element.EndDateForFilter).format('YYYY-MM-DD'),
            nofDays: element.NoOfDays,
            totalNoofDays: element.TotalDays,
            backgroundColor:
              element.Status === 8
                ? '#FFFF33'
                : element.Status === 6
                ? '#2db300'
                : '#cc0033',
            title:
              element.Status === 8
                ? this.translate.instant('OPEN')
                : element.Status === 6
                ? this.translate.instant('APPROVED')
                : this.translate.instant('REJECTED'),
            textColor: 'black',
            toolTip:
              ' ' +
              this.translate.instant('LEAVE_DETAILS') +
              ' \n' +
              '' +
              this.translate.instant('NAME') +
              ': ' +
              element.EmployeeName +
              ' \n' +
              '' +
              this.translate.instant('FROM_DATE') +
              ': ' +
              HelperService.formateDateFilterYMD(
                element.StartDateForFilter,
                '.'
              ) +
              ' \n' +
              '' +
              this.translate.instant('TO_DATE') +
              ': ' +
              HelperService.formateDateFilterYMD(
                element.EndDateForFilter,
                '.'
              ) +
              ' \n' +
              '' +
              this.translate.instant('NO_OF_DAYS') +
              ': ' +
              element.NoOfDays +
              ' \n' +
              '' +
              this.translate.instant('STATUS') +
              ': ' +
              (element.Status === 8
                ? this.translate.instant('OPEN')
                : element.Status === 6
                ? this.translate.instant('APPROVED')
                : this.translate.instant('REJECTED')) +
              ' \n' +
              '' +
              this.translate.instant('APPROVAL_PERSON') +
              ': ' +
              element.ApprovalPersonName +
              ' \n' +
              '',
            resourceEditable: false,
            startEditable: element.Status === 8 ? true : false,
            durationEditable: element.Status === 8 ? true : false,
          };
          event.push(eve);
        });
        element.totalDays = noOfDays == null ? 0 : noOfDays;
        element.allocatedleavedays = allocatedDays;
        let res = {
          name: empName,
          id: employeeId,
          days: element.allocatedleavedays,
          remainingDays: remainDays > 0 ? remainDays : '0',
        };
        resource.push(res);
      });
      this.events = event;
    }
    this.resourcesList = resource;

    if (resource.length <= 0) {
      let employeeNames = [];
      employeeNames.push({
        name: '',
        id: 0,
        days: 0,
      });
      this.resourcesList = employeeNames;
    }
    this.eventsList = event;
    (<any>$('.kubaCalendar')).fullCalendar(
      'option',
      'resources',
      this.resourcesList
    );
    (<any>$('.kubaCalendar')).fullCalendar('refetchResources');
    (<any>$('.kubaCalendar')).fullCalendar('option', 'events', this.eventsList);
    (<any>$('.kubaCalendar')).fullCalendar('refetchEvents');

    (<any>$('.kubaCalendar')).fullCalendar('changeView', 'timelineDays', {
      start: moment(this.daypilotStartDateFilter).format('YYYY-MM-DD'),
      end: moment(this.daypilotEndDateFilter).format('YYYY-MM-DD'),
    });
    (<any>$('.kubaCalendar')).fullCalendar('option', 'visibleRange', {
      start: moment(this.daypilotStartDateFilter).format('YYYY-MM-DD'),
      end: moment(this.daypilotEndDateFilter)
        .add(1, 'days')
        .format('YYYY-MM-DD'),
    });
    (<any>$('.kubaCalendar')).fullCalendar('removeEvents');
    (<any>$('.kubaCalendar')).fullCalendar('addEventSource', this.eventsList);
  }
  saveDayPilotEvents() {
    var modifiedEvents = (<any>$('.kubaCalendar')).fullCalendar(
      'clientEvents',
      function (event: any) {
        return true;
      }
    );
    let objIndex = -1;
    modifiedEvents.forEach((item: any) => {
      let val = {
        id: item.id,
        startDate: item.start,
        endDate: item.end,
        status: item.status,
        noOfDays: item.nofDays, //((moment(item.end._d).diff(item.start._d, 'days'))+1)
      };
      if (val.endDate == null) {
        val.endDate = val.startDate;
        val.noOfDays = 1;
      }
      if (this.modifiedEmployeeLeaves.length > 0) {
        objIndex = this.modifiedEmployeeLeaves.findIndex(
          (obj: any) => obj.id == val.id
        );
      }
      if (objIndex >= 0) {
        this.modifiedEmployeeLeaves[objIndex].startDate = val.startDate;
        this.modifiedEmployeeLeaves[objIndex].endDate = val.endDate;
        this.modifiedEmployeeLeaves[objIndex].status = val.status;
        this.modifiedEmployeeLeaves[objIndex].noOfDays = val.noOfDays;
      } else {
        this.modifiedEmployeeLeaves.push(val);
      }
    });
    this.subscriptions.push(
      this.employeeLeaveService
        .saveDayPilotLeaves(this.modifiedEmployeeLeaves, BaseServices.UserId)
        .subscribe(() => {
          this.toasterComponent.callToast();
          this.RefreshDayPilotList();
        })
    );
  }

  fullScreen() {
    var sw: any = document.getElementById('menu');
    if (sw.style.display === 'block') {
      sw.style.display = 'none';
      $('#eventPara').remove();
    }
    this.fullscreen = !this.fullscreen;
    var fs: any = document.getElementById('dpFullScreenModal');
    if (fs.style.display == 'block') fs.style.display = 'none';
    else {
      fs.style.display = 'block';
      if (
        !(
          this.dayPilotStartDate == null ||
          this.dayPilotStartDate == '' ||
          this.dayPilotStartDate == undefined
        )
      ) {
        this.startDateFs = {
          date: {
            year: moment(this.dayPilotStartDate).year(),
            month: moment(this.dayPilotStartDate).month() + 1,
            day: moment(this.dayPilotStartDate).date(),
          },
        };
      }
      if (
        !(
          this.dayPilotEndaDate == null ||
          this.dayPilotEndaDate == '' ||
          this.dayPilotEndaDate == undefined
        )
      ) {
        this.endDateFs = {
          date: {
            year: moment(this.dayPilotEndaDate).year(),
            month: moment(this.dayPilotEndaDate).month() + 1,
            day: moment(this.dayPilotEndaDate).date(),
          },
        };
      }
      this.loadFullCalender();
      this.RefreshDayPilotList();
    }
  }

  hideModal() {
    var sw: any = document.getElementById('menu');
    if (sw.style.display === 'block') {
      sw.style.display = 'none';
      $('#eventPara').remove();
    }
    this.fullscreen = false;
    this.startDate = this.startDateFs;
    this.endDateFs = this.endDate;
    $('#dpFullScreenModal').css('display', 'none');
  }
  Search() {
    this.dayPilotStartDate = this.dayPilotSourceStart;
    this.dayPilotEndaDate = this.dayPilotSourceEnd;
    this.daypilotEndDateFilter = this.dayPilotSourceEndFilter;
    this.daypilotStartDateFilter = this.dayPilotSourceStartFilter;
    this.startDate = this.startDateSource;
    this.endDate = this.endDateSource;
    this.RefreshDayPilotList();
  }
  globalEmployeeLeaveFilter() {
    let startDate = sessionStorage.getItem('search_EmployeeLeave_StartDate');

    this.startDateFilter =
      startDate && startDate !== 'null'
        ? HelperService.formatInputDate(new Date(startDate))
        : null;
  }

  delete(id: number) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.employeeLeaveService
            .deleteLeave(id)
            .subscribe((deleteResponse) => {
              if (deleteResponse) {
                this.subscriptions.push(
                  this.employeeLeaveService
                    .getAllByBusinessId(BaseServices.BusinessId, this.getYear)
                    .subscribe((result) => {
                      if (result) {
                        this.leaves = result;
                        this.dayPilotLeaveList = result;
                        this.filterDaypilotEmpList();
                        this.filterTable();
                        this.loading = false;
                        $('.kubaCalendar').children().prop('disabled', false);
                      }
                    })
                );
                this.toasterComponent.callToastDlt();
              }
            })
        );
      },
    });
  }
  GetUserRights() {
    this.subscriptions.push(
      this.businessservice.getByUserId(BaseServices.UserId).subscribe((res) => {
        this.userRights = res;
        params1 = [];
        var params1 = JSON.parse(this.userRights.Rights);
        this.Approver = params1.filter((x: any) => x.name == 'APPROVE_LEAVE');
        this.leaves = [];
        let list = this.route.snapshot.data['list'];
        let customList = list.slice(0, 20);
        customList.sort(function (a: any, b: any) {
          let c: any = new Date(a.StartDate);
          let d: any = new Date(b.StartDate);
          return c - d;
        });
        if (list) {
          this.leaves = list;
          this.dayPilotLeaveList = list;
          this.bindDropdowns();
        }
      })
    );
  }
  /**
   * to filter status in list
   * @param e {event}
   * @param YearDropdown {any}
   */
  onYearChanged(e: any, YearDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.leavesTable.filter(
        YearDropdown.selectedOption.value,
        'Year',
        'equals'
      );
    } else {
      this.leavesTable.reset();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
// #endregion
