
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
    template: `<router-outlet></router-outlet>`
})
export class InfoVideoComponent {
    constructor(private http: HttpClient, translate: TranslateService) {
    }
}
