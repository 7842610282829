<div class="tab-content">
    <div [formGroup]="sicknessForm">
        <div class="row">
            <div class="col-sm-6">
                <div class="page-title">
                    <span translate>SICKNESS_ABSENCE</span>
                </div>
                <div class="form-group">
                    <label translate>STATUS</label>
                    <div *ngIf="ApproverRights">
                        <label class="form-check form-check-inline" *ngFor="let option of options">
                            <input type="radio" formControlName="Status" value="{{option.id}}"
                              [checked]="option.id === sicknessForm.controls['Status'].value" class="form-check-input">
                            {{option.name | translate}}
                        </label>
                    </div>
                    <div *ngIf="!ApproverRights">
                        <label class="form-check form-check-inline" *ngFor="let option of options">
                            <input type="radio" formControlName="Status" value="{{option.id}}"
                              [checked]="option.id === sicknessForm.controls['Status'].value" class="form-check-input" disabled>
                            {{option.name | translate}}
                        </label>
                    </div>
                </div>
                <div *ngIf='leaveSaveByUser'>
                    <div class="form-group">
                        <p-checkbox label="{{'SHOW_WEEKEND' | translate}}" [binary]="true" [(ngModel)]="isShowWeekend"
                          [ngModelOptions]="{standalone: true}" (onChange)="changeSettings()"></p-checkbox>
                    </div>
                    <div formGroupName="dates">
                        <div class="form-group-50_50">
                            <div class="form-group">
                                <label for="startDate">
                                    <span translate>START_DATE</span>
                                    <span class="required">*</span>
                                </label>

                                <div>
                                    <my-date-picker [disabled]="isApprovalPerson" name="startdate"
                                      [options]="startDateOptions" formControlName="StartDate"
                                      placeholder="{{'ENTER_THE_START_DATE'|translate}}"
                                      (dateChanged)="onStartDateChanged($any($event))"></my-date-picker>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="endDate">
                                    <span translate>END_DATE</span>
                                    <span class="required">*</span>
                                </label>
                                <div>
                                    <my-date-picker [disabled]="isApprovalPerson" name="enddate"
                                      [options]="endDateOptions" formControlName="EndDate"
                                      placeholder="{{'ENTER_STOP_DATE'|translate}}"
                                      (dateChanged)="onEndDateChanged($any($event))" required></my-date-picker>
                                </div>
                            </div>
                            <control-messages [control]="sicknessForm.controls['dates']"></control-messages>
                        </div>
                    </div>
                    <div class="form-group">
                        <p-checkbox [disabled]="IsShowPercentage" label="{{'SHOW_HOURS' | translate}}" [binary]="true"
                          [(ngModel)]="IsShowHours" [ngModelOptions]="{standalone: true}">
                        </p-checkbox>
                    </div>
                    <div class="form-group" [hidden]="!IsShowHours">
                        <label for="Hours">
                            <span translate>HOURS</span>
                        </label>
                        <div>
                            <p-dropdown [options]="HoursValues" [formControl]="sicknessForm.controls['Hours']"
                              [style]="{'width':'100%'}" (onChange)="CalculateHours()"></p-dropdown>
                        </div>
                    </div>
                    <div class="form-group" [hidden]="!IsShowHours">
                        <label for="TotalHours">
                            <span translate>TOTAL_HOURS</span>
                        </label>
                        <div>
                            <input type="text" pInputText formControlName="TotalHours" readonly>
                        </div>
                    </div>
                    <div class="form-group">
                        <p-checkbox label="{{'SHOW_PERCENTAGE'  | translate}}" [binary]="true"
                          [(ngModel)]="IsShowPercentage" [ngModelOptions]="{standalone: true}"
                          (onChange)="changeSettings()"></p-checkbox>
                    </div>
                    <div class="form-group" [hidden]="!IsShowPercentage">
                        <label for="percentage">
                            <span translate>PERCENTAGE</span>
                        </label>
                        <div>
                            <p-dropdown [options]="percentageValues"
                              [formControl]="sicknessForm.controls['PercentageAbsence']" [style]="{'width':'100%'}"
                              (onChange)="changeSettings()"></p-dropdown>
                        </div>
                    </div>
                    <div class="form-group" [hidden]="!IsShowPercentage">
                        <label for="nDays">
                            <span translate>NUMBER_DAYS</span>
                        </label>
                        <div>
                            <input type="text" pInputText formControlName="NoOfDays" readonly>
                        </div>
                    </div>
                    <div *ngIf="categories" class="form-group">
                        <label>
                            <span translate>REASON</span>
                            <span class="required">*</span>
                        </label>
                        <control-messages [control]="sicknessForm.controls['selectedItems']"></control-messages>
                        <div [formArrayName]="'checkboxProperties'" class="form-group">
                            <div class="row">
                                <div class="col-sm-6"
                                  *ngFor="let category of sicknessForm.get('checkboxProperties')['controls']; let i = index"
                                  [formGroup]="category">
                                    <div class="form-check">
                                        <input type="checkbox" formControlName="checked"
                                          [attr.id]="'CT_' + category.get('key').value"
                                          (change)="reasonChecked($event, category.get('name').value)"
                                          class="form-check-input">
                                        <label class="form-check-label">
                                            {{ category.get('name').value | translate }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isChildage" class="form-group">
                            <label for="Name">
                                <span translate>CHILD_AGE</span>
                            </label>
                            <div>
                                <input type="number" pInputText [formControl]="sicknessForm.controls['ChildsAge']"
                                  (keypress)="onNumberChange($event,4)" />
                            </div>
                        </div>
                        <div *ngIf="Other" class="form-group">
                            <label for="Name">
                                <span translate>OTHER</span>
                            </label>
                            <div>
                                <input type="text" pInputText formControlName="OtherReason" />
                            </div>
                        </div>
                        <div class="form-group" *ngIf="workingModule.id != 7 && workingModule.id != 8">
                            <label for="appPerson">
                                <span translate>APPROVAL_PERSON</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <p-dropdown formControlName="ApprovalPerson" [options]="persons"
                                  [style]="{'width':'200px'}"></p-dropdown>
                            </div>
                            <control-messages [control]="sicknessForm.controls['ApprovalPerson']"></control-messages>
                        </div>
                        <div class="form-group" *ngIf="workingModule.id == 7 || workingModule.id == 8">
                            <label for="appPerson">
                                <span translate>APPROVAL_PERSON</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <p-multiSelect [options]="persons" [style]="{'width':'100%'}"
                                  formControlName="ApprovalPersonList" [defaultLabel]="'CHOOSE' | translate">
                                </p-multiSelect>
                            </div>
                            <control-messages [control]="sicknessForm.controls['ApprovalPersonList']">
                            </control-messages>
                        </div>
                        <div class="form-group">
                            <label for="Desc">
                                <span translate>DESCRIPTION</span>
                            </label>
                            <control-messages [control]="sicknessForm.controls['Description']"></control-messages>
                            <div>
                                <textarea pInputTextarea formControlName="Description"></textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <div>
                                <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
                                  (saveChanges)="saveUploaded($event)">
                                </ecpl-document-explorer>
                            </div>
                        </div>
                        <div [hidden]="IsVisible">
                        </div>
                    </div>
                    <div class="col-sm-6" formArrayName="sicknessFollowUpForm" [hidden]="IsHide">
                        <div class="row mbtm-10">
                            <div class="col-sm-6">
                                <a href="javascript:void(0);">
                                    <span translate>Skjema oppfølgingsplan</span>
                                    <a class="btn btn-outline-secondary"
                                      href="../../assets/template_files/Skjema_oppf_lgingsplan.pdf" download>
                                        <i class="pi pi-exclamation-circle" aria-hidden="true"></i>
                                    </a>
                                </a>
                            </div>
                            <div class="col-sm-6">
                                <a href="javascript:void(0);">
                                    <span translate>INFORM_LEAFLET</span>
                                    <a class="btn btn-outline-secondary"
                                      href="../../assets/template_files/Informasjonsbrosjyre fra NAV.pdf" download>
                                        <i class="pi pi-exclamation-circle" aria-hidden="true"></i>
                                    </a>
                                </a>
                            </div>
                        </div>
                        <div class="sub-panel"
                          *ngFor="let Properties of sicknessForm.controls['sicknessFollowUpForm']['controls']; let i=index">
                            <div class="sub-panel-title"> {{ getTitle(i) }} </div>
                            <div
                              *ngFor="let Properties of sicknessForm.controls['sicknessFollowUpForm']['controls']; let i=index">
                                <div class="sub-panel-content" [formGroupName]="i">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label for="Desc">DEADLINE</label>
                                                <div>
                                                    <my-date-picker name="startdate" [options]="startDateOptions"
                                                      formControlName="DeadLine" placeholder="Select DeadLine">
                                                    </my-date-picker>
                                                </div>
                                                <div class="col-sm-6"> {{ getContent(i) }} </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="Type">
                                                    <span translate>RESPONSIBLE_FOR_EXECUTION</span>
                                                </label>
                                                <div>
                                                    <p-dropdown formControlName="Execution"
                                                      [options]="ResponsibleExecutionType" [filter]="true" id="Type"
                                                      [style]="{'width':'200px'}"></p-dropdown>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="Type">
                                                    <span translate>RESPONSIBLE_FOR_FOLLOW_UP</span>
                                                </label>
                                                <div>
                                                    <p-dropdown [filter]="true" [options]="ResponsibleFollowUpType"
                                                      formControlName="FollowUp" [style]="{'width':'200px'}">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6"> {{ getContent(i) }} </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div *ngIf="i === 1">
                                                <a href="javascript:void(0);">
                                                    <span translate>PREPARATION_DIALOGUE</span>
                                                    <i class="pi pi-exclamation-circle" aria-hidden="true"
                                                      (click)="showDialog(1)"></i>
                                                </a>
                                            </div>
                                            <div *ngIf="i === 1">
                                                <a href="javascript:void(0);">
                                                    <span translate>IMPLEMENTATION_DIALOGUE</span>
                                                    <i class="pi pi-exclamation-circle" aria-hidden="true"
                                                      (click)="showDialog(2)"></i>
                                                </a>
                                            </div>
                                            <div *ngIf="i === 1">
                                                <a href="javascript:void(0);">
                                                    <span translate>FOLLOWUP_DIALOGUE_MEETING</span>
                                                    <i class="pi pi-exclamation-circle" aria-hidden="true"
                                                      (click)="showDialog(3)"></i>
                                                </a>
                                            </div>
                                            <div *ngIf="i === 3">
                                                <a href="javascript:void(0);">
                                                    <span translate>INFORM_LEAFLET</span>
                                                    <i class="pi pi-exclamation-circle" aria-hidden="true"
                                                      (click)="showDialog(4)"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!showFollowUp" class="sub-panel">
                            <div class="sub-panel-title" translate>STOP_SICKNESS_PAYMENT</div>
                            <div class="form-group">
                                <label for="Desc" translate>DATE</label>
                                <div>
                                    <my-date-picker name="startdate" [options]="startDateOptions"
                                      formControlName="startdate" placeholder="Select DeadLine">
                                    </my-date-picker>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="Doc" translate>UPLOAD_DOCUMENT</label>
                                <p-fileUpload name="files[]" (onSelect)="onUploadDoc($event)" [multiple]="true">
                                </p-fileUpload>
                                <h3>
                                    <span translate>STOP_SICKNESS_PAYMENT</span>
                                </h3>
                                <label for="Desc">
                                    <span translate>DATE</span>
                                </label>
                                <my-date-picker name="DeadLine" [(ngModel)]="StopAbsencePayment"
                                  [ngModelOptions]="{standalone: true}" placeholder="Select Date"></my-date-picker>
                            </div>
                            <div class="form-group">
                                <label for="Doc">
                                    <span translate>UPLOAD_DOCUMENT</span>
                                </label>
                                <!-- TODO: Single upload -->
                            </div>
                        </div>
                        <div class="action-btns-wrapper">
                            <button class="btn btn-outline-primary" *ngIf="showFollowUp" type="submit"
                              (click)="showFollowUpPlan(1)">
                                <span translate>FOLLOWUP_PLAN_SICKNESS</span>
                            </button>
                            <button class="btn btn-primary" type="submit" (click)="addSicknessFollowUp()">
                                <span translate>SAVE</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf='!leaveSaveByUser'>
                    <div class="form-group">
                        <p-checkbox [disabled]="true" label="{{'SHOW_WEEKEND' | translate}}" [binary]="true"
                          [(ngModel)]="isShowWeekend" [ngModelOptions]="{standalone: true}"
                          (onChange)="changeSettings()"></p-checkbox>
                    </div>
                    <div formGroupName="dates">
                        <div class="form-group-50_50">
                            <div class="form-group">
                                <label for="startDate">
                                    <span translate>START_DATE</span>
                                    <span class="required">*</span>
                                </label>

                                <div>
                                    <my-date-picker [disabled]="true" name="startdate" [options]="startDateOptions"
                                      placeholder="{{'ENTER_THE_START_DATE'|translate}}" formControlName="StartDate"
                                      (dateChanged)="onStartDateChanged($any($event))">
                                    </my-date-picker>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="endDate">
                                    <span translate>END_DATE</span>
                                    <span class="required">*</span>
                                </label>
                                <div>
                                    <my-date-picker [disabled]="true" name="enddate" [options]="endDateOptions"
                                      formControlName="EndDate" (dateChanged)="onEndDateChanged($any($event))"
                                      placeholder="{{'ENTER_STOP_DATE'|translate}}" required></my-date-picker>
                                </div>
                            </div>
                            <control-messages [control]="sicknessForm.controls['dates']"></control-messages>
                        </div>
                    </div>
                    <div class="form-group">
                        <p-checkbox [disabled]="true" label="{{'SHOW_PERCENTAGE'  | translate}}" [binary]="true"
                          [(ngModel)]="IsShowPercentage" [ngModelOptions]="{standalone: true}"
                          (onChange)="changeSettings()"></p-checkbox>
                    </div>
                    <div class="form-group" [hidden]="!IsShowPercentage">
                        <label for="percentage">
                            <span translate>PERCENTAGE</span>
                        </label>
                        <div>
                            <p-dropdown [disabled]="true" [options]="percentageValues"
                              formControlName="PercentageAbsence" [style]="{'width':'100%'}"
                              (onChange)="changeSettings()"></p-dropdown>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="nDays">
                            <span translate>NUMBER_DAYS</span>
                        </label>
                        <div>
                            <input [disabled]="true" type="text" pInputText formControlName="NoOfDays" readonly>
                        </div>
                    </div>
                    <div *ngIf="categories" class="form-group">
                        <label>
                            <span translate>REASON</span>
                            <span class="required">*</span>
                        </label>
                        <control-messages [control]="sicknessForm.controls['selectedItems']"></control-messages>
                        <div [formArrayName]="'checkboxProperties'" class="form-group">
                            <div class="row">
                                <div class="col-sm-6"
                                  *ngFor="let category of sicknessForm.controls['checkboxProperties']['controls']; let i = index"
                                  [formGroup]="category">
                                    <div class="form-check">
                                        <input [disabled]="true" type="checkbox" formControlName="checked"
                                          id="CT_{{ category.controls['key'].value }}"
                                          (change)="reasonChecked($event,category.controls['name'].value)"
                                          class="form-check-input">
                                        <label class="form-check-label">
                                            {{category.controls['name'].value | translate}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isChildage" class="form-group">
                            <label for="Name">
                                <span translate>CHILD_AGE</span>
                            </label>
                            <div>
                                <input type="number" [disabled]="true" pInputText formControlName="ChildsAge"
                                  (keypress)="onNumberChange($event,4)" />
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="appPerson">
                                <span translate>APPROVAL_PERSON</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <p-multiSelect [disabled]="isApprovalPerson" [options]="persons"
                                  [style]="{'width':'100%'}" formControlName="ApprovalPersonList">
                                </p-multiSelect>
                            </div>
                            <control-messages [control]="sicknessForm.controls['ApprovalPerson']"></control-messages>
                        </div>
                        <div class="form-group">
                            <label for="Desc">
                                <span translate>DESCRIPTION</span>
                            </label>
                            <control-messages [control]="sicknessForm.controls['Description']"></control-messages>
                            <div *ngIf="!editOwnStatus">
                                <textarea pInputTextarea formControlName="Description"></textarea>
                            </div>
                            <div *ngIf="editOwnStatus">
                                <textarea pInputTextarea formControlName="Description"></textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
                              (saveChanges)="saveUploaded($event)">
                            </ecpl-document-explorer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="action-btns-wrapper text-left">
            <div class="row">
                <div class="col-sm-6">
                    <button class="btn btn-outline-secondary" type="submit" (click)="gotoList()">
                        <span translate>BACK</span>
                    </button>
                    <button class="btn btn-primary" type="submit" [ngClass]="{'loading' : loading}" (click)="onSubmit()"
                      [disabled]="!(sicknessForm.valid) || loading || userRights">
                        <span *ngIf="!loading" translate>SAVE</span>
                        <span *ngIf="loading" translate>SAVE_PROCESS</span>
                        <span *ngIf="loading" class="loader-icon"></span>
                    </button>
                    <button class="btn btn-outline-secondary" type="submit" (click)="clear()">
                        <span translate>CLEAR</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="table-view" [hidden]="isGrid">
            <!-- Table starts -->
            <p-table #sicknessList [value]="sicknessobject" [rows]="10" [paginator]="true" [pageLinks]="3"
              [rowsPerPageOptions]="[5,10,20]"
              [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                <!-- Caption -->
                <ng-template pTemplate="caption">
                    <div class="ui-helper-clearfix">
                        <div class="table-options">
                            <div class="pull-right">
                                <kuba-export [reportData]="sicknessList.filteredValue || sicknessList.value"
                                  [additionalData]="additionalData"></kuba-export>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <!-- Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="EmployeeName">{{'EMPLOYEE_NAME'| translate}} <p-sortIcon
                              field="EmployeeName"></p-sortIcon></th>
                        <th pSortableColumn="StartDate">{{'FROM_DATE'| translate}} <p-sortIcon
                              field="StartDate"></p-sortIcon></th>
                        <th pSortableColumn="EndDate">{{'END_DATE'| translate}} <p-sortIcon
                              field="EndDate"></p-sortIcon></th>
                        <th pSortableColumn="TotalAbsenceHours">{{'HOURS'| translate}} <p-sortIcon
                              field="TotalAbsenceHours"></p-sortIcon></th>
                        <th pSortableColumn="NoOfDays">{{'NO_OF_DAYS'| translate}} <p-sortIcon
                              field="NoOfDays"></p-sortIcon></th>
                        <th pSortableColumn="StatusText">{{'STATUS'|translate}} <p-sortIcon
                              field="StatusText"></p-sortIcon></th>
                        <th pSortableColumn="Reason">{{'REASON'|translate}} <p-sortIcon field="Reason"></p-sortIcon>
                        </th>
                        <th pSortableColumn="ApprovalPersonNameList">{{'APPROVAL_PERSON' | translate}} <p-sortIcon
                              field="ApprovalPersonNameList"></p-sortIcon></th>
                        <th [hidden]="isPortal" pSortableColumn="ApprovedDate">{{'DATE_APPROVAL' | translate}}
                            <p-sortIcon field="ApprovedDate"></p-sortIcon>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-sickness>
                    <tr>
                        <td>
                            <span>{{sickness.EmployeeName}}</span>
                        </td>
                        <td>
                            <span>{{sickness.StartDate |date : 'dd/MM/yyyy'}}</span>
                        </td>
                        <td>
                            <span>{{sickness.EndDate |date : 'dd/MM/yyyy'}}</span>
                        </td>
                        <td>
                            <span>{{sickness.Hours}}</span>
                        </td>
                        <td>
                            <span>{{sickness.NoOfDays}}</span>
                        </td>
                        <td>
                            <span>{{sickness.StatusText | translate}}</span>
                        </td>
                        <td>
                            <span>{{sickness.Reason  | sicknessReason }}</span>
                        </td>
                        <td>
                            <span>{{sickness.ApprovalPersonNameList}}</span>
                        </td>
                        <td>
                            <span>{{sickness.ApprovedDate |date : 'dd/MM/yyyy'}}</span>
                        </td>
                        <td>
                            <a (click)="fetchEmployeeSickness(sickness['Id'])" class="btn btn-icon"
                              title="{{'EDIT' | translate}}" routerLinkActive="active">
                                <i class="icon ic-sm icon-pencil-tip"></i>
                            </a>
                            <button type="button" (click)="delete(sickness['Id'])"
                              *ngIf="((sickness.Status===8 || sickness.Status===10) && isUserOrNot===true) || (isNotUser)"
                              class="btn btn-icon" title="{{'DELETE' | translate}}">
                                <i class="icon ic-sm icon-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <!-- Footer -->
                <ng-template pTemplate="summary">
                    <div class="footer-data">
                        <div class="text-left col-md-2" *ngIf="!isPortal">
                            <strong translate>TOTAL_DAYS</strong>
                        </div>
                        <div class="text-left col-md-3" *ngIf="!isPortal">
                            <strong translate>OPEN</strong> : {{OpenNoOfDay}}
                        </div>
                        <div class="text-left col-md-3" *ngIf="!isPortal">
                            <strong translate>APPROVED</strong> : {{ApprovedNoOfDay}}
                        </div>
                        <div class="text-left col-md-2" *ngIf="!isPortal">
                            <strong translate>REJECTED</strong> : {{RejectedNoOfDay}}
                        </div>
                    </div>
                    <div class="footer-data">
                        <div class="text-left col-md-2" *ngIf="!isPortal">
                            <strong translate>TOTAL_HOURS</strong>
                        </div>
                        <div class="text-left col-md-3" *ngIf="!isPortal">
                            <strong translate>OPEN</strong> : {{TotalOpenHours}}
                        </div>
                        <div class="text-left col-md-3" *ngIf="!isPortal">
                            <strong translate>APPROVED</strong> : {{TotalApprovedHours}}
                        </div>
                        <div class="text-left col-md-2" *ngIf="!isPortal">
                            <strong translate>REJECTED</strong> : {{TotalRejectedHours}}
                        </div>
                    </div>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td colspan="10">
                            {{'NO_RECORDS_FOUND'|translate}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <!-- Table ends -->
        </div>
    </div>
    <p-dialog [(visible)]="showCommonModal" [style]="{width: '600px'}" [resizable]="false" [draggable]="false">
        <div [innerHTML]="dialogContent"></div>
    </p-dialog>
    <toaster-component></toaster-component>
    <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
      acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
</div>