import { MenuDropDownResolver, SubMenuDropDownResolver } from './../faqs/services/faqs.resolver.service';
import { InfoVideoComponent } from './info-video.component';
import { Route } from '@angular/router';
import { InfoVideoListComponent } from './components/infovideo-list.component';
import { AuthGuard } from './../../_guards/auth.guard';
export const InfoVideoRoutes: Route[] = [{
    path: 'info-video',
    component: InfoVideoListComponent,
    resolve: {
        menu: MenuDropDownResolver,
        submenu: SubMenuDropDownResolver
    }
}];
