import { Injectable } from '@angular/core';
import { BaseServices } from '../../kuba.services';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs/Subject';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardService {
  private getNews = new Subject<any>();
  getNews$ = this.getNews.asObservable();
  constructor(private http: HttpClient) {}

  getNewsData(data: string) {
    this.getNews.next(data);
  }
  getMeetingPriorityByBusiness(businessId: number) {
    return this.http
      .get<any>(
        `${environment.BASE_URL}/priormeeting/${businessId}`,
        BaseServices.headerOption
      )
      .pipe(map((result) => result));
  }
  getFutureActivityByBusiness(id: number) {
    return this.http
      .get<any>(
        `${environment.BASE_URL}/dashboard-activities/${id}/${BaseServices.roleId}/${BaseServices.UserId}`,
        BaseServices.headerOption
      )
      .pipe(map((result) => result));
  }
  getFutureAdminActivityByBusiness() {
    return this.http
      .get<any>(
        `${environment.BASE_URL}/dashboard-admin-activities`,
        BaseServices.headerOption
      )
      .pipe(map((result) => result));
  }
  getDeviationByBusiness(businessId: number, roleId: string) {
    return this.http
      .get<any>(
        environment.BASE_URL +
          '/dashboard-deviations/' +
          businessId +
          '/' +
          roleId,
        BaseServices.headerOption
      )
      .pipe(map((result) => result));
  }
  getDeviationByUser(roleId: any) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/dashboard-deviation-user/' + roleId,
        BaseServices.headerOption
      )
      .pipe(map((result) => result));
  }
  getDeviationByUserBasedOnDept(roleId: any) {
    return this.http
      .get(
        environment.BASE_URL +
          '/dashboard-userdeviationbasedondepartment/' +
          roleId,
        BaseServices.headerOption
      )
      .pipe(map((result) => result));
  }
  getDeviationByUserBasedOnAdministrateRights(businessId: any) {
    return this.http
      .get(
        environment.BASE_URL +
          '/dashboard-userdeviations-administraterights/' +
          businessId,
        BaseServices.headerOption
      )
      .pipe(map((result) => result));
  }
  getAdminDeviation(businessId: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/dashboard-admin-deviations/' + businessId,
        BaseServices.headerOption
      )
      .pipe(map((result) => result));
  }
  getRiskAnalysis(businessId: number) {
    return this.http
      .get<any>(
        environment.BASE_URL +
          '/dashboard-riskanalysis/' +
          businessId +
          '/' +
          BaseServices.UserRole,
        BaseServices.headerOption
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getInternalNews() {
    return this.http.get<any[]>(
      environment.BASE_URL +
        '/dashboard-internalnews/' +
        BaseServices.BusinessId,
      BaseServices.headerOption
    );
  }
  getAdminNews() {
    return this.http.get<any[]>(
      environment.BASE_URL + '/dashboard-adminnews/',
      BaseServices.headerOption
    );
  }
  getMeasure(id: number) {
    return this.http.get<any[]>(
      environment.BASE_URL +
        '/dashboard-measure/' +
        id +
        '/' +
        BaseServices.UserRole,
      BaseServices.headerOption
    );
  }
  getBusiness(id: number) {
    return this.http.get<any>(
      environment.BASE_URL + '/dashboard-business/' + id,
      BaseServices.headerOption
    );
  }
  getBusinessFunction(id: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/dashboard-function/' + id,
        BaseServices.headerOption
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getUserHits(type: string) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/dashboard-loggeduser/' + type,
        BaseServices.headerOption
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getAboutKuba(id: number) {
    return this.http
      .get<any[]>(
        environment.BASE_URL + '/dashboard-aboutKuba/' + id,
        BaseServices.headerOption
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getcalendarActivity(id: number) {
    return this.http
      .get<any>(
        environment.BASE_URL +
          '/dashboard-calendar/' +
          id +
          '/' +
          BaseServices.roleId,
        BaseServices.headerOption
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getUserMessage(id: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/dashboard-message/' + id,
        BaseServices.headerOption
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getRModeFeature() {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/rmode-feature/' + BaseServices.BusinessId,
        BaseServices.headerOption
      )
      .pipe(
        map((response: HttpHeaderResponse) => {
          return response;
        })
      );
  }

  getAboutKubaIk(id: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/ownpage-aboutKuba/' + id,
        BaseServices.headerOption
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getLinksByAppAndBusiness(bid: number) {
    return this.http.get<any[]>(
      environment.BASE_URL +
        '/dashboard-linklist/' +
        bid +
        '/' +
        BaseServices.UserRole,
      BaseServices.headerOption
    );
  }
  getIsPortalBusiness(bid: number) {
    return this.http
      .get(
        environment.BASE_URL + '/dashboard-IsPortalBusiness/' + bid,
        BaseServices.headerOption
      )
      .pipe(map((result) => result));
  }
  getPortalLinks(bid: number) {
    return this.http.get<any>(
      environment.BASE_URL +
        '/dashboard-portallinklist/' +
        bid +
        '/' +
        BaseServices.UserRole,
      BaseServices.headerOption
    );
  }
}
