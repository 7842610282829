<div class="main-content">
    <div class="main-heading">
        <span translate>INFO_VIDEO</span>
    </div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="InfoVideoForm">
                <div class="form-group">
                    <label>
                        <span translate>SELECT_MENU</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <p-dropdown formControlName="Menu" [readonly]="true" [style]="{'width':'200px'}"
                          (onChange)="onMenuDropDownChanged($event)" [options]="menu">
                            <ng-template let-menu pTemplate="item"> {{ menu.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <control-messages [control]="InfoVideoForm.controls['Menu']"></control-messages>
                </div>
                <div class="form-group">
                    <label>
                        <span translate>SELECT_SUBMENU</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <p-dropdown *ngIf="subMenu" formControlName="SubMenu" [style]="{'width':'200px'}"
                          [options]="subMenu" #subMenuDropDownChanged
                          (onChange)="onSubMenuDropDownChanged($event,subMenuDropDownChanged)">
                            <ng-template let-submenu pTemplate="item"> {{ submenu.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <control-messages [control]="InfoVideoForm.controls['SubMenu']"></control-messages>
                </div>
                <div class="form-group">
                    <label>
                        <span translate>URL</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" formControlName="URL" pInputText [style]="{'width':'200px'}" />
                    </div>
                    <control-messages [control]="InfoVideoForm.controls['URL']"></control-messages>
                </div>
                <div class="action-btns-wrapper">
                    <button class="btn btn-primary" [disabled]="!InfoVideoForm.valid" (click)="saveInfoVideo()"
                      translate>SAVE</button>
                </div>
            </form>
            <div class="table-view mbtm-30">
                <!-- Table starts -->
                <p-table [value]="infoVideoNestedTable" dataKey="Title" #infoVideoTable expandableRows="true"
                  [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
                  [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th></th>
                            <th>{{'MENU'|translate}}</th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-infovideo let-expanded="expanded" let-i="rowIndex">
                        <tr>
                            <td>
                                <a class="btn btn-icon text-primary" *ngIf="infovideo.children.length > 0" (click)="infoVideoTable.toggleRow(infovideo)">
                                    <i class="pi pi-chevron-circle-right" aria-hidden="true" *ngIf="setCollapse(i)"
                                      (click)="toggleClick(i)"></i>
                                    <i class="pi pi-chevron-circle-down" aria-hidden="true" *ngIf="!setCollapse(i)"
                                      (click)="toggleClickHide(i)"></i>
                                </a>
                            </td>
                            <td>
                                <span>{{infovideo.Title |titlecase}}</span>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-infovideo>
                        <tr>
                            <td colspan="2">
                                <div>
                                    <!-- Table starts -->
                                    <p-table [value]="infovideo.children" #subMenuTable [rows]="5">
                                        <!-- Header -->
                                        <ng-template pTemplate="header">
                        <tr>
                            <th>{{'SUB_MENU' | translate}}</th>
                            <th>{{'URL' | translate}}</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-infovideo>
                        <tr>
                            <td>
                                <span>{{infovideo.Title.toUpperCase() | translate}}</span>
                            </td>
                            <td>
                                <span>{{infovideo.VideoPath}}</span>
                            </td>
                            <td>
                                <a (click)="editInfoVideo(infovideo.Id)" class="btn btn-icon"
                                  title="{{'EDIT'|translate}}" routerLinkActive="active">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends-->
            </div>
            </td>
            </tr>
            </ng-template>
            </p-table>
            <!-- Table Ends-->
        </div>
    </div>
</div>
</div>
<toaster-component></toaster-component>