export class Timetable {
    Id: number;
    EmployeeId: number;
    TypeOfWorkId: number;
    StartDate: string;
    StartTime: string;
    EndDate: string;
    ApprovalDate:string;
    EndTime: string;
    HoursSpent: string;
    BreakTime: string;
    ApprovalUserId: number;
    Comments: string;
    Status: string;
    ModifiedBy: number;
    ProjectWorkTypeName: string;
    EmployeeName: string;
    ModifierName: string;
    ProjectName: string;
    Month: string;
    Year: string;
    Featurekey: number;
    BusinessId: number;
    CreatedBy: number;
    FeatureId: number;
    ProjectId: number;
    ModuleFeatureId: string;

}

export class TypeOfWork {
    Id: number;
    Title: string;
    Status: string;
    BusinessId: number;
}
export class Employees {
    Id: number;
    Name: string;
}
export class ApprovalPerson {
    Id: number;
    Name: string;
}
export class Project {
    Title: string;
    Id: number;
}
