import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { element } from 'protractor';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { UserService } from './../../users/services/user.service';
import { TaskService } from './../../task-manager/services/task.services';
import { CompleteCheckListServices } from 'src/app/kuba/shared/services/complete-checklist.service';
import { FeatureKey } from './../../../_models/feature';
import { UploaderConfig } from './../../../shared/ecpl-document-explorer/uploader-config';
import { ActivitySchedule, ActivityAttachment } from './../models/activities';
import { environment } from 'src/environments/environment';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { ActivityServices } from './../services/activities.services';
import { Options } from './../../../_models/options';
import { HelperService } from './../../../_services/helper.service';
import { Activity, Execution, FollowUpActivity } from '../models/activities';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserRights } from '../../employees/models';
// import { Department } from "../models/deviation";
import { BusinessDepartmentService } from 'src/app/kuba/business-department.services';
import { DeviationServices } from '../../deviation/services/deviation.service';
import { Department } from './../../deviation/models/deviationDepartment';
@Component({
  selector: 'followup-newactivity',
  templateUrl: 'activity-edit.component.html',
  styleUrls: ['activity-edit.component.scss'],
})

// TODO: Multiple file upload
export class EditActivitiesComponent implements OnInit {
  //#region variables

  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  options = [
    new Options(8, 'OPEN'),
    new Options(9, 'DONE'),
    new Options(10, 'REJECTED'),
  ];
  dialogDisplay = false;
  uploadedFiles: any = [];
  ActivityScheduleId: number;
  newUploadedFiles: ActivityAttachment[] = [];
  user = new Activity();
  activityForm: FormGroup;
  vDepartment: any[];
  vresperson: SelectItem[];
  vRepetition: SelectItem[];
  follows: SelectItem[];
  repetitions: any;
  createdBy: number;
  additionalData: any;
  upConfig: UploaderConfig;
  CreatorName: string;
  createdOn: string;
  activityLog: any;
  checkList: any;
  displayDialog = false;
  public Title: any;
  activityScheduleId: number;
  selectedDepartment = new Execution();
  selectedDepartment1 = new Department();
  selectedPerson = new Execution();
  activityId: number;
  console: Options;
  IsRepeatChklistRecurr: boolean;
  isSelect = true;
  userRole: string;
  businessId: number;
  activityNumber: string;
  currentBusinessId: number;
  FollowUpDays: any[];
  currentStartDate: any;
  currentEndDate: any;
  isReadOnly = false;
  isClick = true;
  loading = false;
  select: string;
  isRepetetionExists = false;
  isParentId: number;
  disableChecklistDropDown = false;
  showCompleteCheckListButton = true;
  completeChecklistDetails: any;
  showChecklist = true;
  isFutureRecurringDisabled = false;
  RepetitionId = 0;
  firstEdit: boolean;
  isChecklistSelected = false;
  isRepetetion: boolean;
  checklistDetails: any;
  checklistTile: string;
  public dateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '26px',
    selectionTxtFontSize: '13px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
    disableUntil: this.getCurrentDate(),
  };
  defaultDate = new Date();
  assignDefaultDate =
    this.defaultDate.getDay() +
    '/' +
    this.defaultDate.getMonth() +
    1 +
    '/' +
    this.defaultDate.getFullYear();
  activityNoTranslation: string;
  createdOnForSave: Date;
  currentDeadLine: Date;
  currentDate: Date;
  dateDiff: number;
  status: any;
  isPortal: boolean = true;
  FromRiskAnalysis = 1;
  loadingReport = false;

  private subscriptions: Subscription[] = [];
  print: boolean = false;
  isApprovalPerson1: boolean;
  isGuestHideButton = true;
  departmentdetails: Department[];
  departmentForm: FormGroup;
  department: boolean;
  hidesElement = false;
  isDepartmentErrorDisabled: boolean;
  deptId: number;
  isDepartmentAllocated = false;
  formDeviation: FormGroup;
  departments: any[];
  editDepartments: any;
  eDepartment: any;
  //#endregion

  //#region consructor
  /**
   * constructor
   * @param activitySrvc {ActivityServices}
   * @param formBuilder {FormBuilder}
   * @param route {ActivatedRoute}
   * @param location {Location}
   */
  constructor(
    private activitySrvc: ActivityServices,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected location: Location,
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private taskService: TaskService,
    private appManualChkListServices: CompleteCheckListServices,
    private businessDepartmentService: BusinessDepartmentService,
    private deviationService: DeviationServices
  ) {
    this.activityId = this.route.snapshot.params['aid'];

    this.additionalData = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      ParentId: +this.activityId,
      Status: '1',
      CreatedOn: new Date(),
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: new Date(),
    };

    this.upConfig = {
      title: 'ACTIVITY_DOCUMENT',
      titleAsLabel: true,
      editMode: true,
      windowedHeight: false,
      viewSwitch: false,
      showUserMeta: false,
      showSearchFilter: false,
      showUploadButton: true,
      showDeleteButton: true,
      uploaderUri:
        environment.BASE_URL + '/file/upload/business/followup/activity-docs',
      addtionalData: this.additionalData,
    };
    if (+BaseServices.roleId === 4) {
      this.isGuestHideButton = false;
    }
  }
  //#endregion
  //#region page-event
  /**
   * edit activity
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    if (BaseServices.roleId === '2') {
      this.isPortal = false;
    }
    this.userRole = BaseServices.UserRole;
    this.subscriptions.push(
      this.route.params.subscribe((params: Params) => {
        this.activityId = params['aid'];
      })
    );
    this.departmentForm = this.formBuilder.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    let result = this.route.snapshot.data['detail'];
    result.Status = parseInt(result.Status);
    this.activityForm = this.formBuilder.group({
      Status: [],
      Title: ['', Validators.required],
      Description: [''],
      ExecutionUser: ['', Validators.required],
      FollowUp: ['', Validators.required],
      RepetitionId: [
        [],
        [Validators.required, ValidationService.repetetionValidation],
      ],
      Deadline: ['', [Validators.required, Validators.nullValidator]],
      Department: [],
      NotificationBeforeDeadline: ['0', [], this.notificationdays.bind(this)],
      NotificationBeforeFollowUp: [7],
      LinkUrl: [''],
      chkRepeat: [true],
      cmbCheckList: [],
      activityNumber: [''],
      activityPrefix: [''],
      futureRecurring: [false],
    });
    this.console = this.options.filter((item) => item.id === 1)[0];

    if (this.activityId) {
      this.bindDetails(BaseServices.BusinessId);
      if (result) {
        this.isRepetetion = result.RepetitionId > 1 ? true : false;
        this.isChecklistSelected = result.ChecklistId ? true : false;
        if (
          result.RepetitionId > 0 &&
          result.IsCreatedFrom != this.FromRiskAnalysis &&
          result.RepetitionId != this.FromRiskAnalysis
        ) {
          this.isRepetetionExists = true;
          this.RepetitionId = result.RepetitionId;
        }
        if (result.IsFutureRecurring === true) {
          this.isFutureRecurringDisabled = true;
        }
        this.activityId = result.Id;
        if (result.ActivityScheduleDto != null) {
          this.isParentId = result.ActivityScheduleDto.ParentId;
        }
        this.currentBusinessId = result.BusinessId;
        this.createdOn = result.CreatedOn;
        this.createdOnForSave = result.CreatedOn;
        this.isSelect = result.RepetitionId >= 0 ? true : false;
        let activity: any = {};
        this.firstEdit = result.IsFirstEdit;
        activity.activityNumber = result.ActivityNumber;
        activity.activityPrefix = result.Activity_Number_Prefix;
        activity.Status =
          result.Status && result.Status === 7 ? 8 : result.Status;
        this.status = result.Status.toString();
        activity.Status = activity.Status.toString();
        activity.Title = result.Title;
        activity.Description = result.Description;

        let followupList: any = [];
        let executionList: any = [];
        let departmentList: any = [];
        let checkExecutionList = [];
        let checkFollowupList = [];
        let checkDepartmentList = [];
        result.BusinessDepartmentList != null
          ? result.BusinessDepartmentList.forEach((element: any) => {
              departmentList.push(element.BusinessDepartmentId);
            })
          : result.BusinessDepartmentList;
        result.ExecutionUsers.forEach((element: any) => {
          executionList.push(element.Id);
        });
        result.FollowupUsers.forEach((element: any) => {
          followupList.push(element.Id);
        });

        departmentList.forEach((element: any) => {
          let checkValueDept = this.vDepartment.filter(
            (x) => x.value == element
          );
          if (checkValueDept.length > 0) {
            checkDepartmentList.push(checkValueDept);
          }
        });

        executionList.forEach((element: any) => {
          let checkValueExec = this.follows.filter((x) => x.value == element);
          if (checkValueExec.length > 0) {
            checkExecutionList.push(checkValueExec);
          }
        });
        followupList.forEach((element: any) => {
          let checkValFollow = this.follows.filter((x) => x.value == element);
          if (checkValFollow.length > 0) {
            checkFollowupList.push(checkValFollow);
          }
        });
        if (checkExecutionList.length == 0) {
          executionList = [];
        }
        if (checkFollowupList.length == 0) {
          followupList = [];
        }
        if (checkDepartmentList.length == 0) {
          departmentList = [];
        }
        activity.ExecutionUser = executionList;
        activity.FollowUp = followupList;

        activity.RepetitionId = result.RepetitionId;
        activity.Department = departmentList;
        activity.LinkUrl = result.LinkUrl;
        activity.chkRepeat = result.IsRepeatChklistRecurr;
        activity.futureRecurring = result.IsFutureRecurring;
        if (result.CheckListStaus == null || result.CheckListStaus == 1) {
          activity.cmbCheckList = result.ChecklistId;
        } else {
          activity.cmbCheckList = 0;
        }

        if (result.ActivityScheduleDto) {
          this.currentDeadLine = result.ActivityScheduleDto.EndDate
            ? new Date(result.ActivityScheduleDto.EndDate)
            : null!;
          activity.Deadline = HelperService.formatInputDate(
            result.ActivityScheduleDto.EndDate
          )
            ? HelperService.formatInputDate(result.ActivityScheduleDto.EndDate)
            : this.assignDefaultDate;
          this.currentEndDate = activity.StartDate;
          activity.NotificationBeforeDeadline = result.ActivityScheduleDto
            .NotifyBeforeDeadLine
            ? result.ActivityScheduleDto.NotifyBeforeDeadLine
            : '0';
          activity.NotificationBeforeFollowUp = result.ActivityScheduleDto
            .NotifyBeforeFollowup
            ? result.ActivityScheduleDto.NotifyBeforeFollowup
            : '7';
        } else {
          activity.Deadline = '';
          activity.NotificationBeforeDeadline = '0';
          activity.NotificationBeforeFollowUp = '7';
        }
        if (result.Status === 7) {
          this.showChecklist = false;
        }
        if (this.showChecklist) {
          this.isChecklistSelected = true;
        }

        this.createdBy = result.CreatedBy;
        this.CreatorName = result.CreatorName;
        this.subscriptions.push(
          this.translate.stream('ACTIVITY_NO').subscribe((val) => {
            this.select = this.translate.instant('SELECT');
            this.activityNoTranslation = val.ACTIVITY_NUM;
            this.Title =
              result.Title +
              ' (' +
              this.activityNoTranslation +
              ': ' +
              result.No +
              ') ';
          })
        );

        this.createdOn = HelperService.formatDateTo(result.CreatedOn);
        // this.createdOn = formatDate(result.CreatedOn);
        this.activityScheduleId = result.ActivityScheduleDto
          ? result.ActivityScheduleDto.Id
          : 0;
        this.activityForm.setValue(activity, { onlySelf: true });
        if (result.ActivityAttachmentDto) {
          result.ActivityAttachmentDto.forEach((element: any) => {
            this.uploadedFiles.push(
              HelperService.switchKeysCase(element, null, 'L')
            );
          });
          if (this.uploadedFiles.length > 0) {
            if (this.uploadedFiles[0].filename === null) {
              this.uploadedFiles[0].filename =
                this.uploadedFiles[0].path.replace(/^.*[\\\/]/, '');
            }
          }
          this.uploadedFiles = result.ActivityAttachmentDto.filter(
            (x: any) => x.status !== 0
          );
        }
        (<FormGroup>this.activityForm).setValue(activity, { onlySelf: true });
      }
    }
    /**
     * Activity log list
     */
    this.bindDocument();
    this.subscriptions.push(
      this.activitySrvc
        .getActivityLog(BaseServices.BusinessId, this.activityId)
        .subscribe((result: any) => {
          if (result) {
            result.forEach((list: any) => {
              if (list.ColumnName === 'Status') {
                list.OldValue = this.assignStatus(list.OldValue);
                result.OldValue = list.OldValue;
              }
            });
          }
          this.activityLog = result;
        })
    );
    this.getCompleteChecklistDetails(this.activityId);
    if (+BaseServices.roleId === 2) {
      this.isGuestHideButton = false;
    }
  }
  assignStatus(status: string) {
    let statusText: any;
    if (status) {
      switch (status) {
        case '7':
          statusText = 'NEW';
          break;
        case '8':
          statusText = 'OPEN';
          break;
        case '9':
          statusText = 'DONE';
          break;
        case '10':
          statusText = 'REJECTED';
          break;
        default:
          statusText = status;
          break;
      }
    }
    return statusText;
  }
  bindDocument() {
    let docList = this.route.snapshot.data['doclist'];
    if (docList && docList.length > 0) {
      let keyBindings = {
        Originalname: 'OriginalFileName',
        fileName: 'filename',
      };
      docList.forEach((element: any) => {
        this.uploadedFiles.push(
          HelperService.switchKeysCase(element, keyBindings, 'L')
        );
      });
    }
  }

  notificationdays(control: FormControl) {
    this.currentDate = new Date();
    if (this.currentDate && this.currentDeadLine) {
      this.currentDate.setHours(0, 0, 0, 0);
      this.currentDeadLine.setHours(0, 0, 0, 0);
      let ONE_DAY = 1000 * 60 * 60 * 24;
      // Convert both dates to milliseconds
      let date1 = this.currentDate.getTime();
      let date2 = this.currentDeadLine.getTime();
      // Calculate the difference in milliseconds
      let difference_ms = Math.abs(date1 - date2);
      this.dateDiff = Math.round(difference_ms / ONE_DAY) + 1;
    }
    const q = new Promise((resolve) => {
      if (
        control.value >= this.dateDiff &&
        this.activityForm.value.Status !== '9' &&
        this.activityForm.value.Status !== '10'
      ) {
        resolve({ notificationdays: true });
      } else {
        resolve(null);
      }
    });
    return q;
  }

  statusChange() {
    this.activityForm.controls['NotificationBeforeDeadline'].setValue(
      this.activityForm.value.NotificationBeforeDeadline
    );
  }
  //#endregion

  //#region control-event
  /**
   * cost key press event
   * @param e {e}
   * @param limitNumber {limitNumber}
   */
  onNumber(e: any, limitNumber: any) {
    const limit = limitNumber;
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46)
    ) {
      e.preventDefault();
    }
  }
  /**
   * update activity
   * @param activity {FollowUpActivity}
   */
  onSubmitTemplateBased(activity: FollowUpActivity) {
    this.loading = true;
    let updateActivity = new FollowUpActivity();
    updateActivity.ActivityNumber = this.activityForm.value.activityNumber;
    updateActivity.Activity_Number_Prefix =
      this.activityForm.value.activityPrefix;
    updateActivity.BusinessDepartmentId = 0;
    updateActivity.Id = this.activityId;
    updateActivity.IsFirstEdit = this.firstEdit;
    updateActivity.ApplicationId = BaseServices.ApplicationId;
    updateActivity.BusinessId = this.currentBusinessId
      ? this.currentBusinessId
      : BaseServices.BusinessId;
    updateActivity.ChecklistId = this.activityForm.value.cmbCheckList;
    updateActivity.IsRepeatChklistRecurr = this.activityForm.value.chkRepeat; // this.activityForm.value.chkRepeat;
    updateActivity.LinkUrl = this.activityForm.value.LinkUrl;
    updateActivity.Description = this.activityForm.value.Description;
    updateActivity.CreatedOn = this.createdOnForSave;
    updateActivity.ModifiedOn = new Date();
    updateActivity.CreatedBy = this.createdBy;
    updateActivity.LanguageId = BaseServices.userLanguageId;
    updateActivity.Title = this.activityForm.value.Title;
    let activitySchedule = new ActivitySchedule();
    activitySchedule.ActivityId = this.activityId;
    activitySchedule.Id = this.activityScheduleId ? this.activityScheduleId : 0;
    activitySchedule.ParentId = this.isParentId ? this.isParentId : 0;
    if (this.activityForm.value.Deadline) {
      let startdate = new Date();
      activitySchedule.StartDate =
        startdate.getMonth() +
        1 +
        '/' +
        startdate.getDate() +
        '/' +
        startdate.getFullYear();
      activitySchedule.EndDate = HelperService.formatDateFilter(
        this.activityForm.value.Deadline.formatted
      );
    }
    activitySchedule.NotifyBeforeDeadLine =
      this.activityForm.value.NotificationBeforeDeadline;
    activitySchedule.Status = '1';
    activitySchedule.NotifyBeforeFollowup =
      this.activityForm.value.NotificationBeforeFollowUp;
    updateActivity.ActivityScheduleDto = activitySchedule;

    updateActivity.RepetitionId = this.activityForm.value.RepetitionId;
    updateActivity.Status = this.activityForm.value.Status;
    if (this.activityForm.value.Status === 9) {
      updateActivity.CompletedDate = new Date();
    }
    updateActivity.ActivityAttachmentDto = this.newUploadedFiles;
    this.activityForm.value.Department != null
      ? this.activityForm.value.Department.forEach((departmentuser: any) => {
          let department = {
            Id: 0,
            ActivityId: this.activityId,
            BusinessDepartmentId: departmentuser,
          };
          updateActivity.BusinessDepartmentList.push(department);
        })
      : this.activityForm.value.Department;
    updateActivity.ExecutionUsers = [];
    this.activityForm.value.ExecutionUser.forEach((excutionUser: any) => {
      let executionUpUsers = new Execution();
      executionUpUsers.ActivityId = this.activityId;
      executionUpUsers.Id = excutionUser;
      executionUpUsers.Name = 'Execution';
      executionUpUsers.FollowUpId = 0;
      updateActivity.ExecutionUsers!.push(executionUpUsers);
    });
    updateActivity.FollowupUsers = [];
    this.activityForm.value.FollowUp.forEach((followupUsers: any) => {
      let followUpUsers = new Execution();
      followUpUsers.ActivityId = this.activityId;
      followUpUsers.Id = followupUsers;
      followUpUsers.Name = 'Followup';
      followUpUsers.FollowUpId = 0;
      updateActivity.FollowupUsers!.push(followUpUsers);
    });
    updateActivity.ModifiedBy = BaseServices.UserId;
    updateActivity.IsFutureRecurring = this.activityForm.value.futureRecurring
      ? this.activityForm.value.futureRecurring
      : false;
    updateActivity.ModuleFeatureId = FeatureKey.FOLLOW_UP;
    if (this.isClick) {
      this.isClick = false;
      this.subscriptions.push(
        this.activitySrvc
          .update(updateActivity)
          .subscribe((isActivityUpdated) => {
            if (isActivityUpdated) {
              this.toasterComponent.callToast();
              this.goToList();
              this.subscriptions.push(
                this.activitySrvc
                  .getActivityLog(BaseServices.BusinessId, this.activityId)
                  .subscribe((result) => {
                    this.activityLog = result;
                    this.loading = false;
                    // if(this.print == false){
                    //   this.goToList();
                    // }else{
                    //   this.print = false;
                    //   this.exportPdf();
                    // }
                  })
              );
            }
          })
      );
    }
  }
  onPrint() {
    this.print = true;
  }

  onDeadLineChanged(event: IMyDateModel) {
    this.currentDeadLine = event.jsdate
      ? new Date(event.jsdate.getTime())
      : null!;
    this.currentDate = new Date();
  }
  /**
   * going back to the list without new param
   */
  goToList() {
    this.router.navigate([`../../`], { relativeTo: this.route });
  }
  //#endregion

  //#region methods
  /**
   *
   * @param businessId {any}
   */
  bindDetails(businessId: any) {
    let responsiblePerson: any;
    let followUpPerson: any;
    this.vresperson = [];
    responsiblePerson = this.route.snapshot.data['user'];
    if (responsiblePerson) {
      responsiblePerson.forEach((responsiblePerson: any) => {
        this.vresperson.push({
          label: responsiblePerson.Name,
          value: responsiblePerson.Id,
        });
      });
    }
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.vDepartment = [];
        let department = this.route.snapshot.data['departments'];
        console.table(department);
        this.subscriptions.push(
          this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
            this.vDepartment.push({ label: val.SELECT, value: null });
          })
        );
        if (department) {
          const uniqueTypeSet = new Set<string>(); // Use a Set to store unique types
          department.forEach((typeOfEmp: any) => {
            const typeLabel = typeOfEmp.Name;
            const typeId = typeOfEmp.Id;
            const typebusId = typeOfEmp.BusinessId;

            // Check if the typeLabel is unique before pushing
            if (!uniqueTypeSet.has(typeLabel)) {
              uniqueTypeSet.add(typeLabel);

              // Push into departments
              this.vDepartment.push({
                label: typeLabel,
                value: typeId,
                busId: typebusId,
              });
            }
          });
        }
      })
    );

    this.follows = [];
    followUpPerson = this.route.snapshot.data['user'];
    if (followUpPerson) {
      followUpPerson.forEach((followups: any) => {
        this.follows.push({
          label: followups.Name,
          value: followups.Id,
        });
      });
    }
    this.subscriptions.push(
      this.translate.stream('REPETITION_LIST').subscribe((val) => {
        this.repetitions = [];
        this.repetitions.push(
          { label: val.REPETITION_LIST_SELECT, value: null },
          { label: val.REPETITION_LIST_NO_REPETITION, value: 1 },
          { label: val.REPETITION_LIST_DAILY, value: 2 },
          { label: val.REPETITION_LIST_WEEKDAYS, value: 3 },
          { label: val.REPETITION_LIST_EVERY_WEEK, value: 4 },
          { label: val.REPETITION_LIST_EVERY_2_WEEKS, value: 5 },
          { label: val.REPETITION_LIST_EVERY_3_WEEKS, value: 6 },
          { label: val.REPETITION_LIST_EVERY_MONTH, value: 7 },
          { label: val.REPETITION_LIST_EVERY_2_MONTH, value: 8 },
          { label: val.REPETITION_LIST_EVERY_3_MONTH, value: 9 },
          { label: val.REPETITION_LIST_EVERY_4_MONTH, value: 10 },
          { label: val.REPETITION_LIST_EVERY_6_MONTH, value: 11 },
          { label: val.REPETITION_LIST_EVERY_YEAR, value: 12 },
          { label: val.REPETITION_LIST_EVERY_2_YEARS, value: 13 },
          { label: val.REPETITION_LIST_EVERY_3_YEARS, value: 14 },
          { label: val.REPETITION_LIST_EVERY_4_YEARS, value: 15 },
          { label: val.REPETITION_LIST_EVERY_5_YEARS, value: 16 },
          { label: val.REPETITION_LIST_EVERY_6_YEARS, value: 17 },
          { label: val.REPETITION_LIST_EVERY_7_YEARS, value: 18 },
          { label: val.REPETITION_LIST_EVERY_8_YEARS, value: 19 },
          { label: val.REPETITION_LIST_EVERY_9_YEARS, value: 20 },
          { label: val.REPETITION_LIST_EVERY_10_YEARS, value: 21 }
        );
      })
    );
    this.checkList = [];
    let checklist = this.route.snapshot.data['Checklist'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.checkList = [];
        this.checkList.push({ label: val.SELECT, value: null });
        if (checklist) {
          checklist.forEach((checklist: any) => {
            this.checkList.push({
              label: checklist.Title,
              value: checklist.Id,
            });
          });
        }
      })
    );

    this.FollowUpDays = [];
    for (let n = 0; n <= 14; n++) {
      if (n === 0) {
        this.FollowUpDays.push({
          label: this.translate.instant('ON_DEADLINE_DATE'),
          value: n,
        });
      } else {
        this.FollowUpDays.push({ label: n.toString(), value: n });
      }
    }
  }

  /**
   * show check list dialogue box
   */
  showCheckList() {
    this.displayDialog = true;
    this.checklistDetails = [];
    this.subscriptions.push(
      this.appManualChkListServices
        .getManualChecklistDetails(this.activityForm.value.cmbCheckList)
        .subscribe((val: any) => {
          if (val) {
            this.checklistTile = val.Title;
            this.checklistDetails = val;
          }
        })
    );
  }

  completeChecklist() {
    if (this.disableChecklistDropDown) {
      this.router.navigate(
        [
          '../../../../complete-checklist/incomplete/' +
            this.completeChecklistDetails.Id,
          'activity',
          0,
          0,
        ],
        { relativeTo: this.route }
      );
    } else {
      this.router.navigate(
        [
          '../../../../complete-checklist/new/' +
            this.activityForm.value.cmbCheckList,
          'activity',
          FeatureKey.FOLLOW_UP,
          0,
          this.route.snapshot.params['aid'],
        ],
        { relativeTo: this.route }
      );
    }
  }

  /**
   * file upload events
   * @param event {any}
   */
  saveUploaded(event: any) {
    if (event) {
      let eventType = event.eventName;
      switch (eventType) {
        case 'DeleteDefaultFile':
          if (event.item.data.id === 0) {
            // remove unsaved attachment
            this.newUploadedFiles = this.newUploadedFiles.filter(
              (x) => x.Path !== event.item.data.path
            );
            this.uploadedFiles = this.uploadedFiles.filter(
              (x: any) => x.path !== event.item.data.path
            );
            this.toasterComponent.callToastDlt();
          } else {
            // remove existing attachement
            this.subscriptions.push(
              this.taskService
                .deleteActivityAttachment(event.item.data.id)
                .subscribe((result: any) => {
                  if (result) {
                    // remove from display list
                    this.uploadedFiles = this.uploadedFiles.filter(
                      (x: any) => x.path !== event.item.data.path
                    );
                    this.toasterComponent.callToastDlt();
                  }
                })
            );
          }
          break;
        default:
          let keyBindings = {
            Originalname: 'OriginalFileName',
            FileTypeId: 'FileType',
          };
          let deviationDoc = Object.assign({}, event, this.additionalData);
          deviationDoc.id = 0;
          deviationDoc.createdBy = BaseServices.UserId;
          deviationDoc.status = 1;
          this.newUploadedFiles.push(
            HelperService.switchKeysCase(deviationDoc, keyBindings)
          );
          break;
      }
    }
  }
  /**
   * get current date
   */
  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() - 1,
    };
  }
  //#endregion
  getCompleteChecklistDetails(activityId: number) {
    this.subscriptions.push(
      this.activitySrvc
        .getActivityCompleteCheckList(activityId)
        .subscribe((result: any) => {
          if (result) {
            this.activityForm.patchValue({
              cmbCheckList: result.ChecklistId,
            });
            this.disableChecklistDropDown = true;
            if (result.Status !== 1) {
              this.showCompleteCheckListButton = false;
            }
            this.completeChecklistDetails = result;
          }
        })
    );
  }
  showDepartment() {
    this.department = true;
  }
  viewDepartment() {
    this.subscriptions.push(
      this.businessDepartmentService
        .getCachedBusinessDepartment()
        .subscribe((department) => {
          if (department) {
            /* this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
          this.vDepartment = [];
          this.vDepartment.push(
            // { label: val.SELECT, value: null }
          );
          if (department) {
            department.forEach((caseValue: any) => {
              this.vDepartment.push({
                label: caseValue.Name,
                value: caseValue.Id
              });
            });
          }
        })); */
            this.subscriptions.push(
              this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
                this.eDepartment = [];
                // this.vDepartment.push(
                //   { label: val.SELECT, value: null }
                // );
              })
            );
            if (department) {
              const uniqueTypeSet = new Set<string>(); // Use a Set to store unique types
              department.forEach((typeOfEmp: any) => {
                const typeLabel = typeOfEmp.Name;
                const typeId = typeOfEmp.Id;
                const typebusId = typeOfEmp.BusinessId;

                // Check if the typeLabel is unique before pushing
                if (!uniqueTypeSet.has(typeLabel)) {
                  uniqueTypeSet.add(typeLabel);

                  // Push into departments
                  this.eDepartment.push({
                    Name: typeLabel,
                    Id: typeId,
                    busId: typebusId,
                  });
                }
              });
            }
            this.departmentdetails = this.eDepartment;
          }
        })
    );
  }
  editDepartment(deptdetails: any) {
    this.deptId = this.selectedDepartment1.Id;
    this.selectedDepartment1 = deptdetails;
  }
  /**
   * Deleting department
   * @param selectedDepartment1 {any}
   */
  deleteDepartment(selectedDepartment1: any) {
    if (selectedDepartment1.busId > 0) {
      this.subscriptions.push(
        this.deviationService
          .checkDepartmentExist(selectedDepartment1)
          .subscribe((result: any) => {
            if (result.Isavailable == true) {
              this.clearCachedDepartment();
              this.isDepartmentAllocated = true;
              setTimeout(
                function () {
                  this.isDepartmentAllocated = false;
                }.bind(this),
                3000
              );
            } else {
              this.confirmationService.confirm({
                message: this.translate.instant('DELETE_THIS_RECORD'),
                accept: () => {
                  this.subscriptions.push(
                    this.deviationService
                      .deleteDepartment(selectedDepartment1)
                      .subscribe((result) => {
                        if (result) {
                          this.viewDepartment();
                          this.bindDetails(BaseServices.BusinessId);
                          this.toasterComponent.callToastDlt();
                        }
                      })
                  );
                },
              });
            }
          })
      );
    }
  }
  saveDepartment() {
    let deptdetails = new Department();
    deptdetails.BusinessId = BaseServices.BusinessId;
    deptdetails.ApplicationId = BaseServices.ApplicationId;
    deptdetails.Id = this.deptId ? this.deptId : 0;
    deptdetails.Name = this.selectedDepartment1.Name;
    deptdetails.Status = '1';
    if (deptdetails.Id > 0) {
      deptdetails.ModifiedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.deviationService
          .updateDepartment(deptdetails)
          .subscribe((department: any) => {
            if (department) {
              this.clearCachedDepartment();
              this.department = false;
              this.toasterComponent.callToast();
              this.viewDepartment();
              this.bindDetails(BaseServices.BusinessId);
              this.formDeviation.get('Department').patchValue(department.Id);
              this.deptId = 0;
            }
          })
      );
    } else {
      this.clearCachedDepartment();
      deptdetails.Id = 0;
      deptdetails.CreatedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.deviationService
          .checkDepartmentExist(deptdetails.Name)
          .subscribe((result: any) => {
            if (result.Isavailable === true) {
              this.isDepartmentErrorDisabled = true;
            } else {
              this.subscriptions.push(
                this.deviationService
                  .addDepartment(deptdetails)
                  .subscribe((department: any) => {
                    if (department) {
                      this.department = false;
                      this.toasterComponent.callToast();
                      this.viewDepartment();
                      this.bindDetails(BaseServices.BusinessId);
                      this.formDeviation
                        .get('Department')
                        .patchValue(department.Id);
                    }
                  })
              );
            }
          })
      );
    }
    this.clearDepartment();
  }
  clearDepartment() {
    this.selectedDepartment.Id = 0;
    this.departmentForm = this.formBuilder.group({
      Name: [''],
    });
  }

  clearCachedDepartment() {
    this.businessDepartmentService.clearCachedAllDepartment();
  }

  onBeforeCaseDialogHide() {
    this.hidesElement = false;
    this.isDepartmentErrorDisabled = false;
  }

  onChangeFutureRecurring(event: any) {
    if (event === true) {
      this.confirmationService.confirm({
        message: this.translate.instant('STOP_FUTURE_RECURRING_ACTIVTY'),
        accept: () => {
          this.activityForm.get('futureRecurring')!.patchValue(event);
        },
        reject: () => {
          this.activityForm.get('futureRecurring')!.patchValue(false);
        },
      });
    } else {
      this.activityForm.get('futureRecurring')!.patchValue(event);
    }
  }

  /**
   * checklist change event
   */
  onShowSeeChecklist(e: any) {
    if (e.value > 0) {
      this.isChecklistSelected = true;
    } else {
      this.isChecklistSelected = false;
    }
  }

  // showDialog() {
  //   this.dialogDisplay = true;
  // }

  /**
   * repetetion change event
   */
  onRepetetionChange(e: any) {
    if (e.value > 1) {
      this.isRepetetion = true;
    } else {
      this.isRepetetion = false;
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  exportPdf(activity: FollowUpActivity) {
    this.loadingReport = true;
    let fileName = this.translate.instant('ACTIVITY_DETAILS');
    let filetype = 'pdf';
    let cultureInfo = sessionStorage.getItem('languageMode');
    this.confirmationService.confirm({
      message: this.translate.instant('ARE_SURE_TO_PRINT_THIS_PAGE'),
      accept: () => {
        this.isClick = true;
        this.onSubmitTemplateBased(activity);
        this.subscriptions.push(
          this.activitySrvc
            .activityReportGenerate(
              BaseServices.BusinessId,
              this.activityId,
              cultureInfo,
              BaseServices.UserId
            )
            .subscribe(
              (blob) => {
                this.loadingReport = false;
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${fileName}.${filetype.toLowerCase()}`;
                link.click();
              },
              (error) => {
                this.loadingReport = false;
                alert('Export not downloaded');
              }
            )
        );
      },
    });
  }
}
