<a href="javascript:void(0)" (click)="showModal()" id="article-file-{{ id }}">
  <img src="/assets/file-icons/kb-article.png" alt="article File" />
</a>

<div class="article-modal" *ngIf="showModalWindow" id="article-file-{{ id }}">
  <!-- // New and Edit -->
  <div class="modal-dialog" *ngIf="editMode">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          <span class="ic-md">
            <img src="/assets/file-icons/kb-article.png" alt="Article file" />
          </span>
          <span>{{ articleSrc.article.articleName }}</span>
        </h4>
        <button (click)="hideModal()" class="close">
          <span class="icon ic-md icon-multiply"></span>
        </button>
      </div>
      <div class="modal-body" id="article-pop-{{ id }}" *ngIf="articleSrc">
        <!-- Article Form -->
        <div class="modal-content-left">
          <div class="form-group">
            <label for="" translate>ARTICLE_NAME</label>
            <span class="required">*</span>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="articleSrc.article.articleName"
              id="articlaName"
              name="articlaName"
              [disabled]="disabled"
              #articlaName="ngModel"
              placeholder="{{ 'ARTICLE_NAME' | translate }}"
            />
            <div
              *ngIf="
                articlaName.invalid &&
                (articlaName.dirty || articlaName.touched)
              "
              class="alert alert-danger"
            >
              <div *ngIf="articlaName.errors['required']" translate>
                ARTICLE_NAME_REQ
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="" translate>DOCUMENT_NUMBER</label>
            <span class="required">*</span>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="articleSrc.article.documentNumber"
              id="documentNumber"
              name="documentNumber"
              placeholder="{{ 'DOCUMENT_NUMBER' | translate }}"
              [disabled]="disabled || isElectroArticle"
            />
          </div>

          <div class="form-group">
            <label for="" translate>CHAPTER</label>
            <span class="required">*</span>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="articleSrc.article.chapter"
              id="chapter"
              name="chapter"
              [disabled]="disabled"
              placeholder="{{ 'CHAPTER' | translate }}"
            />
          </div>

          <div class="form-group">
            <label for="" translate>VERSION</label>
            <p-spinner
              [(ngModel)]="versionVal"
              [min]="1"
              [max]="1000"
              (onChange)="onChangeVersion(versionVal)"
            >
            </p-spinner>
          </div>
          <div class="form-group" *ngIf="id > 0">
            <label translate>EDITED_DATE</label>
            <my-date-picker
              name="articledate"
              [options]="articleDateOptions"
              (dateChanged)="onDateChanged($event)"
              [(ngModel)]="articleSrc.article.articleDate"
              placeholder="{{ 'CHOOSE_DATE' | translate }}"
            ></my-date-picker>
          </div>
          <div class="form-group" *ngIf="projectId === 0 && id > 0">
            <label translate>EDITED_BY</label>
            <ng-container *ngIf="rollId === 1 || rollId === 2; else editor">
              <p-dropdown
                [options]="adminUsers"
                [(ngModel)]="selectedUser"
                optionLabel="Name"
              >
              </p-dropdown>
            </ng-container>
            <ng-template #editor>
              <input
                type="text"
                readonly
                class="form-control"
                [(ngModel)]="editorEditedBY"
                id="editedBy"
                name="editedBy"
              />
            </ng-template>
          </div>

          <form class="form" enctype="multipart/form-data">
            <ng-container *ngIf="showStatus">
              <div
                class="checkbox"
                *ngFor="let sArticle of articleSrc.subArticles"
              >
                <label *ngIf="showArticleText(sArticle)">
                  <input
                    type="checkbox"
                    (change)="updateStatus($event, sArticle)"
                    [disabled]="sArticle.disable"
                    name="{{ sArticle.name }}"
                    [ngModel]="checkStatus(sArticle.status)"
                  />
                  {{ sArticle.name | translate }}
                </label>
              </div>
            </ng-container>
          </form>
        </div>
        <!-- Article (Sub Articles) -->
        <div class="modal-content-right">
          <div class="row">
            <div class="col-sm-8">
              <!-- tabs -->
              <tabset type="pills">
                <ng-container *ngFor="let tabz of articleSrc.subArticles">
                  <tab
                    *ngIf="showArticleText(tabz)"
                    (selectTab)="tabSelection(tabz)"
                    [heading]="tabz.name | translate"
                  >
                    <ckeditor
                      [editor]="Editor"
                      [config]="config"
                      [(ngModel)]="tabz.text"
                      [disabled]="readOnly"
                    ></ckeditor>
                  </tab>
                </ng-container>
                <button
                  type="button"
                  *ngIf="showCopyStandardText"
                  class="btn btn-primary"
                  (click)="CopyStandardText()"
                >
                  <span translate>COPY_STANDARD_TEXT</span>
                </button>
                <button
                  type="button"
                  *ngIf="showCopyPortalText"
                  class="btn btn-primary"
                  (click)="CopyPortalText()"
                >
                  <span translate>COPY_PORTAL_TEXT</span>
                </button>
                <tab
                  heading="{{ 'PREVIEW' | translate }}"
                  (selectTab)="previewTabSelection()"
                  (deselect)="previewActive = false"
                  [active]="previewActive"
                >
                  <div class="preview-pane">
                    <ckeditor
                      [editor]="InlineEditor"
                      [config]="previewEditorConfig"
                      [data]="createPreviewTextContent(articleSrc.subArticles)"
                      [disabled]="true"
                    >
                    </ckeditor>
                  </div>
                </tab>
              </tabset>
            </div>
            <div class="col-sm-4" *ngIf="showMergeField">
              <h4 translate>MERGE_FIELD_LIST</h4>
              <div
                class="default-list"
                style="max-height: 200px; overflow: auto"
              >
                <div *ngFor="let item of mergeFieldItems">
                  <div class="list-item">
                    <a
                      href="javascript:void(0);"
                      (click)="clickMergeField($event)"
                    >
                      {{ language === "en" ? item.engText : item.norText }}
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="form-group"
                *ngIf="
                  isProject && workingModuleId != 18 && workingModuleId != 41
                "
              >
                <label for="lblApprovalPersons" class="control-label">
                  <span translate>PROJECT</span>
                </label>
                <div>
                  <p-multiSelect
                    [options]="ProjectOptions"
                    [style]="{ width: '100%', height: '30px' }"
                    [(ngModel)]="ProjectData"
                    (onChange)="onChangeProjectDropDown()"
                  >
                  </p-multiSelect>
                </div>
                <!-- <control-messages [control]="TimetableForm.controls.TimeTableApproverId"></control-messages> -->
              </div>
              <div
                class="form-group"
                *ngIf="isFdv && workingModuleId != 16 && workingModuleId != 41"
              >
                <label for="lblApprovalPersons" class="control-label">
                  <span translate>FDV</span>
                </label>
                <div>
                  <p-multiSelect
                    [options]="FdvOptions"
                    [style]="{ width: '100%', height: '30px', display: 'flex' }"
                    [(ngModel)]="FdvData"
                    (onChange)="onChangeFdvDropDown()"
                  >
                  </p-multiSelect>
                </div>
                <!-- <control-messages [control]="TimetableForm.controls.TimeTableApproverId"></control-messages> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          [disabled]="
            !articleSrc.article.articleName ||
            !articleSrc.article.documentNumber ||
            !articleSrc.article.chapter
          "
          type="button"
          *ngIf="showSave"
          class="btn btn-primary"
          (click)="saveArticle()"
        >
          <span translate>SAVE_CHANGES</span>
        </button>
      </div>
    </div>
  </div>

  <!-- // View Mode -->
  <div class="modal-dialog" *ngIf="!editMode && articleSrc.id != 0">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          <span class="ic-md">
            <img src="/assets/file-icons/kb-article.png" alt="Article file" />
          </span>
          <span>{{ articleSrc.article.articleName }}</span>
        </h4>
        <button (click)="hideModal()" class="btn btn-icon close">
          <span class="icon ic-md icon-multiply"></span>
        </button>
      </div>

      <div class="modal-body" id="article-pop-{{ id }}" *ngIf="articleSrc">
        <div class="container">
          <div class="row">
            <div class="col-sm-8 offset-sm-2">
              <div class="article-print-preview">
                <div class="mb-3">
                  <button
                    class="btn text-primary btn-icon"
                    (click)="exportArticlePdf()"
                  >
                    <span class="icon ic-sm icon-print"></span>
                    <span translate>PRINT_AS_PDF</span>
                  </button>
                </div>
                <div class="article-pp-info">
                  <h2>{{ articleSrc.article.articleName }}</h2>
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <label translate>CHAPTER</label>:
                          <label class="text-primary">{{
                            articleSrc.article.chapter
                          }}</label>
                        </div>
                        <div class="col-md-3">
                          <label translate>DOCUMENT_NUMBER</label>:
                          <label class="text-primary"
                            >{{ articleSrc.article.documentNumber }}
                          </label>
                        </div>
                        <div class="col-md-3">
                          <label translate>RELEASE_DATE</label>:
                          <label class="text-primary">{{ createdDate }}</label>
                        </div>
                      </div>
                      <div *ngFor="let headerData of displayHeaderDetails()">
                        <div class="row">
                          <div class="col-md-6">
                            <label translate>{{ headerData.name }}&nbsp;</label>

                            <label translate>VERSION_LOW_CASE</label>:
                            <label
                              *ngIf="headerData.version > 10"
                              class="text-primary"
                              >{{ headerData.version }}</label
                            >
                            <label
                              *ngIf="headerData.version < 10"
                              class="text-primary"
                              ><span>0</span>{{ headerData.version }}</label
                            >
                          </div>
                          <!-- <div class="col-md-3">
                            <label translate>VERSION</label>:
                            <label
                              *ngIf="headerData.version > 10"
                              class="text-primary"
                              >{{ headerData.version }}</label
                            >
                            <label
                              *ngIf="headerData.version < 10"
                              class="text-primary"
                              ><span>0</span>{{ headerData.version }}</label
                            >
                          </div> -->
                          <div class="col-md-3">
                            <label translate>EDITED_BY</label> :
                            <label class="text-primary">{{
                              headerData.editedBy
                            }}</label>
                          </div>
                          <div class="col-md-3">
                            <label translate>EDITED_DATE</label> :
                            <label class="text-primary">{{
                              headerData.editedDate
                            }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="article-pp-content card">
                  <ckeditor
                    class="article-pp-subarticle card-body"
                    [editor]="InlineEditor"
                    [config]="previewEditorConfig"
                    [data]="createPreviewTextContent(displaySubArticle)"
                    [disabled]="true"
                  >
                  </ckeditor>
                </div>
                <hr />
                <div class="article-signature">
                  <div class="row">
                    <div class="col-sm-6">
                      <label translate>DATE</label>: {{ displayDate }}
                    </div>
                    <div class="col-sm-6 text-right">
                      <img
                        *ngIf="
                          articleSrc.article && articleSrc.article.signaturePath
                        "
                        [src]="articleSrc.article.signaturePath"
                        width="100"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <footer class="article-pp-footer"></footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
