import 'rxjs/add/operator/toPromise';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { AdminUser } from '../models';
import { Options } from './../../../_models/options';
import { ConfigService } from './../../../_services/config.service';
import { BusinessServices } from './../../businesses/services/business.services';
import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class AdminUserService {
    headers: Headers;
    options: Options;
    private setImagePath = new Subject<any>();
    private setUserProfileImagePath = new Subject<any>();
    private setExistingImagePath = new Subject<any>();
    private checkNewUserOrExisting = new Subject<boolean>();
    private checkExistingUserData = new Subject<boolean>();
    getImage$ = this.setImagePath.asObservable();
    getUserProfileImage$ = this.setUserProfileImagePath.asObservable();
    getExistingImage$ = this.setExistingImagePath.asObservable();
    checkNewUser$ = this.checkNewUserOrExisting.asObservable();
    checkExistingUser$ = this.checkExistingUserData.asObservable();
    constructor(
        private http: HttpClient,
        private userService: BusinessServices
    ) { }

    getImagePath(path: string) {
        this.setImagePath.next(path);
    }

    getUserProfileImagePath(path: string) {
        this.setUserProfileImagePath.next(path);
    }

    getExistingImage(path: string) {
        this.setExistingImagePath.next(path);
    }

    checkNewUser(check: boolean) {
        this.checkNewUserOrExisting.next(check);
    }

    // checkExistingUser(check: boolean) {
    //     this.checkExistingUserData.next(check);
    // }
    getAllAdminUsers(): Observable<AdminUser[]> {
        return this.http.get(environment.BASE_URL + '/users', BaseServices.headerOption)
            .map((res:any) => res
                .filter(<AdminUser>(x: any) => x.RoleId === ConfigService.ADMIN)) // admin role filter
            .catch(err => Observable.throw(err));
    }
    getAdminUserById(id:any) {
        return this.http.get<any>(environment.BASE_URL + '/admin-user/' + id, BaseServices.headerOption)
        .map((response: any) => {return response} )
    }
    updateAdminUser(id:any, data:any) {
        return this.http.put<HttpHeaderResponse>(environment.BASE_URL + '/admin-user/' + id, data, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }
    updateLanguage(langId:any) {
        return this.http.get(
            environment.BASE_URL + '/user/modify/language/' + langId,
            BaseServices.headerOption).map(result => {
                return result;
            });
    }
    getByUser(id: number) {
        return this.http.get<HttpHeaderResponse>(environment.BASE_URL + '/user/' + id, BaseServices.headerOption)
            .toPromise()
            .then((response: HttpHeaderResponse) => { return response });
    }

    updateUser(id:any, data:any) {
        return this.http.put<HttpHeaderResponse>(environment.BASE_URL + '/user/' + id, data, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }

    getLanguages() {
        return this.http.get(environment.BASE_URL + '/master/languages', BaseServices.headerOption).map(result => result);
    }

    getRoles() {
        return this.http.get<HttpHeaderResponse>(environment.BASE_URL + '/master/roles', BaseServices.headerOption)
            .toPromise().then((response: HttpHeaderResponse) => { return response });
    }

    addusers(data:any) {
        return this.http.post<HttpHeaderResponse>(environment.BASE_URL + '/admin-user/', data, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }

    addCountryCode(data:any) {
        return this.http.post<HttpHeaderResponse>(environment.BASE_URL + '/countrycode/countrycode', data, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }
    updateCountryCode(data:any) {
        return this.http.put<HttpHeaderResponse>(environment.BASE_URL + '/countrycode/countrycode', data, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }

    deleteCountryCode(id:any) {
        return this.http.put<HttpHeaderResponse>(environment.BASE_URL + '/countrycode/countrycode/' + id, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }
    getCountryCodes() {
        return this.http.get<any>(environment.BASE_URL + '/countrycode/countrycodes', BaseServices.headerOption)
            .map(result => result);
    }
    deleteAdminUser(id: number) {
        return this.http.delete<HttpHeaderResponse>(environment.BASE_URL + '/adminuser/' + id, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }
    checkAllocatedCountryCode(code: string) {
        return this.http.get(environment.BASE_URL + '/countrycode/checkexistingcode/' + code, BaseServices.headerOption)
            .map(result => result)
    }
    getAdminCreatedUsers() {
        return this.http.get(environment.BASE_URL + '/admin-users', BaseServices.headerOption)
            .map(result => result)
    }
}

