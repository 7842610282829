<div class="tab-content">
  <div class="page-title">
    <ng-container *ngIf="isEmployeecourses; then first; else second"></ng-container>
    <ng-template #first><span translate>EMPLOYEE_COURSES</span>
      <span class="page-actions pull-right">
        <button (click)="viewVideo()" [disabled]="isVideo" class="btn btn-outline-secondary">
          <span class="icon ic-sm icon-circled-play"></span>
          <span translate>INFO_VIDEO</span>
        </button>
      </span>
    </ng-template>
    <ng-template #second><span translate>CONTRACTS_AND_LICENSES</span>
      <span class="page-actions pull-right">
        <button (click)="viewVideo()" [disabled]="isVideo" class="btn btn-outline-secondary">
          <span class="icon ic-sm icon-circled-play"></span>
          <span translate>INFO_VIDEO</span>
        </button>
      </span>
    </ng-template>
  </div>
  <div *ngIf="isEmployeecourses && !isPortal" class="text-center mtop-20 mbtm-20">
    <button *ngIf="showemployee" (click)="onShowEmployee()" type="button" class="btn btn-block btn-success"
      style="display: flex; justify-content: center">
      <span translate>ADD_COURSES_TO_MULTIPLE_EMPLOYEES</span>
    </button>
  </div>
  <div *ngIf="showdata">
    <div class="row">
      <div class="col-sm-6">
        <div class="page-title">
          <h3 *ngIf="isEmployeecourses" translate>
            ADD_COURSES_TO_MULTIPLE_EMPLOYEES
            <span class="required">*</span>
          </h3>
        </div>
        <div class="form-group">
          <label for="course">
            <span translate>SELECT_A_COURSE</span>
            <span class="required">*</span>
          </label>
          <div style="display: flex">
            <p-dropdown *ngIf="courses" [options]="courses | dropdownSelectTranslate" [(ngModel)]="CourseName"
              [filter]="true" style="flex-grow: 1" #r styleClass="ui-column-filter"></p-dropdown>
            <button *ngIf="isShowAddCourse" class="btn btn-outline-primary" type="button"
              (click)="addCourse = !addCourse" style="margin-left: 10px">
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
            <button *ngIf="!isShowAddCourse" class="btn btn-link btn-icon" type="button"
              (click)="displayOverlay.toggle($event)" style="margin-left: 10px">
              <span class="icon ic-md icon-info"></span>
            </button>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-6">
              <label for="">
                <span translate>START_DATE</span>
                <span class="required">*</span>
              </label>
              <span>
                <my-date-picker name="startdate" [options]="startDateOptions1" [(ngModel)]="SDCOurse"
                  placeholder="{{ 'SELECT_START_DATE' | translate }}" (dateChanged)="onStartDateChanged1($any($event))">
                </my-date-picker>
              </span>
            </div>
            <div class="col-sm-6">
              <label for="">
                <span translate>END_DATE</span>
                <span class="required">*</span>
              </label>
              <span>
                <my-date-picker name="enddate" [options]="endDateOptions1" [(ngModel)]="EDCourse"
                  placeholder="{{ 'SELECT_END_DATE' | translate }}" (dateChanged)="onEndDateChanged1($event)">
                </my-date-picker>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="Renewaldate">
            <span translate>RENEWAL_DATE</span>
          </label>
          <div>
            <my-date-picker name="Renewaldate" [style]="{ width: '160px' }" [(ngModel)]="Renewaldate"
              [options]="renewalDateOptions" placeholder="{{ 'Select a Renewaldate' | translate }}">
            </my-date-picker>
          </div>
        </div>
        <div class="form-group">
          <label for="description">
            <span translate>DESCRIPTION</span>
            <span class="required">*</span>
          </label>
          <div>
            <textarea pInputTextarea [(ngModel)]="Description" id="description" class="ex-description"></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="Institute">
            <span translate>INSTITUTE</span>
          </label>
          <div>
            <input type="text" pInputText [(ngModel)]="Institute" id="Institute" />
          </div>
        </div>
        <div class="form-group">
          <label for="ResponsibleId">
            <span translate>RESPONSIBLE_FOR_RENEWAL</span>
          </label>
          <div>
            <p-dropdown [options]="responsibleExcecution" [filter]="true" [(ngModel)]="ResponsibleId" id="ResponsibleId"
              [style]="{ width: '100%' }"></p-dropdown>
          </div>
        </div>
        <div class="form-group">
          <label for="DeadlineNotification">
            <span translate>NOTIFICATION_BEFORE_DEADLINE_COURSE</span>
          </label>
          <div>
            <input type="number" pInputText [(ngModel)]="DeadlineNotification" id="Deadline" />
          </div>
        </div>
        <div class="form-group">
          <button class="btn btn-primary" type="submit" (click)="onMultipleCourseEmployeeSave()" [disabled]="
              !(
                this.CourseName != null &&
                this.SDCOurse != null &&
                this.EDCourse != null &&
                this.Description != null &&
                this.selectedEmployee.length > 0
              )
            ">
            <span translate>SAVE</span>
          </button>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="page-title">
          <h3 translate>
            SELECT_ONE_OR_MORE_EMPLOYEES<span class="required">*</span>
          </h3>
        </div>
        <div class="table-view">
          <!-- Table starts -->
          <p-table #employeecourse [value]="employeecoursevalue" dataKey="Id" [scrollable]="true" scrollHeight="550px"
            [(selection)]="selectedEmployee">
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th *ngIf="!guest" style="width: 55px">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>{{ "Name" | translate }}</th>
                <th>{{ "DEPARTMENT" | translate }}</th>
                <th>{{ "COURSE_DOCUMENTS" | translate }}</th>
              </tr>
              <tr>
                <th></th>
                <th>
                  <input pInputText type="text" (input)="
                      employeecourse.filter(
                        $any($event.target)?.value,
                        'Name',
                        'contains'
                      )
                    " placeholder="" class="p-column-filter" />
                </th>
                <th>
                  <input pInputText type="text" (input)="
                      employeecourse.filter(
                        $any($event.target)?.value,
                        'DepartmentName',
                        'contains'
                      )
                    " placeholder="" class="p-column-filter" />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-employee>
              <tr>
                <td style="width: 55px">
                  <p-tableCheckbox id="selectedOrders" [value]="employee">
                  </p-tableCheckbox>
                </td>
                <td>{{ employee.Name }}</td>
                <td>{{ employee.DepartmentName }}</td>
                <td>
                  <input type="file" hidden ngFileSelect (uploadOutput)="onUploadOutput($event)"
                    [uploadInput]="uploadInput" #uploadAction multiple />
                  <button *ngIf="employee.AddFiles == ''" type="button" class="btn btn-outline-success"
                    (click)="uploadAction.click()" (blur)="onEmpId(employee.Id)">
                    <span translate>ADD_FILES</span>
                  </button>
                  <div *ngIf="employee.AddFiles != ''" class="d-flex">
                    <img src="../../../../assets/icons/doc.png" width="20px" *ngIf="
                        employee.AddFiles[0].File.extension == 'doc' ||
                        employee.AddFiles[0].File.extension == 'docx'
                      " />
                    <img src="../../../../assets/icons/xls.png" width="20px" *ngIf="
                        employee.AddFiles[0].File.extension == 'xls' ||
                        employee.AddFiles[0].File.extension == 'xlsx'
                      " />
                    <img src="../../../../assets/icons/pdf.png" width="20px" *ngIf="
                        employee.AddFiles[0].File.extension == 'pdf' ||
                        employee.AddFiles[0].File.extension == 'PDF'
                      " />
                    <img src="../../../../assets/icons/ppt.png" width="20px" *ngIf="
                        employee.AddFiles[0].File.extension == 'ppt' ||
                        employee.AddFiles[0].File.extension == 'pptx'
                      " />
                    <img src="../../../../assets/icons/txt.png" width="20px"
                      *ngIf="employee.AddFiles[0].File.extension == 'txt'" />
                    <img src="../../../../assets/icons/jpg.png" width="20px" *ngIf="
                        employee.AddFiles[0].File.extension == 'jpg' ||
                        employee.AddFiles[0].File.extension == 'png' ||
                        employee.AddFiles[0].File.extension == 'jpeg' ||
                        employee.AddFiles[0].File.extension == 'JPEG'
                      " />
                    <span style="text-overflow: ellipsis; overflow: hidden">{{
                      employee.AddFiles[0].File.filename
                      }}</span>
                    <span class="ml-2 icon ic-sm icon-trash text-danger" (click)="onCourseDelete(employee.Id)"
                      aria-hidden="true"></span>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table ends -->
        </div>
      </div>
    </div>
  </div>
  <div class="text-center">
    <button *ngIf="hideemployee" (click)="onHideEmployee()" class="btn btn-block btn-outline-dark"
      style="display: flex; justify-content: center">
      <span translate>HIDE_ADDING_COURSES</span>
    </button>
  </div>
  <br />
  <div class="table-view">
    <!-- Table starts -->
    <p-table #dt [value]="courseDetails" [rows]="10" [paginator]="true" [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]" [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}">
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="filter-controls-wrapper" style="display: none">
            <form [formGroup]="courseDetailForm" class="form">
              <div class="row">
                <div class="col-sm-3" *ngIf="isPortal">
                  <div class="form-group">
                    <label for="business" class="col-form-label">
                      <span translate>BUSINESS</span>
                    </label>
                    <div>
                      <p-dropdown [options]="businesses" [style]="{ width: '160px' }"
                        (onChange)="onBusinessChanged($event, r)" #r styleClass="ui-column-filter"></p-dropdown>
                    </div>
                  </div>
                </div>
                <div class="col" [formGroup]="coursesSearch">
                  <div class="form-group">
                    <label for="coursename" class="col-form-label">
                      <!-- <span translate>COURSE_NAME</span> -->
                      <ng-container *ngIf="
                          isEmployeecourses;
                          then coursename;
                          else contractname
                        ">
                      </ng-container>
                      <ng-template #coursename><span translate>COURSE_NAME</span></ng-template>
                      <ng-template #contractname><span translate>CONTRACT_NAME</span>
                      </ng-template>
                    </label>
                    <div class="input-btn-group">
                      <p-dropdown *ngIf="courses" [options]="courses | dropdownSelectTranslate"
                        formControlName="courseName" [filter]="true" (onChange)="onCourseChanged($event, r)"
                        #r></p-dropdown>
                      <button *ngIf="isPortal" class="btn btn-outline-primary" type="button"
                        (click)="addCourse = !addCourse">
                        <i class="pi pi-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="cmpname" class="col-form-label">
                      <span translate>RENEWAL_FROM_DATE</span>
                    </label>
                    <div>
                      <my-date-picker name="startdate" [options]="startDateOptions" formControlName="StartDate"
                        placeholder="{{ 'SELECT_START_DATE' | translate }}" (dateChanged)="onStartDateChanged($event)"
                        required>
                      </my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="cmpno" class="col-form-label">
                      <span translate>RENEWAL_END_DATE</span>
                    </label>
                    <div>
                      <my-date-picker name="enddate" (dateChanged)="onEndDateChanged($event)" [options]="endDateOptions"
                        formControlName="EndDate" placeholder="{{ 'SELECT_END_DATE' | translate }}"
                        required></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 pb-3">
                  <button class="btn btn-outline-primary" type="submit" (click)="getCourseDetail()">
                    <span class="icon ic-sm icon-reset"></span>
                    <span translate>REFRESH</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="table-options">
            <div class="pull-left"></div>
            <div class="pull-right">
              <div class="action-btns-group">
                <button class="btn p-3 filter-toggle">
                  <span class="icon ic-sm icon-filter"></span>
                  <span translate>FILTER</span>
                </button>
              </div>
              <div class="action-btns-group">
                <kuba-export [reportData]="dt.filteredValue || dt.value"
                  [additionalData]="additionalData"></kuba-export>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="isEmployeecourses && isPortalCourse" pSortableColumn="BusinessName">
            {{ "BUSINESS_NAME" | translate }}
            <p-sortIcon field="BusinessName"></p-sortIcon>
          </th>
          <th pSortableColumn="Employee">
            {{ "EMPLOYEE_NAME" | translate }}
            <p-sortIcon field="Employee"></p-sortIcon>
          </th>
          <th pSortableColumn="Department" *ngIf="isEmployeecourses">
            {{ "DEPARTMENT" | translate }}
            <p-sortIcon field="Department"></p-sortIcon>
          </th>
          <th *ngIf="isEmployeecourses" pSortableColumn="Course">
            {{ "COURSE_NAME" | translate }}
            <p-sortIcon field="Course"></p-sortIcon>
          </th>
          <th pSortableColumn="Course" *ngIf="!isEmployeecourses">
            {{ "CONTRACT_NAME" | translate }}
            <p-sortIcon field="Course"></p-sortIcon>
          </th>
          <th pSortableColumn="Institute" *ngIf="!isEmployeecourses">
            {{ "INSTITUTE1" | translate }}
            <p-sortIcon field="Institute"></p-sortIcon>
          </th>
          <th pSortableColumn="Institute" *ngIf="isEmployeecourses">
            {{ "INSTITUTE" | translate }}
            <p-sortIcon field="Institute"></p-sortIcon>
          </th>
          <th pSortableColumn="RenewalDateForFilter">
            {{ "RENEWAL_DATE" | translate }}
            <p-sortIcon field="RenewalDateForFilter"></p-sortIcon>
          </th>
          <th pSortableColumn="StartDate">
            {{ "START_DATE" | translate }}
            <p-sortIcon field="StartDate"></p-sortIcon>
          </th>
          <th pSortableColumn="EndDate">
            {{ "END_DATE" | translate }}
            <p-sortIcon field="EndDate"></p-sortIcon>
          </th>
          <th *ngIf="isEmployeecourses == true && !isPortalCourse">
            {{ "ATTACHMENTS" | translate }}
          </th>
          <th *ngIf="isEmployeecourses == true && !isPortalCourse">
            {{ "OPTIONS" | translate }}
          </th>
          <!-- <th *ngIf="isEmployeecourses == true">{{'ATTACHMENTS' | translate}}</th> -->
          <th *ngIf="isEmployeecourses == false">
            {{ "OPTIONS" | translate }}
          </th>
        </tr>
        <tr>
          <th *ngIf="isEmployeecourses && isPortalCourse">
            <input pInputText type="text" (input)="
                dt.filter(
                  $any($event.target)?.value,
                  'BusinessName',
                  'contains'
                )
              " placeholder="" class="p-column-filter" />
          </th>
          <th>
            <input pInputText type="text" (input)="
                dt.filter($any($event.target)?.value, 'Employee', 'contains')
              " placeholder="" class="p-column-filter" />
          </th>
          <th *ngIf="isEmployeecourses">
            <input pInputText type="text" (input)="
                dt.filter($any($event.target)?.value, 'Department', 'contains')
              " placeholder="" class="p-column-filter" />
          </th>
          <th>
            <input pInputText type="text" (input)="
                dt.filter($any($event.target)?.value, 'Course', 'contains')
              " placeholder="" class="p-column-filter" />
          </th>
          <th></th>

          <th></th>
          <th></th>
          <th></th>
          <th *ngIf="isEmployeecourses && !isPortalCourse"></th>
          <th *ngIf="isEmployeecourses && !isPortalCourse"></th>
          <!-- <th *ngIf="isEmployeecourses"></th> -->
          <th *ngIf="!isEmployeecourses"></th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-employee>
        <tr>
          <td *ngIf="isEmployeecourses && isPortalCourse">
            <span class="text-overflow">{{ employee.BusinessName }}</span>
          </td>
          <td>
            <span>{{ employee.Employee }}</span>
          </td>
          <td *ngIf="isEmployeecourses">
            <span>{{ employee.Department }}</span>
          </td>
          <td *ngIf="isEmployeecourses">
            <span class="text-overflow">{{ employee.Course }}</span>
          </td>
          <td *ngIf="!isEmployeecourses">
            <span>{{ employee.Course }}</span>
          </td>
          <td>
            <span>{{ employee.Institute }}</span>
          </td>
          <td>
            <span>{{ employee.RenewalDateForFilter | date : "dd/MM/yyyy"}}</span>
          </td>
          <td>
            <span>{{ employee.StartDate | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ employee.EndDate | date : "dd/MM/yyyy" }}</span>
          </td>
          <td *ngIf="isEmployeecourses == true && !isPortalCourse">
            <button *ngIf="
                employee.EmployeeDocument != null &&
                employee.EmployeeDocument.length > 0
              " tooltipPosition="top" class="btn btn-icon" title="{{ 'Testing' | translate }}"
              (click)="ViewDocument(employee.EmployeeDocument)">
              <i class="pi pi-paperclip" aria-hidden="true"></i>
            </button>
          </td>
          <td *ngIf="isEmployeecourses == true && !isPortalCourse">
            <span *ngIf="!userLevel">
              <a [routerLink]="['./../edit', employee.Id]" class="btn btn-icon" title="{{ 'Edit' | translate }}"
                routerLinkActive="active">
                <i class="icon ic-sm icon-pencil-tip"></i>
              </a>
            </span>
          </td>
          <!-- <td *ngIf="isEmployeecourses == true">
                        <button *ngIf="employee.EmployeeDocument!= null && employee.EmployeeDocument > 0"
                          class="btn btn-icon" type="button" (click)=ViewDocument(employee.EmployeeDocument)>
                            <i class="icon ic-sm icon-task-planning"></i>
                        </button>
                    </td> -->
          <td *ngIf="isEmployeecourses == false">
            <span *ngIf="!userLevel">
              <a [routerLink]="['./../contractEdit', employee.Id]" class="btn btn-icon" title="{{ 'Edit' | translate }}"
                routerLinkActive="active">
                <i class="icon ic-sm icon-pencil-tip"></i>
              </a>
            </span>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template *ngIf="isEmployeecourses == true" pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="9">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
      <ng-template *ngIf="isEmployeecourses == false" pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="8">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
  <router-outlet></router-outlet>
  <p-overlayPanel #displayOverlay [dismissable]="true" appendTo="body">
    <h5>{{ "ik.Kuba.no says:" | translate }}</h5>
    <p>
      <ng-container *ngIf="isEmployeecourses === true; then newCourse; else newContract"></ng-container>
      <ng-template #newCourse><span translate>NEW_COURSE_ADDED_PORTAL_ADMIN_EDITORS</span></ng-template>
      <ng-template #newContract><span translate>NEW_CONTRACT_ADDED_PORTAL_ADMIN_EDITORS</span></ng-template>
    </p>
  </p-overlayPanel>
  <p-overlayPanel #displayOverlay2 [dismissable]="true" appendTo="body">
    <h5>
      {{
      "COURSE_IS_ADDED_BY_PORTAL_SO_DOT_NOT_HAVE_RIGHTS_TO_UPDATE_AND_DELETE"
      | translate
      }}
    </h5>
  </p-overlayPanel>
</div>
<p-dialog header="{{ 'INFO_VIDEO' | translate }}" [(visible)]="isInfoVideo" [style]="{ width: '500px' }"
  [resizable]="false" [draggable]="false">
  <div *ngIf="safeURL">
    <iframe width="560" height="315" [src]="safeURL" frameborder="0" allowfullscreen></iframe>
  </div>
</p-dialog>
<p-dialog *ngIf="isEmployeecourses" header="{{ 'ADD_COURSE' | translate }}" [(visible)]="addCourse"
  [style]="{ width: '300px' }" [resizable]="false" [draggable]="false" (onHide)="onBeforeCourseDialogHide()">
  <form [formGroup]="coursePopup">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
        <span class="required">*</span>
      </label>
      <div>
        <input type="text" #title class="label-input form-control" formControlName="Course" maxlength="100" />
        <span style="color: red" *ngIf="isNameExists">{{
          existsAlerttext
          }}</span>
      </div>
    </div>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button type="button" class="btn btn-outline-primary" (click)="viewCourse = !viewCourse">
          <span translate>VIEW</span>
        </button>
        <button type="button" class="btn btn-primary" [disabled]="!coursePopup.valid" (click)="savecourse()">
          <span translate>SAVE</span>
        </button>
      </div>
      <div *ngIf="viewCourse">
        <div class="table-view">
          <!-- Table starts -->
          <p-table #courseTable [value]="courseList" [rows]="10" [paginator]="true" [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]" [responsive]="true">
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "COURSE_NAME" | translate }}</th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-course>
              <tr>
                <td>{{ course.Title }}</td>
                <td>
                  <button type="button" title="{{ 'EDIT' | translate }}" class="btn btn-icon"
                    (click)="editCourse(course)">
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                  <button type="button" class="btn btn-icon" title="{{ 'DELETE' | translate }}"
                    (click)="deleteCourse(course)">
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table ends -->
        </div>
      </div>
    </p-footer>
  </form>
</p-dialog>
<p-dialog *ngIf="!isEmployeecourses" header="{{ 'ADD_CONTRACT' | translate }}" [(visible)]="addCourse"
  [style]="{ width: '300px' }" [resizable]="false" [draggable]="false" (onHide)="onBeforeCourseDialogHide()">
  <form [formGroup]="coursePopup">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
        <span class="required">*</span>
      </label>
      <div>
        <input type="text" #title class="label-input form-control" formControlName="Course" maxlength="100" />
        <span style="color: red" *ngIf="isNameExists">{{
          existsAlerttext
          }}</span>
      </div>
    </div>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button type="button" class="btn btn-outline-primary" (click)="viewCourse = !viewCourse">
          <span translate>VIEW</span>
        </button>
        <button type="button" class="btn btn-primary" [disabled]="!coursePopup.valid" (click)="savecourse()">
          <span translate>SAVE</span>
        </button>
      </div>
      <div *ngIf="viewCourse">
        <div class="table-view">
          <!-- Table starts -->
          <p-table #courseTable [value]="courseList" [rows]="10" [paginator]="true" [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]" [responsive]="true">
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "CONTRACT_NAME" | translate }}</th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-course>
              <tr>
                <td>{{ course.Title }}</td>
                <td>
                  <button type="button" title="{{ 'EDIT' | translate }}" class="btn btn-icon"
                    (click)="editCourse(course)">
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                  <button type="button" class="btn btn-icon" title="{{ 'DELETE' | translate }}"
                    (click)="deleteCourse(course)">
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table ends -->
        </div>
      </div>
    </p-footer>
  </form>
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog header="{{ 'CONFIRMATION' | translate }}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}" rejectLabel="{{ 'SWITCH_NO' | translate }}"></p-confirmDialog>
<p-dialog *ngIf="isEmployeecourses" header="{{ 'ATTACHMENTS' | translate }}" [(visible)]="viewDocument"
  [style]="{ width: '600px', height: '600px' }" [resizable]="false" [draggable]="false">
  <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
    (saveChanges)="saveUploaded($event)">
  </ecpl-document-explorer>
</p-dialog>