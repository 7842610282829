import { FaqServices } from './../faqs/services/faqs.service';
import {
    MenuDropDownResolver,
    SubMenuDropDownResolver
} from './../faqs/services/faqs.resolver.service';
import { AppSharedModule } from './../shared/appShared.module';
import { NgModule } from '@angular/core';
import { InfoVideoListComponent } from './components/infovideo-list.component';
import { InfoVideoComponent } from './info-video.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
    imports: [HttpClientModule, AppSharedModule, RouterModule],
    exports: [InfoVideoListComponent, InfoVideoComponent],
    declarations: [InfoVideoListComponent, InfoVideoComponent],
    providers: [
        FaqServices, MenuDropDownResolver, SubMenuDropDownResolver
    ],
    bootstrap: [InfoVideoListComponent]
})
export class InfoVideoModule {}
