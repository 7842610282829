<div class="page-title">
    <a *ngIf="!isFs" class="btn btn-icon" [routerLink]="['./../../../../list/own']">
        <span translate class="icon ic-xs icon-back"></span>
    </a>
    <span translate>DEVIATION</span>
    <span class="page-actions" *ngIf="isShowNewButton">
        <a [routerLink]="['./../add-deviation']" class="btn btn-success">
            <span class="icon ic-sm icon-add"></span>
            <span translate>NEW</span>
        </a>
    </span>
</div>

<div class="table-view mbtm-30">
    <!-- Table starts -->
    <p-table  [value]="deviationList" #deviationTable
        [(selection)]="selectedDeviation" selectionMode="multiple" [rows]="10" [paginator]="true" [pageLinks]="3"
        [rowsPerPageOptions]="rowsPerPageOptions" exportFilename="Deviation List"
        [rowsPerPageOptions]="[5,10,20]" 
        [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
                <div class="filter-controls-wrapper" style="display:none">
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="col-form-label">
                                    <span translate>RESPONSIBLE_FOR_FOLLOWUP</span>
                                </label>
                                <div>
                                    <p-dropdown [options]="followup"
                                        (onChange)="onFollowupChanged($event, followUpDropdown)" #followUpDropdown
                                        [style]="{'width':'100%'}" [filter]="true"></p-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="col-form-label">
                                    <span translate>DEPARTMENT</span>
                                </label>
                                <div>
                                    <p-dropdown [options]="departments"
                                        (onChange)="onDepartmentChanged($event, departmentDropdown)" #departmentDropdown
                                        [style]="{'width':'100%'}" [filter]="true"></p-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label>
                                    <span translate>DESCRIPTION</span>
                                </label>
                                <div>
                                    <input type="text" pInputText #cf (keyup)="onDescriptionChanged(cf)" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group" [formGroup]="TypeOfCaseForm">
                                <label class="col-form-label">
                                    <span translate>TYPE_OF_CASE</span>
                                </label>
                                <div class="input-btn-group">
                                    <p-dropdown [options]="Case" formControlName="typeOfCase"
                                        (onChange)="onCaseChanged($event, caseDropdown)" #caseDropdown filter="filter">
                                    </p-dropdown>
                                    <button type="button" *ngIf="isHideButton" class="btn btn-outline-primary"
                                        (click)="showCase()">
                                        <i class="pi pi-plus" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="col-form-label">
                                    <span translate>CREATED_BY</span>
                                </label>
                                <div>
                                    <p-dropdown [options]="createdBy"
                                        (onChange)="onCreatedByChanged($event,createdByDropdown)" #createdByDropdown
                                        [style]="{'width':'100%'}" filter="filter"></p-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="col-form-label">
                                    <span translate>RESPONSIBLE_FOR_EXECUTION</span>
                                </label>
                                <div>
                                    <p-dropdown [options]="Execution"
                                        (onChange)="onExecutionChanged($event,executionDropdown)" #executionDropdown
                                        [style]="{'width':'100%'}" filter="filter"></p-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-options">
                    <div class="pull-right">
                        <div class="action-btns-group">
                            <button class="btn p-3 filter-toggle">
                                <span class="icon ic-sm icon-filter"></span>
                                <span translate>FILTER</span>
                            </button>
                        </div>
                        <div class="action-btns-group">
                            <kuba-export [printMode]="1"
                                [reportData]="deviationTable.filteredValue || deviationTable.value"
                                (loading)='loader($event)' [additionalData]="additionalData"></kuba-export>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 38px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th width='35px'></th>
                <th [style.width.px]="isKundeEl ? 100 : 70"  pSortableColumn="No"  >{{'NUMBER_NO'|translate}} <p-sortIcon field="No"></p-sortIcon>
                </th>
                <th  [style.width.px]="isKundeEl ? 100 : 70"  pSortableColumn="Title">{{'TITLE' | translate}} <p-sortIcon field="Title"></p-sortIcon>
                </th>
                <th width='75px'>{{'STATUS' | translate}}</th>
                <th width="85px" pSortableColumn="Importance">{{'IMPORTANCE' | translate}} <p-sortIcon field="Importance">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="ObjectName" [hidden]="isHideObject">{{'OBJECT_NAME' | translate}} <p-sortIcon
                        field="ObjectName"></p-sortIcon>
                </th>
                <th pSortableColumn="TemperatureValue" [hidden]="isHideFS"  width='85px'>{{'TEMPERATURE' | translate}} <p-sortIcon
                        field="TemperatureValue"></p-sortIcon>
                </th>
                <th width="60px" pSortableColumn="Cost" [hidden]="isHidecostandcreated">{{'COST_DEVIATION' | translate}}</th>
                <th [style.width.px]="isKundeEl ? 99 : 60"  pSortableColumn="FeatureCompleteChecklistName" *ngIf="!isHideChecklistName">
                    {{'CHECKLIST_NAME'|translate}} <p-sortIcon field="FeatureCompleteChecklistName"></p-sortIcon>
                </th>
                <th width="105px" pSortableColumn="DeadlineForPdf">{{'DEADLINE' | translate}} <p-sortIcon field="DeadlineForPdf">
                    </p-sortIcon>
                </th>
                <th width="150px" pSortableColumn="ResponsiblePerson">{{'RESPONSIBLE_PERSON' | translate}} <p-sortIcon
                        field="ResponsiblePerson"></p-sortIcon>
                </th>
                <th width="105px" pSortableColumn="CreatedOn">{{'CREATED_ON' | translate}} <p-sortIcon field="CreatedOn"></p-sortIcon>
                </th>
                <th width="105px" pSortableColumn="CreatorName" [hidden]="isHidecostandcreated">{{'CREATED_BY' | translate}} <p-sortIcon field="CreatorName">
                    </p-sortIcon>
                </th>
                <th width="105px" pSortableColumn="CompletedDateForPdf">{{'DONE_ON' | translate}} <p-sortIcon
                        field="CompletedDateForPdf"></p-sortIcon>
                </th>
                <th [style.width.px]="isKundeEl ? 100 : 70" pSortableColumn="EditedBy" [hidden]="hiddenEditedBy">{{'EDITED_BY' | translate}} <p-sortIcon
                        field="No"></p-sortIcon>
                </th>
                <th *ngIf="isHideDeleteIcon" width='45px'></th>
            </tr>
            <tr>
                <th *ngIf="!isKundeEl"></th>
                <th *ngIf="!isKundeEl"></th>
                <th>
                    <input pInputText type="text"
                        (input)="deviationTable.filter($any($event.target)?.value, 'No', 'contains')" placeholder=""
                        class="p-column-filter" >
                </th>
                <th>
                    <input pInputText type="text"
                        (input)="deviationTable.filter($any($event.target)?.value, 'Title', 'contains')" placeholder=""
                        class="p-column-filter" >
                </th>
                <th>
                    <p-dropdown [options]="statuses" (onChange)="onStatusChanged($event, ed)" #ed appendTo="body"
                        styleClass="p-column-filter" placeholder="Select"  [showClear]="true"> 
                        <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                        </ng-template>
                    </p-dropdown>
                </th>
                <th></th>
                <th [hidden]="isHideObject"></th>
                <th [hidden]="isHideFS"></th>
                <th></th>
                <th *ngIf="!isHideChecklistName"></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th [hidden]="hiddenEditedBy"></th>
                <th *ngIf="isHideDeleteIcon"></th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-deviation let-i="rowIndex">
            <tr>
                <td style="width: 38px;">   
                    <p-tableCheckbox  id="selectedOrders" [value]="deviation" >
                    </p-tableCheckbox>
                </td>
                <td>
                    <i *ngIf="deviation.StatusId == 10" class="{{deviation.StatusIcon}}" style="color:red"></i>
                    <i *ngIf="deviation.StatusId != 10" class="{{deviation.StatusIcon}}"></i>
                </td>
                <td>
            
                    <span [style.color]="deviation.ImmediateAction ? 'red': 'black'"   pTooltip="{{deviation.No}}" tooltipPosition="right">
                        {{deviation.No}}
                    </span>
                </td>
                <td>
                    <a pTooltip="{{deviation.Title}}" tooltipPosition="right"
                        *ngIf="(deviation.FollowUpUserId === currentLoginUserId || deviation.ExecutionUserId === currentLoginUserId||isShowSaveButton||isDisableNewButton || isShowDeviation)"
                        [routerLink]="['./../edit',deviation.Id]">{{deviation.Title}}</a>
                    <span
                        *ngIf="!(deviation.FollowUpUserId === currentLoginUserId || deviation.ExecutionUserId === currentLoginUserId||isShowSaveButton||isDisableNewButton || isShowDeviation)">
                        {{deviation.Title}}
                    </span>
                </td>
                <td>
                    <span>{{deviation.Status | translate}}</span>
                </td>
                <td>
                    <span class="badge"
                        [style.background-color]="deviation.Importance=='Low'?'#4ec55c':(deviation.Importance=='Medium'?'#f0ac19':(deviation.Importance=='High'?'#e7644f':''))">{{deviation.Importance
                        | translate}}</span>
                </td>
                <td [hidden]="isHideObject">
                    <span>{{deviation.ObjectName}}</span>
                </td>
                <td [hidden]="isHideFS">
                    <span>{{deviation.TemperatureValue}}</span>
                </td>
                <td [hidden]="isHidecostandcreated">
                    <span>{{deviation.Cost}}</span>
                </td>
                <td *ngIf="!isHideChecklistName">
                    <div pTooltip="{{deviation.FeatureCompleteChecklistName}}" tooltipPosition="right">
                        {{deviation.FeatureCompleteChecklistName}}
                    </div>
                </td>
                <td>
                    <span>{{deviation.DeadlineForPdf}}</span>
                </td>
                <td>
                    <span>{{deviation.ResponsiblePerson}}</span>
                </td>
                <td>
                    <span>{{deviation.CreatedOn |date : 'dd/MM/yyyy'}}</span>
                </td>
                <td [hidden]="isHidecostandcreated">
                    <span>{{deviation.CreatorName}}</span>
                </td>
                <td>
                    <span>{{deviation.CompletedDateForPdf}}</span>
                </td>
                <td [hidden]="hiddenEditedBy">
                    <span>{{deviation.EditedBy}}</span>
                </td>
                <td class="col-button" *ngIf="isHideDeleteIcon">
                    <button type="button" *ngIf="deviation.Status !== 'DONE'" class="btn btn-icon"
                        title="{{'DELETE' | translate}}" (click)="confirm(deviation.Id)">
                        <i class="icon ic-sm icon-trash"></i>
                    </button>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="13">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
        <!-- Footer -->
            <ng-template pTemplate="summary">
                <div class="footer-data">
                    <div class="symbol-descriptions">
                        <div class="symbol-desc-title">
                            <span translate>DESCRIPTION_SYMBOLS</span>
                        </div>
                        <div class="symbol-desc-items">
                            <span class="new-deadline">
                                <i class="icon ic-md icon-new-deadline"></i>
                                <span translate>NEW_ISSUE</span>
                            </span>
                            <span class="far-deadline">
                                <i class="icon ic-md icon-far-deadline"></i>
                                <span>
                                    <span translate>MORE_DEADLINE</span>
                                </span>
                            </span>
                            <span class="over-deadline">
                                <i class="icon ic-md icon-over-deadline"></i>
                                <span>
                                    <span translate>OVERDEADLINE</span>
                                </span>
                            </span>
                            <span class="near-deadline">
                                <i class="icon ic-md icon-near-deadline"></i>
                                <span>
                                    <span translate>LESS_DEADLINE</span>
                                </span>
                            </span>
                            <span class="clear-deadline">
                                <i class="icon ic-md icon-clear-deadline"></i>
                                <span>
                                    <span translate>DONE_STATUS</span>
                                </span>
                            </span>
                            <span class="clear-deadline" style="color:red">
                                <i class="icon ic-md icon-cancel"></i>
                                <span translate>REJECTED</span>
                            </span>
                        </div>
                    </div>
                </div>
            </ng-template>
    </p-table>
    <!-- Table ends -->
</div>

<div class="action-btns-wrapper" *ngIf="isAhlsell">
    <button class="btn btn-primary" [ngClass]="{'loading' : loading}" (click)="bulkReport()">
        <span *ngIf="!loading" translate>BULK_REPORT</span>
        <span *ngIf="loading" translate>BULK_REPORT</span>
        <span *ngIf="loading" class="loader-icon"></span>
    </button>
</div>
<p-dialog [(visible)]="bulkReportDialog" modal="modal " width="600 " responsive="true ">
    <label>
        <span translate> BULK_DOWNLOAD_MESSAGE </span>
    </label>
    <label>
        <span translate> EFO_NRF_DAILOG_MESSAGE2 </span>
    </label>
    <p-footer>
        <button (click)="showNotificationTab()" type="button" class="btn btn-primary">
            <span translate>OK</span>
        </button>
    </p-footer>
</p-dialog>

<p-dialog header="{{'ADD_CASE_HEADER'|translate}}" (onHide)="onCaseDialogHide()" [(visible)]="case" [modal]="true"
    width="300" [responsive]="true">
    <form [formGroup]="caseForm">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>NAME</span>
            </label>
            <div>
                <input type="text" id="title" class="label-input form-control" formControlName="Name">
                <span class="error-msg" *ngIf="isCaseExists">{{caseExisting}}</span>
            </div>
        </div>
        <p-footer>
            <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                <button type="button" class="btn btn-outline-primary" (click)="hidingElement = !hidingElement"
                    (click)="viewCaseType()">
                    <span translate>VIEW</span>
                </button>
                <button type="button" class="btn btn-primary" (click)="saveCase()" [disabled]="!caseForm.valid">
                    <span translate>SAVE</span>
                </button>

            </div>
            <label for="title" color class="col-form-label" *ngIf="isLabelDisabled" [ngStyle]="{'color': 'red'}">
                <span>{{isExistCaseText}}</span>
            </label>
            <div *ngIf="hidingElement">
                <div class="table-view">
                    <!-- Table starts -->
                    <p-table #dt [value]="caseType" [rows]="5"
                        [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="rowsPerPageOptions"
                        >
                        <!-- Header -->
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{'CASE_NAME' | translate}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>
                                    <input pInputText type="text"
                                    (input)="dt.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                                    class="p-column-filter">
                                </th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <!-- Body Content -->
                        <ng-template pTemplate="body" let-deviation>
                            <tr>
                                <td>
                                    <span>{{deviation.Name}}</span>
                                </td>
                                <td class="col-button">
                                    <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                        (click)="editCase(deviation)">
                                        <i class="icon ic-sm icon-pencil-tip"></i>
                                    </button>
                                    <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                        (click)="deleteCase(deviation['Id'])">
                                        <i class="icon ic-sm icon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                        <!-- Empty message -->
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td colspan="2">
                                    {{'NO_RECORDS_FOUND'|translate}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>                    
                <!-- Table ends -->
                </div>
            </div>
        </p-footer>
    </form>
</p-dialog>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>
<!--Panel Loader-->
<div class="panel-loader" *ngIf="Showloader">
    <span>
        <span class="loader-icon"></span>
        <span>Processing...</span>
    </span>
</div>
<!--Panel Loader-->