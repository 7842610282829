import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { EcplArticleViewerComponent } from './ecpl-article-viewer.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MyDatePickerModule } from 'mydatepicker';
import { TranslateModule } from '@ngx-translate/core';
import { KubaArticleViewerService } from './ecpl-article-viewer.service';
import { CalendarModule } from 'primeng/calendar';
import { SpinnerModule } from 'primeng/spinner';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { ContextMenuModule } from 'primeng/contextmenu';
import { EditorModule } from 'primeng/editor';
import { ClassicEditor } from 'ckeditor5';

@NgModule({
  declarations: [EcplArticleViewerComponent],
  imports: [
    CommonModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    MyDatePickerModule,
    CalendarModule,
    SpinnerModule,
    TranslateModule,
    DropdownModule,
    MultiSelectModule,
    EditorModule,
    ContextMenuModule,
    MenuModule,
    InputTextareaModule,
    InputTextModule,
    ButtonModule,
    SharedModule,
    DialogModule,
    TabsModule.forRoot(),
  ],
  exports: [EcplArticleViewerComponent],
  providers: [DatePipe, KubaArticleViewerService],
})
export class EcplArticleViewerModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: EcplArticleViewerModule,
    };
  }
}
