import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { EmployeeServices } from './../services/employee.services';
import { EmployeeSicknessAbscence } from './../models/employeeSicknessAbscence';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { PdfSetting } from './../../../_services/helper.service';
import { HelperService } from 'src/app/_services/helper.service';
import { BaseServices } from './../../kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { EmployeeSicknessServices } from '../services/employeesickness.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

require('jspdf-autotable');
@Component({
    moduleId: module.id,
    selector: 'sickness-list',
    templateUrl: 'employee-sickness-list.component.html'
})
export class SicknessListComponent implements OnInit {
    //#region variables
    departments: SelectItem[];
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    @ViewChild('dt', { static: false }) dt: Table;
    statuses: SelectItem[];
    empName: SelectItem[];
    reason: SelectItem[];
    reasonList: SelectItem[];
    startDate: Date;
    endDate: Date;
    isPortal = false;
    data: any = [];
    employees: EmployeeSicknessAbscence[];
    sicknessForm: FormGroup;
    calculateNoOfDay: any;
    Number: any;
    isInfoVideo = false;
    videoUrl: string;
    safeURL: any;
    additionalData: any;
    isVideo = false;
    toolTip: string;
    approvalPersonList: SelectItem[];
    departmentNameFilter: any = null;
    fromDateFilter: any = null;
    endDateFilter: any = null;
    reasonFilter: any = null;
    currentSortOrder: 'asc' | 'desc' = 'asc';

    event = {
        first: 0,
        rows: 10
    };
    TotalOpenHours: any;
    TotalApprovedHours: any;
    TotalRejectedHours: any;
    OpenNoOfDay: any;
    RejectedNoOfDay: any;
    ApprovedNoOfDay: any;
    selectedEmployeeName: string = '';


    //#endregion

    //#region
    /**
     * Date picker configuration option
     */
    public dateOption: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '26px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        }
    };
    userRights: any;
    Approver: any;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region
    /**
     * constructor
     * @param fb {FormBuilder}
     * @param route {ActivatedRoute}
     */
    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private employeeService: EmployeeServices,
        private _sanitizer: DomSanitizer,
        private translate: TranslateService,
        private businessservice: BusinessServices,
        private employeesicknessservice: EmployeeSicknessServices,
        private confirmationService: ConfirmationService,
        private employeeSicknessServices: EmployeeSicknessServices
    ) {
        let videoPath = this.route.snapshot.data['infovideo'];
        if (videoPath && videoPath.VideoPath) {
            this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
                videoPath.VideoPath
            );
        }

        this.subscriptions.push(this.translate.stream('SELECT_STATUSESFORLEAVE').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.OPEN, value: 'Open' },
                { label: val.APPROVED, value: 'Approved' },
                { label: val.REJECTED, value: 'Rejected' }
            );
        }));

        this.sicknessForm = this.fb.group({
            BusinessDepartmentId: [''],
            FromDate: [''],
            EndDate: ['']
        });
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
            this.additionalData = {
                fileName: val.EMPLOYEE_SICKNESS_LIST,
                header: 'Employee Sickness List',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Employee Name', dataKey: 'EmployeeName' },
                    { title: 'Start Date', dataKey: 'StartDateForFilter' },
                    { title: 'End Date', dataKey: 'EndDateForFilter' },
                    { title: 'No Of Days', dataKey: 'NoOfDays' },
                    { title: 'Hours', dataKey: 'TotalAbsenceHours' },
                    { title: 'Percent absence', dataKey: 'NofDaysWithPercentageAbsence' },
                    { title: 'Status', dataKey: 'StatusText' },
                    { title: 'Reason', dataKey: 'Reason' },
                    { title: 'Approval Person', dataKey: 'ApprovalPersonName' },
                    { title: 'Date Approval', dataKey: 'AprDate' },
                    { title: 'Description', dataKey: 'Description' }
                ]
            };
        }));
    }
    //#endregion

    //#region page-events
    /**
     * on page load event
     */
    ngOnInit(): void {
        this.GetUserRights();
        document.querySelector("body").classList.remove("opened");
        this.employees = [];
        this.employees = this.route.snapshot.data['list'];
        let approverList: any = [];
        this.TotalOpenHours = '0.0';
        this.TotalApprovedHours = '0.0';
        this.TotalRejectedHours = '0.0';
        let openHours = 0;
        let approvedHours = 0;
        let rejectedHours = 0;
        this.employees.forEach(x => {
            if (x.ApprovalPersonNameList != null) {
                x.ApprovalPersonNameList.forEach(y => {
                    approverList.push(y);
                });
            }
            if (x.NoOfDays != null && x.NoOfDays > 0) {
                x.NoOfDays = x.NoOfDays;
            }
            else if (x.NoOfDays == 0) {
                x.NoOfDays = null;
            }
            if (x.TotalHours != null && x.TotalHours != 0) {
                let TotalHours: number;
                TotalHours = parseFloat(x.TotalAbsenceHours);
                x.TotalAbsenceHours = TotalHours.toFixed(1);
                if (x.StatusText == "OPEN" && x.TotalAbsenceHours != null) {
                    openHours += x.TotalHours;
                }
                else if (x.StatusText == "APPROVED" && x.TotalAbsenceHours != null) {
                    approvedHours += x.TotalHours;
                }
                else if (x.StatusText == "REJECTED" && x.TotalAbsenceHours != null) {
                    rejectedHours += x.TotalHours;
                }
            }
        });
        this.TotalOpenHours = openHours.toFixed(1);
        this.TotalApprovedHours = approvedHours.toFixed(1);
        this.TotalRejectedHours = rejectedHours.toFixed(1);
        this.sicknessPercentage(this.employees);
        approverList.sort();
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            let filteredEmployeeList = approverList.map(item => item)
                .filter((value, index, self) => self.indexOf(value) === index);
            this.approvalPersonList = [];
            this.approvalPersonList.push({ label: val.SELECT, value: null });
            filteredEmployeeList.forEach((element: any) => {
                this.approvalPersonList.push({ label: element, value: element });
            });

        }));
        if (BaseServices.roleId === '2') {
            this.isPortal = true;
        } else {
            this.isPortal = false;
        }
        // let event = {
        //     first: 0,
        //     rows: 10
        // };
        this.bindDropDown();
        //  this.calculateNoOfDays();
        this.globalAbsenceFilter();

    }




    /**
     * sickness percentage calculation
     * @param sicknessList {any}
     */
    sicknessPercentage(sicknessList: any) {
        let totalNumOfdays = 0;
        let numOfdays = 0;
        let totalOpenNumOfdays = 0;
        let openNumOfdays = 0;
        let totalRejectedNumOfdays = 0;
        let rejectedNumOfdays = 0;
        let totalApprovedNumOfdays = 0;
        let approvedNumOfdays = 0;
        sicknessList.forEach((e: any) => {
            let noOfDays;
            let percentageAbsenceOnly;
            let openNoOfDays;
            let rejectedNoOfDays;
            let approvedNoOfDays;
            let startDate = HelperService.formatDate(e.StartDate);
            let endDate = HelperService.formatDate(e.EndDate);
            if (e.PercentageAbsence != null && e.PercentageAbsence != 0) {
                noOfDays = e.NoOfDays + ' (' + e.PercentageAbsence + '%)';
                percentageAbsenceOnly = ' (' + e.PercentageAbsence + '%)';
                numOfdays += e.NoOfDays;
                totalNumOfdays += 230 * e.PercentageAbsence / 100;
            } else {
                noOfDays = e.NoOfDays + ' (' + '100' + '%)';
                percentageAbsenceOnly = ' (' + '100' + '%)';
                totalNumOfdays += 230;
                numOfdays += e.NoOfDays;
            }
            if (e.StatusText == 'OPEN' && e.NoOfDays > 0) {
                openNoOfDays = e.NoOfDays + ' (' + '100' + '%)';
                totalOpenNumOfdays += 230;
                openNumOfdays += e.NoOfDays;
            }
            else if (e.StatusText == 'REJECTED' && e.NoOfDays > 0) {
                rejectedNoOfDays = e.NoOfDays + ' (' + '100' + '%)';
                totalRejectedNumOfdays += 230;
                rejectedNumOfdays += e.NoOfDays;
            }
            else if (e.StatusText == 'APPROVED' && e.NoOfDays > 0) {
                approvedNoOfDays = e.NoOfDays + ' (' + '100' + '%)';
                totalApprovedNumOfdays += 230;
                approvedNumOfdays += e.NoOfDays;
            }
            // e.NofDaysWithPercentageAbsence = noOfDays;
            e.NofDaysWithPercentageAbsence = percentageAbsenceOnly;

            return (e.StartDate = startDate), (e.EndDate = endDate);
        });
        this.calculateNoOfDay = numOfdays.toFixed(2) + ' (' + (numOfdays / totalNumOfdays * 100).toFixed(2) + '%)';
        this.OpenNoOfDay = openNumOfdays.toFixed(2);
        this.ApprovedNoOfDay = approvedNumOfdays.toFixed(2);
        this.RejectedNoOfDay = rejectedNumOfdays.toFixed(2);

    }

    //#endregion

    //#region page-events

    /**
     * binding department dropdown
     * @param businessId{number}
     */
    bindDropDown() {
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.departments = [];
            this.departments.push(
                { label: val.SELECT, value: null }
            );
            let department = this.route.snapshot.data['departments'];
            if (department) {
                department.forEach((dept: any) => {
                    this.departments.push({ label: dept.Name, value: dept.Id });
                });
            }
        }));
        this.empName = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.empName = [];
            this.empName.push(
                { label: val.SELECT, value: null }
            );
        }));

        let empDetails = this.route.snapshot.data['list'];
        if (empDetails) {
            empDetails.forEach((emp: any) => {
                this.empName.push({ label: emp.EmployeeName, value: emp.Id });
            });
            this.empName = Array.from(this.empName.reduce((m, t) => m.set(t.label, t), new Map()).values());
        }

        this.subscriptions.push(this.translate.stream('SICKNESS_REASON').subscribe(val => {
            this.reasonList = [];
            this.reasonList.push(
                { label: val.SELECT, value: 'SELECT' },
                { label: val.SICKNESS, value: 'SICKNESS' },
                { label: val.SICKNESS_MED, value: 'SICKNESS_MED' },
                { label: val.WORK_ISSUES, value: 'WORK_ISSUES' },
                { label: val.CHILDREN_SICKNESS, value: 'CHILDREN_SICKNESS' },
                { label: val.CHILDREN_CARE_SICKNESS, value: 'CHILDREN_CARE_SICKNESS' },
                { label: val.PAID_LEAVE, value: 'PAID_LEAVE' },
                { label: val.UNPAID_LEAVE, value: 'UNPAID_LEAVE' },
                { label: val.OTHERS, value: 'OTHERS' },

            );
        }));
    }

    /**
     * table reset
     */
    refreshTable() {
        this.sicknessForm = this.fb.group({
            BusinessDepartmentId: '',
            FromDate: '',
            EndDate: ''
        });
        this.dt.reset();
    }

    /**
     * calculating no of days
     */
    calculateNoOfDays() {
        let total = 0;
        let totPercent = '';
        let percentVal = 0;
        let NoOfDaysTotal: any;
        if (this.employees) {
            this.employees.forEach(res => {
                if (res.NoOfDays != null) {
                    let x = parseFloat(res.NoOfDays.toString());
                    total += x;
                    NoOfDaysTotal = parseFloat(total.toString());
                }
                if (res.PercentageAbsence != null) {
                    let y = parseFloat(res.PercentageAbsence);
                    percentVal += y;
                }
            });
            if (percentVal > 0) {
                totPercent =
                    '(' +
                    (NoOfDaysTotal.toFixed(2) / percentVal * 100)
                        .toFixed(2)
                        .toString() +
                    '%)';
            }
        }
        NoOfDaysTotal = NoOfDaysTotal + totPercent;
        return NoOfDaysTotal;
    }

    /**
     * export pdf
     */
    exportPdf() {
        let pdfSetting = new PdfSetting();
        pdfSetting.date = 'Date:' + HelperService.formatDate(new Date());
        pdfSetting.businessName = BaseServices.BusinessName;
        pdfSetting.pageHeader = 'Employee Sickness List';
        let columns: any = [
            { title: 'EmployeeName', dataKey: 'EmployeeName' },
            { title: 'Start Date', dataKey: 'StartDate' },
            { title: 'End Date', dataKey: 'EndDate' },
            { title: 'Hours', dataKey: 'TotalAbsenceHours' },
            { title: 'No Of Days', dataKey: 'NofDaysWithPercentageAbsence' },
            { title: 'Status', dataKey: 'StatusText' },
            { title: 'Reason', dataKey: 'Reason' },
            { title: 'Approval PersonName', dataKey: 'ApprovalPersonName' },
            { title: 'Date Of Approval', dataKey: 'DateOfApproval' }
        ];
        if (this.dt.filteredValue) {
            HelperService.generatePdf(
                this.dt.filteredValue,
                columns,
                'Employee Sickness List',
                pdfSetting,
                'l'
            );
        } else {
            HelperService.generatePdf(
                this.employees,
                columns,
                'Employee Sickness List',
                pdfSetting,
                'l'
            );
        }
    }

    //#endregion

    //#region control-events
    /**
     * name filter
     * @param e
     * @param employeeNameDropdown
     */
    onNameChanged(e: any, employeeNameDropdown: any) {
        if (e.value) {
            // Set the selected employee name
            const selectedEmployeeName = employeeNameDropdown.selectedOption.label;
    
            // Custom filter datatable
            this.dt.filter(selectedEmployeeName, 'EmployeeName', 'contains');
        } else {
            this.dt.reset();
        }
        
        // Trigger sorting on 'StartDate' after filtering
        this.sort('StartDate');
    }    

    /**
     * Reason filter
     * @param e
     * @param reasonDropdown
     */
    onReasonChanged(e: any, reasonDropdown: any) {
        sessionStorage.setItem('search_EmployeeAbsence_Reason', reasonDropdown.selectedOption.value)
        if (e.value) {
            // custom filter datatable
            this.dt.filter(
                reasonDropdown.selectedOption.value,
                'Reason',
                'equals'
            );
        } else {
            this.dt.reset();
        }
    }

    /**
     * department filter
     * @param e{any}
     * @param departmentDropdown{any}
     */
    onDepartmentChanged(e: any, departmentDropdown: any) {
        sessionStorage.setItem('search_EmployeeAbsence_Departmentname', departmentDropdown.value);
        if (e.value) {
            // custom filter datatable
            this.dt.filter(
                departmentDropdown.selectedOption.value,
                'BusinessDepartmentId',
                'equals'
            );
        } else {
            this.dt.reset();
        }
    }

    /**
     * startdate calendar onselect event
     * @param event{any}
     */
    onStartDateChanged(event: IMyDateModel) {
        this.startDate = event.jsdate;
        this.filterTable(this.startDate, this.endDate);
    }

    /**
     * enddate calendar onselect event
     * @param event {IMyDateModel}
     */
    onEndDateChanged(event: IMyDateModel) {
        this.endDate = event.jsdate
        this.filterTable(this.startDate, this.endDate);
    }
    /**
     * date filter for table
     */
    filterTable(startDate: any, endDate: any) {
        let list = this.route.snapshot.data['list'];
        let StartDate = startDate ? startDate : new Date('1800-01-01');
        let EndDate = endDate ? endDate : new Date();
        this.employees = [];
        if (list) {
            if (startDate || endDate) {
                if (startDate && endDate) {
                    list = list.filter((x: any) => {
                        return (
                            (new Date(x.StartDateForFilter) >=
                                new Date(startDate) &&
                                new Date(x.StartDateForFilter) <= new Date(endDate)) ||
                            (
                                new Date(x.EndDateForFilter) >=
                                new Date(startDate) &&
                                new Date(x.EndDateForFilter) <= new Date(endDate)
                            )
                        );
                    });
                } else if (endDate) {
                    list = list.filter((x: any) => {
                        return (
                            new Date(x.EndDateForFilter) <= new Date(endDate)
                        );
                    });
                } else {
                    list = list.filter((x: any) => {
                        return (
                            new Date(x.StartDateForFilter) >=
                            new Date(startDate)
                        );
                    });
                }
            }
            this.employees.push(...list);
        }
    }
    /**
     * to filter status in list
     * @param e {event}
     * @param statusDropdown {any}
     */
    onStatusChanged(e: any, statusDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.dt.filter(
                statusDropdown.selectedOption.value,
                'StatusText',
                'equals'
            );
        } else {
            this.dt.reset();
        }
    }
    /**
     * panination change
     * @param first {number}
     * @param end {number}
     */
    employeePageChange(event: any) {
        let list = this.employees.slice(event.first, event.first + event.rows);
        if (list.length > 0) {
            this.sicknessPercentage(list);
        } else {
            this.calculateNoOfDay = 0;
        }
    }

    sort(property: string) {
        // If a filter is applied, sort the filtered data
        if (this.dt.hasFilter()) {
            const filteredData = this.dt.filteredValue;
    
            filteredData.sort((a, b) => {
                const dateA = new Date(a[property]);
                const dateB = new Date(b[property]);
    
                if (this.currentSortOrder === 'asc') {
                    if (dateA < dateB) {
                        return -1;
                    } else if (dateA > dateB) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else {
                    if (dateA > dateB) {
                        return -1;
                    } else if (dateA < dateB) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
    
            // Apply the sorted data to the datatable
            this.dt.filteredValue = filteredData;
        } else {
            // If no filter is applied, sort the original data
            this.employees.sort((a, b) => {
                const dateA = new Date(a[property]);
                const dateB = new Date(b[property]);
    
                if (this.currentSortOrder === 'asc') {
                    if (dateA < dateB) {
                        return -1;
                    } else if (dateA > dateB) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else {
                    if (dateA > dateB) {
                        return -1;
                    } else if (dateA < dateB) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    
        // Toggle the sorting order for the next click
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
    }       
    
    /**
     * filter change event
     * @param event {any}
     */
    employeeFilterChange(event: any) {
        let list = event.filteredValue.slice(0, 0 + 10);
        if (list.length > 0) {
            this.sicknessPercentage(list);
        } else {
            this.calculateNoOfDay = 0;
        }
    }

    /**
     * video popup event
     */
    viewVideo() {
        if (this.safeURL.changingThisBreaksApplicationSecurity.trim() == "") {
            alert(this.translate.instant('INFO_VIDEO_URL'));
            this.isVideo = true;
        } else {
            this.isInfoVideo = true;
        }
    }
    //#endregion
    globalAbsenceFilter() {
        let departmentName = sessionStorage.getItem('search_EmployeeAbsence_Departmentname');
        let fromDate = sessionStorage.getItem('search_EmployeeAbsence_FromDate')
        let endDate = sessionStorage.getItem('search_EmployeeAbsence_EndDate');
        let reason = sessionStorage.getItem('search_EmployeeAbsence_Reason');

        if (departmentName && departmentName !== 'null') {
            this.departmentNameFilter = departmentName;
            this.dt.filter(departmentName,
                'BusinessDepartmentId',
                'equals'
            )
        }

        if (reason && reason !== 'null') {
            this.reasonFilter = reason;
            this.dt.filter(reason,
                'reasonUserId',
                'equals'
            )
        }

        this.fromDateFilter = (fromDate && fromDate !== 'null') ?
            HelperService.formatInputDate(new Date(fromDate))
            : null;
        this.endDateFilter = (endDate && endDate !== 'null') ?
            HelperService.formatInputDate(new Date(endDate))
            : null;
    }
    calculateDiff(StartDate: any, EndDate: any) {
        StartDate = new Date(StartDate);
        EndDate = new Date(EndDate);
        var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
        StartDate.setHours(0, 0, 0, 1); // Start just after midnight
        EndDate.setHours(23, 59, 59, 999); // End just before midnight
        var diff = EndDate - StartDate; // Milliseconds between datetime objects
        var days = Math.ceil(diff / millisecondsPerDay);
        // Subtract two weekend days for every week in between
        var weeks = Math.floor(days / 7);
        days = days - (weeks * 2);

        // Handle special cases
        StartDate = StartDate.getDay();
        EndDate = EndDate.getDay();

        // Remove weekend not previously removed.
        if (StartDate - EndDate > 1)
            days = days - 2;

        // Remove start day if span starts on Sunday but ends before Saturday
        if (StartDate == 0 && EndDate != 6)
            days = days - 1;

        // Remove end day if span ends on Saturday but starts after Sunday
        if (EndDate == 6 && StartDate != 0) {
            days = days - 1;
        }
        var leaveDays = days;
        return leaveDays;
    }
    GetUserRights() {
        this.subscriptions.push(this.businessservice.getByUserId(BaseServices.UserId).subscribe(res => {
            if (res != null) {
                this.userRights = res;
                params1 = [];
                var params1 = JSON.parse(this.userRights.Rights);
                this.Approver = params1.filter((x: any) => x.name == "APPROVE_ABSENCE")
                this.employees = [];
                this.employees = this.route.snapshot.data['list'];
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    let filteredEmployeeList = this.employees.map(item => item.ApprovalPersonName)
                        .filter((value, index, self) => self.indexOf(value) === index);
                    this.approvalPersonList = [];
                    this.approvalPersonList.push({ label: val.SELECT, value: null });
                    filteredEmployeeList.forEach(element => {
                        this.approvalPersonList.push({ label: element, value: element });
                    });

                }));
            }
        }))
    }
    delete(id: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.employeesicknessservice.DeleteAbsence(id).subscribe(deleteResponse => {
                    if (deleteResponse) {
                        this.employees = this.employees.filter((x: any) => x.Id != id);
                        this.toasterComponent.callToastDlt();
                    }
                }));
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
