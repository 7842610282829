//#region import
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { FrameworkAgreementServices } from './../services/framework.service';
import { FrameworkList } from 'src/app/kuba/framework-agreement/models/frameworkList';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
//#endregion

@Component({
    selector: 'framework-list-all',
    templateUrl: 'framework-list-all.component.html'
})

export class FrameworkListAllComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    pdf: any;
    additionalData: any;
    frameworklist: FrameworkList[];
    private subscriptions: Subscription[] = [];
    isGuest = true;
    constructor(
        private frameServices: FrameworkAgreementServices,
        public router: Router,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) {
        this.translate.stream('FILENAME').subscribe(val => {  
        this.additionalData = {
            fileName: val.FRAMEWORK_AGREEMENT_LIST,
            header: 'Framework agreement list',
            businessId :BaseServices.BusinessId,
            cultureInfo :BaseServices.userCultureInfo(),   
            columnNames: [
                { title: 'Contract Number', dataKey: 'ContractNumber' },
                { title: 'Contract Name', dataKey: 'ContractName' },
                { title: 'Client Name', dataKey: 'Client' },
                { title: 'Status', dataKey: 'Status' },
                { title: 'Created By', dataKey: 'CreatedName' },
                { title: 'Edited By', dataKey: 'ModifierName' },
            ]
        };
    });
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.roleId === '4') {
            this.isGuest = false;
        }
        this.frameworklist = [];
        let list = <FrameworkList[]>this.route.snapshot.data['List'];
        if (list != null) {
            for (let t of list) {
                if (t.StatusText === '7') {
                    t.Status = 'New';
                }else if (t.StatusText === '8') {
                    t.Status = 'Open';
                }else if (t.StatusText === '9') {
                    t.Status = 'Done';
                } else if (t.StatusText === '10') {
                    t.Status = 'Rejected';
                }else { t.Status = 'New'; }
            }
            this.frameworklist.push(...list);
        }
    }

    /**
     * get frameworklist
     */
    getProjectList() {
        this.subscriptions.push(this.frameServices
            .getFrameworklistByBusiness()
            .subscribe(result => {
                let list = result;
                list.forEach(t => {
                    if (t.StatusText === '7') {
                        t.Status = 'New';
                    }else if (t.StatusText === '8') {
                        t.Status = 'Open';
                    }else if (t.StatusText === '9') {
                        t.Status = 'Done';
                    }else if (t.StatusText === '10') {
                        t.Status = 'Rejected';
                    } else { t.Status = 'New'; }
                });
                this.frameworklist = [];
                this.frameworklist.push(...list);
            }));
    }

    /**
     * delete framework based on id
     * @param  framework
     */
    deleteFramework(framework) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.frameServices
                    .deleteFramework(framework)
                    .subscribe(result => {
                        this.getProjectList();
                        this.toasterComponent.callToastDlt();
                    }));
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
