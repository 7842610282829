import { DatePipe, LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import {
  AccessibilityHelp,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  BlockQuote,
  Bold,
  ClassicEditor,
  CloudServices,
  EditorConfig,
  Essentials,
  Heading,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  InlineEditor,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  PictureEditing,
  SelectAll,
  SimpleUploadAdapter,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline,
  Undo,
} from 'ckeditor5';
import * as _ from 'lodash';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { Subscription } from 'rxjs';
import { FeatureKey } from 'src/app/_models';
import { HelperService } from 'src/app/_services/helper.service';
import { ElectroDocumentService } from 'src/app/kuba/electro/services/electro-document.services';
import { BaseServices, KubaServices } from 'src/app/kuba/kuba.services';
import { ManualService } from 'src/app/kuba/manuals/services/manual.services';
import {
  ArticleContent,
  ArticleDetails,
  ArticleEvent,
  MergeField,
  SubArticle,
  TextTypeAndStatus,
} from 'src/app/shared/ecpl-article-viewer/interfaces';
import { environment } from 'src/environments/environment';
import { KubaArticleViewerService } from './ecpl-article-viewer.service';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
const StringBuilder = require('string-builder');

@Component({
  selector: 'ecpl-article-viewer',
  templateUrl: './ecpl-article-viewer.component.html',
  styleUrls: ['./ecpl-article-viewer.component.css'],
})
export class EcplArticleViewerComponent implements OnInit, OnChanges {
  @Input() id: number;
  @Input() articleSrc: ArticleContent;
  @Input() ecplApiResponse: string;
  @Input() articleName: string;
  @Input() editMode: boolean;
  @Input() businessMode: string;
  @Input() adminUsers: any[];

  @Output() saveChanges: EventEmitter<any> = new EventEmitter<any>();

  public config: EditorConfig = {
    language: 'en',
    toolbar: {
      items: [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'link',
        'insertImage',
        'insertTable',
        'blockQuote',
        'mediaEmbed',
        '|',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent',
      ],
      shouldNotGroupWhenFull: true,
    },
    plugins: [
      AccessibilityHelp,
      Autoformat,
      AutoImage,
      Autosave,
      AutoLink,
      BlockQuote,
      Bold,
      Essentials,
      Heading,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      MediaEmbed,
      Paragraph,
      PasteFromOffice,
      PictureEditing,
      SelectAll,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      Underline,
      Undo,
      SimpleUploadAdapter,
    ],
    image: {
      toolbar: [
        'toggleImageCaption',
        'imageTextAlternative',
        '|',
        'imageStyle:inline',
        'imageStyle:wrapText',
        'imageStyle:breakText',
        '|',
        'resizeImage',
      ],
    },
    simpleUpload: {
      uploadUrl: environment.BASE_URL + '/CKEditor',
      headers: {
        Authorization:
          'Bearer ' +
          JSON.parse(sessionStorage.getItem('session'))['AccessToken'],
      },
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
    },
  };

  previewEditorConfig: EditorConfig = {
    plugins: [
      AccessibilityHelp,
      Autoformat,
      AutoImage,
      Autosave,
      AutoLink,
      BlockQuote,
      Bold,
      Essentials,
      Heading,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      MediaEmbed,
      Paragraph,
      PasteFromOffice,
      PictureEditing,
      SelectAll,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      Underline,
      Undo,
      SimpleUploadAdapter,
    ],
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
    },
  };

  public Editor = ClassicEditor;
  public InlineEditor = InlineEditor;

  public tabz = {
    text: '',
  };

  public articleDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    todayBtnTxt: this.translator.instant('TODAY'),
    dayLabels: {
      su: this.translator.instant('SUN'),
      mo: this.translator.instant('MON'),
      tu: this.translator.instant('TUE'),
      we: this.translator.instant('WED'),
      th: this.translator.instant('THU'),
      fr: this.translator.instant('FRI'),
      sa: this.translator.instant('SAT'),
    },
    monthLabels: {
      1: this.translator.instant('JANUARY'),
      2: this.translator.instant('FEBRUARY'),
      3: this.translator.instant('MARCH'),
      4: this.translator.instant('APRIL'),
      5: this.translator.instant('MAY'),
      6: this.translator.instant('JUNE'),
      7: this.translator.instant('JULY'),
      8: this.translator.instant('AUGUST'),
      9: this.translator.instant('SEPTEMBER'),
      10: this.translator.instant('OCTOBER'),
      11: this.translator.instant('NOVEMBER'),
      12: this.translator.instant('DECEMBER'),
    },
  };
  language: string = this.translator.currentLang
    ? this.translator.currentLang
    : 'en';
  articalEvent: ArticleEvent;
  showModalWindow: boolean;
  mergeField: MergeField;
  workingModule: any;
  workingModuleId: any;
  editedDate: any;
  public selectedFiles: any[];
  applicationId: number;
  businessId: number;
  portalId: number;
  public Save: any;
  displayDate: any;
  createdDate: any;
  modifedDate: any;
  hideVersion = false;
  selectedUser: any;
  showStatus = true;
  editorEditedBY = '';
  // Editor Config
  readOnly = false;

  mergeFieldItems: Array<any>;
  selectedTabz: string;
  showMergeField = false;
  SubArticle: Array<any>;
  OwnText: string;
  StandaredText: string;
  Legislation: string;
  PortalText: string;
  manualId: number;
  mergeFieldData: any;
  ProjectData: any[] = [];
  ProjectOptions: any[] = [];
  userFeatures: any[] = [];
  FdvData: any[] = [];
  FdvOptions: any[] = [];
  isFdv = true;
  isProject = true;
  CopyMergeFieldItems: Array<any>;
  Title: string;
  projectId: number;
  previewActive = true;
  SignaturePath: string;
  loading: boolean;
  uploadStart: boolean;
  uploadStartForSignature: boolean;
  articleDetails: ArticleContent;
  featureId: number;
  showSave = true;
  roleStatus: any;
  aDetails: ArticleDetails;
  disabled = false;
  showCopyStandardText = false;
  saveFromClose = true;
  showCopyPortalText = false;
  displaySubArticle: Array<any>;
  ReplaceOwnText: string;
  versionVal = 0;
  rollId: number;
  textTypeAndStatusList: TextTypeAndStatus[] = [];
  textTypeAndStatus: TextTypeAndStatus;
  Openfiled = '{{';
  Closefiled = '}}';
  defaultRoleStatus: any = {
    admin: [
      { name: 'Own Text', status: 'Active', disable: 'false' },
      { name: 'Standard Text', status: 'Active', disable: 'false' },
      { name: 'Legislation', status: 'Active', disable: 'false' },
    ],
    portal: [
      { name: 'Own Text', status: 'Active', disable: 'false' },
      { name: 'Portal Text', status: 'Active', disable: 'false' },
      { name: 'Standard Text', status: 'Active', disable: 'false' },
      { name: 'Legislation', status: 'Active', disable: 'false' },
    ],
    editor: [
      { name: 'Own Text', status: 'Active', disable: 'false' },
      { name: 'Portal Text', status: 'Active', disable: 'false' },
      { name: 'Standard Text', status: 'Active', disable: 'false' },
      { name: 'Legislation', status: 'Active', disable: 'false' },
    ],
  };
  isElectroArticle = false;
  private subscriptions: Subscription[] = [];
  constructor(
    private exportService: KubaArticleViewerService,
    private renderer: Renderer2,
    private datePipe: DatePipe,
    private http: HttpClient,
    private manualService: ManualService,
    private electroDocumentService: ElectroDocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService,
    private translator: TranslateService,
    private kubaServices: KubaServices,
    location: LocationStrategy
  ) {
    this.applicationId = BaseServices.ApplicationId;
    this.portalId = +this.route.snapshot.params['mPortalId'];
    this.businessId = +this.route.snapshot.params['mBusinessId'];
    if (this.businessId.toString() === 'NaN') {
      this.businessId = +BaseServices.BusinessId;
      this.portalId = 0;
    }
    let workingModule = JSON.parse(sessionStorage.getItem('workingModule')!);
    this.Title = workingModule.Title;
    this.featureId = workingModule.id;
    this.projectId = this.route.snapshot.params['Id'];
    if (this.projectId === undefined) {
      this.projectId = 0;
    } else {
      if (this.featureId != null) {
        this.businessId = BaseServices.BusinessId;
        this.portalId = +BaseServices.PortalId;
      }
    }
    if (this.featureId === FeatureKey.ELECTRO) {
      this.businessId = BaseServices.BusinessId;
      this.portalId = +BaseServices.PortalId;
      this.isElectroArticle = true;
    }
    this.articleSrc = this.articleSrc;
    this.subscriptions.push(
      this.kubaServices.getLanguage$.subscribe((lang) => {
        this.language = lang;
      })
    );
    location.onPopState(() => {
      this.showModalWindow = false;
      this.renderer.removeClass(document.body, 'modal-open');
    });
    this.rollId = +BaseServices.roleId;
  }

  showArticleText(articleText: any): boolean {
    if (
      this.businessId === 0 &&
      this.portalId !== 0 &&
      articleText.name === 'Own text'
    ) {
      return false;
    } else {
      return true;
    }
  }

  showModal() {
    let ReplaceStandaredText: string;
    let ReplacePortalText: string;
    let articleDate: any;
    this.showModalWindow = true;
    this.SubArticle = [];
    this.displaySubArticle = [];
    // TODO: Check if date Exist and show Existing date else Current date
    if (
      typeof this.articleSrc.article.articleDate === 'string' &&
      this.articleSrc.article.articleDate
    ) {
      this.articleSrc.article.articleDate = this.getCurrentDate();
    } else if (
      this.articleSrc.article.articleDate === undefined ||
      this.articleSrc.article.articleDate === null
    ) {
      this.articleSrc.article.articleDate = this.getCurrentDate();
    }
    if (this.articleSrc.article.createdOn != undefined)
      this.articleDateOptions.disableUntil = HelperService.formatDisableDate(
        this.articleSrc.article.createdOn
      ).date;
    if (this.portalId === 0 && this.businessId > 0) {
      let foundOwntext: any = this.articleSrc.subArticles.find(function (
        element
      ) {
        return element.name === 'Own Text';
      });
      if (foundOwntext !== undefined) {
        if (foundOwntext.businessEditedBy > 0) {
          this.editorEditedBY = this.articleSrc.article.modifiedBy;
        }
      }
    }

    this.aDetails = {
      ArticleId: this.articleSrc.article.id!,
      CreaterRoleId: this.articleSrc.article.roleId!,
      CreaterId: this.articleSrc.article.createrId,
      UserRoleId: this.getUserRole(
        this.portalId,
        this.businessId,
        +BaseServices.roleId
      ),
      BusinessId: BaseServices.BusinessId,
      PortalId: this.portalId,
      BusinessMode: this.businessMode,
      Version: this.articleSrc.article.version!,
    };
    // new changes code
    let chkStatus: any;
    if (
      this.projectId === 0 ||
      this.featureId === FeatureKey.ELECTRO ||
      this.featureId == FeatureKey.KUBA_CONTROL
    ) {
      if (this.articleSrc.article.id !== 0) {
        this.manualId = this.route.snapshot.params['mid'];
        this.subscriptions.push(
          this.manualService
            .getArticleTextStatus(this.aDetails, +this.manualId)
            .subscribe((result: any) => {
              if (result) {
                this.articleSrc.article.roleStatus = result;
                if (result) {
                  this.roleStatus = result;
                  if (
                    this.applicationId !== 0 &&
                    this.portalId === 0 &&
                    this.businessId === 0
                  ) {
                    chkStatus = this.roleStatus.admin;
                  } else if (
                    this.applicationId !== 0 &&
                    this.portalId !== 0 &&
                    this.businessId === 0
                  ) {
                    chkStatus = this.roleStatus.portal;
                  } else if (
                    this.applicationId !== 0 &&
                    this.portalId === 0 &&
                    this.businessId !== 0
                  ) {
                    chkStatus = this.roleStatus.editor;
                  }
                  if (chkStatus != null) {
                    this.articleSrc.subArticles.forEach((element: any) => {
                      if (element.name === 'Own Text') {
                        let owntextObj = chkStatus.find(function (
                          element: any
                        ) {
                          return (element.name = 'Own Text');
                        });
                        if (owntextObj !== undefined) {
                          element.status = owntextObj.status;
                          element.disable = owntextObj.disable;
                        }
                      } else if (element.name === 'Portal Text') {
                        let portalTextObj = chkStatus.find(function (
                          element: any
                        ) {
                          return element.name === 'Portal Text';
                        });
                        if (portalTextObj !== undefined) {
                          element.status = portalTextObj.status;
                          element.disable = portalTextObj.disable;
                        } else if (element.disable === '') {
                          element.disable = 'false';
                        }
                      } else if (element.name === 'Standard Text') {
                        let standardTextObj = chkStatus.find(function (
                          element: any
                        ) {
                          return element.name === 'Standard Text';
                        });
                        if (standardTextObj !== undefined) {
                          element.status = standardTextObj.status;
                          element.disable = standardTextObj.disable;
                        }
                      } else if (element.name === 'Legislation') {
                        let legislationObj = chkStatus.find(function (
                          element: any
                        ) {
                          return element.name === 'Legislation';
                        });
                        if (legislationObj !== undefined) {
                          element.status = legislationObj.status;
                          element.disable = legislationObj.disable;
                        }
                      }
                    });
                  }
                }
              }
            })
        );
      }
    }
    // end code
    let count = 0;
    this.articleSrc.subArticles.forEach((element) => {
      if (this.articleSrc.article.id == 0) {
        element.id = count;
        count++;
      }
      switch (element.name) {
        case 'Own Text':
          this.OwnText =
            element.text != null
              ? element.text
                  .replace(new RegExp('&oslash;', 'g'), 'ø')
                  .replace(new RegExp('&aring;', 'g'), 'å')
              : '';
          break;
        case 'Standard Text':
          this.StandaredText =
            element.text != null
              ? element.text
                  .replace(new RegExp('&oslash;', 'g'), 'ø')
                  .replace(new RegExp('&aring;', 'g'), 'å')
              : '';

          break;
        case 'Project Text':
          this.StandaredText =
            element.text != null
              ? element.text
                  .replace(new RegExp('&oslash;', 'g'), 'ø')
                  .replace(new RegExp('&aring;', 'g'), 'å')
              : '';
          break;
        case 'Legislation':
          this.Legislation = element.text != null ? element.text : '';
          break;
        case 'Portal Text':
          this.PortalText =
            element.text != null
              ? element.text
                  .replace(new RegExp('&oslash;', 'g'), 'ø')
                  .replace(new RegExp('&aring;', 'g'), 'å')
              : '';
          break;
        default:
          break;
      }
    });

    // if editmode is false, then fetch and replace merge fields with business values
    if (this.editMode !== true) {
      this.subscriptions.push(
        this.kubaServices.getLanguage$.subscribe((lang) => {
          this.language = lang;
        })
      );
      this.mergeFieldData = [];
      this.manualId = this.route.snapshot.params['mid'];
      if (this.featureId == FeatureKey.KUBA_CONTROL) {
        this.projectId = 0;
      }
      this.subscriptions.push(
        this.manualService
          .getMergeFieldData(
            +BaseServices.ApplicationId,
            +this.manualId,
            +this.portalId,
            this.businessId,
            this.projectId
          )
          .subscribe(
            (result: any) => {
              if (result) {
                this.mergeFieldData = result;
                if (
                  this.OwnText !== '' ||
                  this.StandaredText !== '' ||
                  this.Legislation ||
                  this.PortalText !== ''
                ) {
                  this.ReplaceOwnText = '';
                  ReplaceStandaredText = '';
                  ReplacePortalText = '';
                  for (let i = 0; i < this.mergeFieldItems.length; i++) {
                    let keyText;
                    if (this.language === 'en') {
                      keyText = this.mergeFieldItems[i].engText;
                    } else {
                      keyText = this.mergeFieldItems[i].norText;
                    }
                    let sbOwntext = new StringBuilder();
                    let sbStandaredText = new StringBuilder();
                    let sbPortalText = new StringBuilder();

                    if (this.ReplaceOwnText !== '') {
                      this.OwnText = this.ReplaceOwnText;
                    } else {
                      this.OwnText = this.OwnText;
                    }
                    if (ReplaceStandaredText !== '') {
                      this.StandaredText = ReplaceStandaredText;
                    } else {
                      this.StandaredText = this.StandaredText;
                    }
                    if (ReplacePortalText !== '') {
                      this.PortalText = ReplacePortalText;
                    } else {
                      this.PortalText = this.PortalText;
                    }
                    if (this.OwnText !== undefined) {
                      this.ReplaceOwnText = this.mergeFieldReplace(
                        keyText,
                        this.mergeFieldItems[i].id,
                        this.OwnText,
                        sbOwntext
                      );
                    }
                    if (this.StandaredText !== undefined) {
                      ReplaceStandaredText = this.mergeFieldReplace(
                        keyText,
                        this.mergeFieldItems[i].id,
                        this.StandaredText,
                        sbStandaredText
                      );
                    }
                    if (this.PortalText !== undefined) {
                      if (this.applicationId !== 0) {
                        if (this.PortalText !== '') {
                          ReplacePortalText = this.mergeFieldReplace(
                            keyText,
                            this.mergeFieldItems[i].id,
                            this.PortalText,
                            sbPortalText
                          );
                        }
                      }
                    }
                  }
                }
                if (this.editMode === false) {
                  this.articleSrc.subArticles.forEach((element: any) => {
                    this.displaySubArticle.push(Object.assign({}, element));
                  });
                  this.displaySubArticle.forEach((element: any) => {
                    switch (element.name) {
                      case 'Own Text':
                        element.text = this.ReplaceOwnText;

                        break;
                      case 'Standard Text':
                        element.text = ReplaceStandaredText;
                        break;
                      case 'Project Text':
                        element.text = ReplaceStandaredText;
                        break;
                      case 'Portal Text':
                        element.text = ReplacePortalText;
                        break;
                      default:
                        break;
                    }
                  });
                }
              }
            },
            (error: any) => {
              console.error('Api Error while refreshing tree:', error);
            }
          )
      );
    }
    // if (this.featureId == 41) {
    //   this.articleSrc.subArticles = this.articleSrc.subArticles.filter(
    //     (x) => x.name == 'Own Text'
    //   );
    //   this.showCopyPortalText = false;
    // }
    this.renderer.addClass(document.body, 'modal-open');
  }
  updateTextVersion(version: any) {
    this.articleSrc.subArticles.forEach((element: any) => {
      element.version = this.articleSrc.article.version;
    });
  }

  getEditedByName(id: number): string {
    let name = '';
    if (id !== null && id > 0) {
      let editedBy = this.adminUsers.filter((x) => x.Id === id);
      if (editedBy !== null && editedBy.length > 0) {
        name = editedBy[0].Name;
      }
    }
    return name;
  }

  /**
   * article header data
   */
  displayHeaderDetails(): any {
    let headerData = [];
    if (
      this.applicationId !== 0 &&
      this.portalId === 0 &&
      this.businessId === 0
    ) {
      let standartTextModifiedBy = '';
      let standartTextModifiedDate;
      this.articleSrc.subArticles.forEach((element: any) => {
        if (element.name == 'Standard Text') {
          standartTextModifiedBy =
            element.modifiedName != null ? element.modifiedName : '';
          standartTextModifiedDate =
            element.modifiedDate != null && element.modifiedDate != undefined
              ? HelperService.formatInputDate(element.modifiedDate).formatted
              : '';
        }
      });
      let standardTextObj = {
        name: 'Standard Text',
        version: this.articleSrc.article.version,
        editedBy: standartTextModifiedBy,
        editedDate: standartTextModifiedDate,
      };
      headerData.push(standardTextObj);
    } else if (
      this.applicationId !== 0 &&
      this.portalId !== 0 &&
      this.businessId === 0
    ) {
      if (
        this.articleSrc.article.roleId === 1 &&
        this.articleSrc.article.portalId === 0
      ) {
        let findStandardText = this.articleSrc.subArticles.find(function (
          element
        ) {
          return element.name === 'Standard Text';
        });
        if (
          findStandardText !== undefined &&
          findStandardText.status.toLowerCase() !== 'inactive'
        ) {
          let standardTextObj = {
            name: 'Standard Text',
            version:
              findStandardText.version !== null ? findStandardText.version : 1,
            editedBy: findStandardText.modifiedName,
            editedDate:
              findStandardText.modifiedDate !== null &&
              findStandardText.modifiedDate !== undefined
                ? HelperService.formatInputDate(findStandardText.modifiedDate)
                    .formatted
                : '',
          };
          headerData.push(standardTextObj);
        }

        let findPortalText = this.articleSrc.subArticles.find(function (
          element
        ) {
          return element.name === 'Portal Text';
        });
        if (
          findPortalText !== undefined &&
          findPortalText.status.toLowerCase() !== 'inactive'
        ) {
          let PortalTextObj = {
            name: 'Portal Text',
            version:
              findPortalText.portalVersion !== null
                ? findPortalText.portalVersion
                : 1,
            editedBy: findPortalText.modifiedName,
            editedDate:
              findPortalText.modifiedDate !== null &&
              findPortalText.modifiedDate !== undefined
                ? HelperService.formatInputDate(findPortalText.modifiedDate)
                    .formatted
                : '',
          };
          headerData.push(PortalTextObj);
        }
      } else {
        let findPortalText = this.articleSrc.subArticles.find(function (
          element
        ) {
          return element.name === 'Portal Text';
        });
        if (
          findPortalText !== undefined &&
          findPortalText.status.toLowerCase() !== 'inactive'
        ) {
          let portalTextObj = {
            name: 'Portal Text',
            version: this.articleSrc.article.version,
            editedBy:
              findPortalText.modifiedName !== undefined
                ? findPortalText.modifiedName
                : '',
            editedDate:
              findPortalText.modifiedDate !== null &&
              findPortalText.modifiedDate !== undefined
                ? HelperService.formatInputDate(findPortalText.modifiedDate)
                    .formatted
                : '',
          };
          headerData.push(portalTextObj);
        }
      }
    } else if (
      this.businessId !== 0 &&
      this.projectId !== undefined &&
      this.projectId !== 0
    ) {
      let featureObj = {
        name: 'Own Text',
        version: this.articleSrc.article.version,
        editedBy: this.articleSrc.article.modifiedBy,
        editedDate:
          this.articleSrc.article.articleFormatedDate != null
            ? HelperService.formatInputDate(
                this.articleSrc.article.articleFormatedDate
              ).formatted
            : '',
      };
      headerData.push(featureObj);
    } else if (
      this.applicationId !== 0 &&
      this.portalId === 0 &&
      this.businessId > 0
    ) {
      if (
        (this.articleSrc.article.roleId === 1 &&
          this.articleSrc.article.businessId === 0) ||
        (this.articleSrc.article.roleId === 2 &&
          this.articleSrc.article.businessId === 0)
      ) {
        if (+this.articleSrc.article.roleId === 1) {
          let findStandardText = this.articleSrc.subArticles.find(function (
            element
          ) {
            return element.name === 'Standard Text';
          });
          if (
            findStandardText !== undefined &&
            findStandardText.status.toLowerCase() !== 'inactive'
          ) {
            let standardTextObj = {
              name: 'Standard Text',
              version: this.articleSrc.article.version,
              editedBy: findStandardText.modifiedName,
              editedDate:
                findStandardText.modifiedDate !== null &&
                findStandardText.modifiedDate !== undefined
                  ? HelperService.formatInputDate(findStandardText.modifiedDate)
                      .formatted
                  : '',
            };
            headerData.push(standardTextObj);
          }
        }

        let findPortaltext: any = this.articleSrc.subArticles.find(function (
          element
        ) {
          return element.name === 'Portal Text';
        });
        if (
          this.businessMode === 'underPortal' &&
          findPortaltext.status.toLowerCase() !== 'inactive'
        ) {
          let PortalTextObj = {
            name: 'Portal Text',
            version:
              findPortaltext.portalVersion !== null
                ? findPortaltext.portalVersion
                : 1,
            editedBy: findPortaltext.modifiedName,
            editedDate:
              findPortaltext.modifiedDate !== null &&
              findPortaltext.modifiedDate !== undefined
                ? HelperService.formatInputDate(findPortaltext.modifiedDate)
                    .formatted
                : '',
          };
          headerData.push(PortalTextObj);
        }
        let findOwntext = this.articleSrc.subArticles.find(function (element) {
          return element.name === 'Own Text';
        });
        if (
          findOwntext !== undefined &&
          findOwntext.status.toLowerCase() !== 'inactive'
        ) {
          let ownTextObj = {
            name: 'Own Text',
            version: findOwntext.businessVersion,
            editedBy: findOwntext.modifiedName,
            editedDate:
              findOwntext.modifiedDate !== null &&
              findOwntext.modifiedDate !== undefined
                ? HelperService.formatInputDate(findOwntext.modifiedDate)
                    .formatted
                : '',
          };
          headerData.push(ownTextObj);
        }
      } else {
        let findOwntext = this.articleSrc.subArticles.find(function (element) {
          return element.name === 'Own Text';
        });
        if (
          findOwntext !== undefined &&
          findOwntext.status.toLowerCase() !== 'inactive'
        ) {
          let ownTextObj = {
            name: 'Own Text',
            version: this.articleSrc.article.version,
            editedBy: findOwntext.modifiedName,
            editedDate:
              findOwntext.modifiedDate !== null &&
              findOwntext.modifiedDate !== undefined
                ? HelperService.formatInputDate(findOwntext.modifiedDate)
                    .formatted
                : '',
          };
          headerData.push(ownTextObj);
        }
      }
    }
    return headerData;
  }

  checkStatus(status: any) {
    if (status === 'Active' || status === 'ACTIVE') {
      return true;
    } else {
      return false;
    }
  }
  public filesSelect(selectedFiles: any): void {
    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(
      (file) => file
    );
    let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);
    let session = JSON.parse(sessionStorage.getItem('session'));
    let token = session.AccessToken;
    var headers = {
      Authorization: 'Bearer ' + token, // Add JWT token to headers
      // Add any other headers if needed
    };

    this.subscriptions.push(
      this.http
        .post(
          environment.BASE_URL +
            '/file/upload/application/images/' +
            BaseServices.ApplicationId,
          formData,
          { headers: headers }
        )
        .map((response: any) => {
          response = response;
        })
        .subscribe(
          (result) => {},
          (error) => console.log('Upload article Sign Error : ', error),
          () => console.log('Upload article  Sign Complete')
        )
    );
  }

  updateStatus(event: any, sArticle: any) {
    if (event.target.checked) {
      sArticle.status = 'Active';
    } else {
      sArticle.status = 'Inactive';
    }

    if (this.portalId === 0 && this.businessId === 0) {
      this.roleStatus.admin.forEach((element: any) => {
        if (element.name === sArticle.name) {
          element.status = sArticle.status;
        }
      });
      this.roleStatus.portal.forEach((element: any) => {
        if (element.name !== 'Own Text') {
          if (element.name === sArticle.name) {
            element.status = sArticle.status;
            if (sArticle.status === 'Inactive') {
              element.disable = 'true';
            } else if ((sArticle.status = 'Active')) {
              element.disable = 'false';
            }
          }
        }
      });
      this.roleStatus.editor.forEach((element: any) => {
        if (element.name !== 'Own Text') {
          if (element.name === sArticle.name) {
            element.status = sArticle.status;
            if (sArticle.status === 'Inactive') {
              element.disable = 'true';
            } else if (sArticle.status === 'Active') {
              element.disable = 'false';
            }
          }
        }
      });
    } else if (this.portalId !== 0 && this.businessId === 0) {
      this.roleStatus.portal.forEach((element: any) => {
        if (element.name === sArticle.name) {
          element.status = sArticle.status;
        }
      });
    } else if (
      this.businessId !== 0 &&
      this.projectId !== undefined &&
      this.projectId !== 0 &&
      this.featureId !== FeatureKey.ELECTRO
    ) {
      this.articleSrc.subArticles.forEach((element: any) => {
        if (element.name === sArticle.name) {
          element.status = sArticle.status;
        }
      });
    } else if (this.portalId === 0 && this.businessId !== 0) {
      this.roleStatus.editor.forEach((element: any) => {
        if (element.name === sArticle.name) {
          element.status = sArticle.status;
        }
      });
    }
    this.articleSrc.subArticles.forEach((element: any) => {
      if (element.id == sArticle.id) {
        element.status = sArticle.status;
      }
    });
  }

  saveArticle() {
    this.ecplApiResponse = 'init';
    if (this.articleSrc.article.articleName === '') {
      let result: any = false;
      return result;
    }
    this.selectedUser =
      this.selectedUser === undefined ? null : this.selectedUser;
    if (
      this.applicationId !== 0 &&
      this.portalId === 0 &&
      this.businessId === 0
    ) {
      this.articleSrc.article.version = this.versionVal;
      this.articleSrc.article.editedBy =
        this.selectedUser !== null ? this.selectedUser.Id : null;
    } else if (
      this.applicationId !== 0 &&
      this.portalId !== 0 &&
      this.businessId === 0
    ) {
      if (
        this.articleSrc.article.roleId === 1 &&
        this.articleSrc.article.portalId === 0
      ) {
        this.articleSrc.subArticles.forEach((element: any) => {
          if (element.name === 'Own Text' || element.name === 'Portal Text') {
            element.portalVersion = this.versionVal;
            element.portalEditedBy =
              this.selectedUser !== null ? this.selectedUser.Id : null;
          }
        });
      } else {
        this.articleSrc.article.version = this.versionVal;
        this.articleSrc.subArticles.forEach((element: any) => {
          if (element.name === 'Own Text' || element.name === 'Portal Text') {
            if (this.articleSrc.article.id == 0) {
              element.id = 0;
            }
            element.portalVersion = this.versionVal;
          }
        });
      }
    } else if (
      this.applicationId !== 0 &&
      this.portalId === 0 &&
      this.businessId !== 0
    ) {
      if (
        (this.articleSrc.article.roleId === 1 ||
          this.articleSrc.article.roleId === 2) &&
        this.articleSrc.article.businessId === 0
      ) {
        this.articleSrc.subArticles.forEach((element: any) => {
          if (element.name === 'Own Text') {
            element.businessVersion = this.versionVal;
            element.businessEditedBy = +BaseServices.UserId;
          }
        });
      } else {
        this.articleSrc.article.version = this.versionVal;
        this.articleSrc.article.editedBy = +BaseServices.UserId;
        this.articleSrc.subArticles.forEach((element) => {
          if (this.articleSrc.article.id == 0) {
            element.id = 0;
          }
          if (element.name === 'Own Text') {
            element.businessVersion = this.versionVal;
            element.businessEditedBy = +BaseServices.UserId;
          }
        });
      }
    } else if (
      this.applicationId !== 0 &&
      this.portalId !== 0 &&
      this.businessId !== 0
    ) {
      if (
        (this.articleSrc.article.roleId === 1 ||
          this.articleSrc.article.roleId === 2) &&
        this.articleSrc.article.businessId === 0
      ) {
        this.articleSrc.subArticles.forEach((element: any) => {
          if (element.name === 'Own Text') {
            element.businessVersion = this.versionVal;
            element.businessEditedBy = +BaseServices.UserId;
          }
        });
      } else {
        this.articleSrc.article.version = this.versionVal;
        this.articleSrc.article.editedBy = +BaseServices.UserId;
        this.articleSrc.subArticles.forEach((element) => {
          if (this.articleSrc.article.id == 0) {
            element.id = 0;
          }
          if (element.name === 'Own Text') {
            element.businessVersion = this.versionVal;
            element.businessEditedBy = +BaseServices.UserId;
          }
        });
      }
    }

    let baseServiceArticleId = BaseServices.getArticleId;
    if (baseServiceArticleId === 0) {
      if (this.id === 0) {
        let date = this.articleSrc.article.articleDate.date;
        this.articleSrc.article.articleDate = HelperService.formatInputDate(
          new Date(`${date.month}/${date.day}/${date.year}`)
        );
        this.articalEvent = {
          eventName: 'NewArticle',
          item: {
            articleContent: this.articleSrc,
          },
        };
        BaseServices.setArticleId(0);
        if (this.saveFromClose) {
          this.hideModal();
        }
      } else {
        this.articalEvent = {
          eventName: 'SaveArticle',
          item: {
            articleId: this.id,
            articleContent: this.articleSrc,
          },
        };
        if (this.saveFromClose) {
          this.hideModal();
        }
      }
    } else {
      this.articalEvent = {
        eventName: 'SaveArticle',
        item: {
          articleId: this.id !== 0 ? this.id : baseServiceArticleId,
          articleContent: this.articleSrc,
        },
      };
      if (this.saveFromClose) {
        this.hideModal();
      }
    }
    this.previewActive = true;
    this.articalEvent.item.articleContent.article.articleEditDate =
      this.articalEvent.item.articleContent.article.articleDate.formatted;
    this.saveChanges.emit(this.articalEvent);
  }

  hideModal() {
    BaseServices.setArticleId(0);
    this.showModalWindow = false;
    this.previewActive = true;
    this.showCopyPortalText = false;
    this.showCopyStandardText = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  CopyStandardText() {
    this.articleSrc.subArticles.forEach((element: any) => {
      switch (element.name) {
        case 'Own Text':
          if (
            this.selectedTabz === 'OwnText' ||
            this.selectedTabz === 'Own Text'
          ) {
            element.text =
              (element.text === null ? '' : element.text) + this.StandaredText;
          }
          break;
        case 'Portal Text':
          if (
            this.selectedTabz === 'PortalText' ||
            this.selectedTabz === 'Portal Text'
          ) {
            element.text =
              (element.text === null ? '' : element.text) + this.StandaredText;
          }
          break;
        default:
          break;
      }
    });
  }

  CopyPortalText() {
    this.articleSrc.subArticles.forEach((element: any) => {
      switch (element.name) {
        case 'Own Text':
          element.text =
            (element.text === null ? '' : element.text) + this.PortalText;
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {
    if (
      +BaseServices.roleId === 1 &&
      this.portalId === 0 &&
      this.businessId === 0
    ) {
      this.showStatus = false;
    }

    this.articleDetails = this.articleSrc;
    this.previewActive = true;
    let cDateObj = new Date(this.articleSrc.article.createdOn);
    this.createdDate = cDateObj.toLocaleDateString('en-GB');
    if (this.articleSrc.article.modifiedOn != null) {
      let mDateObj = new Date(this.articleSrc.article.modifiedOn);
      this.modifedDate = mDateObj.toLocaleDateString('en-GB');
    }

    this.aDetails = {
      ArticleId: this.articleSrc.article.id!,
      CreaterRoleId: this.articleSrc.article.roleId!,
      CreaterId: this.articleSrc.article.createrId,
      UserRoleId: +BaseServices.roleId,
      BusinessId: BaseServices.BusinessId,
      PortalId: BaseServices.roleId === '2' ? this.portalId : 0,
      BusinessMode: this.businessMode,
      Version: this.articleSrc.article.version!,
    };
    this.mergeFieldTranslation();
    this.AddProjectData();
    this.saveFromClose = true;
    this.BindProjectAndFdvDropDown();
    this.userFeatures = BaseServices.userFeatures;
    this.userFeatures.forEach((e) => {
      if (e.checked == false && e.id == FeatureKey.FDV) {
        this.isFdv = false;
      }
      if (e.checked == false && e.id == FeatureKey.PROJECT) {
        this.isProject = false;
      }
    });
  }

  mergeFieldTranslation() {
    this.mergeFieldItems = [];
    this.mergeFieldItems = [
      {
        id: 1,
        engText: '{{Business Name}}',
        norText: '{{Navn på virksomheten}}',
        LANG_KEY: 'MERGE_BUSINESS_NAME',
      },
      {
        id: 2,
        engText: '{{Facts about the company}}',
        norText: '{{Fakta om virksomheten}}',
        LANG_KEY: 'MERGE_FACTS_ABOUT_THE_COMPANY',
      },
      {
        id: 3,
        engText: '{{User list}}',
        norText: '{{Brukerliste}}',
        LANG_KEY: 'MERGE_USER_LIST',
      },
      {
        id: 4,
        engText: '{{Contact list}}',
        norText: '{{Kontaktliste}}',
        LANG_KEY: 'MERGE_CONTACT_LIST',
      },
      {
        id: 5,
        engText: '{{List of property/building mass}}',
        norText: '{{Liste over bygninger/eiendommer}}',
        LANG_KEY: 'MERGE_LIST_OF_PROPERTY/BUILDING_MASS',
      },
      {
        id: 6,
        engText: '{{List of all social aspects}}',
        norText: '{{Liste over sosiale forhold}}',
        LANG_KEY: 'MERGE_LIST_OF_ALL_SOCIAL_ASPECTS',
      },
      {
        id: 7,
        engText: '{{List of equipments}}',
        norText: '{{Liste over utstyr}}',
        LANG_KEY: 'MERGE_LIST_OF_EQUIPMENTS',
      },
      {
        id: 8,
        engText: '{{List of activities}}',
        norText: '{{Liste over aktiviteter}}',
        LANG_KEY: 'MERGE_LIST_OF_ACTIVITIES',
      },
      {
        id: 9,
        engText: '{{Employee List}}',
        norText: '{{Ansatteliste}}',
        LANG_KEY: 'MERGE_EMPLOYEE_LIST',
      },
      {
        id: 10,
        engText: '{{Project name}}',
        norText: '{{Prosjektnavn}}',
        LANG_KEY: 'MERGE_PROJECT_NAME',
      },
      {
        id: 11,
        engText: '{{Start date}}',
        norText: '{{Startdato}}',
        LANG_KEY: 'MERGE_START_DATE',
      },
      {
        id: 12,
        engText: '{{Expected to end}}',
        norText: '{{Forventet sluttdato}}',
        LANG_KEY: 'MERGE_EXPECTED_END',
      },
      {
        id: 13,
        engText: '{{Status}}',
        norText: '{{Status}}',
        LANG_KEY: 'STATUS',
      },
      {
        id: 14,
        engText: '{{Builder}}',
        norText: '{{Byggherre}}',
        LANG_KEY: 'MERGE_BUILDER',
      },
      {
        id: 15,
        engText: '{{Address}}',
        norText: '{{Adresse}}',
        LANG_KEY: 'MERGE_ADDRESS',
      },
      {
        id: 16,
        engText: '{{Zip code}}',
        norText: '{{Postnummer}}',
        LANG_KEY: 'MERGE_ZIP_CODE',
      },
      {
        id: 17,
        engText: '{{Our areas of responsibility}}',
        norText: '{{Våre ansvarsområder}}',
        LANG_KEY: 'MERGE_AREA_OF_RESPONSIBILITY',
      },
      {
        id: 18,
        engText: '{{City}}',
        norText: '{{Poststed}}',
        LANG_KEY: 'MERGE_CITY',
      },
      {
        id: 19,
        engText: '{{Property number}}',
        norText: '{{Gnr}}',
        LANG_KEY: 'MERGE_PROP_NUMBER',
      },
      {
        id: 21,
        engText: '{{Ground number}}',
        norText: '{{Bnr}}',
        LANG_KEY: 'MERGE_GROUND_NUMBER',
      },
      {
        id: 22,
        engText: '{{Project cost}}',
        norText: '{{Prosjektkostnader}}',
        LANG_KEY: 'MERGE_PROJ_COST',
      },
      {
        id: 23,
        engText: '{{Project number}}',
        norText: '{{Prosjekt nummer}}',
        LANG_KEY: 'MERGE_PROJ_NUMBER',
      },
      {
        id: 24,
        engText: '{{Sub Contractor}}',
        norText: '{{Underentreprenører}}',
        LANG_KEY: 'MERGE_SUB_CONTRACTOR',
      },
      {
        id: 25,
        engText: '{{Professional manager}}',
        norText: '{{Fagansvarlig}}',
        LANG_KEY: 'MERGE_PROF_MANAGER',
      },
      {
        id: 26,
        engText: '{{Responsible for application}}',
        norText: '{{Ansvarlig søker}}',
        LANG_KEY: 'MERGE_RESPONSE_APPLN',
      },
      {
        id: 27,
        engText: '{{Responsible for project planning}}',
        norText: '{{Ansvarlig prosjekterende}}',
        LANG_KEY: 'MERGE_RESPONSE_PROJ_PLAN',
      },
      {
        id: 28,
        engText: '{{Responsible for execution}}',
        norText: '{{Ansvarlig for utførelse}}',
        LANG_KEY: 'MERGE_RESPONSIBLE_FOR_EXECUTION',
      },
      {
        id: 29,
        engText: '{{Safety representatives}}',
        norText: '{{Verneombud}}',
        LANG_KEY: 'MERGE_SAFETY_REP',
      },
      {
        id: 30,
        engText: '{{Responsible for independent control}}',
        norText: '{{Velg ansvarlig for uavhengig kontroll}}',
        LANG_KEY: 'MERGE_RESPONSE_INPNT_CTRL',
      },
      {
        id: 31,
        engText: '{{Construction manager}}',
        norText: '{{Byggeleder}}',
        LANG_KEY: 'MERGE_CONSTRUCTION_MGR',
      },
      {
        id: 32,
        engText: '{{Coordinator projecting}}',
        norText: '{{Koordinator Prosjektering}}',
        LANG_KEY: 'MERGE_COORDINATOR_PROJECTING',
      },
      {
        id: 33,
        engText: '{{Coordinator execution}}',
        norText: '{{Koordinator Utførelsen}}',
        LANG_KEY: 'MERGE_COORDINATOR_EXECUTION',
      },
      {
        id: 34,
        engText: '{{Project description}}',
        norText: '{{Prosjektbeskrivelse}}',
        LANG_KEY: 'MERGE_PROJECT_DESCRIPTION',
      },
      {
        id: 35,
        engText: '{{Project manager}}',
        norText: '{{Prosjektansvarlig}}',
        LANG_KEY: 'MERGE_PROJ_MANAGER',
      },
    ];
  }

  AddProjectData() {
    this.ProjectOptions = [];
    this.FdvOptions = [];
    let languageId = parseInt(sessionStorage.getItem('languageId'));
    this.workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
    this.workingModuleId = this.workingModule.id;
    if (languageId == 2) {
      this.ProjectOptions = [
        {
          label: 'Prosjektnavn',
          value: 10,
          id: 10,
          engText: '{{Project name}}',
          norText: '{{Prosjektnavn}}',
          LANG_KEY: 'MERGE_PROJECT_NAME',
        },
        {
          label: 'Startdato',
          value: 11,
          id: 11,
          engText: '{{Start date}}',
          norText: '{{Startdato}}',
          LANG_KEY: 'MERGE_START_DATE',
        },
        {
          label: 'Forventet sluttdato',
          value: 12,
          id: 12,
          engText: '{{Expected to end}}',
          norText: '{{Forventet sluttdato}}',
          LANG_KEY: 'MERGE_EXPECTED_END',
        },
        {
          label: 'Status',
          value: 13,
          id: 13,
          engText: '{{Status}}',
          norText: '{{Status}}',
          LANG_KEY: 'STATUS',
        },
        {
          label: 'Byggherre',
          value: 14,
          id: 14,
          engText: '{{Builder}}',
          norText: '{{Byggherre}}',
          LANG_KEY: 'MERGE_BUILDER',
        },
        {
          label: 'Adresse',
          value: 15,
          id: 15,
          engText: '{{Address}}',
          norText: '{{Adresse}}',
          LANG_KEY: 'MERGE_ADDRESS',
        },
        {
          label: 'Postnummer',
          value: 16,
          id: 16,
          engText: '{{Zip code}}',
          norText: '{{Postnummer}}',
          LANG_KEY: 'MERGE_ZIP_CODE',
        },
        {
          label: 'Våre ansvarsområder',
          value: 17,
          id: 17,
          engText: '{{Our areas of responsibility}}',
          norText: '{{Våre ansvarsområder}}',
          LANG_KEY: 'MERGE_AREA_OF_RESPONSIBILITY',
        },
        {
          label: 'Poststed',
          value: 18,
          id: 18,
          engText: '{{City}}',
          norText: '{{Poststed}}',
          LANG_KEY: 'MERGE_CITY',
        },
        {
          label: 'Gnr',
          value: 19,
          id: 19,
          engText: '{{Property number}}',
          norText: '{{Gnr}}',
          LANG_KEY: 'MERGE_PROP_NUMBER',
        },
        {
          label: 'Bnr',
          value: 21,
          id: 21,
          engText: '{{Ground number}}',
          norText: '{{Bnr}}',
          LANG_KEY: 'MERGE_GROUND_NUMBER',
        },
        {
          label: 'Prosjektkostnader',
          value: 22,
          id: 22,
          engText: '{{Project cost}}',
          norText: '{{Prosjektkostnader}}',
          LANG_KEY: 'MERGE_PROJ_COST',
        },
        {
          label: 'Prosjekt nummer',
          value: 23,
          id: 23,
          engText: '{{Project number}}',
          norText: '{{Prosjekt nummer}}',
          LANG_KEY: 'MERGE_PROJ_NUMBER',
        },
        {
          label: 'Underentreprenører',
          value: 24,
          id: 24,
          engText: '{{Sub Contractor}}',
          norText: '{{Underentreprenører}}',
          LANG_KEY: 'MERGE_SUB_CONTRACTOR',
        },
        {
          label: 'Fagansvarlig',
          value: 25,
          id: 25,
          engText: '{{Professional manager}}',
          norText: '{{Fagansvarlig}}',
          LANG_KEY: 'MERGE_PROF_MANAGER',
        },
        {
          label: 'Ansvarlig søker',
          value: 26,
          id: 26,
          engText: '{{Responsible for application}}',
          norText: '{{Ansvarlig søker}}',
          LANG_KEY: 'MERGE_RESPONSE_APPLN',
        },
        {
          label: 'Ansvarlig prosjekterende',
          value: 27,
          id: 27,
          engText: '{{Responsible for project planning}}',
          norText: '{{Ansvarlig prosjekterende}}',
          LANG_KEY: 'MERGE_RESPONSE_PROJ_PLAN',
        },
        {
          label: 'Ansvarlig for utførelse',
          value: 28,
          id: 28,
          engText: '{{Responsible for execution}}',
          norText: '{{Ansvarlig for utførelse}}',
          LANG_KEY: 'MERGE_RESPONSIBLE_FOR_EXECUTION',
        },
        {
          label: 'Verneombud',
          value: 29,
          id: 29,
          engText: '{{Safety representatives}}',
          norText: '{{Verneombud}}',
          LANG_KEY: 'MERGE_SAFETY_REP',
        },
        {
          label: '{Velg ansvarlig for uavhengig kontroll',
          value: 30,
          id: 30,
          engText: '{{Responsible for independent control}}',
          norText: '{{Velg ansvarlig for uavhengig kontroll}}',
          LANG_KEY: 'MERGE_RESPONSE_INPNT_CTRL',
        },
        {
          label: 'Byggeleder',
          value: 31,
          id: 31,
          engText: '{{Construction manager}}',
          norText: '{{Byggeleder}}',
          LANG_KEY: 'MERGE_CONSTRUCTION_MGR',
        },
        {
          label: 'Koordinator Prosjektering',
          value: 32,
          id: 32,
          engText: '{{Coordinator projecting}}',
          norText: '{{Koordinator Prosjektering}}',
          LANG_KEY: 'MERGE_COORDINATOR_PROJECTING',
        },
        {
          label: 'Koordinator Utførelsen',
          value: 33,
          id: 33,
          engText: '{{Coordinator execution}}',
          norText: '{{Koordinator Utførelsen}}',
          LANG_KEY: 'MERGE_COORDINATOR_EXECUTION',
        },
        {
          label: 'Prosjektbeskrivelse',
          value: 34,
          engText: '{{Project description}}',
          norText: '{{Prosjektbeskrivelse}}',
          LANG_KEY: 'MERGE_PROJECT_DESCRIPTION',
        },
        {
          label: 'Prosjektansvarlig',
          value: 35,
          engText: '{{Project manager}}',
          norText: '{{Prosjektansvarlig}}',
          LANG_KEY: 'MERGE_PROJ_MANAGER',
        },
        {
          label: 'Velg ansvarlig for uavhengig kontroll',
          value: 30,
          engText: '{{Responsible for independent control}}',
          norText: '{{Velg ansvarlig for uavhengig kontroll}}',
          LANG_KEY: 'MERGE_RESPONSE_INPNT_CTRL',
        },
      ];
      this.FdvOptions = [
        {
          label: 'Liste over bygninger/eiendommer',
          value: 5,
          id: 5,
          engText: '{{List of property/building mass}}',
          norText: '{{Liste over bygninger/eiendommer}}',
          LANG_KEY: 'MERGE_LIST_OF_PROPERTY/BUILDING_MASS',
        },
        {
          label: 'Liste over sosiale forhold',
          value: 6,
          id: 6,
          engText: '{{List of all social aspects}}',
          norText: '{{Liste over sosiale forhold}}',
          LANG_KEY: 'MERGE_LIST_OF_ALL_SOCIAL_ASPECTS',
        },
        {
          label: 'Liste over utstyr',
          value: 7,
          id: 7,
          engText: '{{List of equipments}}',
          norText: '{{Liste over utstyr}}',
          LANG_KEY: 'MERGE_LIST_OF_EQUIPMENTS',
        },
      ];
    } else {
      this.ProjectOptions = [
        {
          label: 'Project name',
          value: 10,
          id: 10,
          engText: '{{Project name}}',
          norText: '{{Prosjektnavn}}',
          LANG_KEY: 'MERGE_PROJECT_NAME',
        },
        {
          label: 'Start date',
          value: 11,
          id: 11,
          engText: '{{Start date}}',
          norText: '{{Startdato}}',
          LANG_KEY: 'MERGE_START_DATE',
        },
        {
          label: 'Expected to end',
          value: 12,
          id: 12,
          engText: '{{Expected to end}}',
          norText: '{{Forventet sluttdato}}',
          LANG_KEY: 'MERGE_EXPECTED_END',
        },
        {
          label: 'Status',
          value: 13,
          id: 13,
          engText: '{{Status}}',
          norText: '{{Status}}',
          LANG_KEY: 'STATUS',
        },
        {
          label: 'Builder',
          value: 14,
          id: 14,
          engText: '{{Builder}}',
          norText: '{{Byggherre}}',
          LANG_KEY: 'MERGE_BUILDER',
        },
        {
          label: 'Address',
          value: 15,
          id: 15,
          engText: '{{Address}}',
          norText: '{{Adresse}}',
          LANG_KEY: 'MERGE_ADDRESS',
        },
        {
          label: 'Zip code',
          value: 16,
          id: 16,
          engText: '{{Zip code}}',
          norText: '{{Postnummer}}',
          LANG_KEY: 'MERGE_ZIP_CODE',
        },
        {
          label: 'Our areas of responsibility',
          value: 17,
          id: 17,
          engText: '{{Our areas of responsibility}}',
          norText: '{{Våre ansvarsområder}}',
          LANG_KEY: 'MERGE_AREA_OF_RESPONSIBILITY',
        },
        {
          label: 'City',
          value: 18,
          id: 18,
          engText: '{{City}}',
          norText: '{{Poststed}}',
          LANG_KEY: 'MERGE_CITY',
        },
        {
          label: 'Property number',
          value: 19,
          id: 19,
          engText: '{{Property number}}',
          norText: '{{Gnr}}',
          LANG_KEY: 'MERGE_PROP_NUMBER',
        },
        {
          label: 'Ground number',
          value: 21,
          id: 21,
          engText: '{{Ground number}}',
          norText: '{{Bnr}}',
          LANG_KEY: 'MERGE_GROUND_NUMBER',
        },
        {
          label: 'Project cost',
          value: 22,
          id: 22,
          engText: '{{Project cost}}',
          norText: '{{Prosjektkostnader}}',
          LANG_KEY: 'MERGE_PROJ_COST',
        },
        {
          label: 'Project number',
          value: 23,
          id: 23,
          engText: '{{Project number}}',
          norText: '{{Prosjekt nummer}}',
          LANG_KEY: 'MERGE_PROJ_NUMBER',
        },
        {
          label: 'Sub Contractor',
          value: 24,
          id: 24,
          engText: '{{Sub Contractor}}',
          norText: '{{Underentreprenører}}',
          LANG_KEY: 'MERGE_SUB_CONTRACTOR',
        },
        {
          label: 'Professional manager',
          value: 25,
          id: 25,
          engText: '{{Professional manager}}',
          norText: '{{Fagansvarlig}}',
          LANG_KEY: 'MERGE_PROF_MANAGER',
        },
        {
          label: 'Responsible for application',
          value: 26,
          id: 26,
          engText: '{{Responsible for application}}',
          norText: '{{Ansvarlig søker}}',
          LANG_KEY: 'MERGE_RESPONSE_APPLN',
        },
        {
          label: 'Responsible for project planning',
          value: 27,
          id: 27,
          engText: '{{Responsible for project planning}}',
          norText: '{{Ansvarlig prosjekterende}}',
          LANG_KEY: 'MERGE_RESPONSE_PROJ_PLAN',
        },
        {
          label: 'Responsible for execution',
          value: 28,
          id: 28,
          engText: '{{Responsible for execution}}',
          norText: '{{Ansvarlig for utførelse}}',
          LANG_KEY: 'MERGE_RESPONSIBLE_FOR_EXECUTION',
        },
        {
          label: 'Safety representatives',
          value: 29,
          id: 29,
          engText: '{{Safety representatives}}',
          norText: '{{Verneombud}}',
          LANG_KEY: 'MERGE_SAFETY_REP',
        },
        {
          label: 'Responsible for independent control',
          value: 30,
          id: 30,
          engText: '{{Responsible for independent control}}',
          norText: '{{Velg ansvarlig for uavhengig kontroll}}',
          LANG_KEY: 'MERGE_RESPONSE_INPNT_CTRL',
        },
        {
          label: 'Construction manager',
          value: 31,
          id: 31,
          engText: '{{Construction manager}}',
          norText: '{{Byggeleder}}',
          LANG_KEY: 'MERGE_CONSTRUCTION_MGR',
        },
        {
          label: 'Coordinator projecting',
          value: 32,
          id: 32,
          engText: '{{Coordinator projecting}}',
          norText: '{{Koordinator Prosjektering}}',
          LANG_KEY: 'MERGE_COORDINATOR_PROJECTING',
        },
        {
          label: 'Coordinator execution',
          value: 33,
          id: 33,
          engText: '{{Coordinator execution}}',
          norText: '{{Koordinator Utførelsen}}',
          LANG_KEY: 'MERGE_COORDINATOR_EXECUTION',
        },
        {
          label: 'Project description',
          value: 34,
          id: 34,
          engText: '{{Project description}}',
          norText: '{{Prosjektbeskrivelse}}',
          LANG_KEY: 'MERGE_PROJECT_DESCRIPTION',
        },
        {
          label: 'Project manager',
          value: 35,
          id: 35,
          engText: '{{Project manager}}',
          norText: '{{Prosjektansvarlig}}',
          LANG_KEY: 'MERGE_PROJ_MANAGER',
        },
      ];

      this.FdvOptions = [
        {
          label: 'List of property/building mass',
          value: 5,
          id: 5,
          engText: '{{List of property/building mass}}',
          norText: '{{Liste over bygninger/eiendommer}}',
          LANG_KEY: 'MERGE_LIST_OF_PROPERTY/BUILDING_MASS',
        },
        {
          label: 'List of all social aspects',
          value: 6,
          id: 6,
          engText: '{{List of all social aspects}}',
          norText: '{{Liste over sosiale forhold}}',
          LANG_KEY: 'MERGE_LIST_OF_ALL_SOCIAL_ASPECTS',
        },
        {
          label: 'List of equipments',
          value: 7,
          id: 7,
          engText: '{{List of equipments}}',
          norText: '{{Liste over utstyr}}',
          LANG_KEY: 'MERGE_LIST_OF_EQUIPMENTS',
        },
      ];
    }
  }
  onChangeProjectDropDown() {
    let selectedProject = [];
    var breakTag = '';
    let languageId = parseInt(sessionStorage.getItem('languageId'));
    this.ProjectData.forEach((e) => {
      selectedProject.push(this.ProjectOptions.filter((x) => x.value == e));
    });

    this.articleSrc.subArticles.forEach((element) => {
      this.ProjectOptions.forEach((removeUnSelected) => {
        if (element.name === this.selectedTabz) {
          let d = element.text
            .replace('\n<p>' + removeUnSelected.engText + '</p>\n', '')
            .replace('<p>' + removeUnSelected.engText + '</p>', '')
            .replace(removeUnSelected.engText + '<br />', '')
            .replace(removeUnSelected.engText, '')
            .replace('\n<p>' + removeUnSelected.norText + '</p>\n', '')
            .replace('<p>' + removeUnSelected.norText + '</p>', '')
            .replace(removeUnSelected.norText, '')
            .replace(removeUnSelected.norText + '<br />', '');
          element.text = d;
          element.text = element.text
            .replace('<p>&nbsp;</p>', '')
            .replace('<p>&nbsp;</p>', '')
            .replace('<p>&nbsp;</p>', '');
        }
      });
    });
    let index = 0;
    this.articleSrc.subArticles.forEach((element) => {
      selectedProject.forEach((selectedData) => {
        if (element.name === this.selectedTabz) {
          var w = element.text
            .replace('<p>&nbsp;</p>', '')
            .replace('<p>&nbsp;</p>', '')
            .replace('<p>&nbsp;</p>', '')
            .replace('<p><br />', '');
          if (index != 0) {
            breakTag = '<br>';
          }
          if (languageId == 2) {
            element.text = element.text + breakTag + selectedData[0].norText;
          } else {
            element.text = element.text + breakTag + selectedData[0].engText;
          }
          index++;
        }
      });
    });
  }
  onChangeFdvDropDown() {
    let selectedFdv = [];
    var breakTag = '';
    let languageId = parseInt(sessionStorage.getItem('languageId'));
    this.FdvData.forEach((e) => {
      selectedFdv.push(this.FdvOptions.filter((x) => x.value == e));
    });

    this.articleSrc.subArticles.forEach((element) => {
      this.FdvOptions.forEach((removeUnSelected) => {
        if (element.name === this.selectedTabz) {
          let d = element.text
            .replace('\n<p>' + removeUnSelected.engText + '</p>\n', '')
            .replace('<p>' + removeUnSelected.engText + '</p>', '')
            .replace(removeUnSelected.engText + '<br />', '')
            .replace(removeUnSelected.engText, '')
            .replace('\n<p>' + removeUnSelected.norText + '</p>\n', '')
            .replace('<p>' + removeUnSelected.norText + '</p>', '')
            .replace(removeUnSelected.norText, '')
            .replace(removeUnSelected.norText + '<br />', '');
          element.text = d;
          element.text = element.text
            .replace('<p>&nbsp;</p>', '')
            .replace('<p>&nbsp;</p>', '')
            .replace('<p>&nbsp;</p>', '');
        }
      });
    });
    let index = 0;
    this.articleSrc.subArticles.forEach((element) => {
      selectedFdv.forEach((selectedData) => {
        if (element.name === this.selectedTabz) {
          var w = element.text
            .replace('<p>&nbsp;</p>', '')
            .replace('<p>&nbsp;</p>', '')
            .replace('<p>&nbsp;</p>', '')
            .replace('<p><br />', '');
          if (index != 0) {
            breakTag = '<br>';
          }
          if (languageId == 2) {
            element.text = element.text + breakTag + selectedData[0].norText;
          } else {
            element.text = element.text + breakTag + selectedData[0].engText;
          }
          index++;
        }
      });
    });
  }
  BindProjectAndFdvDropDown() {
    this.ProjectData = [];
    this.articleSrc.subArticles.forEach((element) => {
      this.ProjectOptions.forEach((selectedData) => {
        if (element.text != null) {
          if (element.text.includes(selectedData.engText)) {
            this.ProjectData.push(selectedData.value);
          } else if (element.text.includes(selectedData.norText)) {
            this.ProjectData.push(selectedData.value);
          }
        }
      });
    });

    this.FdvData = [];
    this.articleSrc.subArticles.forEach((element) => {
      this.FdvOptions.forEach((selectedData) => {
        if (element.text != null) {
          if (element.text.includes(selectedData.engText)) {
            this.FdvData.push(selectedData.value);
          } else if (element.text.includes(selectedData.norText)) {
            this.FdvData.push(selectedData.value);
          }
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.versionVal = this.articleSrc.article.version;
    if (this.articleSrc.article == undefined) {
    }
    this.mergeFieldTranslation();
    this.subscriptions.push(
      this.kubaServices.getLanguage$.subscribe((lang) => {
        this.language = lang;
      })
    );
    let sArticle;
    this.SubArticle = this.articleSrc.subArticles;
    this.selectedUser =
      this.selectedUser === undefined ? null : this.selectedUser;
    if (
      this.applicationId !== 0 &&
      this.portalId === 0 &&
      this.businessId === 0
    ) {
      let findOwntext = this.articleSrc.subArticles.find(function (element) {
        return element.name === 'Own Text';
      });
      if (findOwntext === undefined) {
        let objOwntext = {
          name: 'Own Text',
          text: '',
          version: 1,
          status: 'Active',
          disable: 'false',
        };
        this.articleSrc.subArticles.push(objOwntext);
      }
      const toDelete = new Set(['Portal Text']);
      sArticle = this.SubArticle.filter((obj) => !toDelete.has(obj.name));
      this.articleSrc.subArticles = null!;
      this.articleSrc.subArticles = sArticle;
      if (
        this.articleSrc.article.editedBy !== null &&
        this.adminUsers !== undefined
      ) {
        let editedBy = this.adminUsers.filter(
          (x) => x.Id === this.articleSrc.article.editedBy
        );
        if (editedBy !== null && editedBy.length > 0) {
          this.selectedUser = editedBy[0];
        }
      }
    } else if (
      this.applicationId !== 0 &&
      this.portalId !== 0 &&
      this.businessId === 0
    ) {
      if (this.selectedUser !== null && this.adminUsers.length > 0) {
        this.selectedUser = {
          Name: this.adminUsers[0].Name,
          Id: this.adminUsers[0].Id,
        };
      }
      if (
        this.articleSrc.article.roleId === 1 &&
        this.articleSrc.article.portalId === 0
      ) {
        this.disabled = true;
        let foundOwntext = this.articleSrc.subArticles.find(function (element) {
          return element.name === 'Own Text';
        });
        let foundPortalText = this.articleSrc.subArticles.find(function (
          element
        ) {
          return element.name === 'Portal Text';
        });
        if (foundOwntext === undefined) {
          let objOwntext = {
            name: 'Own Text',
            text: '',
            version: 1,
            status: 'Active',
            disable: 'false',
            portalVersion: 1,
            portalEditedDate: null,
          };
          this.articleSrc.subArticles.push(objOwntext);
          this.versionVal = 1;
        } else {
          this.versionVal =
            foundOwntext.portalVersion !== null
              ? foundOwntext.portalVersion!
              : 1;
          this.articleSrc.article.articleDate = foundOwntext.portalEditedDate
            ? HelperService.formatInputDate(foundOwntext.portalEditedDate)
            : this.getCurrentDate();
        }
        if (foundPortalText === undefined) {
          let objPortaltext = {
            name: 'Portal Text',
            text: '',
            version: 1,
            status: 'Active',
            disable: 'false',
            portalVersion: 1,
            portalEditedDate: null,
          };
          this.articleSrc.subArticles.push(objPortaltext);
        }
      } else {
        this.disabled = false;
        if (
          this.articleSrc.article.roleId === 2 &&
          this.portalId !== 0 &&
          this.articleSrc.article.portalId !== 0
        ) {
          const toDelete = new Set(['Standard Text', 'Legislation']);
          this.articleSrc.article.editedBy = BaseServices.UserId;
          this.articleSrc.subArticles = this.articleSrc.subArticles.filter(
            (obj) => !toDelete.has(obj.name)
          );
        }
      }
    } else if (
      this.businessId !== 0 &&
      this.projectId !== undefined &&
      this.projectId !== 0 &&
      this.featureId !== FeatureKey.ELECTRO
    ) {
      this.SubArticle = this.articleSrc.subArticles;
      let foundOwntext = this.articleSrc.subArticles.find(function (element) {
        return element.name === 'Own Text';
      });

      if (foundOwntext === undefined) {
        let objOwntext = {
          name: 'Own Text',
          text: '',
          version: 1,
          status: 'Active',
          disable: 'false',
        };
        this.articleSrc.subArticles.push(objOwntext);
      }
      const toRemoveOwnText = new Set([
        'FDV-Dokumenter EL',
        'FDV-Dokumenter VVS',
        'Forvaltning VVS',
        'Forvaltning EL',
      ]);
      if (
        toRemoveOwnText.has(
          this.articleSrc.article.folderName || this.articleSrc.article.chapter
        )
      ) {
        const toDelete = new Set(['Own Text']);
        this.articleSrc.subArticles = this.articleSrc.subArticles.filter(
          (obj) => !toDelete.has(obj.name)
        );
      }
    } else if (
      this.applicationId !== 0 &&
      this.portalId === 0 &&
      this.businessId !== 0
    ) {
      if (
        (this.articleSrc.article.roleId === 1 &&
          this.articleSrc.article.businessId === 0) ||
        (this.articleSrc.article.roleId === 2 &&
          this.articleSrc.article.businessId === 0)
      ) {
        this.disabled = true;
        let foundOwntext: any = this.articleSrc.subArticles.find(function (
          element
        ) {
          return element.name === 'Own Text';
        });
        if (foundOwntext === undefined) {
          let objOwntext = {
            name: 'Own Text',
            text: '',
            version: 1,
            status: 'Active',
            disable: 'false',
            businessVersion: 1,
            businessEditedDate: null,
          };
          this.versionVal = 1;
          this.articleSrc.subArticles.push(objOwntext);
        } else {
          // (foundOwntext.articleDate) ?
          //     HelperService.formatInputDate(foundOwntext.articleDate) :this.getCurrentDate() KW-1484
          this.articleSrc.article.articleDate = this.getCurrentDate();
          if (foundOwntext.businessEditedBy > 0) {
            this.editorEditedBY = this.articleSrc.article.modifiedBy;
          } else {
            this.editorEditedBY = '';
          }
          this.versionVal =
            foundOwntext.businessVersion !== null
              ? foundOwntext.businessVersion
              : 1;
        }
        if (this.articleSrc.article.roleId === 2) {
          const toDelete = new Set(['Standard Text', 'Legislation']);
          this.articleSrc.subArticles = this.articleSrc.subArticles.filter(
            (obj) => !toDelete.has(obj.name)
          );
        } else if (this.articleSrc.article.roleId === 1) {
          const toDelete = new Set(['Portal Text']);
          if (this.businessMode === 'underAdmin') {
            this.articleSrc.subArticles = this.articleSrc.subArticles.filter(
              (obj) => !toDelete.has(obj.name)
            );
          }
        }
        if (this.businessMode === 'underPortal') {
          let foundPortaltext = this.articleSrc.subArticles.find(function (
            element
          ) {
            return element.name === 'Portal Text';
          });
          if (foundPortaltext === undefined) {
            let objPortaltext = {
              name: 'Portal Text',
              text: '',
              version: 1,
              status: 'Active',
              disable: 'false',
              portalVersion: 1,
              portalEditedDate: null,
            };
            this.articleSrc.subArticles.push(objPortaltext);
          }
        }
      } else {
        const toDelete = new Set([
          'Portal Text',
          'Standard Text',
          'Legislation',
        ]);
        this.articleSrc.subArticles = this.articleSrc.subArticles.filter(
          (obj) => !toDelete.has(obj.name)
        );
        this.disabled = false;
        this.showCopyPortalText = false;
        this.showCopyStandardText = false;
      }
    }
    orderArticleType(this.articleSrc.subArticles, [
      'Own Text',
      'Project Text',
      'Portal Text',
      'Standard Text',
      'Legislation',
      'Control Text',
    ]);
    var newArticleType: any;
    function orderArticleType(src: any, order: any) {
      newArticleType = [];
      order.forEach((oel: any) => {
        let obj = src.find(function (sel: any) {
          return sel.name === oel;
        });

        if (obj !== undefined) {
          newArticleType.push(obj);
        }
      });
    }
    this.articleSrc.subArticles = null!;
    this.articleSrc.subArticles = newArticleType;
    this.aDetails = {
      ArticleId: this.articleSrc.article.id!,
      CreaterRoleId: this.articleSrc.article.roleId!,
      CreaterId: this.articleSrc.article.createrId,
      UserRoleId: +BaseServices.roleId,
      BusinessId: BaseServices.BusinessId,
      PortalId: BaseServices.roleId === '2' ? this.portalId : 0,
      BusinessMode: this.businessMode,
      Version: this.articleSrc.article.version!,
    };
    let chkStatus: any;
    if (
      this.featureId === FeatureKey.ELECTRO &&
      this.articleSrc.article.id !== 0
    ) {
      this.subscriptions.push(
        this.electroDocumentService
          .getArticleTextStatus(this.aDetails, +this.projectId)
          .subscribe((result: any) => {
            if (result) {
              this.articleSrc.article.roleStatus = result;
              if (result) {
                this.roleStatus = result;
                if (
                  this.applicationId !== 0 &&
                  this.portalId === 0 &&
                  this.businessId === 0
                ) {
                  chkStatus = this.roleStatus.admin;
                } else if (
                  this.applicationId !== 0 &&
                  this.portalId !== 0 &&
                  this.businessId === 0
                ) {
                  chkStatus = this.roleStatus.portal;
                } else if (
                  this.applicationId !== 0 &&
                  this.portalId === 0 &&
                  this.businessId !== 0
                ) {
                  chkStatus = this.roleStatus.editor;
                }
                if (chkStatus != null) {
                  this.articleSrc.subArticles.forEach((element: any) => {
                    if (element.name === 'Own Text') {
                      let owntextObj = chkStatus.find(function (element: any) {
                        return (element.name = 'Own Text');
                      });
                      if (owntextObj !== undefined) {
                        element.status = owntextObj.status;
                        element.disable = owntextObj.disable;
                      }
                    } else if (element.name === 'Portal Text') {
                      let portalTextObj = chkStatus.find(function (
                        element: any
                      ) {
                        return element.name === 'Portal Text';
                      });
                      if (portalTextObj !== undefined) {
                        element.status = portalTextObj.status;
                        element.disable = portalTextObj.disable;
                      } else if (element.disable === '') {
                        element.disable = 'false';
                      }
                    } else if (element.name === 'Standard Text') {
                      let standardTextObj = chkStatus.find(function (
                        element: any
                      ) {
                        return element.name === 'Standard Text';
                      });
                      if (standardTextObj !== undefined) {
                        element.status = standardTextObj.status;
                        element.disable = standardTextObj.disable;
                      }
                    } else if (element.name === 'Legislation') {
                      let legislationObj = chkStatus.find(function (
                        element: any
                      ) {
                        return element.name === 'Legislation';
                      });
                      if (legislationObj !== undefined) {
                        element.status = legislationObj.status;
                        element.disable = legislationObj.disable;
                      }
                    }
                  });
                }
              }
            }
          })
      );
    } else if (this.projectId === 0) {
      if (this.articleSrc.article.id !== 0) {
        this.subscriptions.push(
          this.manualService
            .getArticleTextStatus(this.aDetails, +this.manualId)
            .subscribe((result: any) => {
              if (result) {
                this.articleSrc.article.roleStatus = result;
                if (result) {
                  this.roleStatus = result;
                  if (
                    this.applicationId !== 0 &&
                    this.portalId === 0 &&
                    this.businessId === 0
                  ) {
                    chkStatus = this.roleStatus.admin;
                  } else if (
                    this.applicationId !== 0 &&
                    this.portalId !== 0 &&
                    this.businessId === 0
                  ) {
                    chkStatus = this.roleStatus.portal;
                  } else if (
                    this.applicationId !== 0 &&
                    this.portalId === 0 &&
                    this.businessId !== 0
                  ) {
                    chkStatus = this.roleStatus.editor;
                  }
                  if (chkStatus != null) {
                    this.articleSrc.subArticles.forEach((element: any) => {
                      if (element.name === 'Own Text') {
                        let owntextObj = chkStatus.find(function (
                          element: any
                        ) {
                          return (element.name = 'Own Text');
                        });
                        if (owntextObj !== undefined) {
                          element.status = owntextObj.status;
                          element.disable = owntextObj.disable;
                        }
                      } else if (element.name === 'Portal Text') {
                        let portalTextObj = chkStatus.find(function (
                          element: any
                        ) {
                          return element.name === 'Portal Text';
                        });
                        if (portalTextObj !== undefined) {
                          element.status = portalTextObj.status;
                          element.disable = portalTextObj.disable;
                        } else if (element.disable === '') {
                          element.disable = 'false';
                        }
                      } else if (element.name === 'Standard Text') {
                        let standardTextObj = chkStatus.find(function (
                          element: any
                        ) {
                          return element.name === 'Standard Text';
                        });
                        if (standardTextObj !== undefined) {
                          element.status = standardTextObj.status;
                          element.disable = standardTextObj.disable;
                        }
                      } else if (element.name === 'Legislation') {
                        let legislationObj = chkStatus.find(function (
                          element: any
                        ) {
                          return element.name === 'Legislation';
                        });
                        if (legislationObj !== undefined) {
                          element.status = legislationObj.status;
                          element.disable = legislationObj.disable;
                        }
                      }
                    });
                  }
                }
              }
            })
        );
      }
    }

    if (changes['ecplApiResponse']) {
      switch (changes['ecplApiResponse'].currentValue) {
        case 'init':
          break;
        case 'failed':
          break;
        case 'success':
          break;
        case 'partial':
          break;
        case 'reloaded':
          this.hideModal();
          this.ecplApiResponse = 'none';
          break;
        default:
          break;
      }
    }
    let cDateObj = new Date(this.articleSrc.article.createdOn);
    this.createdDate = cDateObj.toLocaleDateString('en-GB');
    if (this.articleSrc.article.modifiedOn != null) {
      let mDateObj = new Date(this.articleSrc.article.modifiedOn);
      this.modifedDate = mDateObj.toLocaleDateString('en-GB');
    }
  }

  clickMergeField(event: any) {
    this.articleSrc.subArticles.forEach((element: any) => {
      if (element.name === this.selectedTabz) {
        element.text = element.text + event.currentTarget.innerText;
      }
    });
  }

  getUserRole(portalId: number, businessId: number, roleId: number) {
    if (roleId === 1) {
      if (portalId !== 0 && businessId === 0) {
        return 2;
      } else if (portalId === 0 && businessId > 0) {
        return 3;
      } else {
        return roleId;
      }
    }
    return roleId;
  }

  onChangeVersion(val: any) {
    this.articleSrc.subArticles.forEach((element: any) => {
      if (element.name === 'Own Text') {
        element.version = val;
      }
    });
  }

  previewTabSelection() {
    this.previewActive = true;
    this.showCopyStandardText = false;
    this.showCopyPortalText = false;
    this.showMergeField = false;
  }

  public createPreviewTextContent(subArticles: SubArticle[]) {
    let textContent = '';
    subArticles.forEach((element) => {
      textContent += element.text;
    });
    return textContent;
  }

  tabSelection(tabz: any) {
    let featureIdlist = [89, 83, 45, 51, 57, 77];
    this.selectedTabz = tabz.name;
    this.showCopyPortalText = false;
    if (featureIdlist.indexOf(this.featureId) === -1) {
      if (this.businessId === 0 && this.portalId === 0) {
        if (
          this.selectedTabz === 'OwnText' ||
          this.selectedTabz === 'Own Text' ||
          this.selectedTabz === 'StandardText' ||
          this.selectedTabz === 'Standard Text'
        ) {
          this.showMergeField = this.featureId !== FeatureKey.ELECTRO;
          this.showMergeField = this.featureId !== FeatureKey.KUBA_CONTROL;
        } else {
          this.showMergeField = false;
        }
      } else if (this.businessId === 0 && this.portalId !== 0) {
        if (this.articleSrc.article.roleId === 1) {
          this.showCopyPortalText = false;
          if (tabz.name === 'Own Text') {
            if (this.articleSrc.article.roleId === 1) {
              this.showCopyStandardText = true;
            } else {
              this.showCopyStandardText = false;
            }
          } else if (tabz.name === 'Portal Text') {
            if (this.rollId === 2 && this.portalId !== 0) {
              this.showCopyStandardText = true;
            }
          } else {
            this.showCopyStandardText = false;
          }
        }
        if (
          this.selectedTabz === 'OwnText' ||
          this.selectedTabz === 'Own Text' ||
          this.selectedTabz === 'PortalText' ||
          this.selectedTabz === 'Portal Text'
        ) {
          this.showMergeField = this.featureId !== FeatureKey.ELECTRO;
          this.showMergeField = this.featureId !== FeatureKey.KUBA_CONTROL;
          this.readOnly = false;
        } else {
          this.showMergeField = false;
          this.readOnly = true;
        }
      } else if (
        this.businessId !== 0 &&
        this.projectId !== undefined &&
        this.projectId !== 0 &&
        this.featureId !== FeatureKey.ELECTRO
      ) {
        this.showMergeField = this.featureId !== FeatureKey.ELECTRO;
        this.showMergeField = this.featureId !== FeatureKey.KUBA_CONTROL;
        this.showCopyPortalText = false;
        this.showCopyStandardText = false;
        this.readOnly = false;
      } else if (this.businessId !== 0 && this.portalId === 0) {
        if (this.articleSrc.article.roleId === 1) {
          if (tabz.name === 'Own Text') {
            if (this.articleSrc.article.roleId === 1) {
              this.showCopyPortalText = true;
              this.showCopyStandardText = true;
            } else {
              this.showCopyPortalText = false;
              this.showCopyStandardText = false;
            }
          } else {
            this.showCopyPortalText = false;
            this.showCopyStandardText = false;
          }
        }
        if (
          this.selectedTabz === 'OwnText' ||
          this.selectedTabz === 'Own Text'
        ) {
          this.showMergeField = this.featureId !== FeatureKey.ELECTRO;
          this.showMergeField = this.featureId !== FeatureKey.KUBA_CONTROL;
          this.readOnly = false;
        } else {
          this.showMergeField = false;
          this.readOnly = true;
        }
      }
    } else {
      this.showMergeField = false;
    }

    if (
      this.businessId !== 0 &&
      (this.selectedTabz === 'OwnText' || this.selectedTabz === 'Own Text')
    ) {
      this.showCopyPortalText = false;
      this.articleSrc.subArticles.forEach((element: any) => {
        if (element.name === 'PortalText' || element.name === 'Portal Text') {
          this.showCopyPortalText = true;
        }
      });
    }
  }

  mergeFieldReplace(key: string, id: number, text: string, StringBuilder: any) {
    let mergeText: string;

    switch (key) {
      case '{{Business Name}}':
      case '{{Navn på virksomheten}}':
      case '{{Navn p&aring; virksomheten}}':
        if (
          (this.applicationId !== 0 &&
            this.portalId === 0 &&
            this.businessId === 0) ||
          (this.applicationId !== 0 &&
            this.portalId !== 0 &&
            this.businessId === 0)
        ) {
          mergeText = text
            .replace(new RegExp('{{Business Name}}', 'g'), ' ')
            .replace(new RegExp('{{Navn på virksomheten}}', 'g'), '')
            .replace(new RegExp('{{Navn p&aring; virksomheten}}', 'g'), '');
        } else {
          StringBuilder.append(this.getMergeKeyInfoHtml(key));
          mergeText = text
            .replace(new RegExp('{{Business Name}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Navn på virksomheten}}', 'g'), StringBuilder)
            .replace(
              new RegExp('{{Navn p&aring; virksomheten}}', 'g'),
              StringBuilder
            );
        }

        break;
      case '{{Facts about the company}}':
      case '{{Fakta om virksomheten}}':
        if (
          (this.applicationId !== 0 &&
            this.portalId === 0 &&
            this.businessId === 0) ||
          (this.applicationId !== 0 &&
            this.portalId !== 0 &&
            this.businessId === 0)
        ) {
          mergeText = text
            .replace(new RegExp('{{Facts about the company}}', 'g'), ' ')
            .replace(new RegExp('{{Fakta om virksomheten}}', 'g'), ' ');
        } else {
          StringBuilder.append(
            this.getMergeKeyFactInfoHtml(key, +BaseServices.BusinessId)
          );

          mergeText = text
            .replace(
              new RegExp('{{Facts about the company}}', 'g'),
              StringBuilder
            )
            .replace(
              new RegExp('{{Fakta om virksomheten}}', 'g'),
              StringBuilder
            );
        }

        break;
      case '{{User list}}':
      case '{{Brukerliste}}':
        if (
          (this.applicationId !== 0 &&
            this.portalId === 0 &&
            this.businessId === 0) ||
          (this.applicationId !== 0 &&
            this.portalId !== 0 &&
            this.businessId === 0)
        ) {
          mergeText = text
            .replace(new RegExp('{{User list}}', 'g'), '')
            .replace(new RegExp('{{Brukerliste}}', 'g'), '');
        } else {
          StringBuilder.append(
            this.getMergeKeyUserInfoHtml(key, +BaseServices.BusinessId)
          );
          mergeText = text
            .replace(new RegExp('{{User list}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Brukerliste}}', 'g'), StringBuilder);
        }
        break;
      case '{{Contact list}}':
      case '{{Kontaktliste}}':
        if (this.businessId === 0) {
          mergeText = text
            .replace(new RegExp('{{Contact list}}', 'g'), '')
            .replace(new RegExp('{{Kontaktliste}}', 'g'), '');
        } else {
          StringBuilder.append(
            this.getMergeKeyContactInfoHtml(key, +BaseServices.BusinessId)
          );
          mergeText = text
            .replace(new RegExp('{{Contact list}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Kontaktliste}}', 'g'), StringBuilder);
        }
        break;
      case '{{Liste over bygninger/eiendommer}}':
      case '{{List of property/building mass}}':
        if (this.businessId === 0) {
          mergeText = text
            .replace(new RegExp('{{List of property/building mass}}', 'g'), '')
            .replace(
              new RegExp('{{Liste over bygninger/eiendommer}}', 'g'),
              ''
            );
        } else {
          StringBuilder.append(
            this.getMergeKeyPropertyListInfoHtml(key, +BaseServices.BusinessId)
          );
          mergeText = text
            .replace(
              new RegExp('{{List of property/building mass}}', 'g'),
              StringBuilder
            )
            .replace(
              new RegExp('{{Liste over bygninger/eiendommer}}', 'g'),
              StringBuilder
            );
        }
        break;
      case '{{Liste over sosiale forhold}}':
      case '{{List of all social aspects}}':
        if (this.businessId === 0) {
          mergeText = text
            .replace(new RegExp('{{List of all social aspects}}', 'g'), '')
            .replace(new RegExp('{{Liste over sosiale forhold}}', 'g'), '');
        } else {
          StringBuilder.append(
            this.getMergeKeySocialAspectsInfoHtml(key, +BaseServices.BusinessId)
          );
          mergeText = text
            .replace(
              new RegExp('{{List of all social aspects}}', 'g'),
              StringBuilder
            )
            .replace(
              new RegExp('{{Liste over sosiale forhold}}', 'g'),
              StringBuilder
            );
        }
        break;
      case '{{Liste over utstyr}}':
      case '{{List of equipments}}':
        if (this.businessId === 0) {
          mergeText = text
            .replace(new RegExp('{{List of equipments}}', 'g'), '')
            .replace(new RegExp('{{Liste over utstyr}}', 'g'), '');
        } else {
          StringBuilder.append(
            this.getMergeKeyEquipmentsInfoHtml(key, +BaseServices.BusinessId)
          );
          mergeText = text
            .replace(new RegExp('{{List of equipments}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Liste over utstyr}}', 'g'), StringBuilder);
        }
        break;
      case '{{Liste over aktiviteter}}':
      case '{{List of activities}}':
        StringBuilder.append(
          this.getMergeKeyActivitiesInfoHtml(key, +BaseServices.BusinessId)
        );
        mergeText = text
          .replace(new RegExp('{{List of activities}}', 'g'), StringBuilder)
          .replace(
            new RegExp('{{Liste over aktiviteter}}', 'g'),
            StringBuilder
          );
        break;
      case '{{Ansatteliste}}':
      case '{{Employee List}}':
        if (this.businessId === 0) {
          mergeText = text
            .replace(new RegExp('{{Employee List}}', 'g'), '')
            .replace(new RegExp('{{Ansatteliste}}', 'g'), '');
        } else {
          StringBuilder.append(
            this.getMergeKeyEmployeesInfoHtml(key, +BaseServices.BusinessId)
          );
          mergeText = text
            .replace('{{Employee List}}', StringBuilder)
            .replace('{{Ansatteliste}}', StringBuilder);
        }
        break;
      case '{{Prosjektnavn}}':
      case '{{Project name}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Project name}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Prosjektnavn}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Project name}}', 'g'), '')
            .replace(new RegExp('{{Prosjektnavn}}', 'g'), '');
        }
        break;
      case '{{Underentreprenører}}':
      case '{{Sub Contractor}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Sub Contractor}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Underentreprenører}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Sub Contractor}}', 'g'), '')
            .replace(new RegExp('{{Underentreprenører}}', 'g'), '');
        }
        break;
      case '{{Fagansvarlig}}':
      case '{{Professional manager}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Professional manager}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Fagansvarlig}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Professional manager}}', 'g'), '')
            .replace(new RegExp('{{Fagansvarlig}}', 'g'), '');
        }
        break;
      case '{{Prosjektansvarlig}}':
      case '{{Project manager}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Project manager}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Prosjektansvarlig}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Project manager}}', 'g'), '')
            .replace(new RegExp('{{Prosjektansvarlig}}', 'g'), '');
        }
        break;
      case '{{Ansvarlig søker}}':
      case '{{Responsible for Application}}':
      case '{{Responsible for application}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Responsible for application}}', 'g'),
              StringBuilder
            )
            .replace(
              new RegExp('{{Responsible for Application}}', 'g'),
              StringBuilder
            )
            .replace(new RegExp('{{Ansvarlig søker}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Responsible for application}}', 'g'), '')
            .replace(new RegExp('{{Responsible for Application}}', 'g'), '')
            .replace(new RegExp('{{Ansvarlig søker}}', 'g'), '');
        }
        break;
      case '{{Ansvarlig prosjekterende}}':
      case '{{Responsible for project planning}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Responsible for project planning}}', 'g'),
              StringBuilder
            )
            .replace(
              new RegExp('{{Ansvarlig prosjekterende}}', 'g'),
              StringBuilder
            );
        } else {
          mergeText = text
            .replace(
              new RegExp('{{Responsible for project planning}}', 'g'),
              ''
            )
            .replace(new RegExp('{{Ansvarlig prosjekterende}}', 'g'), '');
        }
        break;
      case 'Responsible for execution':
      case '{{Ansvarlig for utførelse}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Responsible for execution}}', 'g'),
              StringBuilder
            )
            .replace(
              new RegExp('{{Ansvarlig for utførelse}}', 'g'),
              StringBuilder
            );
        } else {
          mergeText = text
            .replace(new RegExp('{{Responsible for execution}}', 'g'), '')
            .replace(new RegExp('{{Ansvarlig for utførelse}}', 'g'), '');
        }
        break;
      case '{{Verneombud}}':
      case '{{Safety representatives}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Safety representatives}}', 'g'),
              StringBuilder
            )
            .replace(new RegExp('{{Verneombud}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Safety representatives}}', 'g'), '')
            .replace(new RegExp('{{Verneombud}}', 'g'), '');
        }
        break;
      case '{{Velg ansvarlig for uavhengig kontroll}}':
      case '{{Responsible for independent control}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Responsible for independent control}}', 'g'),
              StringBuilder
            )
            .replace(
              new RegExp('{{Velg ansvarlig for uavhengig kontroll}}', 'g'),
              StringBuilder
            );
        } else {
          mergeText = text
            .replace(
              new RegExp('{{Responsible for independent control}}', 'g'),
              ''
            )
            .replace(
              new RegExp('{{Velg ansvarlig for uavhengig kontroll}}', 'g'),
              ''
            );
        }
        break;
      case '{{Byggeleder}}':
      case '{{Construction manager}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Construction manager}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Byggeleder}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Construction manager}}', 'g'), '')
            .replace(new RegExp('{{Byggeleder}}', 'g'), '');
        }
        break;
      case '{{Koordinator Prosjektering}}':
      case '{{Coordinator projecting}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace('{{Coordinator projecting}}', StringBuilder)
            .replace(
              new RegExp('{{Koordinator Prosjektering}}', 'g'),
              StringBuilder
            );
        } else {
          mergeText = text
            .replace(new RegExp('{{Coordinator projecting}}', 'g'), '')
            .replace(new RegExp('{{Koordinator Prosjektering}}', 'g'), '');
        }
        break;
      case '{{Koordinator Utførelsen}}':
      case '{{Coordinator execution}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Coordinator execution}}', 'g'),
              StringBuilder
            )
            .replace(
              new RegExp('{{Koordinator Utførelsen}}', 'g'),
              StringBuilder
            );
        } else {
          mergeText = text
            .replace(new RegExp('{{Coordinator execution}}', 'g'), '')
            .replace(new RegExp('{{Koordinator Utførelsen}}', 'g'), '');
        }
        break;
      case '{{Prosjektbeskrivelse}}':
      case '{{Project description}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Project description}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Prosjektbeskrivelse}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Project description}}', 'g'), '')
            .replace(new RegExp('{{Prosjektbeskrivelse}}', 'g'), '');
        }
        break;
      case '{{Start date}}':
      case '{{Startdato}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Start date}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Startdato}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Start date}}', 'g'), '')
            .replace(new RegExp('{{Startdato}}', 'g'), '');
        }
        break;
      case '{{Expected to end}}':
      case '{{Forventet sluttdato}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Expected to end}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Forventet sluttdato}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Expected to end}}', 'g'), '')
            .replace(new RegExp('{{Forventet sluttdato}}', 'g'), '');
        }
        break;
      case '{{Status}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text.replace(
            new RegExp('{{Status}}', 'g'),
            StringBuilder
          );
        } else {
          mergeText = text.replace(new RegExp('{{Status}}', 'g'), '');
        }
        break;
      case '{{Builder}}':
      case '{{Byggherre}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Builder}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Byggherre}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Builder}}', 'g'), '')
            .replace(new RegExp('{{Byggherre}}', 'g'), '');
        }
        break;
      case '{{Address}}':
      case '{{Adresse}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Address}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Adresse}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Address}}', 'g'), '')
            .replace(new RegExp('{{Adresse}}', 'g'), '');
        }
        break;
      case '{{Zip code}}':
      case '{{Postnummer}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Zip code}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Postnummer}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Zip code}}', 'g'), '')
            .replace(new RegExp('{{Postnummer}}', 'g'), '');
        }
        break;
      case '{{Our areas of responsibility}}':
      case '{{Våre ansvarsområder}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Our areas of responsibility}}', 'g'),
              StringBuilder
            )
            .replace(new RegExp('{{Våre ansvarsområder}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Our areas of responsibility}}', 'g'), '')
            .replace(new RegExp('{{Våre ansvarsområder}}', 'g'), '');
        }
        break;
      case '{{Property number}}':
      case '{{Bnr}}':
        mergeText = text
          .replace(new RegExp('{{Property number}}', 'g'), '')
          .replace(new RegExp('{{Bnr}}', 'g'), '');
        break;
      case '{{Ground number}}':
      case '{{Gnr}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Ground number}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Gnr}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Ground number}}', 'g'), '')
            .replace(new RegExp('{{Gnr}}', 'g'), '');
        }
        break;
      case '{{Project cost}}':
      case '{{Prosjektkostnader}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Project cost}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Prosjektkostnader}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Project cost}}', 'g'), '')
            .replace(new RegExp('{{Prosjektkostnader}}', 'g'), '');
        }
        break;
      case '{{Project number}}':
      case '{{Prosjekt nummer}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Project number}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Prosjekt nummer}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Project number}}', 'g'), '')
            .replace(new RegExp('{{Prosjekt nummer}}', 'g'), '');
        }
        break;
      case '{{Professional manager}}':
      case '{{Fagansvarlig}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Professional manager}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Fagansvarlig}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Professional manager}}', 'g'), '')
            .replace(new RegExp('{{Fagansvarlig}}', 'g'), '');
        }
        break;
      case '{{Responsible for application}}':
      case '{{Ansvarlig søker}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Responsible for application}}', 'g'),
              StringBuilder
            )
            .replace(new RegExp('{{Ansvarlig søker}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Responsible for application}}', 'g'), '')
            .replace(new RegExp('{{Ansvarlig søker}}', 'g'), '');
        }
        break;
      case '{{Responsible for project planning}}':
      case '{{Ansvarlig prosjekterende}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Responsible for project planning}}', 'g'),
              StringBuilder
            )
            .replace(
              new RegExp('{{Ansvarlig prosjekterende}}', 'g'),
              StringBuilder
            );
        } else {
          mergeText = text
            .replace(
              new RegExp('{{Responsible for project planning}}', 'g'),
              ''
            )
            .replace(new RegExp('{{Ansvarlig prosjekterende}}', 'g'), '');
        }
        break;
      case '{{Responsible for execution}}':
      case '{{Ansvarlig for utførelse}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Responsible for execution}}', 'g'),
              StringBuilder
            )
            .replace(
              new RegExp('{{Ansvarlig for utførelse}}', 'g'),
              StringBuilder
            );
        } else {
          mergeText = text
            .replace(new RegExp('{{Responsible for execution}}', 'g'), '')
            .replace(new RegExp('{{Ansvarlig for utførelse}}', 'g'), '');
        }
        break;
      case '{{Safety representatives}}':
      case '{{Verneombud}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Safety representatives}}', 'g'),
              StringBuilder
            )
            .replace(new RegExp('{{Verneombud}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Safety representatives}}', 'g'), '')
            .replace(new RegExp('{{Verneombud}}', 'g'), '');
        }
        break;
      case '{{Responsible for independent control}}':
      case '{{Velg ansvarlig for uavhengig kontroll}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Responsible for independent control}}', 'g'),
              StringBuilder
            )
            .replace(
              new RegExp('{{Velg ansvarlig for uavhengig kontroll}}', 'g'),
              StringBuilder
            );
        } else {
          mergeText = text
            .replace(
              new RegExp('{{Responsible for independent control}}', 'g'),
              ''
            )
            .replace(
              new RegExp('{{Velg ansvarlig for uavhengig kontroll}}', 'g'),
              ''
            );
        }
        break;
      case '{{Construction manager}}':
      case '{{Byggeleder}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Construction manager}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Byggeleder}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Construction manager}}', 'g'), '')
            .replace(new RegExp('{{Byggeleder}}', 'g'), '');
        }
        break;
      case '{{Coordinator projecting}}':
      case '{{Koordinator Prosjektering}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Coordinator projecting}}', 'g'),
              StringBuilder
            )
            .replace(
              new RegExp('{{Koordinator Prosjektering}}', 'g'),
              StringBuilder
            );
        } else {
          mergeText = text
            .replace(new RegExp('{{Coordinator projecting}}', 'g'), '')
            .replace(new RegExp('{{Koordinator Prosjektering}}', 'g'), '');
        }
        break;
      case '{{Coordinator execution}}':
      case '{{Koordinator Utførelsen}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(
              new RegExp('{{Coordinator execution}}', 'g'),
              StringBuilder
            )
            .replace(
              new RegExp('{{Koordinator Utførelsen}}', 'g'),
              StringBuilder
            );
        } else {
          mergeText = text
            .replace(new RegExp('{{Coordinator execution}}', 'g'), '')
            .replace(new RegExp('{{Koordinator Utførelsen}}', 'g'), '');
        }
        break;
      case '{{Project description}}':
      case '{{Prosjektbeskrivelse}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Project description}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Prosjektbeskrivelse}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Project description}}', 'g'), '')
            .replace(new RegExp('{{Prosjektbeskrivelse}}', 'g'), '');
        }
        break;
      case '{{Project manager}}':
      case '{{Prosjektansvarlig}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{Project manager}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Prosjektansvarlig}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{Project manager}}', 'g'), '')
            .replace(new RegExp('{{Prosjektansvarlig}}', 'g'), '');
        }
        break;
      case '{{City}}':
      case '{{Poststed}}':
        if (this.projectId !== 0) {
          StringBuilder.append(this.getProjectInfoHtml(id));
          mergeText = text
            .replace(new RegExp('{{City}}', 'g'), StringBuilder)
            .replace(new RegExp('{{Poststed}}', 'g'), StringBuilder);
        } else {
          mergeText = text
            .replace(new RegExp('{{City}}', 'g'), '')
            .replace(new RegExp('{{Poststed}}', 'g'), '');
        }
        break;
      default:
        mergeText = text.replace(key, '');
        break;
    }

    return mergeText;
  }
  // region  mergefields
  getMergeKeyInfoHtml(key: string) {
    this.mergeField = this.mergeFieldData.filter(
      (item: any) => item.id === 1
    )[0];
    let sb = new StringBuilder();
    sb.append(
      '<table class="table table-striped table-bordered"><tr><td>' +
        key.replace('{{', '').replace('}}', '') +
        '</td><td>' +
        ':  ' +
        this.mergeField.data +
        '</td></tr>' +
        '</table></br>'
    );
    return sb;
  }
  getMergeKeyFactInfoHtml(key: string, businessId: number) {
    this.mergeField = this.mergeFieldData.filter(
      (item: any) => item.id === 2
    )[0];
    let obj =
      this.mergeField.data !== '' ? JSON.parse(this.mergeField.data) : null;
    let sb1 = new StringBuilder();
    sb1.append('');
    if (businessId !== 0 && obj !== null) {
      sb1.append(
        '<h4><strong>' +
          this.translator.instant('FACTS_ABOUT_COMPANY') +
          '</strong></h4>'
      );
      sb1.append(
        '<table class="table table-striped table-bordered">' +
          '<tr><th>' +
          this.translator.instant('ORGANISATION_NUMBER') +
          '</th><th>' +
          this.getTranslationForYesNo(obj[0].Value) +
          '</th></tr>' +
          '<tr><th>' +
          this.translator.instant('BUSINESS_NAME') +
          '</th><td>' +
          obj[1].Value +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('NUM_EMPLOYEES') +
          '</th><td>' +
          obj[2].Value +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('ADDRESS') +
          '</th><td>' +
          obj[3].Value +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('CITY_ZIP') +
          '</th><td>' +
          obj[4].Value +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('CATEGORY') +
          '</th><td>' +
          obj[6].Value +
          '</td> </tr>' +
          '<tr><th>' +
          this.translator.instant('TELEPHONE') +
          '</th><td>' +
          obj[7].Value +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('EMAIL') +
          '</th><td>' +
          obj[8].Value +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('INSURANCE_COMPANY') +
          '</th><td>' +
          obj[9].Value +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('HOMEPAGE_ADDRESS') +
          '</th><td>' +
          obj[10].Value +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('HSE_MANAGER') +
          '</th><td>' +
          this.getTranslationForYesNo(obj[11].Value) +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('MAIN_SAFETY_DELEGATE') +
          '</th><td>' +
          this.getTranslationForYesNo(obj[12].Value) +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('SAFETY_DELEGATE') +
          '</th><td>' +
          this.getTranslationForYesNo(obj[13].Value) +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('EMPLOYEE_DELEGATE') +
          '</th><td >' +
          this.getTranslationForYesNo(obj[14].Value) +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('FIRE_DELEGATE') +
          '</th><td >' +
          this.getTranslationForYesNo(obj[15].Value) +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('ACCOUNTANT') +
          '</th><td >' +
          this.getTranslationForYesNo(obj[16].Value) +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('AUDITOR') +
          '</th><td>' +
          this.getTranslationForYesNo(obj[17].Value) +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('WORKMEN_COMP_INSURANCE') +
          '</th><td >' +
          this.getTranslationForYesNo(obj[18].Value) +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('OTP_STATUTORY_INSURANCE') +
          '</th><td >' +
          this.getTranslationForYesNo(obj[19].Value) +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('HEALTH_CARE') +
          '</th><td >' +
          this.getTranslationForYesNo(obj[20].Value) +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('HAVE_PERSONAL_DELEGATE') +
          '</th><td >' +
          this.getTranslationForYesNo(obj[21].Value) +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('ENVIRONMENTAL_LIGHTHOUSE_RESPONSIBLE') +
          '</th><td >' +
          this.getTranslationForYesNo(obj[23].Value) +
          '</td></tr>' +
          '<tr><th>' +
          this.translator.instant('HAVE_EMPLOYEEMENT_AGREEMENT') +
          '</th><td >' +
          this.getTranslationForYesNo(obj[22].Value) +
          '</td></tr>' +
          '</table></br>'
      );
    }
    return sb1;
  }

  getMergeKeyUserInfoHtml(key: string, businessId: number) {
    this.mergeField = this.mergeFieldData.filter(
      (item: any) => item.id === 3
    )[0];
    let obj = JSON.parse(this.mergeField.data);
    let sb1 = new StringBuilder();
    sb1.append('');
    if (businessId !== 0) {
      sb1.append(
        '<h4><strong>' + this.translator.instant('USER_LIST') + '</strong></h4>'
      );
      sb1.append(
        '<table class="table table-striped table-bordered"><tr><th>' +
          this.translator.instant('USER_NAME') +
          '</th>' +
          '<th>' +
          this.translator.instant('ADDITIONAL_ROLE') +
          '</th>' +
          '<th>' +
          this.translator.instant('ACCOUNT_OWNER') +
          '</th>' +
          '<th>' +
          this.translator.instant('MOBILE') +
          '</th>' +
          '<th>' +
          this.translator.instant('EMAIL') +
          '</th>' +
          '</tr>'
      );
      let length = obj.length;
      for (let i = 1; i < length; i++) {
        sb1.append(
          '<tr><td>' +
            obj[i].UserName +
            '</td>' +
            '<td>' +
            this.translator.instant(obj[i].AdditionalRole.toUpperCase()) +
            '</td>' +
            '<td>' +
            obj[i].AccountOwner +
            '</td>' +
            '<td>' +
            obj[i].Mobile +
            '</td>' +
            '<td>' +
            obj[i].EmailId +
            '</td>' +
            '</tr>'
        );
      }
      sb1.append('</table></br>');
    }
    return sb1;
  }

  getMergeKeyContactInfoHtml(key: string, businessId: number) {
    this.mergeField = this.mergeFieldData.filter(
      (item: any) => item.id === 4
    )[0];
    let obj = JSON.parse(this.mergeField.data);
    let count = Object.keys(obj).length;
    let sb = new StringBuilder();
    sb.append('');
    if (businessId !== 0) {
      sb.append(
        '<h4><strong>' +
          this.translator.instant('CONTACT_LIST') +
          '</strong></h4>'
      );
      sb.append(
        '<table class="table table-striped table-bordered"><tr><td>' +
          this.translator.instant('NAME') +
          '</td>' +
          '<td>' +
          this.translator.instant('FUNCTION') +
          '</td>' +
          '<td>' +
          this.translator.instant('MOBILE') +
          '</td>' +
          '<td>' +
          this.translator.instant('EmailId') +
          '</td>' +
          '</tr>'
      );
      for (let i = 1; i < count; i++) {
        sb.append(
          '<tr><td>' +
            obj[i].Name +
            '</td>' +
            '<td>' +
            obj[i].Function +
            '</td>' +
            '<td>' +
            obj[i].CellularPhone +
            '</td>' +
            '<td>' +
            obj[i].EmailId +
            '</td>' +
            '</tr>'
        );
      }
      sb.append('</table></br>');
    }
    return sb;
  }

  getMergeKeyPropertyListInfoHtml(key: string, businessId: number) {
    this.mergeField = this.mergeFieldData.filter(
      (item: any) => item.id === 5
    )[0];
    let obj = JSON.parse(this.mergeField.data);
    let objData = obj.BuildingMassDataList;
    let sbuilder = new StringBuilder();
    sbuilder.append('');
    if (objData !== null) {
      if (businessId !== 0) {
        let count = Object.keys(objData).length;
        sbuilder.append(
          '<p>' +
            this.translator.instant('LIST_OF_PROPERTY/BUILDING_MASS') +
            '</p>'
        );
        for (let i = 0; i < count; i++) {
          let data = objData[i].buildingMassData;
          let BulCount = i + 1;
          sbuilder.append(
            '<p>' +
              this.translator.instant('BUILDING_DETAILS') +
              '' +
              BulCount +
              '</p>'
          );
          sbuilder.append(
            '<table class="table table-striped table-bordered">' +
              '<tr><td>' +
              this.translator.instant('TYPE_OF_OBJECT') +
              '</td><td>' +
              data[0].Value +
              '</td>' +
              '<td>' +
              this.translator.instant('NAME_OF_THE_OBJECT') +
              '</td><td>' +
              data[5].Value +
              '</td>' +
              '<td>' +
              this.translator.instant('NUM_APARTMENTS') +
              '</td><td>' +
              data[11].Value +
              '</td>' +
              '<td>' +
              this.translator.instant('NUM_FLOOR') +
              '</td><td>' +
              data[17].Value +
              '</td>' +
              '</tr>' +
              '<tr><td>' +
              this.translator.instant('COMMON_ATTICS') +
              '</td><td>' +
              this.getTranslationForYesNo(data[1].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('COMMON_BASEMENT') +
              '</td><td>' +
              this.getTranslationForYesNo(data[6].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('COMMON_RECOVERY') +
              '</td><td>' +
              this.getTranslationForYesNo(data[12].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('GARAGE') +
              '</td><td>' +
              this.getTranslationForYesNo(data[18].Value) +
              '</td>' +
              '</tr>' +
              '<tr><td>' +
              this.translator.instant('SEPARATE_LAUNDRY_ROOM') +
              '</td><td>' +
              this.getTranslationForYesNo(data[12].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('FIRE_ALARM') +
              '</td><td>' +
              this.getTranslationForYesNo(data[7].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('SPRINKLERS') +
              '</td><td>' +
              this.getTranslationForYesNo(data[13].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('VENTILATION') +
              '</td><td>' +
              this.getTranslationForYesNo(data[7].Value) +
              '</td>' +
              '</tr>' +
              '<tr><td>' +
              this.translator.instant('SEPARATE_BOILER_ROOM') +
              '</td><td>' +
              this.getTranslationForYesNo(data[3].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('BURIED_OIL_TANK') +
              '</td><td>' +
              this.getTranslationForYesNo(data[8].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('COMMON_WATER_SOLUTION') +
              '</td><td>' +
              this.getTranslationForYesNo(data[14].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('SPECIAL_FIRE_OBJECT') +
              '</td><td>' +
              this.getTranslationForYesNo(data[20].Value) +
              '</td>' +
              '</tr>' +
              '<tr><td>' +
              this.translator.instant('ELEVATOR') +
              '</td><td>' +
              this.getTranslationForYesNo(data[4].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('VACUUM_WASTE') +
              '</td><td>' +
              this.getTranslationForYesNo(data[9].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('STORE_ROOM') +
              '</td><td>' +
              this.getTranslationForYesNo(data[15].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('GARAGE') +
              '</td><td>' +
              this.getTranslationForYesNo(data[18].Value) +
              '</td>' +
              '</tr>' +
              '<tr><td>' +
              this.translator.instant('DISTRICT_HEATING_SYSTEM') +
              '</td><td>' +
              this.getTranslationForYesNo(data[23].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('SHELTERS') +
              '</td><td>' +
              this.getTranslationForYesNo(data[10].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('COMMON_ATTICS') +
              '</td><td>' +
              this.getTranslationForYesNo(data[23].Value) +
              '</td>' +
              '<td>' +
              this.translator.instant('QUANTITY') +
              '</td><td>' +
              data[23].Value +
              '</td>' +
              '</tr>'
          );

          sbuilder.append('</table></br>');
        }
      }
    }
    return sbuilder;
  }

  getTranslationForYesNo(val: string) {
    let splitval = val.split('-');
    let firstsplitval = val.indexOf('-');
    let lastsplitval = val.substring(firstsplitval + 1);
    // let firstsplitval = val.substring(0,splitval);
    if (splitval[0] == 'Yes ') {
      return this.translator.instant('SWITCH_YES') + ' - ' + lastsplitval;
    } else if (splitval[0] === '' || splitval[0] === 'Yes') {
      return this.translator.instant('SWITCH_YES');
    } else if (splitval[0] === '' || splitval[0] === 'No') {
      return this.translator.instant('SWITCH_NO');
    } else if (splitval[0] === '') {
    } else {
      return val;
    }
  }

  getMergeKeySocialAspectsInfoHtml(key: string, businessId: number) {
    this.mergeField = this.mergeFieldData.filter(
      (item: any) => item.id === 6
    )[0];
    let obj = JSON.parse(this.mergeField.data);
    let count = Object.keys(obj).length;
    let sb = new StringBuilder();
    sb.append('');
    if (businessId !== 0) {
      sb.append(
        '<h4><strong>' +
          this.translator.instant('LIST_OF_ALL_SOCIAL_ASPECTS') +
          '</strong></h4>'
      );
      sb.append(
        '<table class="table table-striped table-bordered"><tr><td>' +
          this.translator.instant('TITLE') +
          '</td>' +
          '<td>' +
          this.translator.instant('DESCRIPTION') +
          '</td>' +
          '<td>' +
          this.translator.instant('APPLICABLE') +
          '</td>' +
          '</tr>'
      );
      for (let i = 1; i < count; i++) {
        sb.append(
          '<tr><td>' +
            obj[i].Type +
            '</td>' +
            '<td>' +
            obj[i].Description +
            '</td>' +
            '<td>' +
            obj[i].Applicable +
            '</td>' +
            '</tr>'
        );
      }
      sb.append('</table></br>');
    }
    return sb;
  }

  getMergeKeyEquipmentsInfoHtml(key: string, businessId: number) {
    let sb = new StringBuilder();
    sb.append('');
    this.mergeField = this.mergeFieldData.filter(
      (item: any) => item.id === 7
    )[0];
    if (this.mergeField !== undefined && this.mergeField.data !== '') {
      let obj = JSON.parse(this.mergeField.data);
      let count = Object.keys(obj).length;
      if (businessId !== 0) {
        sb.append(
          '<h4><strong> ' +
            this.translator.instant('LIST_OF_EQUIPMENTS') +
            '</strong></h4>'
        );
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('TYPE_OF_EQUIPMENT') +
            '</td>' +
            '<td>' +
            this.translator.instant('EQUIPMENT') +
            '</td>' +
            '</tr>'
        );
        for (let i = 1; i < count; i++) {
          sb.append(
            '<tr><td>' +
              obj[i].TypeOfEquipment +
              '</td>' +
              '<td>' +
              obj[i].Equipment +
              '</td>' +
              '</tr>'
          );
        }
        sb.append('</table></br>');
      }
    }
    return sb;
  }

  getMergeKeyActivitiesInfoHtml(key: string, businessId: number) {
    let sb = new StringBuilder();
    sb.append('');
    if (businessId !== 0) {
      this.mergeField = this.mergeFieldData.filter(
        (item: any) => item.id === 8
      )[0];
      let obj = JSON.parse(this.mergeField.data);
      let count = Object.keys(obj).length;
      sb.append(
        '<h4><strong> ' +
          this.translator.instant('LIST_OF_ACTIVITIES') +
          '</strong></h4 > '
      );
      sb.append(
        '<table class="table table-striped table-bordered"><tr><td>' +
          this.translator.instant('TITLE') +
          '</td>' +
          '<td>' +
          this.translator.instant('DESCRIPTION') +
          '</td>' +
          '<td>' +
          this.translator.instant('DEADLINE') +
          '</td>' +
          '<td>' +
          this.translator.instant('REPETITION') +
          '</td>' +
          '<td>' +
          this.translator.instant('RESPONSIBLE_FOR_EXECUTION') +
          '</td>' +
          '<td>' +
          this.translator.instant('RESPONSIBLE_FOR_FOLLOW_UP') +
          '</td>' +
          '</tr>'
      );
      for (let i = 1; i < count; i++) {
        sb.append(
          '<tr><td>' +
            obj[i].Title +
            '</td>' +
            '<td>' +
            obj[i].Description +
            '</td>' +
            '<td>' +
            obj[i].Deadline +
            '</td>' +
            '<td>' +
            obj[i].Repetition +
            '</td>' +
            '<td>' +
            obj[i].ResponsibleForExecution +
            '</td>' +
            '<td>' +
            obj[i].ResponsibleForFollowUp +
            '</td>' +
            '</tr>'
        );
      }
      sb.append('</table></br>');
    }
    return sb;
  }

  getMergeKeyEmployeesInfoHtml(key: string, businessId: number) {
    let sb = new StringBuilder();
    sb.append('');
    if (businessId !== 0) {
      this.mergeField = this.mergeFieldData.filter(
        (item: any) => item.id === 9
      )[0];
      let obj = JSON.parse(this.mergeField.data);
      let count = Object.keys(obj).length;
      sb.append(
        '<h4><strong>' +
          this.translator.instant('EMPLOYEE_LIST') +
          '</strong></h4>'
      );
      sb.append(
        '<table class="table table-striped table-bordered"><tr><td>' +
          this.translator.instant('NAME') +
          '</td>' +
          '<td>' +
          this.translator.instant('POSITION') +
          '</td>' +
          '<td>' +
          this.translator.instant('DEPARTMENT') +
          '</td>' +
          '<td>' +
          this.translator.instant('MOBILE') +
          '</td>' +
          '<td>' +
          this.translator.instant('EMAIL') +
          '</td>' +
          '</tr>'
      );
      for (let i = 1; i < count; i++) {
        sb.append(
          '<tr><td>' +
            obj[i].Name +
            '</td>' +
            '<td>' +
            obj[i].Position +
            '</td>' +
            '<td>' +
            obj[i].Department +
            '</td>' +
            '<td>' +
            obj[i].CellularPhone +
            '</td>' +
            '<td>' +
            obj[i].EmailId +
            '</td>' +
            '</tr>'
        );
      }
      sb.append('</table></br>');
    }
    return sb;
  }
  getProjectInfoHtml(id: number) {
    let sb = new StringBuilder();
    sb.append('');
    switch (id) {
      case 10:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 10
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('PROJ_NAME') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 11:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 11
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('START_DATE') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 12:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 12
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('EXPECTED_END') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 13:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 13
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('STATUS') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 14:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 14
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('BUILDER') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 15:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 15
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('ADDRESS') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 16:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 16
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('ZIP_CODE') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 17:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 17
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('AREA_OF_RESPONSIBILITY') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 18:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 18
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('CITY') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 19:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 19
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('PROP_NUMBER') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 20:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 20
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.mergeField.name +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 21:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 21
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('GROUND_NUMBER') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 22:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 22
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('PROJ_COST') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 23:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 23
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('PROJ_NUMBER') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 24:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 24
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('SUB_CONTRACTOR') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 25:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 25
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('PROF_MANAGER') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 26:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 26
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('RESPONSE_APPLN') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 27:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 27
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('RESPONSE_PROJ_PLAN') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 28:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 28
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('RESPONSIBLE_FOR_EXECUTION') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 29:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 29
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('SAFETY_REP') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 30:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 30
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('RESPONSE_INPNT_CTRL') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 31:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 31
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('CONSTRUCTION_MGR') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 32:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 32
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('COORDINATOR_PROJECTING') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 33:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 33
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('COORDINATOR_EXECUTION') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 34:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 34
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('PROJECT_DESCRIPTION') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      case 35:
        this.mergeField = this.mergeFieldData.filter(
          (item: any) => item.id === 35
        )[0];
        sb.append(
          '<table class="table table-striped table-bordered"><tr><td>' +
            this.translator.instant('PROJ_MANAGER') +
            '</td><td>' +
            ':  ' +
            this.mergeField.data +
            '</td></tr>' +
            '</table></br>'
        );
        break;
      default:
        break;
    }
    this.mergeField = this.mergeFieldData.filter(
      (item: any) => item.id === 1
    )[0];

    return sb;
  }

  getMergeKeysubContractorInfoHtml(key: string, businessId: number) {
    this.mergeField = this.mergeFieldData.filter(
      (item: any) => item.id === 2
    )[0];
    let sb1 = new StringBuilder();
    sb1.append('');
    if (businessId !== 0) {
    }
  }
  // endregion

  /**
   * get current date
   */
  getCurrentDate(date = null) {
    let dtObj, dt;

    if (date == null) {
      dtObj = new Date();
      dt = {
        date: {
          year: dtObj.getFullYear(),
          month: dtObj.getMonth() + 1,
          day: dtObj.getDate(),
        },
        formatted: `${dtObj.getDate()}/${
          dtObj.getMonth() + 1
        }/${dtObj.getFullYear()}`,
        jsDate: dtObj,
        jsdate: dtObj,
      };
    } else {
      dtObj = new Date(date);
      dt = {
        date: {
          year: dtObj.getFullYear(),
          month: dtObj.getMonth() + 1,
          day: dtObj.getDate(),
        },
      };
    }

    this.displayDate = dtObj.toLocaleDateString('en-GB');

    return dt;
  }

  onDateChanged(event: IMyDateModel) {
    this.articleSrc.article.articleDate = event.date;
    this.articleSrc.article.formattedDate = `${event.date.day}/${event.date.month}/${event.date.year}`;
    this.editedDate = `${event.date.day}/${event.date.month}/${event.date.year}`;
  }

  /**
   * upload for article signature
   * @param selectedFiles
   */
  filesSelectForSignature(selectedFiles: any) {
    this.showSave = false;
    this.uploadStartForSignature = true;
    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(
      (file) => file
    );

    let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);

    let session = JSON.parse(sessionStorage.getItem('session'));
    let token = session.AccessToken;
    var headers = {
      Authorization: 'Bearer ' + token, // Add JWT token to headers
      // Add any other headers if needed
    };
    this.subscriptions.push(
      this.http
        .post(
          environment.BASE_URL +
            '/file/upload/article/signature/' +
            BaseServices.ApplicationId +
            '/' +
            this.businessId,
          formData,
          { headers: headers }
        )
        .map((response: any) => {
          this.uploadStartForSignature = false;
          let res = response;
          this.articleSrc.article.signaturePath = res.Result.path;
          this.loading = false;
        })
        .subscribe(
          (result) => {
            this.showSave = true;
          },
          (error) => console.log('Upload article Sign Error : ', error),
          () => console.log('Upload article  Sign Complete')
        )
    );
  }
  exportArticlePdf() {
    this.loading = true;
    let cloneArticleSrc = _.clone(this.articleSrc);
    if (this.articleSrc !== undefined) {
      this.articleSrc.article.articleDate = null;
    }
    let type = 'pdf';
    let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
    let languageId = parseInt(sessionStorage.getItem('languageId')!);
    let CultureInfo = 'en-GB';
    switch (languageId) {
      case 1:
        CultureInfo = 'en-GB';
        break;
      case 2:
        CultureInfo = 'no-NO';
        break;
      case 3:
        CultureInfo = 'sv-SE';
        break;
      case 4:
        CultureInfo = 'pl-PL';
        break;
    }

    this.subscriptions.push(
      this.exportService
        .generateArticleFile(
          HelperService.switchKeysCase(cloneArticleSrc, null, 'U'),
          this.businessId,
          CultureInfo,
          this.portalId,
          this.projectId
        )
        .subscribe(
          (blob: any) => {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${this.articleName}.${type.toLowerCase()}`;
            link.click();
            this.loading = false;
          },
          (error: any) => {
            alert('Export not downloaded');
            this.loading = false;
          }
        )
    );
  }

  openImageExplorer($event: any) {}

  insert_name(event: any) {}

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
