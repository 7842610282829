import { HelperService } from './../../../_services/helper.service';
//#region import
import { environment } from 'src/environments/environment';
import { UploaderConfig } from './../../../shared/ecpl-document-explorer/uploader-config';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { SelectItem } from 'primeng/api';
import { Options } from './../../../_models/options';
import {
  FrameworkUsers,
  FrameworkClientUsers,
} from './../models/FrameworkEdit';
import { User } from './../models/user';
import { BaseServices } from './../../kuba.services';
import {
  FrameworkAgreement,
  BusinessProperty,
} from 'src/app/kuba/framework-agreement/models/index';
import { FrameworkAgreementServices } from './../services/framework.service';
import { TranslateService } from '@ngx-translate/core';

import {
  UploadOutput,
  UploadInput,
  UploadFile,
  humanizeBytes,
  UploaderOptions,
  UploadStatus,
} from 'ngx-uploader';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
//#endregion

@Component({
  selector: 'framework-form',
  templateUrl: 'framework-form.component.html',
})
export class FrameworkFormComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  contractorName: string;
  frameworkForm: FormGroup;
  contracterId: number;
  frameWorkUsers: User[];
  clients: SelectItem[];
  frameworkClientUsers: FrameworkClientUsers[];
  selectedUser: any[];
  selectedClient: any[];
  frameworkList: SelectItem[];
  businessPropertyId: number;
  options = [
    new Options(7, 'New'),
    new Options(8, 'Open'),
    new Options(9, 'Done'),
    new Options(10, 'Rejected'),
  ];
  upConfig: UploaderConfig;
  uploadedFiles = [];
  additionalData: any;
  newDocumentAdded: any = [];
  isCopyFolder = false;
  IsHidden = false;
  uploadInput: EventEmitter<UploadInput>;
  isFileChoosed = false;
  files: UploadFile[];
  dragOver: boolean;
  frameworkAgreementImg: string;
  public selectedFiles;
  uploadStart: boolean;
  bindAllUsers = false;
  bindSelectedUsers = true;
  selectedUsers: any = [];
  userSubscribers: SelectItem[];
  selectedSubscribers: SelectItem[];
  emailUsers: any[];
  userId: number;
  FAUsers: any[];
  emailSubscriber = [];
  selectedEmailSubscriber = [];
  selectAllEmailSubscriber = [];
  checkedAll: boolean;
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private frameworkServices: FrameworkAgreementServices,
    private fb: FormBuilder,
    private location: Location,
    private http: HttpClient,
    private translate: TranslateService
  ) {
    this.uploadInput = new EventEmitter<UploadInput>();
    this.frameworkForm = this.fb.group({
      ContractNumber: ['', Validators.required],
      ContractName: ['', Validators.required],
      Address: [''],
      Zip: [''],
      City: [''],
      Status: ['7'],
      ClientId: [],
      Comments: [''],
      ContractImage: [],
      Client: [],
      ChooseProject: [],
      CopySJA: [''],
      ContactPerson: [[]],
      frameworkUser: [[]],
      frameworkClientUsers: [[]],
      selectedEmailSubscriber: [''],
      selectAllEmailSubscriber: [''],
    });
    this.userId = BaseServices.UserId;
  }

  ngOnInit(): void {
    document.querySelector('body').classList.remove('opened');
    this.contracterId = this.route.snapshot.params['fId'];
    this.bindFormDropdowns();
    let res = this.route.snapshot.data['edit'];
    if (res) {
      this.initForm(res);
    } else {
      this.initForm();
    }
    this.additionalData = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      ParentId: +this.contracterId ? +this.contracterId : 0,
      Status: '1',
      CreatedOn: new Date(),
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: new Date(),
    };
    this.upConfig = {
      title: this.translate.instant('FRAMEWORK_AGREEMENT'),
      titleAsLabel: true,
      editMode: true,
      windowedHeight: false,
      viewSwitch: false,
      showUserMeta: false,
      showSearchFilter: false,
      showUploadButton: true,
      uploaderUri: environment.BASE_URL + '/framework/file-upload',
      addtionalData: this.additionalData,
    };
  }

  /**
   * file upload events
   * @param event {any}
   */
  saveUploaded(event: any) {
    if (event) {
      let eventType = event.type;
      switch (eventType) {
        case 'remove':
          this.uploadedFiles = this.uploadedFiles.filter(
            (x) => x.id !== +event.id
          );
          break;
        default:
          let keyBindings = {
            Originalname: 'OriginalFileName',
            FileTypeId: 'FileType',
          };
          let serviceDoc = Object.assign({}, event, this.additionalData);
          serviceDoc.id = 0;
          serviceDoc.createdBy = BaseServices.UserId;
          this.newDocumentAdded.push(
            HelperService.switchKeysCase(serviceDoc, keyBindings)
          );
          break;
      }
    }
  }
  onClientChanged(event, clientDropdown) {
    this.getContactByClientId(event.value);
  }

  getContactByClientId(clientId: number) {
    if (!clientId) {
      clientId = 0;
    }
    this.subscriptions.push(
      this.frameworkServices
        .getActiveClientContactUser(clientId)
        .subscribe((result: any) => {
          if (result) {
            this.frameworkClientUsers = [];
            result.forEach((element) => {
              let user = new FrameworkClientUsers();

              user.Name = element.Name;
              user.ClientUserId = element.ClientUserId;
              user.ClientId = element.ClientId;
              user.FrameworkId = this.contracterId ? this.contracterId : 0;
              user.Id = element.ClientUserId;

              this.frameworkClientUsers.push(user);
            });
          }
        })
    );
  }
  /**
   * save and create new button click event
   */
  saveAndCreateNew() {
    this.saveChanges();
    this.clear();
  }
  filesSelect(selectedFiles: any): void {
    this.uploadStart = true;
    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(
      (file) => file
    );
    let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);
    let session = JSON.parse(sessionStorage.getItem('session'));
    let token = session.AccessToken;
    var headers = {
      Authorization: 'Bearer ' + token, // Add JWT token to headers
      // Add any other headers if needed
    };

    this.subscriptions.push(
      this.http
        .post(
          environment.BASE_URL +
            '/framework/file/upload/FrameworkAgreement-image/' +
            BaseServices.ApplicationId +
            '/' +
            0,
          formData,
          { headers: headers }
        )
        .map((response: any) => {
          this.uploadStart = false;
          let res = response;
          this.frameworkAgreementImg = res.path;
        })
        .subscribe(
          (result) => {},
          (error) => console.log('Upload article Sign Error : ', error),
          () => console.log('Upload article  Sign Complete')
        )
    );
  }

  saveChanges() {
    let userId = BaseServices.UserId;
    let name = BaseServices.userName;
    let checkUserId = this.selectedUser.filter((x) => x.Id == userId);
    if (checkUserId.length === 0) {
      this.selectedUser.push({ Id: userId, Name: name });
    }
    if (this.frameworkForm.valid) {
      let framework = new FrameworkAgreement();
      framework.Id = this.contracterId ? this.contracterId : 0;
      framework.ContractNumber = this.frameworkForm.value.ContractNumber;
      framework.ContractName = this.frameworkForm.value.ContractName;
      framework.ClientId = this.frameworkForm.value.ClientId;
      framework.ContractImage = this.frameworkAgreementImg;
      // tslint:disable-next-line:radix
      if (this.frameworkForm.value.Status === '') {
        framework.Status = '2';
      } else {
        framework.Status = this.frameworkForm.value.Status;
      }
      framework.CreatedOn = new Date();
      framework.CreatedBy = BaseServices.UserId;
      framework.BusinessId = BaseServices.BusinessId;
      framework.Comments = this.frameworkForm.value.Comments;
      framework.ShowDeviation =
        !this.frameworkForm.value.IsShowDeviationChecked;
      framework.FrameworkAgreementDocument = this.newDocumentAdded;
      let businessProperty = new BusinessProperty();
      businessProperty.Id = this.businessPropertyId
        ? this.businessPropertyId
        : 0;
      businessProperty.Address = this.frameworkForm.value.Address
        ? this.frameworkForm.value.Address
        : '';
      businessProperty.City = this.frameworkForm.value.City;
      businessProperty.Zip = this.frameworkForm.value.Zip;
      businessProperty.CreatedOn = new Date();
      businessProperty.CreatedBy = BaseServices.UserId;
      businessProperty.BusinessId = BaseServices.BusinessId;
      framework.BusinessPropertyDto = businessProperty;
      if (
        this.bindSelectedUsers &&
        this.frameworkForm.value.selectedEmailSubscriber
      ) {
        this.emailSubscriber = this.frameworkForm.value.selectedEmailSubscriber;
      }
      if (
        this.bindAllUsers &&
        this.frameworkForm.value.selectAllEmailSubscriber
      ) {
        this.emailSubscriber =
          this.frameworkForm.value.selectAllEmailSubscriber;
      }
      framework.FrameworkUsers = [];
      if (this.selectedUser) {
        // tslint:disable-next-line:no-shadowed-variable
        this.selectedUser.forEach((element) => {
          let user = new FrameworkUsers();
          user.FrameworkId = this.contracterId ? this.contracterId : 0;
          user.Name = element.Name ? element.Name : '';
          user.UserId = element.Id;
          user.IsEmail = this.emailSubscriber.find((x) => x.Id == element.Id)
            ? true
            : false;
          framework.FrameworkUsers.push(user);
        });
      }

      framework.FrameworkClientUsers = [];
      if (this.selectedClient) {
        // tslint:disable-next-line:no-shadowed-variable
        this.selectedClient.forEach((element) => {
          let user = new FrameworkClientUsers();
          user.FrameworkId = this.contracterId ? this.contracterId : 0;
          user.Name = element.Name;
          user.ClientId = element.ClientId;
          user.ClientUserId = element.Id;
          user.ClientId = element.ClientId;
          framework.FrameworkClientUsers.push(user);
        });
      } else if (framework.ClientId) {
        let client = new FrameworkClientUsers();
        client.FrameworkId = this.contracterId ? this.contracterId : 0;
        client.ClientId = framework.ClientId;
        framework.FrameworkClientUsers.push(client);
      }

      if (framework.Id > 0) {
        framework.ModifiedBy = BaseServices.UserId;
        this.frameworkServices.update(framework).subscribe((result) => {
          this.toasterComponent.callToast();
        });
      } else {
        this.subscriptions.push(
          this.frameworkServices.create(framework).subscribe((result) => {
            if (result) {
              this.toasterComponent.callToast();
            }
            this.gotoBack();
          })
        );
      }
    }
  }
  /**
   * clear form data
   */
  clear() {
    this.initForm();
    this.contracterId = 0;
    this.uploadedFiles = [];
  }

  initForm(data?: any) {
    let contractNumber = '';
    let ContractName = '';
    let address = '';
    let city = '';
    let zip = '';
    let Comments = '';
    let chooseProject = '';
    let client = '';
    let selectedClient = [];
    let selectedUser = [];
    let status = '';
    let copySJA = '';
    let IsShowDeviationChecked = false;
    let selectedEmailSubscriber = this.selectedEmailSubscriber;
    let selectAllEmailSubscriber = this.selectAllEmailSubscriber;
    if (data) {
      if (data.FrameworkAgreementDocument) {
        data.FrameworkAgreementDocument.forEach((element) => {
          this.uploadedFiles.push(
            HelperService.switchKeysCase(element, null, 'L')
          );
        });
      }
      ContractName = data.ContractName;
      contractNumber = data.ContractNumber;
      this.frameworkAgreementImg = data.ContractImage;
      address = data.BusinessPropertyDto
        ? data.BusinessPropertyDto.Address
        : '';
      city = data.BusinessPropertyDto ? data.BusinessPropertyDto.City : '';
      zip = data.BusinessPropertyDto ? data.BusinessPropertyDto.Zip : '';
      this.businessPropertyId = data.BusinessPropertyId;
      Comments = data.Comments;
      IsShowDeviationChecked = !data.ShowDeviation;
      client = data.ClientId;
      this.getContactByClientId(+client);
      status = data.Status.toString();
      copySJA = data.CopySJA;
      this.selectedUser = [];
      if (data.FrameworkUsers) {
        // tslint:disable-next-line:no-shadowed-variable
        data.FrameworkUsers.forEach((element) => {
          let selectedUsers = this.FAUsers.find((x) => x.Id === element.UserId);
          if (selectedUsers) {
            selectedUsers.status = true;
            selectedUsers.isEmail = false;
            this.checked(selectedUsers);
            if (element.IsEmail) {
              this.selectedEmailSubscriber.push({ Id: element.UserId });
              this.selectAllEmailSubscriber.push({ Id: element.UserId });
            }
          }
        });
        this.frameworkForm.patchValue({
          selectedEmailSubscriber: this.selectedEmailSubscriber,
        });
      }
      this.selectedClient = [];
      if (data.FrameworkClientUsers) {
        // tslint:disable-next-line:no-shadowed-variable
        data.FrameworkClientUsers.forEach((element) => {
          this.selectedClient.push({
            Id: element.ClientUserId,
            Name: element.Name,
            CCId: element.Id,
            ClientId: element.ClientId,
          });
        });
      }

      this.frameworkForm = this.fb.group({
        ContractNumber: new FormControl(contractNumber),
        ContractName: new FormControl(ContractName),
        Address: new FormControl(address),
        City: new FormControl(city),
        Zip: new FormControl(zip),
        Comments: new FormControl(Comments),
        // ChooseProject: new FormControl(ChooseProject),
        ClientId: new FormControl(client),
        Status: new FormControl(status),
        IsShowDeviationChecked: new FormControl(IsShowDeviationChecked),
        selectedEmailSubscriber: new FormControl(selectedEmailSubscriber),
        selectAllEmailSubscriber: new FormControl(selectAllEmailSubscriber),

        // CopySJA: new FormControl(CopySJA)
      });
    } else {
      this.frameworkForm = this.fb.group({
        ContractNumber: new FormControl(contractNumber),
        ContractName: new FormControl(ContractName),
        Address: new FormControl(address),
        City: new FormControl(city),
        Zip: new FormControl(zip),
        Comments: new FormControl(Comments),
        // ChooseProject: new FormControl(ChooseProject),
        ClientId: new FormControl(client),
        Status: new FormControl('7'),
        IsShowDeviationChecked: new FormControl(IsShowDeviationChecked),
        selectedEmailSubscriber: new FormControl(selectedEmailSubscriber),
        selectAllEmailSubscriber: new FormControl(selectAllEmailSubscriber),
        // CopySJA: new FormControl(CopySJA)
      });
    }
  }
  bindFormDropdowns() {
    this.selectedSubscribers = [];
    this.userSubscribers = [];
    let userSubscription = this.route.snapshot.data['users_subscription'];
    this.emailUsers = userSubscription;
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.userSubscribers = [];
        if (userSubscription) {
          userSubscription.forEach((element: any) => {
            this.selectedSubscribers.push({
              label: element.Name,
              value: { Id: element.Id },
            });
            if (element.Id == BaseServices.UserId) {
              this.userSubscribers.push({
                label: element.Name,
                value: { Id: element.Id },
              });
            }
          });
        }
      })
    );
    let users = this.route.snapshot.data['users'];
    if (users) {
      this.frameWorkUsers = users;
      this.FAUsers = users;
    }
    this.selectedUser = [];
    this.selectedClient = [];

    this.frameworkList = [];
    let frameworkList = this.route.snapshot.data['frameworkList'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.frameworkList = [];
        this.frameworkList.push({ label: val.SELECT, value: null });
      })
    );
    if (frameworkList) {
      // tslint:disable-next-line:no-shadowed-variable
      frameworkList.forEach((element) => {
        this.frameworkList.push({
          label: element.ContractName,
          value: element.Id,
        });
      });
    }

    this.clients = [];
    let Clients = this.route.snapshot.data['clients'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.clients = [];
        this.clients.push({ label: val.SELECT, value: null });
      })
    );
    if (Clients) {
      // tslint:disable-next-line:no-shadowed-variable
      Clients.forEach((element) => {
        this.clients.push({ label: element.Name, value: element.Id });
      });
    }
  }

  /**
   * upload event
   */
  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url:
        'http://localhost:53145/api/kuba/v1/framework/file/upload/FrameworkAgreement-image/' +
        BaseServices.ApplicationId +
        '/' +
        0,
      method: 'POST',
      data: { foo: 'bar' },
    };
    this.uploadInput.emit(event);
  }

  /**
   * on upload event
   * @param output
   */
  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
      this.isFileChoosed = true;
      this.files.push(output.file);
    } else if (
      output.type === 'uploading' &&
      typeof output.file !== 'undefined'
    ) {
      const index = this.files.findIndex(
        (file) =>
          typeof output.file !== 'undefined' && file.id === output.file.id
      );
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      this.files = this.files.filter(
        (file: UploadFile) => file !== output.file
      );
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'done') {
      this.frameworkAgreementImg = output.file.response.Result.path;
      this.files.push(output.file);
    } else if (output.type === 'rejected' && typeof output.file !== 'undefined')
      this.files = this.files.filter(
        (file) => file.progress.status !== UploadStatus.Done
      );
  }

  /**
   * mobile number validation
   * @param e {any}
   * @param limitNumber {any}
   */
  onContractNumber(e: any, limitNumber: any) {
    const limit = limitNumber;
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      (charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      e.preventDefault();
    }
  }

  gotoBack() {
    this.location.back();
  }

  checked(value) {
    if (value.status) {
      this.selectedUser.push(value);
      if (this.emailUsers) {
        let selectedUsers = this.emailUsers.find((x) => x.Id === value.Id);
        this.emailUsers.forEach((element: any) => {
          if (
            element.Id === value.Id &&
            value.Id !== BaseServices.UserId &&
            !value.isEmail
          ) {
            this.userSubscribers.push({
              label: element.Name,
              value: { Id: element.Id },
            });
          }
        });
      }
    } else {
      _.remove(this.selectedUser, (val) => {
        if (this.userSubscribers) {
          _.forEach(this.userSubscribers, (item: any) => {
            if (item.value.Id == value.Id) {
              this.userSubscribers.pop();
            }
          });
        }
        return val === value;
      });
    }
  }

  checkAll(event) {
    _.forEach(this.frameWorkUsers, (item: any) => {
      if (event) {
        item.status = true;
      } else {
        ``;
        item.status =
          // to remain checked state for current user
          this.userId === +item.Id ? true : false;
      }
    });

    this.selectedUser = this.frameWorkUsers;
    if (!event) {
      let userId = BaseServices.UserId;
      let name = BaseServices.Name;
      let checkUserId = this.selectedUser.filter((x) => x.Id == userId);
      if (checkUserId.length > 0) {
        this.userSubscribers = [];
        this.userSubscribers.push({ label: name, value: { Id: userId } });
      }
      this.selectedUser = [];
      this.bindAllUsers = false;
      this.bindSelectedUsers = true;
    } else {
      this.bindAllUsers = true;
      this.bindSelectedUsers = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
