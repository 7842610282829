import { PortalActivitiesComponent } from './components/activity-portal.component';
import { 
    EmployeeContactUserResolver, 
    DeviationUserListResolver, 
    UserRoleResolver, 
    EmployeeContactConvenerResolver 
} from './../users/services/user.resolver.service';
import { MessageToViewComponent } from './components/message-to-view.component';
import { MessageViewComponent } from './components/message-view.component';
import {
    MessageListResolver,
    OutBoxMessageResolver,
    MessageViewResolver,
    MessageUserListResolver,
    MessageInboxUserListResolver,
} from './services/message.resolver.service';
import { MessagenewComponent } from './components/message-new.component';
import { MessageOutboxComponent } from './components/message-outbox.component';
import { MessageInboxComponent } from './components/message-inbox.component';
import { FollowupMessagingComponent } from './components/messaging.component';
import { EmployeeDocResolver } from './../employees/services/employee.resolver.service';
import {
    RiskAnalysisTopicCachedResolver,
    RiskAnalysisDepartmentResolver,
    RiskAnalysisEditResolver,
    RiskAnalysisListResolver,
    BusinessesListResolver,
    ApprovalNoticesResolver,
    RiskAnalysisLogResolver,
    RiskAnalysisAdminResolver,
    RiskAttachmentResolver,
    RiskAnalysisTopicForAdminResolver,
    FollowupDepartmentForAdminResolver,
    UserForAdminResolver,
    RiskAnalysisCategoryResolver
} from './services/risk-analysis.resolver.service';
import { 
    BusinessListResolver, 
    CompanyNameResolver, 
    BusinessCacheResolver, 
    BusinessUserEditResolver, 
    RiskListResolver 
} from './../businesses/services/business.resolver.service';
import {
    EmployeeContactsListResolver,
    FollowupCaseTypeResolver,
    AllDepartmentResolver,
    AllDepartmentCachedResolver,
    ResponsibleForExecutionResolver,
    ClientResolver,
    BusinessResolver,
    DeviationsByUserResolver,
    DeviationUserResolver,
    AllDepartmentPortalResolver
} from './services/deviation.resolver.service';
import {
    DeviationEditResolver,
    DeviationListResolver,
    DepartmentForAdminResolver
} from '../deviation/services/deviation.resolver';
import { EditDeviationComponent } from '../deviation/components/deviation-edit/deviation-edit.component';
import {
    ConsumptionListResolver,
    ConsumptionsEditResolver,
    ConsumptionTypeResolver,
    UnitOfConsumptionResolver
} from './services/consumptions.resolver.service';3
import { NotesEditResolver } from './../notes/services/notes.resolver.service';
import { DetailsComponent } from './../notes/components/user-signin/details.component';
import { AddDeviationComponent } from './components/deviation-new.component';
import { SuggestionListResolver } from './services/suggestion.resolver.service';
import {
    MeetingListResolver,
    MeetingEditResolver,
    MeetingTypeResolver,
    MeetingUserResolver
} from './services/meeting.resolver.service';
import {
    ActivityListResolver,
    ActivityEditResolver,
    ResponsiblePersonListResolver,
    RepetionListResolver,
    ActivtyListByUserResolver,
    DepartmentCachedListResolver,
    ChecklistResolver,
    ActivityUserForAdminResolver,
    UserListResolver,
    RepetitionResolver,
    BusinessPortalContactsResolver,
    ActiveBusinessResolver
} from './services/activities.resolver.service';
import { AuthGuard } from './../../_guards/auth.guard';
import { ActivityListComponent } from './components/activity-list.component';

import { Route } from '@angular/router';

import { FollowUpsComponent } from './followups.component';
import {
    FollowUpMeetingComponent,
    FollowUpNoteComponent,
    FollowUpSuggestionListComponent,
    FollowUpActivitiesComponent,
    FollowUpDeviationComponent,
    MeetingListComponent,
    EditActivitiesComponent,
    NewRiskAnalysisComponent,
    RiskAnalysisComponent,
    RiskAnalysisListComponent,
    DeviationListComponent,
    NewMeetingComponent,
    SuggestionComponent,
    NewSuggestionComponent,
    ConsumptionListComponent,
    NewConsumptionComponent,
    ConsumptionsComponent,
    NewRiskPortalComponent,
    RuhNewComponent,
    RuhListComponent,
    RuhComponent,
    RuhEditComponent
} from './components/index';
import { ArchiveTreeDataResolver } from '../archive/archive.resolver.service';
import { resolve } from 'path';
import { CountryCodeResolver } from './../client/services/client.resolver.service';
import { RuhCaseTypeResolver, RuhTopicResolver, ActionPlanResolver } from './services/ruh.resolver.service';
import { SjaFormComponent } from '../sja-form/sja-form.component';
import { SjaListComponent } from '../sja-form/components/sja-list.component';
import { SJAFormListResolver, SJAFormResolver, SJATitleListResolver } from '../sja-form/sjaform.resolver.service';
import { SjaUserformComponent } from '../sja-form/components/sja-userform.component';


export const followupRoutes: Route[] = [
    {
        path: 'follow-up',
        component: FollowUpsComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'messaging', redirectTo: 'messaging', pathMatch: 'full' },
            {
                path: 'messaging',
                component: FollowupMessagingComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: '', redirectTo: 'inbox', pathMatch: 'full' },
                    {
                        path: 'inbox',
                        component: MessageInboxComponent,
                        resolve: {
                            message: MessageListResolver,
                        }
                    },
                    {
                        path: 'outbox',
                        component: MessageOutboxComponent,
                        resolve: {
                            message: OutBoxMessageResolver,
                        }
                    }
                ]
            },
            {
                path: 'new',
                component: MessagenewComponent,
                resolve: {
                    list: MessageUserListResolver,
                }
            },
            {
                path: 'view/:Id',
                component: MessageViewComponent,
                canActivate: [AuthGuard],
                resolve: {
                    message: MessageViewResolver
                }
            },
            {
                path: 'toview/:Id',
                component: MessageToViewComponent,
                canActivate: [AuthGuard],
                resolve: {
                    message: MessageViewResolver
                }
            },

            {
                path: 'meetings',
                component: FollowUpMeetingComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: MeetingListComponent,
                        resolve: {
                            list: MeetingListResolver,
                            business: CompanyNameResolver,
                        }
                    },
                    {
                        path: 'new/:mid',
                        component: NewMeetingComponent,
                        resolve: {
                            detail: MeetingEditResolver,
                            type: MeetingTypeResolver,
                            business: CompanyNameResolver,
                            meetinguser: EmployeeContactUserResolver,
                            meetingconvener: EmployeeContactConvenerResolver,
                            doclist: EmployeeDocResolver
                        }
                    }
                ]
            },
            {
                path: 'consumptions',
                component: ConsumptionsComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: ConsumptionListComponent,
                        resolve: {
                            list: ConsumptionListResolver,
                            consumptionType: ConsumptionTypeResolver,
                            business: CompanyNameResolver
                        }
                    },
                    {
                        path: 'new/:cid',
                        component: NewConsumptionComponent,
                        resolve: {
                            list: ConsumptionListResolver,
                            detail: ConsumptionsEditResolver,
                            consumptionType: ConsumptionTypeResolver,
                            unitOfConsumption: UnitOfConsumptionResolver
                        }
                    }
                ]
            },
            {
                path: 'notes',
                component: DetailsComponent,
                resolve: {
                    business: CompanyNameResolver
                }
            },
            {
                path: 'RUH',
                component: RuhComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: RuhListComponent,
                        resolve: {
                            caseType: RuhCaseTypeResolver,
                            department: AllDepartmentCachedResolver,
                            user: EmployeeContactUserResolver,
                        }
                    },
                    {
                        path: 'edit/:ruhid',
                        component: RuhEditComponent,
                        data: {
                            parent: 'RUH'
                        },
                        resolve: {
                            userrole: UserRoleResolver,
                            countryCode: CountryCodeResolver,
                            department: AllDepartmentCachedResolver,
                            caseType: RuhCaseTypeResolver,
                            user: EmployeeContactUserResolver,
                            actionPlanList: ActionPlanResolver,
                        }
                    },

                    {
                        path: 'new',
                        component: RuhNewComponent,
                        resolve: {
                            caseType: RuhCaseTypeResolver,
                            department: AllDepartmentCachedResolver,
                            user: EmployeeContactUserResolver,
                            userrole: UserRoleResolver,
                        }
                    }
                ]
            },


            {
                path: 'suggestions',
                component: SuggestionComponent,
                resolve: {
                    list: SuggestionListResolver
                },
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: FollowUpSuggestionListComponent,
                        resolve: {
                            list: SuggestionListResolver
                        }
                    },
                    {
                        path: 'new/:sid',
                        component: NewSuggestionComponent
                    }
                ]
            },
            {
                path: 'activities',
                component: FollowUpActivitiesComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: ActivityListComponent,
                        resolve: {
                            list: ActivityListResolver,
                            listbyuser: ActivtyListByUserResolver,
                            repetition: RepetionListResolver,
                            user: EmployeeContactUserResolver,
                            users: UserListResolver,
                            portalContacts: BusinessPortalContactsResolver,
                            business: CompanyNameResolver,
                            departments: AllDepartmentResolver
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'edit/:aid',
                        component: EditActivitiesComponent,
                        resolve: {
                            detail: ActivityEditResolver,
                            departments: DepartmentCachedListResolver,
                            repetition: RepetionListResolver,
                            Checklist: ChecklistResolver,
                            user: EmployeeContactUserResolver,

                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'portalactivity',
                        component: PortalActivitiesComponent,
                        resolve: {
                            repetition: RepetionListResolver,
                            Checklist: ChecklistResolver,
                            business: CompanyNameResolver
                        },
                        canActivate: [AuthGuard]
                    }
                ]
            },

            {
                path: 'deviations',
                component: FollowUpDeviationComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: DeviationListComponent,
                        resolve: {
                            client: ClientResolver,
                            business: CompanyNameResolver,
                            user: EmployeeContactUserResolver,
                            topic: RiskAnalysisTopicCachedResolver,
                            caseType: FollowupCaseTypeResolver,
                            deviationByUser: DeviationsByUserResolver,
                            department: AllDepartmentCachedResolver,
                            deviationuser: DeviationUserResolver,
                            departmentPortal: AllDepartmentPortalResolver,
                            departments: AllDepartmentResolver
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'new',
                        component: AddDeviationComponent,
                        data: {
                            parent: 'FOLLOWUP'
                        },
                        resolve: {
                            topic: RiskAnalysisTopicCachedResolver,
                            caseType: FollowupCaseTypeResolver,
                            department: AllDepartmentCachedResolver,
                            doclist: EmployeeDocResolver,
                            user: EmployeeContactUserResolver
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'edit/:deviationId',
                        component: EditDeviationComponent,
                        resolve: {
                            edit: DeviationEditResolver,
                            topic: RiskAnalysisTopicCachedResolver,
                            caseType: FollowupCaseTypeResolver,
                            department: AllDepartmentCachedResolver,
                            user: EmployeeContactUserResolver,
                            adminDepartment: DepartmentForAdminResolver,
                            businessUser: EmployeeContactsListResolver
                        }
                    }
                ]
            },
            {
                path: 'risk-analysis',
                component: RiskAnalysisComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: RiskAnalysisListComponent,
                        resolve: {
                            detail: RiskAnalysisEditResolver,
                            approvalNotices: ApprovalNoticesResolver,
                            list: RiskAnalysisListResolver,
                            departments: AllDepartmentResolver,
                            adminlist: RiskAnalysisAdminResolver,
                            topic: RiskAnalysisTopicCachedResolver,
                            business: CompanyNameResolver,
                            Risklist : RiskListResolver,
                            category: RiskAnalysisCategoryResolver
                          
                        }
                    },
                    {

                        path: 'newrisk',
                        component: NewRiskAnalysisComponent,
                        resolve: {
                            department: DepartmentCachedListResolver,
                            topic: RiskAnalysisTopicCachedResolver,
                            user: EmployeeContactUserResolver,
                            category: RiskAnalysisCategoryResolver,
                            business: CompanyNameResolver
                        }
                    },
                    {
                        path: 'edit-risk/:rid',
                        component: NewRiskAnalysisComponent,
                        resolve: {
                            detail: RiskAnalysisEditResolver,
                            department: DepartmentCachedListResolver,
                            topic: RiskAnalysisTopicCachedResolver,
                            user: EmployeeContactUserResolver,
                            log: RiskAnalysisLogResolver,
                            adminTopic: RiskAnalysisTopicForAdminResolver,
                            adminDepartment: FollowupDepartmentForAdminResolver,
                            usersAdmin: UserForAdminResolver,
                            category: RiskAnalysisCategoryResolver,
                            business: CompanyNameResolver
                        }
                    },
                    {
                        path: 'riskportal',
                        component: NewRiskPortalComponent,
                        resolve: {
                            business: BusinessCacheResolver,
                        }
                    }
                ]
            },
            {
                path: 'risk-analysis',
                component: RiskAnalysisComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: RiskAnalysisListComponent,
                        resolve: {
                            detail: RiskAnalysisEditResolver,
                            list: RiskAnalysisListResolver,
                            departments: AllDepartmentResolver,
                            approvalNotices: ApprovalNoticesResolver,
                            business: CompanyNameResolver,
                            Risklist : RiskListResolver,
                            category: RiskAnalysisCategoryResolver,
                           
                        }
                    },
                    {
                        path: 'newrisk',
                        component: NewRiskAnalysisComponent,
                        resolve: {
                            department: DepartmentCachedListResolver,
                            topic: RiskAnalysisTopicCachedResolver,
                            user: ResponsibleForExecutionResolver,
                            business: CompanyNameResolver
                        }
                    },
                    {
                        path: 'edit-risk/:rid',
                        component: NewRiskAnalysisComponent,
                        resolve: {
                            detail: RiskAnalysisEditResolver,
                            department: DepartmentCachedListResolver,
                            topic: RiskAnalysisTopicCachedResolver,
                            user: ResponsibleForExecutionResolver,
                            treeData: ArchiveTreeDataResolver,
                            business: CompanyNameResolver
                        }
                    },
                ]
            },
            {
                path: 'sjaform',
                component: SjaFormComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'list',
                        component: SjaListComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                            business: CompanyNameResolver,
                            list: SJAFormListResolver
                        }
                    },
                    {
                        path: 'new',
                        component: SjaUserformComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                            sjaTitleList: SJATitleListResolver
                        }
                    },
                    {
                        path: 'edit/:Id',
                        component: SjaUserformComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                            sjaform: SJAFormResolver,
                            sjaTitleList: SJATitleListResolver
                        }
                    }]
            }
        ]
    }
];
