import {
  HttpClient,
  HttpHeaderResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { BaseServices } from '../kuba/kuba.services';

import { ResetCredential } from '../kuba/users/models/user';

import { ApplicationUi, LoggedUser } from '../_models/';

@Injectable()
export class AuthenticationService {
  currentUser = new LoggedUser();
  appUi = new ApplicationUi();
  timeouts = [];
  time: any;
  device: any;
  constructor(private http: HttpClient, private router: Router) {}

  createAuthorizationHeader(headers: HttpHeaders) {
    headers.append('content-type', 'application/json');
  }

  login(username: string, password: string, IpAddress: any) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.http
      .post(
        environment.BASE_URL + '/authentication/login',
        {
          Username: username,
          Password: password,
          ipAddress: IpAddress,
          device: 'Desktop',
        },
        //{ Username: username, Password: password},
        { headers: headers }
      )
      .pipe(
        map((response: HttpHeaderResponse) => {
          const res: any = response;

          // Check if the response contains a token property
          if (res && res.AccessToken) {
            const tokenParts = res.AccessToken.split('.');

            // Check if there are at least two parts (header and payload) before removing the first character of the second part
            if (tokenParts.length >= 2) {
              const payload = tokenParts[1];
              // Remove the first character of the second part (payload)
              tokenParts[1] = payload.substring(0, 5) + payload.substring(6);
              // tokenParts[1] = tokenParts[1].substring(1);

              // Join the token parts back together with a dot separator
              const modifiedJwttoken = tokenParts.join('.');

              // Store the modified token in session storage or process it as needed
              sessionStorage.setItem(
                'session',
                JSON.stringify({ AccessToken: res.AccessToken })
              );
            } else {
              // Handle the case where the token doesn't have the expected format
              console.error('Invalid JWT token format');
            }
          } else {
            // Handle the case where the response doesn't contain a token property
            console.error('No JWT token found in the response');
          }
          return res;
        })
      );
  }

  extendSession() {
    this.timeouts.push(
      setTimeout(() => {
        let result = confirm(
          'Your session is expired. Do you wish to retain session?'
        );
        if (result === false) {
          this.logout();
        } else {
          this.refreshServerSession();
          this.extendSession();
        }
      }, 3300000)
    );
  }

  refreshServerSession() {
    return this.http
      .get(environment.BASE_URL + '/business/1', BaseServices.headerOption)
      .pipe(map((result) => result));
  }

  idleLogout() {
    this.time = sessionStorage.getItem('idleTimer');
    const timer = JSON.parse(this.time);
    if (timer && Date.now() > timer) {
      sessionStorage.clear();
      this.router.navigate(['login']);
    } else {
      const time_to_login = Date.now() + 60000; // 3600000;// one hour
      sessionStorage.setItem('idleTimer', JSON.stringify(time_to_login));
    }
  }

  forgetPassword(userCredential: any) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.http
      .put(
        environment.BASE_URL + '/authentication/forgotpassword',
        userCredential,
        { headers: headers }
      )
      .pipe(map((result) => result));
  }

  getUserIdByToken(credential: ResetCredential) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.http
      .post(
        environment.BASE_URL + '/authentication/useridbytoken',
        credential,
        { headers: headers }
      )
      .pipe(map((result) => result));
  }

  resetPassword(userCredential: any) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.http
      .put(
        environment.BASE_URL + '/authentication/reset-password',
        userCredential,
        { headers: headers }
      )
      .pipe(map((result) => result));
  }

  sendPasswordLink(userCredential: any, appId: number) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    let languageId = sessionStorage.getItem('languageId');
    languageId = languageId == null ? '2' : languageId;
    return this.http
      .post(
        environment.BASE_URL +
          '/authentication/sendpasswordlink/' +
          languageId +
          '/' +
          appId,
        userCredential,
        { headers: headers }
      )
      .pipe(map((result) => result));
  }

  getSession(token: any, type: any) {
    let headers = new HttpHeaders();
    headers.append('Authorization', type + ' ' + token);
    headers.append('content-type', 'application/json');

    return this.http
      .get<HttpHeaderResponse>(environment.BASE_URL + '/application/access', {
        headers: headers,
      })
      .pipe(map((response: HttpHeaderResponse) => (response = response)));
  }
  addAccessLog(data: any) {
    let body = JSON.stringify(data);
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/accessLog/' + body,
        BaseServices.headerOption
      )
      .pipe(
        map((response: HttpHeaderResponse) => {
          return response;
        })
      );
  }
  logout() {
    sessionStorage.clear();
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i]);
    }
    this.router.navigate(['login']);
  }

  getDecryptedLoginCredentials(encUsername: string, encPassword: string) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    let auth = {
      Username: encUsername,
      Password: encPassword,
      RememberMe: false,
    };
    return this.http
      .post<HttpHeaderResponse>(
        environment.BASE_URL + '/authentication/decrypt-logincredentials',
        auth,
        { headers: headers }
      )
      .pipe(
        map((response: HttpHeaderResponse) => {
          let res = response;
          return res;
        })
      );
  }

  getTOCstatus(username: string, password: string) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/user/gdprstatus/' + username + '/' + password,
        { headers: headers }
      )
      .pipe(
        map((response: HttpHeaderResponse) => {
          let res = response;
          return res;
        })
      );
  }
}
