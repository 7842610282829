import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FeaturesBaseServices } from './../../features/services/features-base.services';
import { Rights, FeatureKey } from './../../../_models/feature';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { EmployeeServices } from '../services/employee.services';
import { UserRole } from '../../follow-ups/models/deviation';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'employees',
  templateUrl: 'employee-list.component.html',
})
export class EmployeeListComponent implements OnInit {
  //#region variables
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChild('employee', { static: false }) employeeTable: Table;
  pdf: any;
  showNotification = false;
  isRightsEnabled = false;
  isAddNewEmployee = false;
  isPortal = false;
  isHideEmployee = false;
  employeeobj: any = [];
  statuses: SelectItem[];
  bdepartment: any = [];
  selectedValue: any;
  ShowStatus: any;
  selectedEmployee: any = [];
  addSubDept = false;
  videoUrl: string;
  safeURL: any;
  additionalData: any;
  guest = false;
  loading: boolean;
  BusinessDepartmentId: number = 0;
  RoleIdCommon: number;
  first: number = 0;
  selectedStatus: number;
  private subscriptions: Subscription[] = [];

  //#endregion

  //#region constructor
  /**
   * constructor
   * @param employeeServices {employeeServices}
   * @param router {router}
   * @param confirmationService {confirmationService}
   * @param route {route}
   * @param employeeCourseService {employeeCourseService}
   */
  constructor(
    private employeeServices: EmployeeServices,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private featuresBaseServices: FeaturesBaseServices,
    private translate: TranslateService
  ) {
    this.featuresBaseServices.setEmployeeEditModeNavigation(false);
    
    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe((val) => {
        this.additionalData = {
          fileName: val.EMPLOYEE_LIST,
          header: 'Employee list',
          businessId: BaseServices.BusinessId,
          cultureInfo: BaseServices.userCultureInfo(),
          columnNames: [
            { title: 'Employee Number', dataKey: 'Number' },
            { title: 'Name', dataKey: 'Name' },
            { title: 'Department', dataKey: 'DepartmentName' },
            { title: 'SubDepartment', dataKey: 'SubDepartment' },
            { title: 'Mobile', dataKey: 'Mobile' },
            { title: 'Email', dataKey: 'Email' },
            { title: 'Date Birth', dataKey: 'Dob' },
            { title: 'Position Name', dataKey: 'PositionName' },
            { title: 'Status', dataKey: 'StatusKey' },
            { title: 'Notification By Email', datakey: 'NotificationByEmail' },
            { title: 'Notification By SMS', datakey: 'NotificationBySMS' },
            { title: 'Show To Guest', datakey: 'ShowToGuest' },
            { title: 'Show on Dashboard', datakey: 'ShowOnDashboard' },
            { title: 'Approval', datakey: 'Approval' },
          ],
        };
      })
    );
  }
  //#endregion

  //#region page-events
  /**
   * page load event
   */
  ngOnInit(): void {
    document.querySelector('body').classList.remove('opened');
    this.RoleIdCommon = +BaseServices.roleId;
    if (BaseServices.roleId === '2') {
      this.isPortal = true;
      this.employeeobj = this.route.snapshot.data['portalList'];
    } else if (+BaseServices.roleId === UserRole.GUEST) {
      this.guest = true;
      this.employeeobj = this.route.snapshot.data['list'];
    } else if (+BaseServices.roleId === UserRole.USER) {
      this.departmentEditRights();
      this.employeeobj = this.route.snapshot.data['list'];
    } else {
      this.isPortal = false;
      this.employeeobj = this.route.snapshot.data['active'];
    }
    this.selectedValue = '1';
    this.subscriptions.push(
      this.translate.stream('SELECT_STATUS_DROP').subscribe((val) => {
        this.statuses = [];
        this.statuses.push(
          { label: val.SELECT, value: null },
          { label: val.INACTIVE, value: 2 },
          { label: val.ACTIVE, value: 1 }
        );
      })
    );
    // Default to "Active" status (value: 1) on the first load
    this.selectedStatus = 1;
    this.onAddNewEmployee();
    this.onHideEmployeeList();
  }

  ngAfterViewInit() {
    this.first =
      parseInt(sessionStorage.getItem('Pageindex')!) *
        parseInt(sessionStorage.getItem('PageRow')!) -
      parseInt(sessionStorage.getItem('PageRow')!);
  }
  paginate(event: any) {
    sessionStorage.setItem(
      'Pageindex',
      JSON.stringify(event.first / event.rows + 1)
    );
    sessionStorage.setItem('PageRow', JSON.stringify(event.rows));
  }
  //#region methods
  /**
   * get employee by business
   */
  getEmployee() {
    this.subscriptions.push(
      this.employeeServices
        .list(BaseServices.BusinessId)
        .subscribe((result) => {
          this.employeeobj = result;
        })
    );
  }

  /**
   * bind status dropdown
   */
  refreshStatusDropDown() {
    this.selectedValue = null;
    this.subscriptions.push(
      this.translate.stream('SELECT_STATUS_DROP').subscribe((val) => {
        this.statuses = [];
        this.statuses.push(
          { label: val.SELECT, value: null },
          { label: val.INACTIVE, value: 2 },
          { label: val.ACTIVE, value: 1 }
        );
      })
    );
  }
  //#region performance improvement

  //#endregion

  /**
   * employee multi delete
   * @param selectedEmployee
   */
  deleteEmployee(selectedEmployee: any) {
    let employeeIds: any = [];
    this.selectedEmployee.forEach((element: any) => {
      employeeIds.push(element.Id);
    });
    if (employeeIds.length > 0) {
      this.confirmationService.confirm({
        message: this.translate.instant('DELETE_THIS_RECORD'),
        accept: () => {
          this.subscriptions.push(
            this.employeeServices
              .deleteMultipleEmployee(employeeIds)
              .subscribe(() => {
                this.selectedEmployee = [];
                this.toasterComponent.callToastDlt();
                this.refreshStatusDropDown();
                this.subscriptions.push(
                  this.employeeServices
                    .list(BaseServices.BusinessId)
                    .subscribe((result) => {
                      this.employeeobj = result;
                    })
                );
              })
          );
        },
      });
    } else {
      this.showNotification = true;
    }
  }

  /**
   * edit status
   * @param Id
   * @param Status
   */
  editStatus(Id: any, Status: string) {
    let currentStatus = Status === 'ACTIVE' ? 1 : 2;
    if (currentStatus == 1) {
      this.confirmationService.confirm({
        message: this.translate.instant('EDIT_INACTIVE_THIS_RECORD'),
        accept: () => {
          this.loading = true;
          this.subscriptions.push(
            this.employeeServices
              .statusUpdate(Id, currentStatus)
              .subscribe(() => {
                this.getEmployee();
                this.loading = false;
              })
          );
        },
      });
    } else {
      this.confirmationService.confirm({
        message: this.translate.instant('EDIT_ACTIVE_THIS_RECORD'),
        accept: () => {
          this.loading = true;
          this.subscriptions.push(
            this.employeeServices
              .statusUpdate(Id, currentStatus)
              .subscribe(() => {
                this.getEmployee();
                this.loading = false;
              })
          );
        },
      });
    }
  }

  //#endregion

  //#region control-events
  /**
   * department filter
   * @param e
   * @param departmentDropdown
   */
  onDepartmentChanged(e: any, departmentDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.employeeTable.filter(
        departmentDropdown.selectedOption.label,
        'DepartmentName',
        'equals'
      );
    } else {
      this.employeeTable.reset();
    }
  }

  /**
   * status dropdown change event
   * @param e {any}
   * @param statusDropdown {any}
   */
  onStatusChanged(e: any, statusDropdown: any) {
    if (BaseServices.roleId === '2') {
      this.isPortal = true;
      this.employeeobj = this.route.snapshot.data['portalList'];
    } else {
      this.employeeobj = this.route.snapshot.data['list'];
    }
    if (e.value) {
      // custom filter datatable
      this.employeeTable.filter(
        statusDropdown.selectedOption.value,
        'Status',
        'equals'
      );
    } else {
      this.employeeTable.reset();
    }
  }

  /**
   * hide/show new employee
   */
  onAddNewEmployee() {
    let userRightsId = Rights.ADD_NEW_EMPLOYEE;
    if (+BaseServices.roleId === UserRole.USER) {
      this.isAddNewEmployee = BaseServices.checkUserRights(
        userRightsId,
        BaseServices.UserRole
      );
    } else if (+BaseServices.roleId === UserRole.GUEST) {
      this.isAddNewEmployee = false;
    } else {
      this.isAddNewEmployee = true;
    }
  }
  /**
   * hide/show employee list
   */
  onHideEmployeeList() {
    let userRightsId = Rights.HIDE_EMPLOYEE_LIST;
    let role = BaseServices.UserRole;
    if (role === 'User' || role === 'Guest') {
      this.isHideEmployee = BaseServices.checkUserRights(userRightsId, role);
      if (this.isHideEmployee === true) {
        this.isRightsEnabled = true;
      }
    } else {
      this.isHideEmployee = false;
    }
  }

  departmentEditRights() {
    let userRightsId = Rights.EDIT_DEPARTMENT_EMPLOYEE_RIGHTS;
    let role = BaseServices.UserRole;
    if (role === 'User') {
      let dataaaaaa = BaseServices.checkUserRights(userRightsId, role);
      this.BusinessDepartmentId = BaseServices.BusDepartment;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }

  //   Profile image display:
  employee = {
    Avatar: '/assets/icons/default_profile.jpg', // default profile image
  };

  /**
   * Checks if the given URL has a valid image extension.
   * @param url - The URL to check.
   * @returns `true` if the URL has a valid image extension, otherwise `false`.
   * Used to display the default profile image if the user has not uploaded a profile image.
   */
  isValidImageExtension(url: string): boolean {
    const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
    const urlExtension = url.split('.').pop()?.toLowerCase();
    return validExtensions.includes(urlExtension || '');
  }
  //#endregion
}
