<div class="tab-content">
  <div class="page-title">
    <span translate>{{newTimeTable | translate}}</span>
  </div>
  <form class="form" [formGroup]="EditTimetableForm">
    <div *ngIf="isEditTimeTable&&!isHideStatus" class="form-group">
      <label for="lblEmployeeName" class="col-form-label">
        <span translate>STATUS</span>
      </label>
      <div>
        <div class="radio-inline" *ngFor="let option of options">
          <label>
            <input type="radio" formControlName="Status" value="{{option.id}}"
              [checked]="option.id === EditTimetableForm.controls['Status'].value">
            {{option.name.toUpperCase() | translate}}
          </label>
        </div>
      </div>
    </div>
    <timetable-form *ngIf="EditTimetableForm" [TimetableForm]="EditTimetableForm"></timetable-form>
    <div class="action-btns-wrapper text-left">
      <div class="row">
        <div class="col-sm-6">
          <button type="button" class="btn btn-outline-secondary" (click)="gotoList()">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
          </button>
          <button type="button" class="btn btn-outline-secondary" (click)="clearTimetableControls()" *ngIf="!isGuest">
            <span translate>CLEAR</span>
          </button>
          <button class="btn btn-primary" type="submit" (click)="saveChanges()" *ngIf="!isGuest"
            [disabled]="!EditTimetableForm.valid || userAccess">
            <span translate>SAVE_CHANGES</span>
          </button>
        </div>
      </div>
    </div>
  </form>

  <div class="table-view">
    <!-- Table starts -->
    <p-table *ngIf="isVisibleTimetable" #timetableTableEdit [value]="EmployeeTimeTable" [rows]="10" [paginator]="true"
      [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
      [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="filter-controls-wrapper" style="display:none">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="department" class="col-form-label">
                    <span translate>FROM_DATE</span>
                  </label>
                  <div>
                    <my-date-picker name="FromDate" [options]="dateOptions"
                      placeholder="{{'ENTER_THE_START_DATE'|translate}}"
                      (dateChanged)="onTimeTableStartDateChanged($event)"></my-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="department" class="col-form-label">
                    <span translate>END_DATE</span>
                  </label>
                  <div>
                    <my-date-picker name="enddate" [options]="dateOptions"
                      (dateChanged)="onTimeTableEndDateChanged($event)" placeholder="{{'ENTER_STOP_DATE'|translate}}">
                    </my-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-options">
            <div class="pull-right">
              <div class="action-btns-group">
                <button class="btn p-3 filter-toggle">
                  <span class="icon ic-sm icon-filter"></span>
                  <span translate>FILTER</span>
                </button>
              </div>
              <div class="action-btns-group">
                <kuba-export [reportData]="timetableTableEdit.filteredValue || timetableTableEdit.value"
                  [additionalData]="additionalData"></kuba-export>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="ProjectWorkTypeName">{{'TYPE_OF_WORK'|translate}} <p-sortIcon field="ProjectWorkTypeName"></p-sortIcon></th>
          <th pSortableColumn="ProjectName" *ngIf="isProject">{{'ORIGIN'|translate}} <p-sortIcon field="ProjectName"></p-sortIcon></th>
          <th pSortableColumn="EmployeeName">{{'NAME' | translate}} <p-sortIcon field="EmployeeName"></p-sortIcon></th>
          <th pSortableColumn="Comments">{{'COMMENTS'|translate}} <p-sortIcon field="Comments"></p-sortIcon></th>
          <th pSortableColumn="StartDate">{{'START_DATE'|translate}} <p-sortIcon field="StartDate"></p-sortIcon></th>
          <th pSortableColumn="EndDate">{{'END_DATE'|translate}} <p-sortIcon field="EndDate"></p-sortIcon></th>
          <th pSortableColumn="StartTime">{{'START_TIME'|translate}} <p-sortIcon field="StartTime"></p-sortIcon></th>
          <th pSortableColumn="EndTime">{{'END_TIME'|translate}} <p-sortIcon field="EndTime"></p-sortIcon></th>
          <th pSortableColumn="BreakTime">{{'Break'|translate}} <p-sortIcon field="BreakTime"></p-sortIcon></th>
          <th pSortableColumn="HoursSpent">{{'TOTAL_HOURS_SPENT'|translate}} <p-sortIcon field="HoursSpent"></p-sortIcon></th>
          <th pSortableColumn="ApproverNameList">{{'APPROVAL_PERSON'|translate}} <p-sortIcon field="ApproverNameList"></p-sortIcon></th>
          <th pSortableColumn="ApprovalDate">{{'DATE_APPROVAL'|translate}} <p-sortIcon field="ApprovalDate"></p-sortIcon></th>
          <th>{{'OPTIONS'| translate}}</th>
        </tr>
        <tr>
          <th>
            <input pInputText type="text"
              (input)="timetableTableEdit.filter($any($event.target)?.value, 'ProjectWorkTypeName', 'contains')" placeholder=""
              class="p-column-filter">
          </th>
          <th *ngIf="isProject"></th>
          <th>
            <input pInputText type="text"
              (input)="timetableTableEdit.filter($any($event.target)?.value, 'EmployeeName', 'contains')" placeholder=""
              class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text"
              (input)="timetableTableEdit.filter($any($event.target)?.value, 'Comments', 'contains')" placeholder=""
              class="p-column-filter">
          </th>
          <th></th>
          <th></th>
          <th>
            <input pInputText type="text"
              (input)="timetableTableEdit.filter($any($event.target)?.value, 'StartTime', 'contains')" placeholder=""
              class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text"
              (input)="timetableTableEdit.filter($any($event.target)?.value, 'EndTime', 'contains')" placeholder=""
              class="p-column-filter">
          </th>
          <th></th>
          <th>
            <input pInputText type="text"
              (input)="timetableTableEdit.filter($any($event.target)?.value, 'HoursSpent', 'contains')" placeholder=""
              class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text"
              (input)="timetableTableEdit.filter($any($event.target)?.value, 'ApproverNameList', 'contains')" placeholder=""
              class="p-column-filter">
          </th>
          <th>
            <input pInputText type="text"
              (input)="timetableTableEdit.filter($any($event.target)?.value, 'ApprovalDate', 'contains')" placeholder=""
              class="p-column-filter">
          </th>
          <th></th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-timetable>
        <tr>
          <td>
            <span>{{timetable.ProjectWorkTypeName}}</span>
          </td>
          <td>
            <span>{{timetable.ProjectName}}</span>
          </td>
          <td>
            <span>{{timetable.EmployeeName}}</span>
          </td>
          <td>
            <span>{{timetable.Comments}}</span>
          </td>
          <td>
            <span>{{timetable.StartDate |date : 'dd/MM/yyyy'}}</span>
          </td>
          <td>
            <span>{{timetable.EndDate |date : 'dd/MM/yyyy'}}</span>
          </td>
          <td>
            <span>{{timetable.StartTime | formatHours}}</span>
          </td>
          <td>
            <span>{{timetable.EndTime | formatHours}}</span>
          </td>
          <td>
            <span>{{timetable.BreakTime}}</span>
          </td>
          <td>
            <span>{{timetable.HoursSpent}}</span>
          </td>
          <td>
            <span>{{timetable.ApproverNameList}}</span>
          </td>
          <td>
            <span>{{timetable.ApprovalDate |date : 'dd/MM/yyyy'}}</span>
          </td>
          <td class="col-button">
            <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
              (click)="editEmployeeTimeTable(timetable)">
              <i class="icon ic-sm icon-pencil-tip"></i>
            </button>
            <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
              (click)="deleteEmployeeTimeTable(timetable.Id)">
              <i class="icon ic-sm icon-trash"></i>
            </button>
          </td>
        </tr>
      </ng-template>

      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="13">
            {{'NO_RECORDS_FOUND'|translate}}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
</div>
<toaster-component></toaster-component>
