<div class="tab-content">
  <div class="page-title">
    <span translate>EMPLOYEE_LIST</span>
    <span class="page-actions pull-right" *ngIf="!isHideEmployee">
      <!--                                                                            REMOVED FEATURE AS WE DO NOT USE INFO-VIDEO -->
      <!-- <button
        (click)="viewVideo()"
        [disabled]="isVideo"
        class="btn btn-outline-secondary"
        *ngIf="!isPortal"
      >
        <span class="icon ic-sm icon-circled-play"></span>
        <span translate>INFO_VIDEO</span>
      </button>
      <button
        (click)="viewVideo()"
        [disabled]="isVideo"
        class="btn btn-outline-secondary"
        *ngIf="isPortal"
      >
        <span class="icon ic-sm icon-circled-play"></span>
        <span translate>INFO_VIDEO</span>
      </button> -->
      <a
        *ngIf="isAddNewEmployee && !isPortal"
        [routerLink]="['./../edit', 0]"
        class="btn btn-success"
      >
        <span class="icon ic-sm icon-add"></span>
        <span translate>EMPLOYEE</span>
      </a>
    </span>
  </div>
  <div class="table-view" *ngIf="!isHideEmployee">
    <!-- Table starts -->
    <p-table
      #employee
      [value]="employeeobj"
      dataKey="Id"
      [(selection)]="selectedEmployee"
      [rows]="10"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      exportFilename="employeeobj"
      (onPage)="paginate($event)"
      [(first)]="first"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="table-options">
            <div class="pull-right">
              <kuba-export
                [reportData]="employee.filteredValue || employee.value"
                [additionalData]="additionalData"
              ></kuba-export>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <!-- Checkboxes -->
          <th style="width: 55px; text-align: center">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <!-- Employee Number -->
          <th pSortableColumn="Number" width="110px">
            {{ "EMPLOYEE_NUMBER" | translate }}
            <p-sortIcon field="Number"></p-sortIcon>
          </th>
          <!-- Profile image -->
          <th *ngIf="!isPortal" style="width: 60px"></th>

          <!-- Name -->
          <th pSortableColumn="Name" style="min-width: 70px">
            {{ "NAME" | translate }} <p-sortIcon field="Name"></p-sortIcon>
          </th>
          <!-- Department -->
          <th pSortableColumn="DepartmentName" width="300px">
            {{ "DEPARTMENT" | translate }}
            <p-sortIcon field="DepartmentName"></p-sortIcon>
          </th>
          <!-- Mobile number -->
          <th pSortableColumn="Mobile" width="130px">
            {{ "MOBILE" | translate }} <p-sortIcon field="Mobile"></p-sortIcon>
          </th>
          <!-- Email -->
          <th pSortableColumn="Email" width="220px">
            {{ "EMAIL" | translate }} <p-sortIcon field="Email"></p-sortIcon>
          </th>
          <!-- Position -->
          <th pSortableColumn="PositionName" width="120px">
            {{ "POSITION" | translate }}
            <p-sortIcon field="PositionName"></p-sortIcon>
          </th>
          <!-- Percentage -->
          <th *ngIf="!isPortal" pSortableColumn="Percentage" width="95px">
            {{ "PERCENTAGE" | translate }}
          </th>
          <!-- Status -->
          <th width="85px">{{ "STATUS" | translate }}</th>
          <!-- Business name if in portal -->
          <th *ngIf="isPortal" pSortableColumn="BusinessName">
            {{ "BUSINESS_NAME" | translate }}
          </th>
        </tr>
        <tr>
          <!-- Empty filter field for Checkboxes -->
          <th></th>
          <!-- Filter field for Employee number -->
          <th>
            <input
              pInputText
              type="text"
              (input)="
                employee.filter(
                  $any($event.target)?.value,
                  'Number',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <!-- Empty filter field for Profile image -->
          <th *ngIf="!isPortal"></th>
          <!-- Filter field for Name -->
          <th>
            <input
              pInputText
              type="text"
              (input)="
                employee.filter($any($event.target)?.value, 'Name', 'contains')
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <!-- Filter field for Department -->
          <th>
            <input
              pInputText
              type="text"
              (input)="
                employee.filter(
                  $any($event.target)?.value,
                  'DepartmentName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <!-- Filter field for Mobile -->
          <th>
            <input
              pInputText
              type="text"
              (input)="
                employee.filter(
                  $any($event.target)?.value,
                  'Mobile',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <!-- Filter field for Email -->
          <th>
            <input
              pInputText
              type="text"
              (input)="
                employee.filter($any($event.target)?.value, 'Email', 'contains')
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <!-- Filter field for Position -->
          <th>
            <input
              pInputText
              type="text"
              (input)="
                employee.filter(
                  $any($event.target)?.value,
                  'PositionName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <!-- Empty filter field for Percentage -->
          <th *ngIf="!isPortal"></th>
          <!-- Filter field for Status -->
          <th>
            <p-dropdown
              [options]="statuses"
              #statusDropdown
              (onChange)="onStatusChanged($event, statusDropdown)"
              styleClass="p-column-filter"
              placeholder=""
              [filterBy]="null"
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <!-- Filter field for Business name if in portal -->
          <th *ngIf="isPortal">
            <input
              pInputText
              type="text"
              (input)="
                employee.filter(
                  $any($event.target)?.value,
                  'BusinessName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-employee>
        <!-- Checkbox -->
        <tr>
          <td style="width: 55px; text-align: center">
            <p-tableCheckbox id="selectedOrders" [value]="employee">
            </p-tableCheckbox>
          </td>

          <!-- Employee number -->
          <td>
            <span
              translate
              pTooltip="{{ employee.Number }}"
              tooltipPosition="top"
              >{{ employee.Number }}</span
            >
          </td>
          <!-- Profile image -->
          <td *ngIf="!isPortal">
            <span
              *ngIf="
                employee.Avatar != null &&
                isValidImageExtension(employee.Avatar)
              "
            >
              <a [routerLink]="['./../edit', employee.Id]">
                <img
                  [src]="employee.Avatar"
                  alt="Employee Avatar"
                  width="60"
                  height="60"
                  class="thumbnail"
                  style="
                    border-radius: 50%; /* Makes the image circular */
                    border: 2px solid #ccc; /* Adds a border around the image */
                    object-fit: cover; /* Ensures the image covers the area */
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow for a modern look */
                  "
                />
              </a>
            </span>
            <span
              *ngIf="
                employee.Avatar == null ||
                !isValidImageExtension(employee.Avatar)
              "
            >
              <a [routerLink]="['./../edit', employee.Id]">
                <img
                  src="/assets/icons/default_profile.jpg"
                  alt="Stock Profile Picture"
                  width="60"
                  height="60"
                  class=""
                  style="
                    border-radius: 50%; /* Makes the image circular */
                    border: 2px solid #ccc; /* Adds a border around the image */
                    object-fit: cover; /* Ensures the image covers the area */
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow for a modern look */
                  "
                />
              </a>
            </span>
          </td>

          <!-- Employee name -->
          <td>
            <span>
              <div
                *ngIf="
                  this.RoleIdCommon == 3
                    ? true
                    : this.RoleIdCommon == 5
                    ? employee.BusinessDepartmentId == BusinessDepartmentId
                    : this.RoleIdCommon == 4
                    ? false
                    : false;
                  else elseBlock
                "
              >
                <a [routerLink]="['./../edit', employee.Id]"
                  ><span pTooltip="{{ employee.Name }}" tooltipPosition="top">{{
                    employee.Name
                  }}</span></a
                >
              </div>
              <ng-template #elseBlock>
                <span>{{ employee.Name }}</span>
              </ng-template>
            </span>
          </td>
          <td>
            <span>{{ employee.DepartmentName }}</span>
          </td>
          <td>
            <span>{{ employee.Mobile }}</span>
          </td>
          <td>
            <span
              translate
              pTooltip="{{ employee.Email }}"
              tooltipPosition="top"
              >{{ employee.Email }}</span
            >
          </td>
          <td>
            <span>{{ employee.PositionName }}</span>
          </td>
          <td *ngIf="!isPortal">
            <span>{{ employee.Percentage }}</span>
          </td>

          <td *ngIf="guest">
            <span>{{ employee.StatusKey }}</span>
          </td>
          <td *ngIf="!guest">
            <span>
              <a
                href="javascript:void(0);"
                (click)="editStatus(employee['Id'], employee['StatusKey'])"
                routerLinkActive="active"
                >{{ employee.StatusKey | translate }}</a
              >
            </span>
          </td>

          <td *ngIf="isPortal">{{ employee.BusinessName }}</td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="9">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
      <!-- Footer -->
      <ng-template pTemplate="summary">
        <div class="ui-helper-clearfix">
          <div class="footer-data">
            <div *ngIf="!guest && !isPortal" class="text-left">
              <button
                class="btn btn-danger"
                (click)="deleteEmployee(selectedEmployee)"
              >
                <i class="icon ic-sm icon-trash"></i>
                <span translate>DELETE_CHECKED</span>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
  <p-confirmDialog
    header="{{ 'CONFIRMATION' | translate }}"
    icon="pi pi-question-circle"
    width="425"
    acceptLabel="{{ 'SWITCH_YES' | translate }}"
    rejectLabel="{{ 'SWITCH_NO' | translate }}"
  ></p-confirmDialog>
  <router-outlet></router-outlet>
</div>

<p-dialog
  header="{{ 'INFO_VIDEO' | translate }}"
  [(visible)]="isInfoVideo"
  [style]="{ width: '500px' }"
  [resizable]="false"
  [draggable]="false"
>
  <div *ngIf="safeURL">
    <iframe
      width="560"
      height="315"
      [src]="safeURL"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</p-dialog>
<p-dialog
  header="{{ 'NOTIFICATION' | translate }}"
  [(visible)]="showNotification"
  [style]="{ width: '600px' }"
  [resizable]="false"
  [draggable]="false"
>
  <span translate> SELECT_ONERECORD </span>
</p-dialog>
<p-dialog
  header="{{ 'NOTIFICATION' | translate }}"
  [(visible)]="isRightsEnabled"
  [style]="{ width: '600px' }"
  [resizable]="false"
  [draggable]="false"
>
  <span translate> DONT_HAVE_RIGHTS </span>
</p-dialog>
<toaster-component></toaster-component>
<!--Panel Loader-->
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
<!--Panel Loader-->
