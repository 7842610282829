export class InfoVideo {
    Id: number;
    FeatureId: number;
    AdditionalFeatureId: number;
    Title: string;
    Description: string;
    VideoPath: string;
    ContentData: string;
    Status: string;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    TranslateKey: string;
}
export class Menu {
    Id: number;
    Title: string;
    FeatureId: number;
}
export class SubMenu {
    Id: number;
    Title: string;
    VideoPath: string;
    FeatureId: number;
}