import { Injectable } from '@angular/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { QualitySystem } from '../models/quality-system';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class QualitySystemService {


  constructor(private http: HttpClient) {}

  // List all InternalControl
  getAll(Id: number) {
      return this.http
          .get(
              environment.BASE_URL + '/qualitysystem/getall/' + Id,
              BaseServices.headerOption
          )
          .map((result: any) => result);
  }

  list(Id: number) {
      return this.http
          .get(
              environment.BASE_URL + '/qualitysystem/getall/' + Id,
              BaseServices.headerOption
          )
          .map(result => result);
  }



  getInternalControlById(Id: number) {
      return this.http
          .get(
              environment.BASE_URL + '/qualitysystem/' + Id,
              BaseServices.headerOption
          )
          .map((result: any) => result);
  }

  add(data) {
      return this.http
          .post(
              environment.BASE_URL + '/qualitysystem/save',
              data,
              BaseServices.headerOption
          )
          .map(result => result);
  }

  update(data: QualitySystem) {
      return this.http
          .put(
              environment.BASE_URL + '/qualitysystem/update',
              data,
              BaseServices.headerOption
          )
          .map(result => result);
  }

  deleteControl(Id) {
      return this.http
          .delete(
              environment.BASE_URL + '/qualitysystem/delete/' + Id,
              BaseServices.headerOption
          )
          .toPromise()
          .then((response: Response) => {
              return response;
          });
  }

  getInternalControlFeatures(businessId: number) {
      let featureId: number;
      let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
      featureId = workingModule.id;
      return this.http
          .get(
              environment.BASE_URL + '/business/' + businessId,
              BaseServices.headerOption
          )
          .map((result:any) => {
              let feature = result.Features;
              let internalcontrolAdditionalFeature = _.find(
                  JSON.parse(feature),
                  { id: featureId, access: true }
              );
              return internalcontrolAdditionalFeature;
          });
  }

  getInternalByUser(Id: number) {
      return this.http.get(environment.BASE_URL + '/qualitysystem/getuserQS/' + Id, BaseServices.headerOption)
          .map((result: any) => result);
  }

  
}
